import { useDispatch } from "react-redux"
import { ReportAPI } from "../apis/ReportAPI";
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";
import { ApiStatus } from "../types/api/status";
import { changeStatus, onFetchDataReportAssignment,onFetchDataReportReservation, onFetchDataReportRenditionPayment, onSetFilter, onFetchDataReportRendition,  onSetOwner, onSetDepartment, onSetYear, onSetMonth, selectReportState } from "../redux/slices/reportSlice";
import { useSelector } from "../redux/store";
import { Year } from "../types/slices/yearType";

export const useReportStore = () => {
    const dispatch = useDispatch();
    const { dataReportRenditionPayment, dataReportReservation, dataReportAssignment, dataReportRendition,status, filter, year, month, department, owner} = useSelector(selectReportState)

    const getReportRenditionPay = async (data?:any) => {
        try{
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ReportAPI.getReportRenditionPay(data);
            if(!response?.status) {
                return CustomSnackbar('error', response.data.message || '');
            }
            const { detail } = response.data
            dispatch(onFetchDataReportRenditionPayment(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHING))
            console.log(error)
        }
    }

    const getReportRendition = async (data?:any) => {
        try{
            console.log(data)
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ReportAPI.getReportRendition(data);
            if(!response?.status) {
                return CustomSnackbar('error', response.data.message || '');
            }
            const { detail } = response.data
            dispatch(onFetchDataReportRendition(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHING))
            console.log(error)
        }
    }

    const getReportReservation = async (data?:any) => {
        try{
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ReportAPI.getReportReservation(data);
            if(!response?.status) {
                return CustomSnackbar('error', response.data.message || '');
            }
            const { detail } = response.data
            dispatch(onFetchDataReportReservation(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHING))
            console.log(error)
        }
    }

    const getReportAssignment = async (data?:any) => {
        try{
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ReportAPI.getReportAssignment(data);
            if(!response?.status) {
                return CustomSnackbar('error', response.data.message || '');
            }
            const { detail } = response.data
            dispatch(onFetchDataReportAssignment(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHING))
            console.log(error)
        }
    }

    const setFilter = (filter:string) => {
        dispatch(onSetFilter(filter))
    }

    const setOwner = (filter:any) => {
        dispatch(onSetOwner(filter))
    }

    const setDepartment = (filter:any) => {
        dispatch(onSetDepartment(filter))
    }

    const setYear = (filter:Year | null) => {
        dispatch(onSetYear(filter))
    }

    const setMonth = (filter:string) => {
        dispatch(onSetMonth(filter))
    }

    return {
        dataReportAssignment,
        dataReportRenditionPayment,
        dataReportRendition,
        dataReportReservation,
        status,
        filter,
        year,
        month,
        department,
        owner,
        getReportRenditionPay,
        getReportAssignment,
        getReportReservation,
        getReportRendition,
        setFilter,
        setOwner,
        setDepartment,
        setYear,
        setMonth
    }
}