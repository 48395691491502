import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";
import { RenditionBuildingPaid, RenditionBuildingPaidType } from "../../types/slices/renditionBuildingPaidType";

const initialState: RenditionBuildingPaidType = {
    status: ApiStatus.FETCHED,
    renditionBuildingPaids: [],
    errorMessage: undefined,
    selectedRenditionBuildingPaid: {} as RenditionBuildingPaid
}

const renditionBuildingPaidSlice = createSlice({
    name: 'renditionBuildingPaid',
    initialState,
    reducers: {
        onFetchRenditionBuildingPaid (state, { payload }: { payload: RenditionBuildingPaid[] }) {
            state.status       = ApiStatus.FETCHED
            state.renditionBuildingPaids    = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedRenditioBuildingnPaid (state, { payload }: { payload: RenditionBuildingPaid }) {
            state.selectedRenditionBuildingPaid = payload
        }
    }
})

export const selectRenditionBuildingPaidState = (state: RootState) => state.renditionBuildingPaidSlice
export default renditionBuildingPaidSlice.reducer

export const {
    onFetchRenditionBuildingPaid,
    changeStatus,
    onSetSelectedRenditioBuildingnPaid
} = renditionBuildingPaidSlice.actions