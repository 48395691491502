import { ApiStatus } from "../types/api/status"
import { useDispatch, useSelector } from "../redux/store"
import { changeStatus, onFetchAssignments, onFetchHistoryAssignments, onSetSelectedAssignment, onSetSelectedHistoryAssignment, selectAssignmentState } from "../redux/slices/assignmentSlice"
import { AssignmentAPI } from "../apis/AssignmentAPI"
import { Assignment } from "../types/slices/assignmentType"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"

export const useAssignmentStore = () => {

    const dispatch = useDispatch()
    const { assignments, historyAssignments, selectedAssignment, selectedHistoryAssignment, status } = useSelector(selectAssignmentState)

    const getAssignment = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            let response = await AssignmentAPI.getAssignment(data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
          const { detail } = response.data

          if (detail.length > 0) {
            detail.map((value) => {
              if (value.type_assignee == "2") {
                value.name_assignee = value.name_invited_owner
              }
            })
          }

          dispatch(onFetchAssignments(detail || []))
          dispatch(changeStatus(ApiStatus.FETCHED))

        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
  }
  
  const getHistoryAssignment = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            let response = await AssignmentAPI.getHistoryAssignment(data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            const { detail } = response.data
            if (detail.length > 0) {
              detail.map((value) => {
                if (value.type_assignee == "2") {
                  value.name_assignee = value.name_invited_owner;
                }
                let setConfirmed;
                switch (value.isconfirmed) {
                  case "1":
                    setConfirmed = "Aceptado";
                    break;
                  case "2":
                    setConfirmed = "Rechazado";
                    break;
                  case "3":
                    setConfirmed = "Eliminado";
                    break;
                  default:
                    setConfirmed = "Pendiente";
                    break;
                }
                value.isconfirmed = setConfirmed;
              })
            }
            
            dispatch(onFetchHistoryAssignments(detail || []))
            dispatch(changeStatus(ApiStatus.FETCHED))

        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const acceptAssignment = async (id:number) => {
        try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          let response = await AssignmentAPI.acceptAssignment(id)
          if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || '')
          }
          CustomSnackbar('success', response.data.message)
          dispatch(changeStatus(ApiStatus.FETCHED))
          return true
        } catch(error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const editAssignment = async (id:number, data:any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            let response = await AssignmentAPI.editAssignment(id, data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            CustomSnackbar('success', response.data.message)
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch(error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const deleteAssignment = async (id:number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            let response = await AssignmentAPI.deleteAssignment(id)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            CustomSnackbar('success', response.data.message)
            dispatch(changeStatus(ApiStatus.FETCHED))
            return true
        } catch(error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const setSelectedAssignment = (assignment:Assignment) => {
        dispatch(onSetSelectedAssignment(assignment))
    }

    const setSelectedHistoryAssignment = (assignment:Assignment) => {
        dispatch(onSetSelectedHistoryAssignment(assignment))
    }

    return {
        //states
        assignments,
        historyAssignments,
        selectedAssignment,
        selectedHistoryAssignment,
        status,
        //actions
        getAssignment,
        getHistoryAssignment,
        acceptAssignment,
        editAssignment,
        deleteAssignment,
        setSelectedAssignment,
        setSelectedHistoryAssignment,
    }
}