import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { Assignment, AssignmentType } from "../../types/slices/assignmentType";
import { RootState } from "../store";

const initialState: AssignmentType =
{
    status: ApiStatus.FETCHED,
    assignments: [],
    historyAssignments: [],
    errorMessage: undefined,
    selectedAssignment: {} as Assignment,
    selectedHistoryAssignment: {} as Assignment
}

const assignmentSlice = createSlice({
    name: 'assignment',
    initialState,
    reducers: {
        onFetchAssignments (state, { payload }: { payload: Assignment[] }) {
            state.status       = ApiStatus.FETCHED
            state.assignments  = payload
            state.errorMessage = undefined
        },
        onFetchHistoryAssignments (state, { payload }: { payload: Assignment[] }) {
            state.status       = ApiStatus.FETCHED
            state.historyAssignments  = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedAssignment (state, { payload }: { payload: Assignment }) {
            state.selectedAssignment = payload
        },
        onSetSelectedHistoryAssignment (state, { payload }: { payload: Assignment }) {
            state.selectedHistoryAssignment = payload
        }
    }
})

export const selectAssignmentState = (state: RootState) => state.assignmentSlice
export default assignmentSlice.reducer

export const {
    onFetchAssignments,
    onFetchHistoryAssignments,
    changeStatus,
    onSetSelectedAssignment,
    onSetSelectedHistoryAssignment,
} = assignmentSlice.actions