import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { ApiStatus } from '../../types/api/status'
import { Guest, GuestType, typeGuest } from '../../types/slices/guestType'

const initialState: GuestType =
{
  status: ApiStatus.FETCHED,
  guests: [],
  typeGuest: [],
  selectedGuest: {} as Guest,
  selectedGuestType: {} as typeGuest,
  errorMessage: undefined,
  action: '',
}

const guestSlice = createSlice({
  name: 'guestSlice',
  initialState,
  reducers: {
    onFetchGuest (state, { payload }: { payload: Guest[]}) {
      state.status = ApiStatus.FETCHED
      state.guests = payload
      state.errorMessage = undefined
    },
    onFetchGuestType (state, { payload }: { payload: typeGuest[]}) {
      state.status = ApiStatus.FETCHED
      state.typeGuest = payload
      state.errorMessage = undefined
    },
    onSetSelectedGuest (state, { payload }: { payload: Guest }) {
      state.selectedGuest = payload
    },
    onSetSelectedGuestType (state, { payload }: { payload: typeGuest }) {
      state.selectedGuestType = payload
    },
    changeStatus (state, { payload }: { payload: ApiStatus}){
      state.status = payload
    },
    setAction (state, { payload }: { payload: string }) {
      state.action = payload
    },
  }
})

export const selectGuestState = (state: RootState) => state.guestSlice
export default guestSlice.reducer
export const {
    onFetchGuest,
    onFetchGuestType,
    onSetSelectedGuest,
    onSetSelectedGuestType,
    changeStatus,
    setAction,
} = guestSlice.actions
