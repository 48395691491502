import { useSelector } from "react-redux"
import { useDispatch } from "../redux/store"
import { changeStatus, onFetchRenditionOwner, onFetchRenditions, onSetIdRendition, onSetSelectedRendition, selectRenditionState } from "../redux/slices/renditionSlice"
import { ApiStatus } from "../types/api/status"
import { RenditionAPI } from "../apis/RenditionAPI"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { Rendition } from "../types/slices/renditionType"
import { onFetchRenditionSpent } from "../redux/slices/renditionSpentSlice"
import { onFetchRenditionSpentIndividual } from "../redux/slices/renditionSpentIndividual"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"
import { useDepartmentStore } from "./useDepartmentStore"

export const useRenditionStore = () => {
  const dispatch = useDispatch()
  const { renditions, selectedRendition, status, renditionOwner, idrendition } = useSelector(selectRenditionState)

  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany
  
    const getRenditions = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await RenditionAPI.getRenditions(idcompany, data)
            if (!response?.status) {                
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchRenditions(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
            return detail
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const createRendition = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await RenditionAPI.create(idcompany, data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              CustomSnackbar('error', response.data.message || "")
              return false
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const deleteRendition = async (id: number) => {
      try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await RenditionAPI.delete(id, idcompany)
          if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('error', response.data.message || "");
            return false
          }
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar('success', response.data.message || "");
          return true
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
  }

    const setSelectedRendition = (rendition: Rendition) => {
      try {
          dispatch(onSetSelectedRendition(rendition))
      } catch (error) {
          console.log(error)
      }
  }

  const getOrCreateRendition = async (data?: any) => {
      try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await RenditionAPI.getOrCreate(idcompany, data)
          if (!response?.status) {                
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || "");
          }
          const { detail } = response.data
          if (detail[0].validation_status == "1") {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', "La Rención para la fecha seleccionada ya fue registrada y cerrada.");
          }
          dispatch(onFetchRenditions(detail))
          dispatch(onFetchRenditionSpent(detail[0].spent_general) || [])
          dispatch(onFetchRenditionSpentIndividual(detail[0].spent_individual || []))
          dispatch(changeStatus(ApiStatus.FETCHED))
          return true
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
  }

  const getRenditionOwner = async (iddepartment: number, idowner: any) => {
    try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await RenditionAPI.getRenditionOwner(iddepartment, idowner)
        if (!response?.status) {                
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || "");
        }
        const { detail } = response.data
        dispatch(onFetchRenditionOwner(detail))
        dispatch(changeStatus(ApiStatus.FETCHED))
        return true
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const getRenditionById = async (id:number) => {
      try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await RenditionAPI.getById(id)
        if (!response?.status) {                
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || "");
        }
        const { detail } = response.data
        dispatch(onFetchRenditions(detail))
        dispatch(onFetchRenditionSpent(detail[0].spent_general) || [])
        dispatch(onFetchRenditionSpentIndividual(detail[0].spent_individual || []))
        dispatch(changeStatus(ApiStatus.FETCHED))
        return true
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
  }

  const getRenditionOwnerPDF = async (idrendition:number, idowner:number) => {
      try {
        const response = await RenditionAPI.getRenditionOwnerPDF(idrendition, idowner, idcompany)
        if (!response?.status) {                
          CustomSnackbar('error', response.data.message)
          return false
        }
        return response.data
      } catch (error) {
        console.log(error)
      }
  }

  const closeRendition = async (idrendition:number) => {
    try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await RenditionAPI.closeRendition(idrendition, idcompany)
        if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar('error', response.data.message || "")
          return false
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message || "");
        return true
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const setIdRendition = (id:number) => {
      try {
          dispatch(onSetIdRendition(id))
      } catch (error) {
          console.log(error)
      }
  }

  const detailRendicionAdminPDF = async (idrendition:number) => {
    try {
      const response = await RenditionAPI.detailRendicionAdminPDF(idrendition, idcompany)
      if (!response?.status) {                
        CustomSnackbar('error', response.data.message)
        return false
      }
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  return {
    //states
    status,
    renditions,
    selectedRendition,
    renditionOwner,
    idrendition,
    //actions
    getRenditions,
    createRendition,
    setSelectedRendition,
    deleteRendition,
    getOrCreateRendition,
    getRenditionOwner,
    getRenditionById,
    getRenditionOwnerPDF,
    closeRendition,
    setIdRendition,
    detailRendicionAdminPDF,
  }
}