import { useDispatch, useSelector } from "react-redux"
import { onFetchConfiguration, onFetchConfigurations, changeStatus, onSetSelectedConfigurationDepartment } from "../redux/slices/configurationDepartmentSlice"
import { ApiStatus } from "../types/api/status"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { selectConfiguracionDepartmentState, onSetEndCycle, onSetLastDayPayment, onSetBillingCycleCutoffTime, onSetWaitingReservation, onSetCheckIn, onSetCheckOut, onSetDontBookWeekends, onSetStartCycle, onSetDayIssuanceDocument, onSetMaximumSelectionDays } from "../redux/slices/configurationDepartmentSlice"
import { ConfigurationDepartmentAPI } from "../apis/ConfigurationDepartmentAPI"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"

export const useConfigurationDepartmentStore = () => {
    const dispatch = useDispatch();

    const { status, configurationDepartment, configurationDepartments, selectedConfigurationDepartment } = useSelector(selectConfiguracionDepartmentState)
    
    const userData = readLocalStorage(LocalStorageKey.USER_DATA)
    const idcompany = userData?.data?.idcompany

    const getConfigurationDepartment = async (listType?:string) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ConfigurationDepartmentAPI.getConfigurationDepartment(idcompany, listType)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            const { detail } = response.data
            dispatch(onFetchConfigurations(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const getConfigurationByDepartment = async (iddepartment:any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ConfigurationDepartmentAPI.getConfigurationByDepartment(iddepartment)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            const { detail } = response.data
            dispatch(onFetchConfiguration(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const getConfigurationDepartmentByOwner = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ConfigurationDepartmentAPI.getConfigurationDepartmentByOwner(idcompany, data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            const { detail } = response.data
            dispatch(onFetchConfiguration(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const editConfigurationCompany = async (iddepartment: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ConfigurationDepartmentAPI.updateConfigurationDepartment(data, iddepartment)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || '')
            return true
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
    }

    const setWaitingReservation = (waitingReservation: number) => {
        try{
            dispatch(onSetWaitingReservation(waitingReservation))
        }catch(error){
            console.log(error)
        }
    }

    const setCheckIn = (checkIn: any) => {
        try{
            dispatch(onSetCheckIn(checkIn))
        }catch(error){
            console.log(error)
        }
    }

    const setCheckOut = (checkOut: any) => {
        try{
            dispatch(onSetCheckOut(checkOut))
        }catch(error){
            console.log(error)
        }
    }

    const setDontBookWeekends = (dontBookWeekend: number) => {
        try{
            dispatch(onSetDontBookWeekends(dontBookWeekend))
        }catch(error){
            console.log(error)
        }
    }

    const setMaximumSelectionDays = (maximumSelectionDays: number) => {
        try{
            dispatch(onSetMaximumSelectionDays(maximumSelectionDays))
        }catch(error){
            console.log(error)
        }
    }

    const setBillingCycleCutoffTime = (maximumSelectionDays: number) => {
        try{
            dispatch(onSetBillingCycleCutoffTime(maximumSelectionDays))
        }catch(error){
            console.log(error)
        }
    }

    const setStartCycle = (startCycle: number) => {
        try{
            dispatch(onSetStartCycle(startCycle))
        }catch(error){
            console.log(error)
        }
    }

    const setEndCycle = (endCycle: number) => {
        try{
            dispatch(onSetEndCycle(endCycle))
        }catch(error){
            console.log(error)
        }
    }

    const setDayIssuanceDocument = (dayIssuanceDocument: number) => {
        try{
            dispatch(onSetDayIssuanceDocument(dayIssuanceDocument))
        }catch(error){
            console.log(error)
        }
    }

    const setLastDayPayment = (lastDayPayment: number) => {
        try{
            dispatch(onSetLastDayPayment(lastDayPayment))
        }catch(error){
            console.log(error)
        }
    }
    
    const setSelectedConfigurationDepartment = (configurationDepartment:any) => {
      dispatch(onSetSelectedConfigurationDepartment(configurationDepartment))
    }

    const validateStatus = (data:any) => {
      switch (data) {
        case "1":
          return "Habilitado";
        default:
          return "Deshabilitado";
      }
    }

  return {
    status,
    configurationDepartment,
    configurationDepartments,
    selectedConfigurationDepartment,
    getConfigurationDepartment,
    getConfigurationDepartmentByOwner,
    editConfigurationCompany,
    getConfigurationByDepartment,
    setWaitingReservation,
    setCheckIn,
    setCheckOut,
    setDontBookWeekends,
    setMaximumSelectionDays,
    setBillingCycleCutoffTime,
    setStartCycle,
    setEndCycle,
    setDayIssuanceDocument,
    setLastDayPayment,
    setSelectedConfigurationDepartment
  }
}