import { api } from "./configs/axiosConfigs"

export const BuildingAPI ={
    getBuilding: async (data?: any) => {
        const response = await api.get(
            '/building',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    createBuilding: async (data: any, idcompany: number) => {
        const response = await api.post(
            '/building/register', 
            {
                ...data,
                idcompany: idcompany
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    createBuildingAndDepa: async (data: any, idbuilding: number) => {
        const response = await api.post(
            '/buildingDepartments/register', 
            {
                ...data,
                idbuilding: idbuilding
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    editBuilding: async (iddepartment: number, data: any) => {
        const response = await api.patch(
        `/building/${iddepartment}`,
        {
            ...data
        }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    deleteBuilding: async (iddepartment: number) => {
        const response = await api.delete(
        `/building/${iddepartment}`
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    // editBuildingAll: async (iddepartment: number, data: any) => {
    //     const response = await api.patch(
    //     `/departments/editAll/${iddepartment}`,
    //     {
    //         ...data
    //     }
    //     ).then(response => response.data)
    //     .catch((error) => {
    //         return error.response.data
    //     })
    //     return response
    // },
    
    // addDepartmentOwner: async (data: any, idcompany: number) => {
    //     const response = await api.post(
    //         '/departmentsOwner/register', 
    //         {
    //             ...data,
    //             idcompany: idcompany
    //         }
    //     ).then(response => response.data)
    //     .catch((error) => {
    //         console.error(error)
    //         return error.response.data
    //     })
    //     return response
    // },
    getBuildingByDepartments: async (id?: number) => {
      const response = await api.get(
        `/buildingDepartments`,{
            params:{
                idbuilding: id
            }
        }
      ).then(response => response.data)
      .catch((error) => {
        console.error(error)
        return error.response.data
      })
      return response
    },
    deleteBuildingByDepartment: async (iddepartment: number) => {
        const response = await api.delete(
        `/buildingDepartments/${iddepartment}`
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
}