
import { useEffect, useState } from "react";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material"
import dayGridPlugin from '@fullcalendar/daygrid'
import momentPlugin from '@fullcalendar/moment'
import FullCalendar from '@fullcalendar/react'
import { useReservationStore } from "../../../hooks/useReservationStore";
import { formatDataFullCalendar } from "../../../toolbox/helpers/fullcalendar-helpers";
import { ReservationAdminModal } from "./ReservationAdminModal";
import { Reservation } from "../../../types/slices/reservationType";
import { ReservationParams } from "../../../types/api/Reservation.type";
import SearchIcon from '@mui/icons-material/Search'
import { ApiStatus } from "../../../types/api/status";
import { CustomBackdrop } from "../../../components/common/CustomBackdrop/CustomBackdrop";
import moment from "moment";

export const CustomFullCalendar: React.FC<any> = (props): JSX.Element | any => {

    const { status: statusReservation, reservations, selectedReservation, getReservations, setSelectedReservation } = useReservationStore()

    const [initial_date, setinitial_date] = useState<any>(new Date())
    const [calendarRegister, setCalendarRegister] = useState<any>([])
    const [showModalReserve, setShowModalReserve] = useState<boolean>(false);

    /* filter */
    const [filterBy, setFilterBy] = useState<any>('03');
    const [specific_date, setspecific_date] = useState<any>('');

    useEffect(() => {
        getReservations({status: '1'} as ReservationParams)
    },[])

    useEffect(() => {
        const dataFormated = formatDataFullCalendar(reservations)
        setCalendarRegister(dataFormated)
    }, [reservations])

    const handleEventClick = (horarySelected) => {
        const { event } = horarySelected;
        const { start, end, title} = event

        const owner = title.split('/')[2] || '';
        const departament = title.split('/')[1] || '';
        const idreserve = title.split('/')[0] || '';

        const currentReserve = reservations.find(reserve => (reserve.id == idreserve))
        const check_in = currentReserve ? currentReserve.check_in : '-----';
        const check_out = currentReserve ? currentReserve.check_out : '-----';
        const arrivalDate = start ? moment(start).format('LL') : '-----';
        const returnDate = end ? moment(end).subtract(1, 'day').format('LL') : '-----';

        const reserve:any = {
            name_department: departament,
            name_owner: owner,
            startDate: arrivalDate,
            endDate: returnDate,
            check_in: check_in,
            check_out: check_out,
        }
        setShowModalReserve(true);
        setSelectedReservation(reserve);
    }

    const onCancelFilter = () => {
        setinitial_date(new Date())
        getReservations({status: '1'} as ReservationParams)
    }

    const onListReservation = () => {

        if(specific_date) setinitial_date(new Date(`${specific_date} 00:00:00`))

        switch (filterBy) {
            case '03':
                if(specific_date) getReservations({specific_date: specific_date, type: 1, status: '1'} as ReservationParams)
                break;
            case '04':
                if(specific_date) getReservations({specific_date: specific_date, type: 2, status: '1'} as ReservationParams)
                break;
            default:
                break;
        }
    }

    return (
        <>
        { statusReservation === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
        <Grid container>
            <Grid item container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom:'10px'}}>
                <Typography variant='h6' component='h1' gutterBottom color='#808080' className="tittle" sx={{ mt: 1, ml:1 , fontWeight:600}}>
                    {('Calendario de Reservas').toUpperCase()}
                </Typography>
            </Grid>

            <Grid item container xs={12} direction="row" justifyContent="space-between" alignItems="center" sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px'}}>
                <Grid xs={12} sm ={12} md={3} lg={3} xl={3} sx={{padding: '7px'}}>
                    <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" style={{ fontSize: "13px" }}>Tipo de filtrado</InputLabel>
                    <Select
                        id="combo-box-department"
                        size="small"
                        value={filterBy}
                        onChange={(event) => {setFilterBy(event.target.value)}}
                        sx={{width:'240px', fontSize:"12px"}}
                        label='Tipo de filtrado'
                    >
                        <MenuItem value={'03'}>{"Por fecha específica (llegada)"}</MenuItem>
                        <MenuItem value={'04'}>{"Por fecha específica (salida)"}</MenuItem>
                    </Select>
                    </FormControl>
                </Grid>
                <Grid xs={12} sm ={12} md={9} lg={9} xl={9} item container display='flex' 
                    // justifyContent='end' 
                    sx={{
                        alignItems: 'center', 
                        justifyContent:{ xs: 'start', sm: 'start', md:'end', lg:'end', xl:'end'} 
                    }}
                >
                    <Grid item >
                        {
                            (filterBy == '03' || filterBy == '04') && (
                                <TextField
                                    id="date"
                                    type="date" 
                                    name="date"
                                    size="small"
                                    value={specific_date}
                                    onChange={(e)=>{setspecific_date(e.target.value)}}
                                    sx={{width:'240px', padding: '8px'}}
                                    inputProps={{ style: { fontSize: "12px" } }}
                                />
                            )
                        }
                    </Grid>
                    
                    <Grid item display='flex' justifyContent='space-between'>
                        {
                            (filterBy != '01') && (
                                <Button 
                                    startIcon={<SearchIcon/>} 
                                    onClick={onListReservation} 
                                    size="small" 
                                    variant="contained" 
                                    sx={{ height:'35px',backgroundColor: '#212D39', color: '#fff', textTransform: 'none', padding: '4px 10px', "&:hover": {backgroundColor: "#212D39" }, fontSize:"12px", marginLeft:'6px', marginRight:'3px' }}>
                                        Filtrar busqueda
                                </Button>
                            )
                        }
                        {
                            (filterBy != '01') && (
                                <Button 
                                    onClick={onCancelFilter} 
                                    size="small" 
                                    variant="contained" 
                                    sx={{ height:'35px', backgroundColor: '#5a5959', color: '#fff', textTransform: 'none', padding: '4px 10px', "&:hover": {backgroundColor: "#a79f9f" }, marginLeft:'3px'}}>
                                        Cancelar filtro
                                </Button>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
            {
                statusReservation === ApiStatus.FETCHED && (
                    <Grid sx={{ width:'100%', marginTop:'10px'}}>
                <FullCalendar
                    height={'492px'}
                    plugins={[momentPlugin, dayGridPlugin]}
                    initialView='dayGridMonth'
                    initialDate={initial_date}
                    weekends={true}
                    events={calendarRegister}
                    locale={'es-us'}
                    navLinks={true}
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    buttonText={{
                        today: 'Hoy',
                        month: 'Mes',
                        week: 'Semana',
                        day: 'Dia',
                        list: 'Lista',
                    }}
                    headerToolbar={{
                        left: 'title',
                        right:'prev,next,today'
                    }}
                    eventClick={handleEventClick}
                />
            </Grid>
                )
            }
            
        </Grid>
        {
            showModalReserve && (
                <ReservationAdminModal
                    open={showModalReserve}
                    closeModal={()=>{
                        setShowModalReserve(false); 
                        setSelectedReservation({} as Reservation)
                    }}
                    rowSelected={selectedReservation}
                />
            )
        }
        </>
    )
}