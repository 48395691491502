import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { ApiStatus } from '../../types/api/status'
import { Reservation, ReservationGuest, reservationType, Blocked } from '../../types/slices/reservationType'

const initialState: reservationType =
{
  status: ApiStatus.FETCHED,
  reservations: [],
  reservationsByUser: [],
  reservationGuests: [],
  selectedReservation: {} as Reservation,
  selectedReservationGuest: {} as ReservationGuest,
  specificReservation: {} as Reservation,
  blocked: [],
  errorMessage: undefined,
}

const reservationSlice = createSlice({
  name: 'reservationSlice',
  initialState,
  reducers: {
    onFetchReservations (state, { payload }: { payload: Reservation[]}) {
      state.status = ApiStatus.FETCHED
      state.reservations = payload
      state.errorMessage = undefined
    },
    onFetchBlockedData (state, {payload}:{ payload: Blocked[]}){
      state.status = ApiStatus.FETCHED
      state.blocked = payload
      state.errorMessage = undefined
    },
    onFetchReservationsByuser (state, { payload }: { payload: Reservation[]}) {
      state.status = ApiStatus.FETCHED
      state.reservationsByUser = payload
      state.errorMessage = undefined
    },
    onFetchReservationGuests (state, { payload }: { payload: ReservationGuest[]}) {
      state.status = ApiStatus.FETCHED
      state.reservationGuests = payload
      state.errorMessage = undefined
    },
    onSetSpecificReservation (state, { payload }: { payload: Reservation }) {
      state.specificReservation = payload
    },
    onSetSelectedReservation (state, { payload }: { payload: Reservation }) {
      state.selectedReservation = payload
    },
    onSetSelectedReservationGuest (state, { payload }: { payload: ReservationGuest }) {
      state.selectedReservationGuest = payload
    },
    changeStatus (state, { payload }: { payload: ApiStatus}){
      state.status = payload
    },
  }
})

export const selectReservationState = (state: RootState) => state.reservationSlice
export default reservationSlice.reducer
export const {
    onFetchReservations,
    onFetchBlockedData,
    onFetchReservationsByuser,
    onFetchReservationGuests,
    onSetSpecificReservation,
    onSetSelectedReservation,
    onSetSelectedReservationGuest,
    changeStatus,
} = reservationSlice.actions
