import { useEffect, useState } from "react";
import { Grid } from '@mui/material'
import CustomTable from '../../components/common/CustomTable/CustomTable';
import { RoutesMap } from "../../types";
import { useNavigate } from "react-router-dom";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { useServiceStore } from "../../hooks/useServiceStore";
import { Service } from "../../types/slices/serviceSlice";
import { useSelector } from "../../redux/store";
import { selectHeaderState } from "../../redux/slices/headerSlice";

const columns = [
    { type: 'options',  align:'center',  field: 'options', label: 'OPCIONES' },
    { type: 'text',     align:'center',  field: 'name', label: 'NOMBRE' },
    { type: 'text',     align:'center',  field: 'service_type', label: 'TIPO' },
    { type: 'detail',   align:'center',  field: 'detail', label: '' },
]

export const ServiceView = () => {

    const navigate = useNavigate();
    const { search } = useSelector(selectHeaderState);
  
    const { status: serviceStatus, services, getServices, setSelectedService, deleteService, setActionService } = useServiceStore()

    const [ rowSelected, setRowSelected ]           = useState<Service>({} as Service);
    const [ showModalConfirm, setShowModalConfirm ] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getServicesApi()
    },[search])

    const getServicesApi = async() => {
        try {
          setLoading(true);
          const response = await getServices({search});
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const onAdd = () => {
        setActionService('add')
        navigate({ pathname: RoutesMap.SERVICES_FORM })
    }

    const onEdit = (rowSelected) => {
        setActionService('edit')
        setSelectedService(rowSelected)
        navigate({ pathname: RoutesMap.SERVICES_FORM })
    }

    const onDelete = (rowSelected) => {
        setActionService('delete')
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = async() => {
        const response = await deleteService(rowSelected.id)
        if(response){
            setSelectedService({} as Service)
            setShowModalConfirm(false)
            getServicesApi()
        }else{
            return false;
        }
    }

    return (
        <>
            {/* {serviceStatus === ApiStatus.FETCHING && <CustomBackdrop open={true}/>} */}

            <Grid item xs={12}>
                <CustomTable 
                    title={'Servicios'}
                    columns={columns}
                    loading={loading}
                    rows={services || []}
                    onRowClick={() => {}}
                    hasOptions
                    onAddFn={onAdd}
                    onDelete={onDelete}
                    onEdit={onEdit}
                />
            </Grid>
            {
                showModalConfirm && (
                    <ModalConfirm
                        open={showModalConfirm}
                        closeModal={()=>{setShowModalConfirm(false)}}
                        onCancel={()=>{setShowModalConfirm(false)}}
                        onConfirm={onDeleteConfirm}
                        status2={serviceStatus == ApiStatus.FETCHING ? true : false}
                    />
                )
            }
        </>
    )
}