import { FC, useEffect, useState } from "react";
import { useAdministratorStore } from "../../hooks/useAdministratorStore";
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { RoutesMap } from "../../types";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { Divider, FormLabel, Grid, TextField, Autocomplete } from "@mui/material";
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter";
import { useNavigate } from "react-router-dom";
import { Administrator } from "../../types/slices/administratorType";
import { Company } from "../../types/slices/companyType";
import { useCompanyStore } from "../../hooks/useCompanyStore";

type AdminDataForm = {
    id: number
    name: string
    rut: string
    email: string
    phone: string
}

export const AdministratorForm : FC = () => {

    const navigate = useNavigate()
    const { status: statusAdmin, selectedAdministrator, setSelectedAdministrator, createAdministrator, editAdmin } = useAdministratorStore()
    const { status: statusCompany, companies, getCompanies } = useCompanyStore()

    const [ selectedCompany, setSelectedCompany ] = useState<Company|null>(null)
    const [ isEdit, setIsEdit ] = useState<boolean>(false)
    const [data, setData] = useState<AdminDataForm>({
        id: 0,
        name: '',
        rut: '',
        email: '',
        phone: ''
    });

    useEffect(()=>{
        getCompanies()
        if(Object.entries(selectedAdministrator).length > 0) {
            setIsEdit(true)
            setData({
                id: selectedAdministrator.id,
                rut: selectedAdministrator.rut,
                name: selectedAdministrator.name,
                email: selectedAdministrator.email,
                phone: selectedAdministrator.phone
            })
            const company = companies.find(company => company.id === selectedAdministrator.idcompany)
            setSelectedCompany(company || {} as Company)
            setSelectedAdministrator({} as Administrator)
        }
    },[])

    const validateForm = (values) => {
        let errors:any = {};
        if(!values.name) errors.name = "Nombre es requerido";
        if(!values.rut) errors.rut = "RUT es requerido";
        if(!values.email) errors.email = "Email es requerido";
        if(!values.phone) errors.phone = "Celular es requerido";
        return errors;
    }

    const onSubmit = async (values)  => {
        const payload = {
            ...values,
            idcompany: selectedCompany?.id
        }
        const method = !isEdit ? createAdministrator(payload) : editAdmin(data.id, payload)
        const response = await method
        if (response === true) {
            navigate({ pathname: RoutesMap.ADMINISTRATOR })
        }
    }

    const onCancel = async()  => {
        navigate({ pathname: RoutesMap.ADMINISTRATOR })
    }

    return (
        <CustomForm >
            { statusAdmin === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
            { statusCompany === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
            <CustomFormHeader
                title={!isEdit ? 'Nuevo Administrador' : 'Editar Administrador' }
                text={!isEdit ? 'Ingrese los datos del nuevo administrador' : 'Ingrese los datos del administrador'}
                goBack={RoutesMap.ADMINISTRATOR}
            >
            </CustomFormHeader>

            <CustomFormBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{padding:'20px'}}>

                                <Grid item container xs={12} spacing={2} sx={{mt:2}}>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Nombre:</FormLabel>
                                        <TextField
                                            id="name"
                                            type="text" 
                                            name="name"
                                            fullWidth
                                            size="small"
                                            value={values.name}
                                            onChange={handleChange}
                                            error={errors.rut && touched.rut ? true : false}
                                            helperText={errors.rut && touched.rut ? errors.rut : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>RUT:</FormLabel>
                                        <TextField
                                            id="rut"
                                            type="text" 
                                            name="rut"
                                            fullWidth
                                            size="small"
                                            value={values.rut}
                                            onChange={handleChange}
                                            error={errors.rut && touched.rut ? true : false}
                                            helperText={errors.rut && touched.rut ? errors.rut : ''}
                                            InputProps={{
                                                inputProps: {
                                                    maxLength: 10
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Email:</FormLabel>
                                        <TextField
                                            id="email"
                                            type="email" 
                                            name="email"
                                            fullWidth
                                            size="small"
                                            value={values.email}
                                            onChange={handleChange}
                                            error={errors.email && touched.email ? true : false}
                                            helperText={errors.email && touched.email ? errors.email : ''}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} spacing={2} sx={{marginTop:'10px'}}>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Celular:</FormLabel>
                                        <TextField
                                            id="phone"
                                            type="text" 
                                            name="phone"
                                            fullWidth
                                            size="small"
                                            value={values.phone}
                                            onChange={(e) => {
                                                const regex = /^[0-9\b]+$/;
                                                if (e.target.value === "" || regex.test(e.target.value)) {
                                                  setFieldValue('phone', e.target.value)
                                                }
                                            }}
                                            error={errors.phone && touched.phone ? true : false}
                                            helperText={errors.phone && touched.phone ? errors.phone : ''}                                       
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Compañia:</FormLabel>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-company"
                                            value={selectedCompany}
                                            options={companies || []}
                                            getOptionLabel={(option) => option.business_name || ""}
                                            isOptionEqualToValue={(option, value) => option?.business_name === value?.business_name}
                                            fullWidth
                                            renderInput={(params) => <TextField {...params} size="small" />}
                                            onChange={(e, newValue) => {
                                                setSelectedCompany(newValue);
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                
                                </Grid>

                                <Divider />
                                <CustomFormFooter 
                                    buttonType="submit"
                                    confirmText={ !isEdit ? 'Guardar' : 'Actualizar' }
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        )
                    }} 
                </Formik>

            </CustomFormBody>

        </CustomForm>
    )
}