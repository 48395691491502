import { useSelector } from "react-redux"
import { useDispatch } from "../redux/store"
import { ApiStatus } from "../types/api/status"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"
import { changeStatus, onFetchRenditionBuildingSpentIndividual, selectRenditionBuildingSpentIndividualState } from "../redux/slices/renditionBuildingSpentIndividualSlice"
import { RenditionBuildingSpentIndividualAPI } from "../apis/RenditionBuildingSpentIndividualAPI"

export const useRenditionBuildingSpentIndividualStore = () => {
  const dispatch = useDispatch()
  const { renditionBuildingSpentIndividuals, selectedRenditionBuildingSpentIndividuals, status } = useSelector(selectRenditionBuildingSpentIndividualState)
  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany
    
    const getRenditionBuildingSpentIndividuals = async (data?: any) => {
        try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await RenditionBuildingSpentIndividualAPI.getRenditionBuildingSpentIndividuals(idcompany, data)
          if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
          }
          const { detail } = response.data
          dispatch(onFetchRenditionBuildingSpentIndividual(detail))
          dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
          console.error(error)
          dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const createRenditionBuildingSpentIndividual = async (data: any) => {
        try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await RenditionBuildingSpentIndividualAPI.create(data)
          if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || "")
          }
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar('success', response.data.message || '')
          return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const editRenditionBuildingSpentIndividual = async (id: number, data: any) => {
      try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await RenditionBuildingSpentIndividualAPI.edit(id, data)
          if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || "");
          }
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar('success', response.data.message  || "");
          return true
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
    }

    const deleteRenditionBuildingSpentIndividual = async (id: number) => {
      try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await RenditionBuildingSpentIndividualAPI.delete(id, idcompany)
        if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || "");
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        return true
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
    }

    return {
      //status
      renditionBuildingSpentIndividuals,
      selectedRenditionBuildingSpentIndividuals,
      status,
      //actions
      getRenditionBuildingSpentIndividuals,
      createRenditionBuildingSpentIndividual,
      editRenditionBuildingSpentIndividual,
      deleteRenditionBuildingSpentIndividual
    }
}