import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { Owner, OwnerType } from "../../types/slices/ownerType";
import { RootState } from "../store";

const initialState: OwnerType =
{
  status: ApiStatus.FETCHED,
  owners: [],
  ownersDistincs: [],
  errorMessage: undefined,
  selectedOwner: {} as Owner,
  selectedOwnerAdd: {} as Owner || null,
  ownerAssignor: {} as Owner || null,
  ownerAssignee: {} as Owner || null,
  action: '',
}

const ownerSlice = createSlice({
    name: 'owner',
    initialState,
    reducers: {
        onFetchOwners (state, { payload }: { payload: Owner[] }) {
            state.status       = ApiStatus.FETCHED
            state.owners       = payload
            state.errorMessage = undefined
        },
        onFetchOwnersDistincs (state, { payload }: { payload: Owner[] }) {
            state.status       = ApiStatus.FETCHED
            state.ownersDistincs       = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedOwner (state, { payload }: { payload: Owner }) {
            state.selectedOwner = payload
        },
        onSetSelectedOwnerAdd (state, { payload }: { payload: Owner }) {
            state.selectedOwnerAdd = payload
        },
        onSetSelectedOwnerAssignor (state, { payload }: { payload: Owner  }) {
            state.ownerAssignor = payload
        },
        onSetSelectedOwnerAssignee (state, { payload }: { payload: Owner }) {
            state.ownerAssignee = payload
        },
        setAction (state, { payload }: { payload: string }) {
            state.action = payload
        },
    }
})

export const selectOwnerState = (state: RootState) => state.ownerSlice
export default ownerSlice.reducer

export const {
  onFetchOwners,
  onFetchOwnersDistincs,
  changeStatus,
  onSetSelectedOwner,
  onSetSelectedOwnerAdd,
  onSetSelectedOwnerAssignor,
  onSetSelectedOwnerAssignee,
  setAction,
} = ownerSlice.actions