import { useSelector } from "react-redux"
import { useDispatch } from "../redux/store"
import { changeStatus, onFetchRenditionSpentIndividual, selectRenditionSpentIndividualState } from "../redux/slices/renditionSpentIndividual"
import { ApiStatus } from "../types/api/status"
import { RenditionSpentIndividualAPI } from "../apis/RenditionSpentIndividualAPI"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"

export const useRenditionSpentIndividualStore = () => {
  const dispatch = useDispatch()
  const { renditionSpentIndividuals, selectedRenditionSpentIndividuals, status } = useSelector(selectRenditionSpentIndividualState)
  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany
    
    const getRenditionSpentIndividuals = async (data?: any) => {
        try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await RenditionSpentIndividualAPI.getRenditionSpentIndividuals(idcompany, data)
          if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
          }
          const { detail } = response.data
          dispatch(onFetchRenditionSpentIndividual(detail))
          dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
          console.error(error)
          dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const createRenditionSpentIndividual = async (data: any) => {
        try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await RenditionSpentIndividualAPI.create(data)
          if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || "")
          }
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar('success', response.data.message || '')
          return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const editRenditionSpentIndividual = async (id: number, data: any) => {
      try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await RenditionSpentIndividualAPI.edit(id, data)
          if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || "");
          }
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar('success', response.data.message  || "");
          return true
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
    }

    const deleteRenditionSpentIndividual = async (id: number) => {
      try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await RenditionSpentIndividualAPI.delete(id, idcompany)
        if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || "");
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        return true
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
    }

    return {
      //status
      renditionSpentIndividuals,
      selectedRenditionSpentIndividuals,
      status,
      //actions
      getRenditionSpentIndividuals,
      createRenditionSpentIndividual,
      editRenditionSpentIndividual,
      deleteRenditionSpentIndividual
    }
}