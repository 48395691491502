import { api } from "./configs/axiosConfigs"

export const RenditionBuildingPaidAPI = {
    getRenditionBuildingPaids: async (idcompany: number, data?: any) => {
        const response = await api.get(
            '/reportPayBuilding',
            {
                params: {
                    ...data,
                    idcompany
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    create: async (data: any) => {
        const response = await api.post(
            '/reportPayBuilding/register', data
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    validate: async (id: number, idcompany: number, data: any) => {
        const response = await api.post(
        `/reportPayBuilding/validate/${id}`,
        {
            ...data,
            idcompany
            
        }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    }
}