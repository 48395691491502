import { api } from "./configs/axiosConfigs"

export const AuthAPI = {

  login: async (iduser_type: number, password: string, rut: string, idcompany: number|string  ) => {
    const response = await api.post('/auth/login',
      {
        rut,
        password,
        password_confirmation: password,
        iduser_type,
        idcompany,
      }
    )
    .then(response => response.data.data)
    .catch((error) => {
        console.error(error)
    })

    return response
  },

  refresh: async (token: string) => {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`

    const response = await api.post(
      '/auth/refresh',
    ).then(response => response.data.data)
      .catch((error) => {
        console.error(error)
      })
    return response
  },

  logout: async () => {
    const response = await api.post(
      '/auth/logout'
    ).then(response => response.data.data)
      .catch((error) => {
        return error.response.data
      })
    return response
  },
  verifyToken: async (token) => {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`
    const response = await api.get(
      'auth/validateToken',
    ).then(response => {
      return response.data.data.detail
    })
      .catch((error) => {
        console.error(error)
      })
    return response
  },
}