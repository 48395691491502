import { FC, useState } from "react";
import { Grid, ButtonGroup, IconButton, Typography, Autocomplete, Button, TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { useOwnerStore } from "../../hooks/useOwnerStore";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { useReportStore } from "../../hooks/useReportStore";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { FilterComponent } from "./components/FilterComponent";
import { moneyFormat } from "../../toolbox/helpers/money-helpers";
import { NotData } from "./components/NotData";
import { ReportAPI } from "../../apis/ReportAPI";

const columns = [
    { type: 'text', field: 'description_report', label: 'DESCRIPCIÓN', align: 'center' },
    { type: 'text', field: 'name', label: 'PROPIETARIO', align: 'center' },
    { type: 'text', field: 'amount_debt', label: 'TOTAL A PAGAR', align: 'center' , 
    format: (row) => moneyFormat(row.amount_debt, '$')},
    { type: 'text', field: 'amount_payable', label: 'TOTAL PAGADO', align: 'center',
    format: (row) => moneyFormat(row.amount_payable, '$') },
    { type: 'text', field: 'amount_balance', label: 'BALANCE', align: 'center',
    format: (row) => moneyFormat(row.amount_balance, '$') },
]

export const ReportAdminRenditionPayment: FC = () => {

    const { status: statusApiDepartment } = useDepartmentStore()
    const { status: statusOwnerApi } = useOwnerStore()

    const { getReportRenditionPay, dataReportRenditionPayment: data, filter, department, owner, year, month, status } = useReportStore();
    const [params, setParams] = useState<any>();

    const downloadExcel = async () => {
        const response  = await ReportAPI.getReportRenditionPayExcel(params);
        
        if(response.status){
            const btn_excel = document.createElement('a');
            btn_excel.href = `${response.link}`
            document.body.appendChild(btn_excel);
            btn_excel.click();
            document.body.removeChild(btn_excel)
        }

    }

    const onListRenditionPay = () => {
        let dataParams = {} 
        switch (filter) {
            case '01':
                dataParams = {
                    "month": month.substring(month.length - 2),
                    "year": month.substring(0, 4),
                    "iddepartment": department.id,
                    "idcompany": 1
                }
                getReportRenditionPay(dataParams)
                setParams(dataParams)
                break;
            case '02':
                dataParams = {
                    "year": year?.name,
                    "iddepartment": department.id,
                    "idcompany": 1
                }
                getReportRenditionPay(dataParams)
                setParams(dataParams)
                break;
            case '03':
                dataParams = {
                    "month": month.substring(month.length - 2),
                    "year": month.substring(0, 4),
                    "idowner": owner.id,
                    "idcompany": 1
                }
                getReportRenditionPay(dataParams)
                setParams(dataParams)
                break;
            case '04':
                dataParams = {
                    "year": year?.name,
                    "idowner": owner.id,
                    "idcompany": 1
                }
                getReportRenditionPay(dataParams)
                setParams(dataParams)
                break;
            case '05':
                dataParams = {
                    "month": month.substring(month.length - 2),
                    "year": month.substring(0, 4),
                    "idowner": owner.id,
                    "iddepartment": department.id,
                    "idcompany": 1
                }
                getReportRenditionPay(dataParams)
                setParams(dataParams)
                break;
            case '06':
                dataParams = {
                    "year": year?.name,
                    "idowner": owner.id,
                    "iddepartment": department.id,
                    "idcompany": 1
                }
                getReportRenditionPay(dataParams)
                setParams(dataParams)
                break;
            default:
                break;
        }
    }

    return (
        <>

            {statusApiDepartment === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            {statusOwnerApi === ApiStatus.FETCHING && <CustomBackdrop open={true} />}
            {status === ApiStatus.FETCHING && <CustomBackdrop open={true} />}

            <Grid container>
                <Grid item container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom: '10px' }}>
                    <Typography variant='h6' component='h1' gutterBottom color='#808080' className="tittle" sx={{ mt: 1, ml: 1, fontWeight: 600 }}>
                        {('Reporte de Pago de Rendiciones').toUpperCase()}
                    </Typography>
                </Grid>
                <FilterComponent submit={onListRenditionPay} />
                {
                   data &&  data.data_department ? <>
                        <Grid item container sx={{ padding: 2 }}>
                            <Button onClick={() => { downloadExcel() }}
                                sx={{
                                    height: '35px',
                                    backgroundColor: '#212D39',
                                    color: '#fff',
                                    textTransform: 'none',
                                    padding: '4px 10px',
                                    "&:hover": { backgroundColor: "#212D39" }, fontSize: "12px"
                                }}>
                                Descargar Excel</Button>
                        </Grid>
                        <Grid item container sx={{ padding: 2, mt:2 }}>
                            <Grid item xs={12}>
                                <Typography> Total a Pagar:</Typography> <Typography>{moneyFormat(data?.total_debt, '$')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography> Total a Pagar propietarios:</Typography> <Typography>{moneyFormat(data?.total_debt_owners, '$')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography> Total pagado:</Typography> <Typography>{moneyFormat(data?.total_payable, '$')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography> Balance:</Typography> <Typography>{moneyFormat(data?.total_balance, '$')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography> Balance propietarios:</Typography> <Typography>{moneyFormat(data?.total_balance_owners, '$')}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                (data.data_department || []).map((dataDepartment)=>{
                                    return (
                                        <>
                                        <Grid item xs={12}>
                                                <Typography> Departamento:</Typography> <Typography>{dataDepartment.name_department}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography> Total a Pagar:</Typography> <Typography>{moneyFormat(dataDepartment?.total_debt, '$')}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography> Total a Pagar propietarios:</Typography> <Typography>{moneyFormat(dataDepartment?.total_debt_owners, '$')}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography> Total pagado:</Typography> <Typography>{moneyFormat(dataDepartment?.total_payable, '$')}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography> Balance:</Typography> <Typography>{moneyFormat(dataDepartment?.total_balance, '$')}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography> Balance propietarios:</Typography> <Typography>{moneyFormat(dataDepartment?.total_balance_owners, '$')}</Typography>
                                            </Grid>
                                            <CustomTable
                                                title={''}
                                                columns={columns}
                                                rows={dataDepartment.data_pay || []}
                                                onRowClick={() => { }}
                                            />
                                        </>
                                    )
                                })
                            }
                        </Grid>
                    </>:
                    <NotData/>
                }

            </Grid>
        </>
    )
}