import { useEffect, useState } from "react";
import { Button, Grid, Typography, AccordionDetails, ListItemButton, Box, Skeleton, styled, Divider, Tooltip, ListItemIcon, Card, CardContent } from "@mui/material"
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm"
import { useDispatch } from "../../redux/store";
import { ReservationAssignModal } from "./components/ReservationAssignModal"
import { useAuthStore } from "../../hooks";
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { useReservationStore } from "../../hooks/useReservationStore";
import { ApiStatus } from "../../types/api/status";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Reservation } from "../../types/slices/reservationType";
import { Role } from "../../types/roles/roleTypes";
import { ReservationServicesModal } from "./components/ReservationServicesModal";
import { ReservationPoliticsModal } from "./components/ReservationPoliticsModal";
import moment from "moment";
import 'moment/locale/es';

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'rgba(102, 102, 102, 0.4)',
}));

const skeletons:any = ['template1', 'template2']
  
export const ReservationView = () => {

    const dispatch = useDispatch();
    moment.locale('es');

    const { user } = useAuthStore()
    const { status: statusDepartment, selectedDepartment } = useDepartmentStore()
    const { status: statusReservation, reservationsByUser, selectedReservation, getReservations, setSelectedReservation, cancelReservation, getReservationsByTypeUser } = useReservationStore()

    const userData:any = user?.data

    const num_bathrooms = selectedDepartment?.detail?.num_bathrooms || 0;
    const num_kitchen = selectedDepartment?.detail?.num_kitchen || 0;
    const num_rooms = selectedDepartment?.detail?.num_rooms || 0;

    const [showDetail, setShowDetail] = useState<boolean>(false);
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
    const [showModalAssign, setShowModalAssign] = useState<boolean>(false);const [showModalServices, setShowModalServices] = useState<boolean>(false);
    const [showModalPolitics, setShowModalPolitics] = useState<boolean>(false);

    const [typeList, setTypeList] = useState<any>('card');

    useEffect(()=>{
        if(selectedDepartment.id) onSearchReservation()
    }, [selectedDepartment])

    const onSearchReservation = () => {
      let idowner:any;
      switch (user.userType) {
        case Role.OWNER:
          idowner = userData?.id;
          break;
        case Role.FAMILY:
          idowner = userData?.idowner;
          break;
      }
        const params:any = {
            iddepartment: selectedDepartment.id,
            idowner,
            status: 1
        }
        getReservationsByTypeUser(params);
    }

    const onCancel = (reservation) => {
        setSelectedReservation(reservation)
        setShowModalConfirm(true);
    }

    const onCancelConfirm = async (e) => {
        if (selectedReservation.id) {
            const response = await cancelReservation(selectedReservation.id)
            if(response){
                setSelectedReservation({} as Reservation)
                setShowModalConfirm(false)
                onSearchReservation()
            }else{
                return false;
            }
        }
    }

    return (
        <>

        {
          statusReservation == ApiStatus.FETCHED ? (
            <Grid item xs={12} container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom:'10px'}} marginBottom='10px'>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ textAlign: 'left' }}>
                <Grid sx={{paddingLeft:'30px'}}>
                  <Typography color='#808080'sx={{ fontSize:15, fontWeight:600}}>
                    {(selectedDepartment && selectedDepartment.id ) ? selectedDepartment.name : ''}
                  </Typography>
                  <Grid display='flex' alignItems='center'>
                    <Typography color='#808080' sx={{ fontSize:14, fontWeight:100}}>
                      {(selectedDepartment && selectedDepartment.id ) ? selectedDepartment.address : ''}
                    </Typography>
                    <ListItemIcon>
                      <LocationOnIcon/>
                    </ListItemIcon>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ textAlign: 'right' }}>
                <Button
                  onClick={()=>{setShowModalServices(true)}}
                  sx={{ marginRight: "10px", border: 'solid 1px #73B2FF',mt: "10px",color:'#73B2FF', '&:hover':{ bgcolor:'#73B2FF', color: '#fff'}}}
                >
                {'Servicios'}
                </Button>
                <Button
                  onClick={()=>{setShowModalPolitics(true)}}
                  sx={{ border: 'solid 1px #73B2FF',mt: "10px",color:'#73B2FF', '&:hover':{ bgcolor:'#73B2FF', color: '#fff'}}}
                >
                {'Políticas'}
                </Button>
              </Grid>
            </Grid>
          ) : (
              <Grid item xs={12} container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom: '10px' }}>
                <Grid item xs={8} sx={{ textAlign: 'left' }}>
                  <Grid xs={12} sx={{paddingLeft:'30px', paddingTop:'10px'}}>
                    <Skeleton width="10%"/>
                    <Grid display='flex' alignItems='center'>
                      <Skeleton width="15%"/>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} sx={{ justifyContent: "end", alignItems: "end", display: "flex" }}>
                  <Skeleton width="50%" height="60px"/>
                </Grid>
            </Grid>
          )
        }
        {/* <Collage 
            title = {(selectedDepartment && selectedDepartment.id ) ? selectedDepartment.name : ''} 
            subtitle = {(selectedDepartment && selectedDepartment.id ) ? selectedDepartment.address : ''} 
            icon= { <LocationOnIcon/> }
            loading={statusDepartment === ApiStatus.FETCHING ? true : false}
        /> */}

        <Grid item container sx={{padding:'10px 30px 5px 30px'}}>
            <Grid item container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom:2}}>
                <Grid xs={12} sm={6}>
                    <Typography variant='body1' color='#808080' sx={{ fontWeight:400}}>{'Mis reservas en curso'}</Typography>
                </Grid>
            </Grid>

            <Grid item container justifyContent='center'>
                {
                    statusReservation == ApiStatus.FETCHED && reservationsByUser.length > 0 && reservationsByUser.map((item, key) => (
                        <Card key={key} sx={{ minWidth: 400, margin:'5px' }}>
                            <CardContent>
                                <Grid item xs={12} display='flex' alignItems='center' justifyContent='space-between'>
                                    <Grid xs={6} >
                                        <Tooltip title={'fecha de reserva'}>
                                        <ListItemButton>
                                            <Typography sx={{ color: 'text.secondary', fontSize:'12px' }} >
                                                {moment(item.reservation_date).format('D [de] MMMM [de] YYYY') || '-----'}
                                            </Typography>
                                        </ListItemButton>
                                        </Tooltip>
                                    </Grid>
                                    <Grid xs={6} sx={{ textAlign: 'right'}}>
                                        {
                                            item.idlabel && (
                                                <Button 
                                                sx={{ 
                                                    backgroundColor: item.idlabel == 3 ? '#ef491b' : (item.idlabel == 2 ? '#60d711' : '#1180d7'),
                                                    color:'#FFFFFF',
                                                }}
                                                >
                                                    {item.name_label}
                                                </Button>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                                <Grid xs={12}>
                                    <AccordionDetails>
                                        <ListItemButton>
                                            <Typography sx={{ width: '30%', flexShrink: 0 }}>Solicitante:</Typography>
                                            <Typography sx={{ color: 'text.secondary' }}>
                                            {item.name_invited ? item.name_invited : (item.name_owner ? item.name_owner : '-----')}
                                            </Typography>
                                        </ListItemButton>

                                        <ListItemButton>
                                            <Typography sx={{ width: '30%', flexShrink: 0 }}>Llegada:</Typography>
                                            <Typography sx={{ color: 'text.secondary' }}>
                                            {moment(item.startDate).format('D [de] MMMM [de] YYYY') || '-----'}
                                            </Typography>
                                        </ListItemButton>

                                        <ListItemButton>
                                            <Typography sx={{ width: '30%', flexShrink: 0 }}>Salida:</Typography>
                                            <Typography sx={{ color: 'text.secondary' }}>
                                            {moment(item.endDate).format('D [de] MMMM [de] YYYY') || '-----'}
                                            </Typography>
                                        </ListItemButton>
                                        <Divider />
                                    </AccordionDetails>
                                
                                    {/* detalle del departamento */}
                                    <Grid display='flex' justifyContent='center' marginBottom={1}>
                                        <Typography color='#101010' sx={{ fontWeight:400, marginRight:3, fontSize:'14px'}}>
                                            {num_rooms} dormitorio(s) - {num_kitchen} cocina(s) - {num_bathrooms} baño(s) 
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>

                            <Grid item container display='flex' justifyContent='center'>
                                <Button
                                    onClick={() => {onCancel(item)}}
                                    sx={{ margin: 1, width:'200px', border: 'solid 1px #73B2FF', color:'#73B2FF', fontWeight:'bold', '&:hover':{ bgcolor:'#73B2FF', color: '#fff'}}}
                                >
                                    {'Cancelar'}
                                </Button>
                            </Grid>
                        </Card>
                    ))
                }
                {
                    (statusReservation == ApiStatus.FETCHED && reservationsByUser.length == 0) && (
                        <Grid sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px', marginBottom:'10px', width: '100%'}}>
                            <Div>{"NO TIENES RESERVACIONES EN CURSO"}</Div>
                        </Grid>
                    )
                }
                {
                    statusReservation == ApiStatus.FETCHING && skeletons.map((item, key)=> (
                        <Card key={key} sx={{ minWidth: 400, margin:'5px' }}>
                            <CardContent>
                                <Grid item container display='flex'>
                                    <Grid item xs={12} display='flex' alignItems='center' justifyContent='space-between'>
                                        <ListItemButton>
                                            <Skeleton width="70%" />
                                            <span style={{marginRight:'5px', marginLeft:'15px'}}></span>
                                            <Skeleton width="20%" height='50px'/>
                                        </ListItemButton>
                                    </Grid>
                                    <Grid xs={12}>
                                        <AccordionDetails>
                                            <ListItemButton>
                                                <Skeleton width="30%" />
                                                <span style={{marginRight:'5px', marginLeft:'5px'}}>:</span>
                                                <Skeleton width="60%" />
                                            </ListItemButton>

                                            <ListItemButton>
                                                <Skeleton width="30%" />
                                                <span style={{marginRight:'5px', marginLeft:'5px'}}>:</span>
                                                <Skeleton width="60%" />
                                            </ListItemButton>

                                            <ListItemButton>
                                                <Skeleton width="30%" />
                                                <span style={{marginRight:'5px', marginLeft:'5px'}}>:</span>
                                                <Skeleton width="60%" />
                                            </ListItemButton>

                                            <Divider />

                                            <Grid display='flex' justifyContent='center' marginTop={1}>
                                                <ListItemButton>
                                                    <Skeleton width="40%" />
                                                    <span style={{marginRight:'5px', marginLeft:'5px'}}>:</span>
                                                    <Skeleton width="40%" />

                                                    <span style={{marginRight:'5px', marginLeft:'5px'}}></span>

                                                    <Skeleton width="40%" />
                                                    <span style={{marginRight:'5px', marginLeft:'5px'}}>:</span>
                                                    <Skeleton width="40%" />
                                                </ListItemButton>
                                            </Grid>

                                            <Divider />
                                        </AccordionDetails>
                                        
                                        {/* detalle del departamento */}
                                        <Grid display='flex' justifyContent='center' marginBottom={1}>
                                            <ListItemButton>
                                                <Skeleton width="40%" />
                                                <span style={{marginRight:'5px', marginLeft:'5px'}}>-</span>
                                                <Skeleton width="40%" />
                                                <span style={{marginRight:'5px', marginLeft:'5px'}}>-</span>
                                                <Skeleton width="40%" />
                                            </ListItemButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item container display='flex' justifyContent='center'>
                                    <Skeleton width="30%" />
                                </Grid>
                            </CardContent>
                        </Card>
                    ))
                }
            </Grid>
            
            {/* {
                statusReservation == ApiStatus.FETCHED && (

                    <ReservationDetail/>
                )
            } */}

        </Grid>

        {
            showModalConfirm && (
                <ModalConfirm
                    open={showModalConfirm}
                    title={'Confirmación de la "Cancelación de la reserva"'}
                    text={'La reserva puede ser cancelada hasta 72 horas antes de la misma.'}
                    closeModal={()=>{setShowModalConfirm(false)}}
                    onConfirm={onCancelConfirm}
                    onCancel={()=>{
                        setShowModalConfirm(false)
                        setSelectedReservation({} as Reservation)
                    }}
                    status2={statusReservation == ApiStatus.FETCHING ? true : false}
                />
            )
        }
        {
            showModalAssign && (
                <ReservationAssignModal
                    open={showModalAssign}
                    closeModal={()=>{setShowModalAssign(false)}}
                />
            )
        }
        {
            showModalServices && (
                <ReservationServicesModal
                    open={showModalServices}
                    closeModal={()=>{setShowModalServices(false)}}
                />
            )
        }
        {
            showModalPolitics && (
                <ReservationPoliticsModal
                    open={showModalPolitics}
                    closeModal={()=>{setShowModalPolitics(false)}}
                />
            )
        }
        </>
    )
}