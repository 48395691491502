import { Grid, Typography, Card, Avatar, TextField, Divider, Accordion, AccordionDetails, AccordionSummary, Skeleton } from '@mui/material';
import { Formik } from 'formik';
import { CustomFormFooter } from '../../components/common/CustomForm/CustomFormFooter';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuthStore } from '../../hooks';
import { Role } from '../../types/roles/roleTypes';
import { useOwnerStore } from '../../hooks/useOwnerStore';
import { useUserStore } from '../../hooks/UseUserStore';

type Values = {
    userType: string,
    name: string,
    rut: string,
    phone: string,
    email: string,
}

export const MyProfileView = () => {


    const { findOwner, findInvitedByOwner, editOwner, editInvitedByOwner } = useOwnerStore();
    const { findAdmin, editAdmin } = useUserStore();

    const { user } = useAuthStore()
    const datalogin:any = user || ''
    const dataUser:any = user?.data || ''
    const role:any = user?.userType || ''

    const [edit, setEdit] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadEdit, setLoadEdit] = useState<boolean>(false);

    const [data, setData] = useState<Values>({
        userType: '',
        name: '',
        rut: '',
        phone: '',
        email: '',
    });

    useEffect(()=>{
        datausersession(role, dataUser.id)
    }, [datalogin])
  
    const getInvitedByOwnerApi = async(idinvitedbyowner) => {
        try {
          setLoading(true);
          const response = await findInvitedByOwner(idinvitedbyowner);
          if(response){
            setLoading(false);
            setData({
                ...response,
                userType: datalogin ? datalogin.userType : '',
                name: response ? response.name : '',
                rut: response ? response.rut : '',
                phone: response ? response.phone : '',
                email: response ? response.email : '',
            })
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const getOwnerApi = async(idowner) => {
        try {
          setLoading(true);
          const response = await findOwner(idowner);
          if(response){
            setLoading(false);
            setData({
                userType: datalogin ? datalogin.userType : '',
                name: response ? response.name : '',
                rut: response ? response.rut : '',
                phone: response ? response.phone : '',
                email: response ? response.email : '',
            })
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const getAdminApi = async(idadmin) => {
        try {
          setLoading(true);
          const response = await findAdmin(idadmin);
          if(response){
            setLoading(false);
            setData({
                userType: datalogin ? datalogin.userType : '',
                name: response ? response.name : '',
                rut: response ? response.rut : '',
                phone: response ? response.phone : '',
                email: response ? response.email : '',
            })
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const datausersession = (typeuser, id) => {
        switch (typeuser) {
            case Role.SUPER_ADMIN:
                setData({
                    userType: datalogin ? datalogin.userType : '',
                    name: '',
                    rut: '',
                    phone: '',
                    email: '',
                })
              break
            case Role.ADMIN:
                getAdminApi(id)
              break
            case Role.OWNER:
                getOwnerApi(id)
              break
            case Role.FAMILY:
                getInvitedByOwnerApi(id)
              break
            default:
              break
        }
    }

    const validateForm = (values) => {
        let errors:any = {};
        if(!values.name) errors.name = "nombre requerido";
        if(!values.email) errors.email = "correo requerido";
        if(!values.phone) errors.phone = "telefono requerido";
        return errors;
    }

    const onSubmit = async(values)  => {
        if(role == Role.ADMIN){
            setLoadEdit(true)
            let res = await editAdmin(dataUser.id, {...values, idcompany: dataUser.idcompany})
            if(res) getAdminApi(dataUser.id)
            setLoadEdit(false)
        }
        if(role == Role.OWNER){
            setLoadEdit(true)
            const res = await editOwner(dataUser.id, values)
            if(res) getOwnerApi(dataUser.id)
            setLoadEdit(false)
        }
        if(role == Role.FAMILY){
          setLoadEdit(true)
          const getData:any = data;
            
          const res = await editInvitedByOwner(dataUser.id, {...values, idowner: getData?.idowner, idguest_type: getData?.idguest_type })
          if(res) getInvitedByOwnerApi(dataUser.id)
          setLoadEdit(false)
        }
        setEdit(false)
    }

    return (
        <Grid container  sx={{padding:'30px 30px 30px 30px'}}>
            <Grid item container xs={12} display='flex' justifyContent='center' alignItems='center' mb={2}>
                <Card sx={{ width: '700px', display:'flex', justifyContent:'start', alignItems:'center', border:'solid 1px #808080', padding:'10px' }}>
                    <Grid sx={{mr:3}}>
                        <Avatar
                            alt="Remy Sharp"
                            src=""
                            sx={{ width: 56, height: 56 }}
                        />
                    </Grid>
                    <Grid sx={{width: '100%'}}>
                        <Grid xs={12}>
                            {
                                !loading ? (
                                    <Typography color='#212D39' sx={{ fontSize:24, fontWeight:600}}>
                                        {role == Role.SUPER_ADMIN ? 'Super Administrador' : data.name || '' }
                                    </Typography>
                                ) : (
                                    <Skeleton width="100%" />
                                )
                            }
                            
                        </Grid>
                        <div><Typography color='#212D39' sx={{ fontSize:12, fontWeight:400}}>{'Modifica los datos relacionados a tu perfil'}</Typography></div>
                    </Grid>
                </Card>
            </Grid>
            <Grid item container xs={12} display='flex' justifyContent='center' alignItems='center' mb={2}>
                <Accordion  sx={{width:'700px'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Mis Datos</Typography>
                    </AccordionSummary>
                    <Divider />
                    <AccordionDetails>
                        <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                            {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Grid container sx={{padding:'20px'}}>

                                            {
                                                role != Role.SUPER_ADMIN && (
                                                    <>
                                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                        <Typography sx={{ width: '60%', flexShrink: 0 }}>Nombre:</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                                            {
                                                                !loading ? (
                                                                    <TextField
                                                                        id="name"
                                                                        variant="standard"
                                                                        type="text" 
                                                                        name="name"
                                                                        multiline
                                                                        fullWidth
                                                                        value={values.name}
                                                                        onChange={handleChange}
                                                                        placeholder={"name"}
                                                                        error={errors.name && touched.name ? true : false}
                                                                        helperText={errors.name && touched.name ? errors.name: ''}
                                                                        inputProps={{ readOnly: edit ? false : true }}
                                                                    />
                                                                ) : (
                                                                    <Skeleton width="100%" />
                                                                )
                                                            }
                                                        
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                        <Typography sx={{ width: '60%', flexShrink: 0 }}>Rut:</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                                            {
                                                                !loading ? (
                                                                    <TextField
                                                                        id="rut"
                                                                        variant="standard"
                                                                        type="text" 
                                                                        name="rut"
                                                                        multiline
                                                                        fullWidth
                                                                        value={values.rut}
                                                                        onChange={handleChange}
                                                                        placeholder={"rut"}
                                                                        error={errors.rut && touched.rut ? true : false}
                                                                        helperText={errors.rut && touched.rut ? errors.rut: ''}
                                                                        inputProps={{ readOnly: true }}
                                                                    />
                                                                ) : (
                                                                    <Skeleton width="100%" />
                                                                )
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                        <Typography sx={{ width: '60%', flexShrink: 0 }}>Correo:</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                                            {
                                                                !loading ? (
                                                                    <TextField
                                                                        id="email"
                                                                        variant="standard"
                                                                        type="email" 
                                                                        name="email"
                                                                        multiline
                                                                        fullWidth
                                                                        value={values.email}
                                                                        onChange={handleChange}
                                                                        placeholder={"email"}
                                                                        error={errors.email && touched.email ? true : false}
                                                                        helperText={errors.email && touched.email ? errors.email: ''}
                                                                        inputProps={{ readOnly: edit ? false : true }}
                                                                    />
                                                                ) : (
                                                                    <Skeleton width="100%" />
                                                                )
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                        <Typography sx={{ width: '60%', flexShrink: 0 }}>Teléfono:</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                                            {
                                                                !loading ? (
                                                                    <TextField
                                                                        id="phone"
                                                                        variant="standard"
                                                                        type="number" 
                                                                        name="phone"
                                                                        multiline
                                                                        fullWidth
                                                                        value={values.phone}
                                                                        onChange={(e) => {
                                                                            const regex = /^[0-9\b]+$/;
                                                                            if (e.target.value === "" || regex.test(e.target.value)) {
                                                                              setFieldValue('phone', e.target.value)
                                                                            }
                                                                        }}
                                                                        placeholder={"phone"}
                                                                        error={errors.phone && touched.phone ? true : false}
                                                                        helperText={errors.phone && touched.phone ? errors.phone: ''}
                                                                        inputProps={{ readOnly: edit ? false : true }}
                                                                    />
                                                                ) : (
                                                                    <Skeleton width="100%" />
                                                                )
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    </>
                                                )
                                            }
                                            
                                            <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                <Typography sx={{ width: '60%', flexShrink: 0 }}>Tipo de usuario:</Typography>
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                                {
                                                    !loading ? (
                                                        <TextField
                                                            id="userType"
                                                            variant="standard"
                                                            type="text" 
                                                            name="userType"
                                                            fullWidth
                                                            value={values.userType}
                                                            onChange={handleChange}
                                                            placeholder={"userType"}
                                                            error={errors.userType && touched.userType ? true : false}
                                                            helperText={errors.userType && touched.userType ? errors.userType: ''}
                                                            inputProps={{ readOnly: true }}
                                                        />
                                                    ) : (
                                                        <Skeleton width="100%" />
                                                    )
                                                }
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Divider />

                                        {
                                            (edit && role != Role.SUPER_ADMIN && !loadEdit) && (
                                                <CustomFormFooter 
                                                    buttonType="submit"
                                                    confirmText={'Guardar Cambios'}
                                                    onConfirm={handleSubmit}
                                                    cancelText={"Descartar cambios"}
                                                    onCancel={()=>{setEdit(false)}}
                                                />
                                            )
                                        }
                                        {
                                            (!edit && role != Role.SUPER_ADMIN && !loadEdit) && (
                                                <CustomFormFooter 
                                                    buttonType="submit"
                                                    cancelText={"Editar mis Datos"}
                                                    onCancel={()=>{setEdit(true)}}
                                                />
                                            )
                                        }
                                        {
                                            loadEdit && (
                                                <Grid sx={{width: '100%', textAlign:'end', mt:2}}>
                                                <Typography color='#212D39' sx={{ fontSize:14, fontWeight:400}}>{'Espere un momento...'}</Typography>
                                                </Grid>
                                            )
                                        }
                                        
                                    </form>
                                )
                            }} 
                        </Formik>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            {/* <Grid item container xs={12} display='flex' justifyContent='center' alignItems='center' mb={2}>
                <Accordion  sx={{width:'700px'}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Privacidad</Typography>
                    </AccordionSummary>
                    <AccordionDetails>

                    </AccordionDetails>
                </Accordion>
            </Grid> */}
        </Grid>
    )
}