import { useDispatch, useSelector } from "react-redux"
import { changeStatus, onFetchPaymentConcepts, onFetchSpentLevel, onSetSelectedDetail, onSetSelectedPaymentConcept, selectPaymentConceptState, setAction } from "../redux/slices/paymentConceptSlice"
import { ApiStatus } from "../types/api/status"
import { PaymentConceptAPI } from "../apis/PaymentConceptAPI"
import { PaymentConcept } from "../types/slices/paymentConceptType"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"

export const usePaymentConceptStore = () => {
  const dispatch = useDispatch()
  const { paymentConcepts, status, selectedPaymentConcept, selectedDetail, action, spentLevel } = useSelector(selectPaymentConceptState)
  
  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany

  const getPaymentConcepts = async (data?: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await PaymentConceptAPI.getPaymentConcepts(data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      const { detail } = response.data
      dispatch(onFetchPaymentConcepts(detail))
      dispatch(changeStatus(ApiStatus.FETCHED))
    } catch (error) {
      console.error(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const createPaymentConcept = async (data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await PaymentConceptAPI.createPaymentConcept(data, idcompany)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const editPaymentConcept = async (idpayment_concept: number, data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await PaymentConceptAPI.editPaymentConcept(idpayment_concept, data, idcompany)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const deletePaymentConcept = async (idpayment_concept: number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await PaymentConceptAPI.deletePaymentConcept(idpayment_concept)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('error', response.data.message || "");
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const setSelectedPaymentConcept = (payment_concept: PaymentConcept) => {
    try {
      dispatch(onSetSelectedPaymentConcept(payment_concept))
    } catch (error) {
      console.log(error)
    }
  }

  const setSelectedDetail = (payment_concept: PaymentConcept) => {
    try {
      dispatch(onSetSelectedDetail(payment_concept))
    } catch (error) {
      console.log(error)
    }
  }

  const setActionPayment = (state) => {
    try {
        dispatch(setAction(state))
    } catch (error) {
        console.log(error)
    }
  }

  const getSpentLevel = async () => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await PaymentConceptAPI.getSpentLevel()
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      const { detail } = response.data
      dispatch(onFetchSpentLevel(detail))
      dispatch(changeStatus(ApiStatus.FETCHED))
    } catch (error) {
      console.error(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  return {
    //states
    paymentConcepts,
    status,
    selectedPaymentConcept,
    selectedDetail,
    spentLevel,
    action,

    //actions
    getPaymentConcepts,
    createPaymentConcept,
    editPaymentConcept,
    deletePaymentConcept,
    setSelectedPaymentConcept,
    setSelectedDetail,
    setActionPayment,
    getSpentLevel,
  }
}