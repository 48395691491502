import { Grid, ButtonGroup, IconButton, Typography, Autocomplete, Button, TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDepartmentStore } from '../../../hooks/useDepartmentStore';
import { useOwnerStore } from '../../../hooks/useOwnerStore';
import SearchIcon from '@mui/icons-material/Search'
import { useReportStore } from '../../../hooks/useReportStore';
import { useEffect, useState } from 'react';

const theme = createTheme({
    typography: {
        fontSize: 12, // Tamaño de texto personalizado
    },
});

const years = [
    { id: 2023, name: '2023' },
    { id: 2024, name: '2024' },
    { id: 2025, name: '2025' },
    { id: 2026, name: '2026' },
    { id: 2027, name: '2027' },
    { id: 2028, name: '2028' },
    { id: 2029, name: '2029' },
    { id: 2030, name: '2030' },
    { id: 2031, name: '2031' },
    { id: 2032, name: '2032' },
    { id: 2033, name: '2033' },
];

export const FilterComponent = (props) => {

    const {submit} = props
    const { departments, getDepartments } = useDepartmentStore()
    const { owners, getOwners } = useOwnerStore()
    const { filter, setFilter, year, setYear, month, setMonth, department, setDepartment, owner, setOwner} = useReportStore();
    const [ stateGrilla, setStateGrilla ] = useState<any>(false);
   
    const onFilterBy = (e) => {
        const type = e.target.value;
        setFilter(type)
        switch (type) {
            case '01':
            case '02':
                setStateGrilla(false)
                getDepartments()
                break;
            case '03':
            case '04':
                setStateGrilla(false)
                getOwners()
                break;
            case '05':
            case '06':
                setStateGrilla(true)
                getOwners()
                getDepartments()
                break;
            default:
                break;
        }
    }

    useEffect(()=>{
        setFilter('01')
        getDepartments()
    },[])

    return (
        <>
            <Grid item container xs={12} direction="row" justifyContent="space-between" alignItems="center" sx={{ border: 'solid 1px #e9e2e2', borderRadius: '10px' }}>
                <Grid xs={12} sm={12} md={3} lg={3} xl={3} sx={{ padding: '7px' }}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" style={{ fontSize: "13px" }}>Tipo de filtrado</InputLabel>
                        <Select
                            id="combo-box-department"
                            size="small"
                            value={filter}
                            onChange={(event) => { onFilterBy(event) }}
                            sx={{ width: '240px', fontSize: "12px" }}
                            label='Tipo de filtrado'
                        >
                            <MenuItem value={'01'}>{"Por departamento (mes)"}</MenuItem>
                            <MenuItem value={'02'}>{"Por departamento (año)"}</MenuItem>
                            <MenuItem value={'03'}>{"Por propietario (mes)"}</MenuItem>
                            <MenuItem value={'04'}>{"Por propietario (año)"}</MenuItem>
                            <MenuItem value={'05'}>{"Por propietario y departamento (mes)"}</MenuItem>
                            <MenuItem value={'06'}>{"Por propietario y departamento (año)"}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={12} sm={12} md={stateGrilla? 12 : 9} lg={stateGrilla? 12 : 9} xl={stateGrilla? 12 : 9} item container display='flex' 
                    sx={{
                        width:'100%',
                        alignItems: 'center', 
                        justifyContent:{ xs: 'start', sm: 'start', md:'end', lg:'end', xl:'end'}, 
                    }}
                >
                    <Grid item display='flex' alignItems='center' justifyContent='end'>
                        <Grid item container>
                        {
                            (filter == '01' || filter == '02' || filter == '05' || filter == '06') && (
                                <ThemeProvider theme={theme}>
                                    <Autocomplete
                                        id="combo-box-department"
                                        size="small"
                                        options={departments}
                                        value={department}
                                        getOptionLabel={(departament) => departament?.name}
                                        renderInput={(params) => <TextField {...params} placeholder="buscar..." label="Departamento" size="small" InputLabelProps={{
                                            shrink: true,
                                        }} />}
                                        sx={{ width: '240px', padding: '8px', }}
                                        onChange={(e, newValue) => {
                                            setDepartment(newValue);
                                        }}
                                    />
                                </ThemeProvider>
                            )
                        }
                        {
                            (filter == '03' || filter == '04' || filter == '05' || filter == '06') && (
                                <ThemeProvider theme={theme}>
                                    <Autocomplete
                                        id="combo-box-department"
                                        size="small"
                                        options={owners}
                                        value={owner}
                                        getOptionLabel={(owner) => owner.name}
                                        renderInput={(params) => <TextField {...params} placeholder="buscar..." label="Propietario" size="small" InputLabelProps={{
                                            shrink: true,
                                        }} />}
                                        sx={{ width: '240px', padding: '8px' }}
                                        onChange={(e, newValue) => {
                                            setOwner(newValue);
                                        }}
                                    />
                                </ThemeProvider>
                            )
                        }
                        {
                            (filter == '01' || filter == '03' || filter == '05') && (
                                <TextField
                                    id="date"
                                    type="month"
                                    name="date"
                                    size="small"
                                    value={month}
                                    onChange={(e) => { setMonth(e.target.value) }}
                                    sx={{ width: '240px', padding: '8px' }}
                                    inputProps={{ style: { fontSize: "12px" } }}
                                />
                            )
                        }
                        {
                            (filter == '02' || filter == '04' || filter == '06') && (
                                <ThemeProvider theme={theme}>
                                    <Autocomplete
                                        id="combo-box-year"
                                        size="small"
                                        value={year}
                                        options={years}
                                        getOptionLabel={(year) => year.name}
                                        isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                        sx={{ width: '240px', padding: '8px' }}
                                        renderInput={(params) => <TextField {...params} placeholder="seleccione..." label="Año"  size="small" InputLabelProps={{
                                            shrink: true,
                                        }}/>}
                                        onChange={(e, newValue) => {
                                            setYear(newValue)
                                        }}
                                    />
                                </ThemeProvider>
                            )
                        }
                        </Grid>
                    </Grid>
                    <Grid item display='flex' justifyContent='space-between' paddingRight='5px'>
                        <Button
                            startIcon={<SearchIcon />}
                            onClick={submit}
                            size="small"
                            variant="contained"
                            sx={{ height: '35px', backgroundColor: '#212D39', color: '#fff', textTransform: 'none', padding: '4px 10px', "&:hover": { backgroundColor: "#212D39" }, fontSize: "12px", marginLeft:'6px', marginRight:'3px' }}>
                            Filtrar busqueda
                        </Button>
                        <Button
                            onClick={() => setFilter('01')}
                            size="small"
                            variant="contained"
                            sx={{ height: '35px', backgroundColor: '#5a5959', color: '#fff', textTransform: 'none', padding: '4px 10px', "&:hover": { backgroundColor: "#a79f9f" }, fontSize: "12px" }}>
                            Cancelar filtro
                        </Button>
                    </Grid>
                </Grid>

            </Grid>
        </>
    )
}