import { Formik } from "formik";
import { Modal } from "../../components/common/Modal/Modal";
import { ModalBody } from "../../components/common/Modal/ModalBody";
import { ModalHeader } from "../../components/common/Modal/ModalHeader";
import { ModalFooter } from "../../components/common/Modal/ModalFooter";
import { Divider, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSeasonStore } from "../../hooks/useSeasonStore";
import { useDepartmentStore } from "../../hooks/useDepartmentStore";

type Values = {
    id: number | undefined
    rut: string
    name: string
    phone: string
    email: string
    typeguest: string
    idowner: number | undefined
    idguest_type: number | undefined
}

export const GuestAdminModal: React.FC<any> = (props): JSX.Element | any => {

    const { open, closeModal, rowSelected, clearState } = props;

    const { seasonSelected } = useSeasonStore();
    const { departments, getDepartments, setSelectedDepartment, selectedDepartment } = useDepartmentStore()

    const [data, setData] = useState<Values>({
        id: rowSelected ? rowSelected.id :'',
        rut: rowSelected ? rowSelected.rut :'',
        name: rowSelected ? rowSelected.name :'',
        phone: rowSelected ? rowSelected.phone :'',
        email: rowSelected ? rowSelected.email :'',
        typeguest: rowSelected ? rowSelected.typeguest :'',
        idowner: rowSelected ? rowSelected.idowner :'',
        idguest_type: rowSelected ? rowSelected.idguest_type :'',
    });

    const validateForm = (values) => {}

    const onSubmit = async(values)  => {}

    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
            <ModalHeader
                text={'Detalle del Invitado'}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{padding:'20px'}}>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{"Rut"}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <TextField
                                            id="rut"
                                            variant="standard"
                                            type="text" 
                                            name="rut"
                                            multiline
                                            fullWidth
                                            value={values.rut}
                                            placeholder={"rut"}
                                            inputProps={{ readOnly: true }}
                                        />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{"Nombre"}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <TextField
                                            id="name"
                                            variant="standard"
                                            type="text" 
                                            name="name"
                                            multiline
                                            fullWidth
                                            value={values.name}
                                            placeholder={"nombre"}
                                            inputProps={{ readOnly: true }}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{"Teléfono"}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <TextField
                                            id="phone"
                                            variant="standard"
                                            type="text" 
                                            name="phone"
                                            multiline
                                            fullWidth
                                            value={values.phone}
                                            placeholder={"telefono"}
                                            inputProps={{ readOnly: true }}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{"Correo"}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <TextField
                                            id="email"
                                            variant="standard"
                                            type="text" 
                                            name="email"
                                            multiline
                                            fullWidth
                                            value={values.email}
                                            placeholder={"correo"}
                                            inputProps={{ readOnly: true }}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{"Tipo invitado"}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <TextField
                                            id="typeguest"
                                            variant="standard"
                                            type="text" 
                                            name="typeguest"
                                            multiline
                                            fullWidth
                                            value={values.typeguest}
                                            placeholder={"typeguest"}
                                            inputProps={{ readOnly: true }}
                                        />
                                        </Grid>
                                    </Grid>
                                
                                </Grid>

                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    cancelText={"Cancelar"}
                                    onCancel={closeModal}
                                />
                            </form>
                        )
                    }} 
                </Formik>
            </ModalBody>
        </Modal>
    )
}