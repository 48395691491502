import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { Rendition, RenditionType } from "../../types/slices/renditionType";
import { RootState } from "../store";

const initialState: RenditionType = {
    status: ApiStatus.FETCHED,
    renditions: [],
    errorMessage: undefined,
    selectedRendition: {} as Rendition,
    renditionOwner: [],
    idrendition: null
}

const renditionSlice = createSlice({
    name: 'rendition',
    initialState,
    reducers: {
        onFetchRenditions (state, { payload }: { payload: Rendition[] }) {
            state.status       = ApiStatus.FETCHED
            state.renditions    = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedRendition (state, { payload }: { payload: Rendition }) {
            state.selectedRendition = payload
        },
        onFetchRenditionOwner (state, { payload }: { payload: any }) {
            state.status         = ApiStatus.FETCHED
            state.renditionOwner = payload
            state.errorMessage   = undefined
        },
        onSetIdRendition (state, { payload }: { payload: number }) {
            state.idrendition = payload
        }, 
    }
})

export const selectRenditionState = (state: RootState) => state.renditionSlice
export default renditionSlice.reducer

export const {
    onFetchRenditions,
    changeStatus,
    onSetSelectedRendition,
    onFetchRenditionOwner,
    onSetIdRendition
} = renditionSlice.actions