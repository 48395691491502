import { DialogContent } from '@mui/material';
import '../../../assets/styles/modals.css';

export const ModalBody = (props:any) => {

    const { children } = props;

    return (
        <DialogContent  dividers>
            {children}
        </DialogContent>
    )
}
