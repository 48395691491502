import axios from 'axios'
import { LocalStorageKey } from '../../types';
import { readLocalStorage } from '../../toolbox/helpers/local-storage-helpers';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

/* api.interceptors.request.use((config) => {
  const token = readLocalStorage(LocalStorageKey.TOKEN_KYTE);
  config.headers.Authorization = `Bearer ${token}`;
  return config;
}, (error) => {
  return Promise.reject(error);
}); */
//Custom error handler
const errorHandler = (error) => {
  const statusCode = error.response?.data?.code
  // console.log(error.response?.data?.code)
  if (statusCode && statusCode == 401){
    console.error(error)
    localStorage.clear();
    window.location.href = '/login';
  }
  return Promise.reject(error)
}

api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error)
})

// api.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     console.log('nada', error.response )
//     if (error.response && error.response.code === 401) {
//       console.log('Error 401: Unauthorized');
//     }
//     return Promise.reject(error);
//   }
// );