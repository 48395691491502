import { Grid, Typography } from "@mui/material"

export const NotData = () => {
    return (
        <Grid sx={{ backgroundColor: '#f5f5f5',
        width: '80vw',
        height: '300px',
        mt: 3,
        borderRadius: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'}}>
            <Typography>Sin data para mostrar</Typography>
        </Grid>
    )
}