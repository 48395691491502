import { Button, Grid, FormControlLabel, RadioGroup, Radio, Typography } from "@mui/material";
import { ChangeEvent, FC, useState } from "react";

import CustomTable from "../../components/common/CustomTable/CustomTable";

const columns = [
    { type: 'text', field: 'column1', label: 'FECHA DE MODIFICACIÓN' },
    { type: 'text', field: 'column2', label: 'DEPARTAMENTO' },
    { type: 'text', field: 'column3', label: 'CONFIGURACIÓN DE HORAS' }
]

const datatable = [
    {
        column1: '12 de abril 2023',
        column2: 'Concon',
        column3: '48 horas'
    },
    {
        column1: '12 de abril 2023',
        column2: 'Concon',
        column3: '48 horas'
    },
    {
        column1: '12 de abril 2023',
        column2: 'Concon',
        column3: '48 horas'
    },
    {
        column1: '12 de abril 2023',
        column2: 'Concon',
        column3: '48 horas'
    },
    {
        column1: '12 de abril 2023',
        column2: 'Concon',
        column3: '48 horas'
    }
]

export const SettingAdminCession : FC = () => {

    const [ cessionType, setCessionType ] = useState<number>(1)

    const handleChangeCessionType = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const option = parseInt(event.target.value)
        setCessionType(option)
    }

    return (
        <Grid container sx={{ margin: '0px 20px' }}>
            <Grid item xs={12} md={12}>
                <Button
                    onClick={ () => null }
                    sx={{ color:'#73B2FF', border: '1px solid #73B2FF', borderRadius: 10, float: 'right', height: 30, margin:'0px 20px', padding: '6px 16px', textTransform: 'none', width: 210  }}
                >
                    Configurar departamento
                </Button>
            </Grid>
            <Grid item xs={12} md={12} sx={{ margin: '10px 0px' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bolder' }} variant="h6">Politicas de cesión:</Typography>
            </Grid>
            <Grid item container xs={12} md={12} sx={{ alignItems: 'center', display: 'flex', margin: '10px 0px' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: 300, marginRight: '10px' }}>Definir tipo de cesión:</Typography>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    row
                >
                    <FormControlLabel sx={{ padding: '0px 20px' }} value={1} checked={cessionType === 1 ? true : false} control={<Radio onChange={ (e) => handleChangeCessionType(e) } sx={{ color: '#000000', '&.Mui-checked': { color: '#000000' } }}/>} label="Equitativo" />
                    <FormControlLabel sx={{ padding: '0px 20px' }} value={2} checked={cessionType === 2 ? true : false} control={<Radio onChange={ (e) => handleChangeCessionType(e) } sx={{ color: '#000000', '&.Mui-checked': { color: '#000000' } }}/>} label="Negociable"/>
                </RadioGroup>
            </Grid>
            <Grid item xs={12} md={12}>
                <CustomTable
                columns={columns} 
                rows={datatable || []}
                />
            </Grid>
        </Grid>
    )
}