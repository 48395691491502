import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";

const initialState: any =
{
  status: ApiStatus.FETCHED,
  buildingSpents: [],
  buildingSpentsExisting: [],
  errorMessage: undefined,
  selectedBuildingSpent: {} as any,
  action: '',
}

const buildingSpentSlice = createSlice({
    name: 'buildingSpent',
    initialState,
    reducers: {
        onFetchBuildingSpent (state, { payload }: { payload: any[] }) {
            state.status = ApiStatus.FETCHED
            state.buildingSpents = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetBuildingSpentsExisting (state, { payload }: { payload: any[] }) {
            state.buildingSpentsExisting = payload
        },
        onSetSelectedBuildingSpent (state, { payload }: { payload: any }) {
            state.selectedBuildingSpent = payload
        },
        setAction (state, { payload }: { payload: string }) {
            state.action = payload
        },
    }
})

export const selectBuildingSpentState = (state: RootState) => state.buildingSpentSlice

export default buildingSpentSlice.reducer

export const {
  onFetchBuildingSpent,
  changeStatus,
  onSetBuildingSpentsExisting,
  onSetSelectedBuildingSpent,
  setAction
} = buildingSpentSlice.actions