import { Grid, Typography } from '@mui/material'
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';

export const RequestDepartmentsView = () => {
  return (
    <>
      <Grid xs={12} container justifyContent='center' textAlign='center' sx={{height:'500px', paddingTop:'30px'}}>
        <Grid xs={12} justifyContent='center' >
          <Grid>
            <NotificationImportantIcon sx={{fontSize: '100px', color:'#cbcbcbde'}} />
          </Grid>
          <Typography sx={{ color:'#716f6fde', fontSize: 14 }}>{'No cuenta con departamentos asignados. Comuníquese con su administrador.'}</Typography>
        </Grid>
      </Grid>
    </>
  );
}