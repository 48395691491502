import { useDispatch, useSelector } from "react-redux"
import { changeStatus, onFetchCompanies, onSetSelectedCompany, selectCompanyState, setAction } from "../redux/slices/companySlice"
import { ApiStatus } from "../types/api/status"
import { CompanyAPI } from "../apis/CompanyAPI"
import { Company } from "../types/slices/companyType"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar";

export const useCompanyStore = () => {

    const dispatch = useDispatch()
    const { companies, status, selectedCompany, action } = useSelector(selectCompanyState)

    const getCompanies = async () => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await CompanyAPI.getCompanies()
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            const { detail } = response.data
            dispatch(onFetchCompanies(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const createCompany = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await CompanyAPI.createCompany(data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || '')
            return true
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
    }

    const editCompany = async (idcompany: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await CompanyAPI.editCompany(idcompany, data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || '')
            return true
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
    }

    const deleteCompany = async (idcompany: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await CompanyAPI.deleteCompany(idcompany)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || '')
            return true
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
    }

    const setSelectedCompany = (company: Company) => {
        try {
            dispatch(onSetSelectedCompany(company))
        } catch (error) {
            console.log(error)
        }
    }

    const setActionCompany = (state) => {
        try {
            dispatch(setAction(state))
        } catch (error) {
            console.log(error)
        }
    }

    return {
        //states
        companies,
        status,
        selectedCompany,
        action,
        //actions
        getCompanies,
        createCompany,
        editCompany,
        deleteCompany,
        setSelectedCompany,
        setActionCompany,
    }
}