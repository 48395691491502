import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";

const initialState: any = {
    status: ApiStatus.FETCHED,
    renditionBuildingSpentIndividuals: [],
    errorMessage: undefined,
    selectedRenditionBuildingSpentIndividuals: {} as any
}

const renditionBuildingSpentIndividualSlice = createSlice({
    name: 'renditionSpentIndividual',
    initialState,
    reducers: {
        onFetchRenditionBuildingSpentIndividual (state, { payload }: { payload: any[] }) {
            state.status       = ApiStatus.FETCHED
            state.renditionBuildingSpentIndividuals = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedRenditionBuildingSpentIndividual (state, { payload }: { payload: any }) {
            state.selectedRenditionBuildingSpentIndividuals = payload
        }
    }
})

export const selectRenditionBuildingSpentIndividualState = (state: RootState) => state.renditionBuildingSpentIndividualSlice
export default renditionBuildingSpentIndividualSlice.reducer

export const {
    onFetchRenditionBuildingSpentIndividual,
    changeStatus,
    onSetSelectedRenditionBuildingSpentIndividual
} = renditionBuildingSpentIndividualSlice.actions