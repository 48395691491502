import { addDays } from 'date-fns'

export const formatDataFullCalendar = (reservation) => {
    try {
        //format de data YY-mm-dd HH:mm:ss
        const arrayFilter:any=[];
        if(reservation.length > 0 ){
            reservation.map((item)=>{
                let newDates = {
                    //idreserva / name_department / name_owner
                    title:`${item.id} / ${item.name_department} / ${item.name_owner}`,
                    start: new Date(item.startDate), 
                    end: addDays(new Date(item.endDate), 1),
                    // color: 'rgba(250, 35, 5, 0.4)',
                    color: '#212D39',
                }
                arrayFilter.push(newDates);
            })
            return arrayFilter;
        }else{
            return [];
        }
        
    } catch (error) {
        console.error(error);
    }
}