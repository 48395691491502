import MoreVertIcon from '@mui/icons-material/MoreVert'
import { IconButton, MenuList, Popover } from '@mui/material'
import { MouseEvent, ReactNode, useState } from 'react'

type OptionPopoverProps = {
  id: string | number
  children: ReactNode
}

export const CommonOptionPopover = (props: OptionPopoverProps) => {
  const { id, children } = props

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleOpenOption = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleCloseOption = () => {
    setAnchorEl(null)
  }

  const optionOpened = Boolean(anchorEl)
  const idPopoverOption = optionOpened ? `simple-popover-${id}` : undefined

  return (
    <>
      <IconButton onClick={handleOpenOption}>
        <MoreVertIcon style={{ cursor: 'pointer' }} />
      </IconButton>
      <Popover
        id={idPopoverOption}
        open={optionOpened}
        anchorEl={anchorEl}
        onClose={handleCloseOption}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuList>
          {children}
        </MenuList>
      </Popover>
    </>
  )
}