import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RenditionPaid, RenditionPaidType } from "../../types/slices/renditionPaidType";
import { RootState } from "../store";

const initialState: RenditionPaidType = {
    status: ApiStatus.FETCHED,
    renditionPaids: [],
    errorMessage: undefined,
    selectedRenditionPaid: {} as RenditionPaid
}

const renditionPaidSlice = createSlice({
    name: 'renditionPaid',
    initialState,
    reducers: {
        onFetchRenditionPaid (state, { payload }: { payload: RenditionPaid[] }) {
            state.status       = ApiStatus.FETCHED
            state.renditionPaids    = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedRenditionPaid (state, { payload }: { payload: RenditionPaid }) {
            state.selectedRenditionPaid = payload
        }
    }
})

export const selectRenditionPaidState = (state: RootState) => state.renditionPaidSlice
export default renditionPaidSlice.reducer

export const {
    onFetchRenditionPaid,
    changeStatus,
    onSetSelectedRenditionPaid
} = renditionPaidSlice.actions