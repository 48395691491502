import { useState } from 'react';
import { Grid, Typography, Card, CardContent, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { LocalStorageKey, RoutesMap, UserData } from '../../types';
import { ModalCalendar } from '../../components/common/ModalCalendar/ModalCalendar';
import { useCalendar } from '../../hooks/useCalendar';
import { CustomSnackbar } from '../../components/common/CustomSnackbar/CustomSnackbar';
import { ApiStatus } from '../../types/api/status';
import { CustomBackdrop } from '../../components/common/CustomBackdrop/CustomBackdrop';
import { ReservationDataForm } from '../../types/api/Reservation.type';
import { useReservationStore } from '../../hooks/useReservationStore';
import { useAuthStore } from '../../hooks';
import { CustomForm } from '../../components/common/CustomForm/CustomForm';
import { CustomFormHeader } from '../../components/common/CustomForm/CustomFormHeader';
import moment from 'moment';
import { Role } from '../../types/roles/roleTypes';
import { readLocalStorage } from '../../toolbox/helpers/local-storage-helpers';


export const ReservationConfirm = (props) => {
    const navigate = useNavigate();

    const { user } = useAuthStore()
    const { arrayDates, getCurrentRange, departament, owner, resetCalendar } = useCalendar();
    const { status: statusReservation, createReservation } = useReservationStore()

    const [showModalCalendar, setShowModalCalendar] = useState<boolean>(false);
    const idowner:any = user?.userType == Role.FAMILY ? user?.data?.idowner :  user?.data?.id
    const idinvited:any = user?.userType == Role.FAMILY ? user?.data?.id : null //new request - session invitado

    const userData = readLocalStorage(LocalStorageKey.USER_DATA)
    
    const idcompany = userData?.data?.idcompany
    const ownerName:any = user?.userType == Role.FAMILY ? userData?.data?.owner_guest_type?.name : userData?.data?.name;

    const currentRange = getCurrentRange();
    const arrivalDate = currentRange ? moment(currentRange.startDate).format('LL') : '-----';
    const returnDate = currentRange ? moment(currentRange.endDate).format('LL') : '-----';

    const checkIn = (departament?.detail && departament?.detail?.check_in) ? departament.detail.check_in + ' horas.' : '-----'
    const checkOut = (departament?.detail && departament?.detail?.check_out) ? departament.detail.check_out + ' horas.' : '-----'

    const clearState = () => {
      resetCalendar(); 
    }
    
    const onConfirmReservation = async() =>{
      if (!currentRange?.startDate && !currentRange?.endDate) return CustomSnackbar('warning', 'Aún no ha seleccionado su estadía.') 
      
      let getIdLabel;
      switch (user.userType) {
        case Role.OWNER:
          getIdLabel = 2;
          break
        case Role.FAMILY:
          getIdLabel = 1;
          break
      }
      
      const data:ReservationDataForm = {
        arrival_date: moment(currentRange.startDate).format('YYYY-MM-DD'),
        exit_date: moment(currentRange.endDate).format('YYYY-MM-DD'),
        iddepartment: departament.id,
        idowner: idowner,
        idinvited: idinvited,
        idlabel: getIdLabel, // 1:Invitado, 2:Propietario
        reservation_date: moment().format('YYYY-MM-DD'),
        idcompany: idcompany
      }

      const response = await createReservation(data);
      if (response){
        resetCalendar(); navigate({ pathname: RoutesMap.RESERVATION })
      }else{
        return false;
      }
    }

    return (
        <>
        { statusReservation === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }

        <CustomForm>

            <CustomFormHeader
                title={'Resumen de la Reservación'}
                text={'Asegurese de verificar los datos de la reservación'}
                goBack={RoutesMap.RESERVATION}
                clearState={clearState}
            >
            </CustomFormHeader>

            <Grid display='flex' justifyContent='center' alignItems='center' sx={{ width:'100%'}}>
                <Card onClick={()=>{setShowModalCalendar(true)}} sx={{ width: '600px', display:'flex', justifyContent:'center', alignItems:'center', border:'solid 1px #808080', cursor:'pointer' }}>
                    <Grid sx={{borderRight: '.5px solid #808080'}}>
                        <CardContent>
                            <Typography color='#808080' sx={{ fontWeight:400, fontSize:'14px'}}>
                                LLegada:
                            </Typography>
                            <Grid sx={{padding:'0px 40px 0px 40px', textAlign:'center'}}>
                                <Typography color='#212D39' sx={{ fontWeight:600, fontSize:'21px'}}>
                                    {arrivalDate}
                                </Typography>
                                {/* <Typography color='#212D39' sx={{ fontWeight:400, fontSize:'14px'}}>
                                    {checkIn}
                                </Typography> */}
                            </Grid>
                        </CardContent>
                    </Grid>
                    <Grid sx={{borderLeft: '.5px solid #808080'}}>
                        <CardContent>
                            <Typography color='#808080' sx={{ fontWeight:400, fontSize:'14px'}}>
                                Salida :
                            </Typography>
                            <Grid sx={{padding:'0px 40px 0px 40px', textAlign:'center'}}>
                                <Typography color='#212D39' sx={{ fontWeight:600, fontSize:'21px'}}>
                                    {returnDate}
                                </Typography>
                                {/* <Typography color='#212D39' sx={{ fontWeight:400, fontSize:'14px'}}>
                                    {checkOut}
                                </Typography> */}
                            </Grid>
                        </CardContent>
                    </Grid>
                </Card>
            </Grid>
            <Grid item justifyContent='center' alignContent='center' alignItems='center' 
                sx={{
                    padding:{ xs: '20px', sm: '20px', md:'20px 150px 20px 150px', lg:'20px 150px 20px 150px', xl:'20px 150px 20px 150px'},
                    width:'100%'
                }}
            >
                <Grid display='flex' mb={1}>
                    <Typography color='#000000' sx={{ fontWeight:600, pr:2, fontSize:'14px' }}>Reservador:</Typography>
                    <Typography sx={{fontSize:'14px'}}>{owner ? owner.name : '-----'}</Typography>
                </Grid>
                <Grid display='flex' mb={1}>
                    <Typography color='#000000' sx={{ fontWeight:600, pr:2, fontSize:'14px' }}>Departamento:</Typography>
                    <Typography  sx={{fontSize:'14px'}}>{departament ? departament.name : '-----'}</Typography>
                </Grid>
                <Grid display='flex' mb={1}>
                    <Typography color='#000000' sx={{ fontWeight:600, pr:2, fontSize:'14px' }}>Propietario:</Typography>
                    <Typography  sx={{fontSize:'14px'}}>{ownerName || '-----'}</Typography>
                </Grid>
                <Grid display='block' mb={2}>
                    <Typography color='#000000' sx={{ fontWeight:600, pr:2, mb:1,fontSize:'14px' }}>Reglas Fundamentales:</Typography>
                    <Typography mb={2} sx={{fontSize:'14px'}}>{'Pedimos a todos los huéspedes que recuerden algunas cosas sencillas sobre lo que hace que un huésped sea excelente.'}</Typography>

                    <div><Typography variant='body1' sx={{fontSize:'14px'}}>{'* Sigue las reglas de la casa'}</Typography></div>
                    <div><Typography variant='body1' sx={{fontSize:'14px'}}>{'* Trata el alojamiento del anfitrión como si fuera tuyo'}</Typography></div>
                </Grid>
                <Grid display='block' mb={1}>
                    <Typography color='#000000' sx={{ fontWeight:600, pr:2,fontSize:'14px' }}>Políticas de cancelación:</Typography>
                </Grid>
                <Grid display='flex' mb={1} alignItems='center'>
                    <Typography color='#000000' sx={{ fontWeight:500, pr:2, fontSize:'14px' }}>Esta reservación no es reembolsable:</Typography>
                    <Grid sx={{cursor:'pointer'}}>
                        <Typography color='#212D39' sx={{ fontWeight:600, '&:hover':{ color: '#808080'}, fontSize:'16px' }}>{'Más información'}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid display='flex' justifyContent='center' alignItems='center' sx={{ width:'100%'}}>
                <Button onClick={onConfirmReservation} size="small" variant="contained" sx={{background:'#212D39',color:'#fff', '&:hover':{bgcolor:'#007ea7'}}}>
                    {'Confirmar reservación'}
                </Button>
            </Grid>
        </CustomForm>

        {
            showModalCalendar && (
                <ModalCalendar
                    open={showModalCalendar}
                    closeModal={()=>{setShowModalCalendar(false)}}
                    datesRegister={arrayDates}
                    onReservation={() => {navigate('/reservation/nameDepartament')}}
                />
            )
        }
        </>
    )
}