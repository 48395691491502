import { Grid } from '@mui/material';

export const CustomForm = (props:any) => {

    const { open, handleClose, size="", children, className, disableBackdropClick, disableEscapeKeyDown} = props;

    return (
        <Grid>
            {children}
        </Grid>
    )
}