import { DialogActions,  Button} from '@mui/material';
export const CustomFormFooter = (props:any) => {

  const { 
    confirmText, onConfirm, cancelText, onCancel, cancelColor, confirmColor, className, backgroundColor, buttonType,
    secondaryText, onSecondaryConfirm, clearState
  } = props;

    return (
      <DialogActions className={className || "modal-footer"} style={{position: 'sticky'}}>
        {
          cancelText && (
            <Button onClick={()=>{onCancel && onCancel(); clearState && clearState()}} size="small" color={cancelColor || "secondary"} variant="contained" className={backgroundColor || ''}
            sx={{background:'#F3CFAD',color:'#101010', '&:hover':{bgcolor:'#ff9700'}}}>
              {cancelText}
            </Button>
          )
        }
        {
          confirmText && (
            <Button onClick={onConfirm} type={buttonType} size="small" color={confirmColor || "primary"} autoFocus variant="contained"
            sx={{background:'#FFFFFF',color:'#73B2FF', border:'solid 1px #73B2FF', '&:hover':{bgcolor:'#73B2FF', color:'#FFFFFF'}}}>
              {confirmText}
            </Button>
          )
        }
        {
          secondaryText && (
            <Button onClick={onSecondaryConfirm} type={buttonType} size="small" color={"error" || confirmColor} autoFocus variant="contained">
              {secondaryText}
            </Button>
          )
        }
      </DialogActions>
    )
}