import { CategoryDataForm } from "../types/api/conceptCategory.type"
import { api } from "./configs/axiosConfigs"

export const ConceptCategoryAPI = {
    getConceptCategory: async (data?: any) => {
        const response = await api.get(
            '/concept/conceptCategory',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    createConceptCategory: async (data: CategoryDataForm, idcompany: number) => {
        const response = await api.post(
            '/concept/registerConceptCategory', {...data, idcompany:idcompany}
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    editConceptCategory: async (idcategory: number, data: CategoryDataForm) => {
        const response = await api.patch(
        `/concept/conceptCategory/${idcategory}`,
        { ...data }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    
    deleteConceptCategory: async (idcategory: number) => {
        const response = await api.delete(
        `/concept/conceptCategory/${idcategory}`
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    }
}