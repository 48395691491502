import { api } from "./configs/axiosConfigs"

export const DepartmentSpentAPI = {
    getDepartmentSpents: async (data?: any, idcompany?: number) => {
        const response = await api.get(
            '/departmentsSpent',
            {
                params: {
                    ...data,
                    idcompany: idcompany
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    createDepartmentSpent: async (data: any, idcompany) => {
        const response = await api.post(
            '/departmentsSpent/register', 
            {
                ...data,
                idcompany
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    editDepartmentSpent: async (id: number, data: any, idcompany: number) => {
        const response = await api.patch(
        `/departmentsSpent/${id}`,
        {
            ...data,
            idcompany
        }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    deleteDepartmentSpent: async (id: number, idcompany) => {
        const response = await api.delete(
        `/departmentsSpent/${id}`,
        {
            params: {
                idcompany
            }
        }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    }
}