import { api } from "./configs/axiosConfigs"

export const ServiceAPI = {
    getServices: async (data?: any) => {
        const response = await api.get(
            '/service',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    create: async (data: any) => {
        const response = await api.post(
            '/service/register', data
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    edit: async (id: number, data: any) => {
        const response = await api.patch(
        `/service/${id}`,
        {
            ...data
        }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    delete: async (id: number) => {
        const response = await api.delete(
        `/service/${id}`
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    }
}