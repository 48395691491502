import { useEffect, useState } from "react";
import { Grid } from '@mui/material';
import CustomTable from '../../components/common/CustomTable/CustomTable';
import { RoutesMap } from "../../types";
import { useNavigate } from "react-router-dom";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { GuestAdminModal } from "./GuestAdminModal";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { useGuestStore } from "../../hooks/useGuestStore";
import { Guest } from "../../types/slices/guestType";
import { useOwnerStore } from "../../hooks/useOwnerStore";
import { Owner } from "../../types/slices/ownerType";
import { ApiStatus } from "../../types/api/status";

const columns = [
    { type: 'options', field: 'options', label: 'Opciones' },
    { type: 'text', field: 'rut', label: 'Rut' },
    { type: 'text', field: 'name', label: 'Nombre' },
    { type: 'text', field: 'email', label: 'Correo' },
    { type: 'text', field: 'typeguest', label: 'Tipo de invitado'},
    { type: 'detail', field: 'detail', label: '', align:'center' },
];

export const GuestAdminView = () => {

    const navigate = useNavigate();

    const { guests, selectedGuest, status:statusGuest, getGuestsByOwner, setSelectedGuest, deleteGuest, setActionGuest } = useGuestStore();
    const { selectedOwner,  setSelectedOwner } = useOwnerStore()

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
        if(!selectedOwner.id) {
            clearState();
            navigate({ pathname: RoutesMap.OWNER}, { replace: true })
        }
    }, [!selectedOwner.id])

    useEffect(() => {
        if(selectedOwner.id) getGuestsByOwnerApi(selectedOwner.id)
    },[selectedOwner.id])

    const getGuestsByOwnerApi = async(idowner) => {
        try {
          setLoading(true);
          const response = await getGuestsByOwner(idowner);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const onAdd = () => {
        setActionGuest('add')
        navigate({ pathname: RoutesMap.GUEST_ADMIN_FORM })
    }
    const onEdit = (rowSelected) => {
        setActionGuest('edit')
        setSelectedGuest(rowSelected)
        navigate({ pathname: RoutesMap.GUEST_ADMIN_FORM })
    }
    const onDelete = (rowSelected) => {
        setActionGuest('delete')
        setSelectedGuest(rowSelected)
        setShowModalConfirm(true);
    }
    const onDetails = (rowSelected) => {
        setActionGuest('detail')
        setSelectedGuest(rowSelected)
        setShowModal(true);
    }

    const onDeleteConfirm = async() => {
        if (selectedGuest.id) {
            const response = await deleteGuest(selectedGuest.id)
            if(response === true){
                setSelectedGuest({} as Guest)
                setShowModalConfirm(false)
                getGuestsByOwnerApi(selectedOwner.id)
            }else{
                return false;
            }
        }
    }

    const clearState = () => {
        setSelectedGuest({} as Guest)
        setSelectedOwner({} as Owner)
        setActionGuest('')
    }

    return (
        <>
            {/* {statusGuest == ApiStatus.FETCHING && <CustomBackdrop open={true}/>} */}

            <Grid item xs={12}>
                <CustomFormHeader
                    title={selectedOwner?.id ? `Invitados de ${selectedOwner.name}` : 'Invitados'}
                    goBack={RoutesMap.OWNER}
                    clearState={clearState}
                >
                </CustomFormHeader>

                <CustomTable 
                    disabled_title
                    columns={columns} 
                    loading={loading}
                    rows={guests || []}
                    onRowClick={() => {}}
                    hasOptions
                    onAddFn={onAdd}
                    onDetails={onDetails}
                    onDelete={onDelete}
                    onEdit={onEdit}
                />
            </Grid>

            {
                showModalConfirm && (
                    <ModalConfirm
                        open={showModalConfirm}
                        closeModal={()=>{
                            setShowModalConfirm(false)
                            setSelectedGuest({} as Guest)
                            // setSelectedOwner({} as Owner)
                        }}
                        onConfirm={onDeleteConfirm}
                        status2={statusGuest == ApiStatus.FETCHING ? true : false}
                    />
                )
            }
            {
                showModal && (
                    <GuestAdminModal
                        open={showModal}
                        closeModal={()=>{
                            setShowModal(false); 
                            setSelectedGuest({} as Guest)
                            // setSelectedOwner({} as Owner)
                        }}
                        rowSelected={selectedGuest}
                    />
                )
            }
        </>
    )
}