import React, { useEffect, useState } from "react";
import { Modal } from "../../components/common/Modal/Modal";
import { ModalBody } from "../../components/common/Modal/ModalBody";
import { ModalHeader } from "../../components/common/Modal/ModalHeader";
import { ModalFooter } from "../../components/common/Modal/ModalFooter";
import { Formik } from "formik";
import { Grid, Divider, FormLabel, TextField, Autocomplete } from "@mui/material"
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { useOwnerStore } from "../../hooks/useOwnerStore";
import { Owner } from "../../types/slices/ownerType";
import { useDepartmentStore } from "../../hooks/useDepartmentStore";

type Values = {
  idowner: number|string,
}

export const DepartamentByBuildingModal: React.FC<any> = (props): JSX.Element | any => {
  const { open, closeModal, row } = props;

  const { ownersDistincs, selectedOwnerAdd, setSelectedOwnerAdd, getOwnersFilter } = useOwnerStore();
  const { departmentByOwner, addDepartmentOwner } = useDepartmentStore();

  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Values>({
    idowner: "",
  });

  useEffect(() => {
    getOwnersFilterApi();
  }, [])

  const getOwnersFilterApi = async () => {
    try {      
      setLoading(true);
      const response = await getOwnersFilter(departmentByOwner);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const handleChangeSelectOwner = (newValue:Owner|null, setFieldValue:any) => {
    if(row.num_owner == 0) return CustomSnackbar('warning', 'El N° de propiertarios no puede ser 0.')
    if(row.owners.length >= row.num_owner) return CustomSnackbar('warning', 'La cantidad de propietarios para el departamento esta completa.')

    setSelectedOwnerAdd(newValue || {} as Owner);
    setFieldValue('idowner', newValue ? newValue.id : "")
  }

  const validateForm = (values) => {
    let errors: any = {};
    if(!values.idowner) errors.idowner = "Campo requerido";
    
    return errors;
  }
  
  const onSubmit = async (formData) => {
    setLoadingSubmit(true);    
    const data = {
      ...formData,
      iddepartment: row.detail.iddepartment
    }
    const response:any = await addDepartmentOwner(data);
    setLoadingSubmit(false);
    
    if (response?.status)  closeModal(true);
  }

  return ( 
    <>
      <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="sm">
        <ModalHeader
          text={"Añadir un departamento"}
          className="positionElements"
          onCancel={closeModal}
        />
        <ModalBody>
          <Formik
            initialValues={data}
            enableReinitialize
            validate={ (values) => validateForm(values) }
            onSubmit={onSubmit}
          >
          {({errors, touched, handleSubmit, setFieldValue}) => {
            return (
              <>
                <Grid item xs={12} md={12}>
                  <FormLabel>Departamento</FormLabel>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={selectedOwnerAdd?.id ? selectedOwnerAdd : null}
                    options={ownersDistincs || []}
                    loading={loading}
                    getOptionLabel={(option) => option?.name || ""}
                    fullWidth
                    isOptionEqualToValue={(option, value) => option?.name === value?.name}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        size="small"
                        error={errors.idowner && touched.idowner ? true : false}
                        helperText={errors.idowner && touched.idowner ? errors.idowner : ''}
                      />
                    }
                    onChange={(e, newValue) => {
                      handleChangeSelectOwner(newValue,setFieldValue);
                    }}
                />
                </Grid>
                <Divider />
                <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'center'}}>
                  <ModalFooter 
                    confirmText={'Guardar'}
                    onConfirm={() => handleSubmit()}
                    loadingConfirmText={loadingSubmit}
                    cancelText={"Cancelar"}
                    onCancel={closeModal}
                  />
                </Grid>
              </>
            )
          }} 
          </Formik>
        </ModalBody>
      </Modal>
    </>
  )
}