import { Grid } from '@mui/material';

export const CustomFormBody = (props:any) => {

    const { children } = props;

    return (
        <Grid  >
            {children}
        </Grid>
    )
}
