import React, { useEffect, useState } from 'react'
import { Grid, TextField, Paper, InputAdornment } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from '../../../redux/store';
import { onSetSearch } from '../../../redux/slices/headerSlice';
import './CustomSearchByTableStyle.css'

export const CustomSearchByTable = (props:any)=> {

  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [searchTimeout, setSearchTimeout] = useState<string | number | undefined>(undefined);

  useEffect(() => {
    clearTimeout(searchTimeout);
    setSearchTimeout(
      window.setTimeout(() => {
        dispatch(onSetSearch(value))
      }, 500)
    )
  }, [value]);
  
  const handleSearch = (event: any) => {
    setValue(event.target.value);
  }

  const clearSearch = () => {
    setValue("");
    dispatch(onSetSearch(""))
  }

  return (
    <Paper elevation={5} className='paper-search-table'>
      <Grid className='main-container'>
        <TextField
          type="text"
          value={value}
          onChange={(e)=>{handleSearch(e)}}
          placeholder={'Buscador'}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon fontSize="small" />
              </InputAdornment>
            ),
            endAdornment: (
              value ? (
                <InputAdornment position="end" style={{cursor:"pointer"}} onClick={()=>clearSearch()}>
                  <CloseIcon fontSize="small"/>
                </InputAdornment>
              ) : null
            )
          }}
          sx={{width:350}}
          size="small"
        />
      </Grid>
    </Paper>
  )
}