import { api } from "./configs/axiosConfigs"

export const ConfigurationCompanyAPI = {
    getConfigurationCompany: async (id: number) => {
        const response = await api.get(
            `/companyConfigurations/${id}`
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    getConfigurationCompanyByOwner: async (idcompany: number, data?: any) => {
      //CONSULTAR EN BACK PORQUE SIEMPRE RESPONDE La compañía seleccionada es inválida.
        const response = await api.get(
            `/companyConfigurations2/owner`,
            {
              params: {
                ...data,
                idcompany
              }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    updateConfigurationCompany: async (data:any,id: number) => {
        const response = await api.patch(
            `/companyConfigurations/${id}`,{
                ...data
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    }
}