import { useEffect, useState } from "react";
import { Grid } from '@mui/material'
import CustomTable from '../../components/common/CustomTable/CustomTable';
import { RoutesMap } from "../../types";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { Department } from "../../types/slices/departmentType";
import { ApiStatus } from "../../types/api/status";
import { useNavigate } from "react-router-dom";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { useSelector } from "../../redux/store";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { BuildingModal } from "./BuildingModal";
import { Building } from "../../types/slices/buildingType";
import { useBuildingStore } from "../../hooks/useBuildingStore";

const columns = [
    { type: 'options',  field: 'options',        align:'center',     label: 'Opciones' },
    { type: 'text',     field: 'name',           align:'center',     label: 'Nombre' },
    // { type: 'text',     field: 'address',        align:'center',     label: 'Dirección' },
    // { type: 'text',     field: 'location_url',   align:'center',     label: 'Locación' },
    { type: 'text',     field: 'dimensions',    align:'center',     label: 'Capacidad' },
    { type: 'text',     field: 'number_departments', align:'center',     label: 'N° Max de Departamentos' },
    { type: 'detail',   field: 'detail',         align:'center',     label: '' }
];

export const BuildingView = () => {
    
    const navigate = useNavigate();
    const { search } = useSelector(selectHeaderState);

    const { status: statusApiBuilding ,buildings, getBuildings, setSelectedBuilding, deleteBuilding, selectedBuilding } = useBuildingStore()

    const [ rows , setRows ]                        = useState<Building[]>([])
    const [ rowSelected, setRowSelected ]           = useState<Building>({} as Building);
    const [ showModal, setShowModal ]               = useState<boolean>(false);
    const [ showModalConfirm, setShowModalConfirm ] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
      setSelectedBuilding({} as Building)
      getBuildingsApi();
    },[search])

    useEffect(() => {
        if (buildings) {
          setRows(buildings)
          return
        }
        setRows([])
    }, [buildings])

    const getBuildingsApi = async() => {
        try {
          setLoading(true);
          const response = await getBuildings({search});
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }
    
    const onEdit = (rowSelected) => {
        console.log('rowSelected', rowSelected)
        // const a_iddepartament   = rowSelected.departaments.map(departament => departament.id)
        // const a_idservice = rowSelected.services.map(service => service.idservices)
        setSelectedBuilding({
            id: rowSelected.id,
            name: rowSelected.name,
            idsdepartament: [],
            dimensions: rowSelected.dimensions,
            idcompany: rowSelected.idcompany,
            number_departments: rowSelected.number_departments,
            departments: rowSelected.departments,
            status: rowSelected.status
        })
        navigate({ pathname: RoutesMap.BUILDING_FORM }) 
    }
    
    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = async () => {
        const response = await deleteBuilding(rowSelected.id)
        if (response === true) {
            setSelectedBuilding({} as Building)
            setShowModalConfirm(false)
            getBuildingsApi()
        }
    }

    const onDetails = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModal(true);
    }

    const onHelper = (rowSelected) => {
        setSelectedBuilding(rowSelected)
        navigate({ pathname: RoutesMap.DEPARTAMENT_BY_BUILDING })
    }

    return (
        <>
            {
                statusApiBuilding === ApiStatus.FETCHING && <CustomBackdrop open={true} />
            }
            <Grid item xs={12}>
                <CustomTable 
                    title={'Edificios'}
                    columns={columns} 
                    loading={loading}
                    rows={rows || []}
                    onRowClick={() => {}}
                    hasOptions
                    onAdd={RoutesMap.BUILDING_FORM}
                    onDetails={onDetails}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    onHelper={onHelper}
                    icon_helper={<Diversity3Icon fontSize="small" />}
                    tooltip_helper={'Departamentos'}
                />
            </Grid>
            {
                showModalConfirm && (
                    <ModalConfirm
                        open={showModalConfirm}
                        closeModal={()=>{setShowModalConfirm(false)}}
                        onConfirm={onDeleteConfirm}
                        onCancel={()=>{setShowModalConfirm(false)}}
                    />
                )
            }
            {
                showModal && (
                    <BuildingModal
                        open={showModal}
                        closeModal={()=>{setShowModal(false)}}
                        rowSelected={rowSelected}
                    />
                )
            }
        </>
    )
}