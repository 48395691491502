import { Autocomplete, Button, Grid, IconButton, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"

//assets
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import SearchIcon from '@mui/icons-material/Search'
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop"
import { ApiStatus } from "../../types/api/status"
import { Month } from "../../types/slices/monthType"
import { Year } from "../../types/slices/yearType"
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar"
import { Rendition } from "../../types/slices/renditionType"
import { useBuildingStore } from "../../hooks/useBuildingStore"
import { Building } from "../../types/slices/buildingType"
import { useRenditionBuildingStore } from "../../hooks/useRenditionBuildingStore"
import { RenditionBuildingAddCostModal } from "./components/RenditionBuildingAddCostModal"
import { RenditionBuildingTableDetail } from "./components/RenditionBuildingTableDetail"

const months = [
    { id: 1, name: 'Enero' },
    { id: 2, name: 'Febrero' },
    { id: 3, name: 'Marzo' },
    { id: 4, name: 'Abril' },
    { id: 5, name: 'Mayo' },
    { id: 6, name: 'Junio' },
    { id: 7, name: 'Julio' },
    { id: 8, name: 'Agosto' },
    { id: 9, name: 'Setiembre'},
    { id: 10, name: 'Octubre'},
    { id: 11, name: 'Noviembre'},
    { id: 12, name: 'Diciembre'},
]

const years = [
    { id: 2023, name: '2023'}
]

export const RenditionBuildingOpen = (props) => {
  const { status: renditionBuildingStatus, selectedRenditionBuilding, setSelectedRenditionBuilding, getOrCreateRendition } = useRenditionBuildingStore()
  const { status: statusBuildingAPI, buildings, selectedBuilding, getBuildings, setSelectedBuilding } = useBuildingStore();

  const [isCreate, setIsCreate] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [selectedMonth, setSelectedMonth] = useState<Month | null>(null)
  const [selectedYear, setSelectedYear] = useState<Year | null>(null)

  useEffect(() => {
    setSelectedBuilding({} as Building) 
    getBuildings()
    validateIsEdit()
  }, [])

  const handleOpenRendition = async () => {
    if (!selectedBuilding.id) return CustomSnackbar('error', 'Seleccione un departamento')
    if (selectedMonth === null) return CustomSnackbar('error', 'Seleccione un mes')
    if (selectedYear === null) return CustomSnackbar('error', 'Seleccione un año')
    const response = await getOrCreateRendition({ month: selectedMonth?.id, year: selectedYear?.id, idbuilding: selectedBuilding.id });
    if (response === true) setIsCreate(true)
  }

  const closeRendition = () => {
    setIsCreate(false)
    setSelectedMonth(null)
    setSelectedYear(null)
    setSelectedBuilding({} as Building)
  }

  const validateIsEdit = async () => {
    if (selectedRenditionBuilding && Object.entries(selectedRenditionBuilding).length > 0) {
      const month = months.find(month => month.id === parseInt(selectedRenditionBuilding.month))
      const year  = years.find(year => year.id === parseInt(selectedRenditionBuilding.year))
      const building = buildings.find(item => item.id === selectedRenditionBuilding.idbuilding)
      setSelectedMonth(month || null)
      setSelectedYear(year || null)
      setSelectedBuilding(building || {} as Building) 
      await getOrCreateRendition({ month: selectedRenditionBuilding.month, year: selectedRenditionBuilding.year, idbuilding: selectedRenditionBuilding.idbuilding })
      await setIsCreate(true)
      setSelectedRenditionBuilding({} as Rendition)
    }
  }

  return(
    <Grid container>
        { statusBuildingAPI === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
        { renditionBuildingStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
        {
          isCreate && (
            <Grid item xs={12} display='flex' alignItems='center'>
                <IconButton id="back" size="small" color="primary" aria-label="view" onClick={() =>  closeRendition() }>
                    <ArrowBackIcon fontSize='small'/>
                </IconButton>
                <Typography>Volver</Typography>
            </Grid>
          )
        }
        <Grid item container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom: '20px' }}>
            <Typography variant='h6' component='h1' gutterBottom color='#808080' className="tittle" sx={{ mt: 1, ml:1 , fontWeight:600 }}>
                APERTURA DE RENDICIONES PARA EDIFICIO
            </Typography>
        </Grid>
        <Grid item container xs={12} direction="row" justifyContent="center" alignItems="center" sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px'}}>
            <Autocomplete
                id="combo-box-department"
                size="small"
                disabled={ isCreate ? true : false }
                value={selectedBuilding}
                options={buildings || []}
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={(option, value) => option?.name === value?.name}
                sx={{width:'300px', padding: '8px'}}
                renderInput={(params) => <TextField {...params} label="Edificios" size="small" />}
                onChange={(e, newValue) => {
                  setSelectedBuilding(newValue || {} as Building)
                }}
            />
            <Autocomplete
                id="combo-box-month"
                size="small"
                value={selectedMonth}
                options={months}
                disabled={ isCreate ? true : false }
                getOptionLabel={(month) => month.name}
                isOptionEqualToValue={(option, value) => option?.name === value?.name}
                sx={{width:'200px', padding: '8px'}}
                renderInput={(params) => <TextField {...params} label="Mes"  size="small"/>}
                onChange={(e, newValue) => {
                    setSelectedMonth(newValue)
                }}
            />
            <Autocomplete
                id="combo-box-year"
                size="small"
                value={selectedYear}
                disabled={ isCreate ? true : false }
                options={years}
                getOptionLabel={(year) => year.name}
                isOptionEqualToValue={(option, value) => option?.name === value?.name}
                sx={{width:'200px', padding: '8px'}}
                renderInput={(params) => <TextField {...params} label="Año"  size="small" />}
                onChange={(e, newValue) => {
                    setSelectedYear(newValue)
                }}
            />
            {
                !isCreate
                ?
                <Button 
                    onClick={ () => handleOpenRendition() }
                    size="small"
                    variant="contained" 
                    sx={{ backgroundColor: '#212D39', color: '#fff', textTransform: 'none', padding: '8px', fontSize:"12px", "&:hover": {backgroundColor: "#212D39" } }}
                >
                    Crear apertura
                </Button>
                : 
                <Button
                    onClick={ () => setShowModal(true) }
                    startIcon={<SearchIcon/>} 
                    sx={{ backgroundColor: '#212D39', color: '#fff', textTransform: 'none', padding: '8px', fontSize:"12px", "&:hover": {backgroundColor: "#212D39" } }}
                >
                    Agregar gasto
                </Button>
            }
        </Grid>
        <Grid item container xs={10} sx={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '20px' }}>
            {
                !isCreate
                ?
                <Grid item xs={12}> 
                    <Typography sx={{ fontWeight: 'bolder' }}>
                        Consejos de la aplicación:
                    </Typography>
                    <Typography sx={{ textAlign: 'justify', margin: '10px 0px'}}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vitae cursus lectus. Cras condimentum mi eget lectus fermentum interdum. Fusce non blandit dolor. Etiam viverra felis enim, sed aliquam quam mollis sit amet. Nulla a finibus mauris. Proin dignissim aliquet feugiat. Aliquam enim orci, tristique aliquet interdum id, dapibus a nibh. Interdum et malesuada fames ac ante ipsum primis in faucibus. Pellentesque placerat, augue et tincidunt rhoncus, nisl nunc bibendum urna, in semper ipsum diam non risus. Donec eget ligula id ante dignissim ornare. Sed blandit dui augue, eget venenatis magna consectetur nec. Quisque in lorem vitae sapien finibus interdum. Aliquam erat volutpat. Sed eget velit vitae eros gravida tempus.
                    </Typography>
                </Grid>
                :
                <RenditionBuildingTableDetail
                handleBack = {closeRendition}
                buildingSelected={selectedBuilding}
                />
            }
        </Grid>
        {
            showModal && (
                <RenditionBuildingAddCostModal
                    open={showModal}
                    edit={false}
                    closeModal={ () => setShowModal(false) }
                    buildingSelected={selectedBuilding}
                    monthSelected={selectedMonth}
                    yearSelected={selectedYear}
                />
            )
        }
    </Grid>
  )
}