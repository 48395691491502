import { useEffect, useState } from "react";
import { Grid, List, ListItem, ListItemButton, ListItemText, Checkbox, Button, Typography, styled } from '@mui/material'
import CustomTable from '../../components/common/CustomTable/CustomTable';
import { RoutesMap } from "../../types";
import { useNavigate } from "react-router-dom";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { useReservationStore } from "../../hooks/useReservationStore";
import { Reservation, ReservationGuest } from "../../types/slices/reservationType";
import ListItemIcon from '@mui/material/ListItemIcon';
import { useGuestStore } from "../../hooks/useGuestStore";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'rgba(102, 102, 102, 0.4)',
}));

const columns = [
    { type: 'options', field: 'options', label: 'Opciones', align:'center' },
    { type: 'text', field: 'rut_invited', label: 'Rut', align:'center' },
    { type: 'text', field: 'name_invited', label: 'Nombre', align:'center' },
];

export const ReservationAdminInvited = () => {

    const navigate = useNavigate();
    
    const { status: statusReservation, reservationGuests, selectedReservation, selectedReservationGuest, setSelectedReservation, getReservationGuests, setSelectedReservationGuest, deleteReservationGuest, addGuestsReservation } = useReservationStore()
    const { guests, status:statusGuest, getGuestsByOwner } = useGuestStore();

    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
    const [guestFilter, setGuestFilter] = useState<any>([]);
    const [guestsSelected, setGuestsSelected] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    const handleToggle = (value: any) => () => {
        const currentIndex = guestsSelected.indexOf(value);
        const newChecked = [...guestsSelected];
        
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setGuestsSelected(newChecked);
    };

    useEffect(()=>{
        if(!selectedReservation.id) {
            clearState();
            navigate({ pathname: RoutesMap.RESERVATION_ADMIN_LIST}, { replace: true })
        }
    }, [!selectedReservation.id])

    useEffect(() => {
        if(selectedReservation.id) getReservationGuestsApi(selectedReservation.id)
        if(selectedReservation.idowner) getGuestsByOwner(selectedReservation.idowner)
    },[selectedReservation.id])

    useEffect(() => {
        if(reservationGuests && guests) GuestFilter(reservationGuests, guests)
    },[reservationGuests && guests])


    const getReservationGuestsApi = async(idreservation) => {
        try {
          setLoading(true);
          const response = await getReservationGuests(idreservation);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const onDelete = (rowSelected) => {
        setSelectedReservationGuest(rowSelected)
        setShowModalConfirm(true);
    }
    const onDeleteConfirm = () => {
        if (selectedReservationGuest.id) deleteReservationGuest(selectedReservationGuest.id)
        setSelectedReservationGuest({} as ReservationGuest)
        setShowModalConfirm(false)
        if(selectedReservation.id) getReservationGuestsApi(selectedReservation.id)
        if(selectedReservation.idowner) getGuestsByOwner(selectedReservation.idowner)
    }

    const clearState = () => {
        setSelectedReservationGuest({} as ReservationGuest)
        setSelectedReservation({} as Reservation)
    }

    const GuestFilter = (guestsReserve, guestsOwner) => {

        if( guestsOwner.length > 0 ) {

            var filter = guestsOwner.filter(function(guestowner) {
                return !guestsReserve.some(function(guestreserve) {
                  return guestowner.id === guestreserve.idinvited;
                });
            });

            return setGuestFilter(filter || []);
        }else{
            return setGuestFilter([]);
        }
    }

    const AddGuest = async() => {
        if(guestsSelected.length == 0) return  CustomSnackbar('warning', 'Seleccione al menos un invitado.')

        let arrayIdGuests:any = [];
        guestsSelected.map((item) => {
            arrayIdGuests.push(item.id);
        });

        console.log(arrayIdGuests)

        if(selectedReservation.id){
            const response = await addGuestsReservation(selectedReservation.id, arrayIdGuests);

            if(response) {
                setGuestsSelected([])
                if(selectedReservation.id) getReservationGuestsApi(selectedReservation.id)
                if(selectedReservation.idowner) getGuestsByOwner(selectedReservation.idowner)
            }
        }
    }

    return (
        <>
            {/* {statusReservation == ApiStatus.FETCHING && <CustomBackdrop open={true}/>}
            {statusGuest == ApiStatus.FETCHING && <CustomBackdrop open={true}/>} */}

            <Grid item xs={12}>
                <CustomFormHeader
                    goBack={RoutesMap.RESERVATION_ADMIN_LIST}
                    clearState={clearState}
                >
                </CustomFormHeader>
            </Grid>

            <Grid item container xs={12} spacing={2} sx={{alignItems:'center'}}>
                    
                <Grid item xs={12} md={8}>
                    <CustomTable 
                        title={'Invitados de la Reserva'}
                        columns={columns} 
                        loading={loading}
                        rows={reservationGuests || []}
                        onRowClick={() => {}}
                        hasOptions
                        onDelete={onDelete}
                    />
                </Grid>
                
                <Grid item xs={12} md={4} sx={{ padding:'10px'}}>
                    <Grid display='flex' justifyContent='center' alignItems='center' sx={{ width:'100%', padding:'10px 0px 10px 0px'}}>
                        <Typography variant='h6' component='h1' gutterBottom color='#808080' className="tittle" sx={{ mt: 1, ml:1 , fontWeight:400}}>
                                {'Añadir Invitados'}
                        </Typography>
                    </Grid>
                    {
                        ( statusReservation == ApiStatus.FETCHED && guestFilter && guestFilter.length > 0 ) && (
                        <Grid item sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px'}}>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            {
                                guestFilter.map((item) => (
                                    <ListItem key={item.id}>
                                        <ListItemButton onClick={handleToggle(item)} >
                                            <ListItemIcon>
                                                <Checkbox
                                                edge="start"
                                                checked={guestsSelected.indexOf(item) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={item.name} />
                                        </ListItemButton>
                                    </ListItem>
                                ))
                            }
                        </List>
                        <Grid display='flex' justifyContent='center' alignItems='center' sx={{ width:'100%', borderTop:'1px solid #e9e2e2', padding:'10px 0px 10px 0px'}}>
                            <Button onClick={AddGuest} size="small" variant="contained" sx={{background:'#212D39',color:'#fff', '&:hover':{bgcolor:'#007ea7'}}}>
                                {'Añadir Invitado'}
                            </Button>
                        </Grid>
                        </Grid>
                        )
                    }
                    {
                        (statusReservation == ApiStatus.FETCHED && guestFilter && guestFilter.length == 0 ) && (
                            <Grid sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px' }}>
                                <Div>{"Sin data"}</Div>
                            </Grid>
                        )
                    }
                    {
                        (statusReservation == ApiStatus.FETCHING) && (
                            <Grid sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px' }}>
                                <Div>{"cargando..."}</Div>
                            </Grid>
                        )
                    }
                </Grid>

            </Grid>

            {
                showModalConfirm && (
                    <ModalConfirm
                        open={showModalConfirm}
                        closeModal={()=>{
                            setShowModalConfirm(false)
                            setSelectedReservationGuest({} as ReservationGuest)
                        }}
                        onConfirm={onDeleteConfirm}
                        status={statusReservation}
                    />
                )
            }
        </>
    )
}