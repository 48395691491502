import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { Month, MonthType } from "../../types/slices/monthType";
import { RootState } from "../store";

const initialState: MonthType = {
    status: ApiStatus.FETCHED,
    months: [],
    errorMessage: undefined,
    selectedMonth: {} as Month
}

const monthSlice = createSlice({
    name: 'month',
    initialState,
    reducers: {
        onFetchMonths (state, { payload }: { payload: Month[] }) {
            state.status       = ApiStatus.FETCHED
            state.months    = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedMonth (state, { payload }: { payload: Month }) {
            state.selectedMonth = payload
        }
    }
})

export const selectMonthState = (state: RootState) => state.monthSlice
export default monthSlice.reducer

export const {
    onFetchMonths,
    changeStatus,
    onSetSelectedMonth
} = monthSlice.actions