import { Formik } from "formik";
import { Modal } from "../../../components/common/Modal/Modal";
import { ModalBody } from "../../../components/common/Modal/ModalBody";
import { ModalHeader } from "../../../components/common/Modal/ModalHeader";
import { ModalFooter } from "../../../components/common/Modal/ModalFooter";
import { Autocomplete, Divider, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDepartmentStore } from "../../../hooks/useDepartmentStore";
import { Department } from "../../../types/slices/departmentType";
import { useConfigurationDepartmentStore } from "../../../hooks/useConfigurationDepartmentStore";

type Values = {
    check_in: number | undefined,
    check_out: number | undefined,
    iddepartment: number | undefined
    idcompany: number | undefined
}

export const ModalChecking: React.FC<any> = (props): JSX.Element | any => {

  const { open, closeModal, clearState, editMode } = props;

  const { departments, getDepartments, setSelectedDepartment, selectedDepartment } = useDepartmentStore();
  
  const { getConfigurationByDepartment, getConfigurationDepartment, configurationDepartment, selectedConfigurationDepartment, setCheckIn, setCheckOut, editConfigurationCompany } = useConfigurationDepartmentStore();
  
  const [loadingDepartment, setLoadingDepartment] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  
  const [data, setData] = useState<Values>({
      check_in: undefined,
      check_out: undefined,
      iddepartment: undefined,
      idcompany: undefined,
  });

  useEffect(() => {
    if (editMode) {
      setData({
        idcompany: selectedConfigurationDepartment.idcompany,
        iddepartment: selectedConfigurationDepartment.iddepartment,
        check_in: selectedConfigurationDepartment.check_in,
        check_out: selectedConfigurationDepartment.check_out,
      })
    } else {
      getDepartmentsAPI()
    }
  },[])
  
  const getDepartmentsAPI = async() => {
    setLoadingDepartment(true);
    const response = await getDepartments()
    setLoadingDepartment(false);
  }

  const validateForm = (values) => {
    let errors:any = {};
    if(!values.check_in) errors.check_in = "campo requirido";
    if(!values.check_out) errors.check_out = "campo requirido";
    if(!values.iddepartment) errors.iddepartment = "campo requirido";
    return errors;
  }

  const onSubmit = async(values)  => {
    setLoadingSubmit(true);
    let success:any;
    if (editMode) {
      success = await editConfigurationCompany(values.iddepartment, {
        check_in: values.check_in,
        check_out: values.check_out
      })
    } else {
      success = await editConfigurationCompany(values.iddepartment, {
        check_in: values.check_in,
        check_out: values.check_out,
        status_check: "1"
      })
    }
    setLoadingSubmit(false);

    if (success == true) {
      closeModal(true);
    }
  }

  return (
    <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
      <ModalHeader
        text={'Configuración de Departamento'}
        className='positionElements'
        onCancel={closeModal}
        clearState={clearState}
      >
      </ModalHeader>
      <ModalBody>
          <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
              {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                  return (
                      <form onSubmit={handleSubmit}>
                          <Grid container sx={{padding:'20px'}}>
                              {
                                !editMode && (
                                  <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Typography variant="subtitle1" className="custom-input"><b>{"Departamento"}:</b></Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <Autocomplete
                                            id="iddepartment"
                                            size="small"
                                            placeholder="Seleccione un Departamento"
                                            options={departments || []}
                                            loading={loadingDepartment}
                                            getOptionLabel={(departament) => departament?.name}
                                            onChange={(e, newValue: any) => {
                                              setSelectedDepartment(newValue || {} as Department);
                                              
                                              setFieldValue('iddepartment', newValue ? newValue.id : "");
                                              
                                              !newValue && setCheckIn(0)
                                              !newValue && setCheckOut(0)
                                              newValue && getConfigurationByDepartment(newValue.id)
                                            }}
                                            isOptionEqualToValue={(option, value) => option.id === value?.id}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    label=""
                                                    size="small"
                                                    error={errors.iddepartment && touched.iddepartment ? true : false}
                                                    helperText={errors.iddepartment && touched.iddepartment ? errors.iddepartment : ''}
                                                />
                                            }
                                        />
                                    </Grid>
                                  </Grid>
                                )
                              }
                              <Grid item container xs={12} md={12} sx={{ alignItems: 'center', display: 'flex', margin: '10px 0px' }}>
                                <Typography sx={{ fontSize: '16px', fontWeight: 300, marginRight: '10px' }}>Check-in:</Typography>
                                <TextField
                                  size="small"
                                  sx={{ width: '110px' }}
                                  type="time"
                                  name="check_in"
                                  value={values.check_in}
                                  disabled={!!!values.iddepartment}
                                  onChange={(e)=> {setCheckIn(e.target.value + ':00'); setFieldValue('check_in', e.target.value + ':00')}}
                                  error={errors.check_in && touched.check_in ? true : false}
                                />
                                <Typography sx={{ fontSize: '16px', fontWeight: 300, margin: '0px 10px' }}>horas.</Typography>
                              </Grid>    
                              <Grid item container xs={12} md={12} sx={{ alignItems: 'center', display: 'flex', margin: '10px 0px' }}>
                                <Typography sx={{ fontSize: '16px', fontWeight: 300, marginRight: '10px' }}>Check-out:</Typography>
                                <TextField
                                  size="small"
                                  sx={{ width: '110px' }}
                                  type="time"
                                  name="check_out"
                                  value={values.check_out}
                                  disabled={!!!values.iddepartment}
                                  onChange={(e)=> {setCheckOut(e.target.value + ':00'); setFieldValue('check_out', e.target.value + ':00')}}
                                  error={errors.check_out && touched.check_out ? true : false}
                                />
                                <Typography sx={{ fontSize: '16px', fontWeight: 300, margin: '0px 10px' }}>horas antes.</Typography>
                              </Grid>    
                          </Grid>
                          <Divider />
                          <ModalFooter 
                            buttonType="submit"
                            cancelText={"Cancelar"}
                            onCancel={closeModal}
                            onConfirm={handleSubmit}
                            confirmText={"Actualizar"}
                            loadingConfirmText={loadingSubmit}
                          />
                      </form>
                  )
              }} 
          </Formik>
      </ModalBody>
    </Modal>
  )
}