import { useDispatch, useSelector } from "react-redux"
import { changeStatus, onFetchDepartmentSpents, onSetSelectedDepartmentSpent, selectDepartmentSpentState } from "../redux/slices/departmentSpentSlice"
import { ApiStatus } from "../types/api/status"
import { DepartmentSpentAPI } from "../apis/departmentSpentAPI"
import { DepartmentSpent } from "../types/slices/departmentSpentType"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"

export const useDepartmentSpentStore = () => {

  const dispatch = useDispatch()
  const { departmentSpents, status, selecteddepartmentSpent } = useSelector(selectDepartmentSpentState)

  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany

    const getDepartmentSpents = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await DepartmentSpentAPI.getDepartmentSpents(data, idcompany)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            const { detail } = response.data
            dispatch(onFetchDepartmentSpents(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const createDepartmentSpent = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await DepartmentSpentAPI.createDepartmentSpent(data, idcompany)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || '')
            return true
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
    }

    const editDepartmentSpent = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await DepartmentSpentAPI.editDepartmentSpent(id, data, idcompany)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || '')
            return true
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
    }

    const deleteDepartmentSpent = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await DepartmentSpentAPI.deleteDepartmentSpent(id, idcompany)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || '')
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || '')
            return true
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
    }

    const setSelectedDepartmentSpent= (object: DepartmentSpent) => {
        try {
            dispatch(onSetSelectedDepartmentSpent(object))
        } catch (error) {
            console.log(error)
        }
    }

    return {
        //states
        departmentSpents,
        status,
        selecteddepartmentSpent,
        //actions
        getDepartmentSpents,
        createDepartmentSpent,
        editDepartmentSpent,
        deleteDepartmentSpent,
        setSelectedDepartmentSpent
    }
}