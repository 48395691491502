import { Divider, Grid, Pagination, Typography } from '@mui/material'
//third party
import { OptionPopover } from './OptionPopover'
import { useAuthStore } from '../../hooks'
import { useNotificationStore } from '../../hooks/useNotificationStore'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import moment from 'moment'
import 'moment/locale/es'
import { useEffect, useState } from 'react'
// import { HeaderClient } from '../Main/HeaderClient/HeaderClient'
moment.locale('es')
const themeAvenir = createTheme({
  typography: {
    fontFamily: 'Avenir',
    fontSize: 15
  },
})

export const NotificationView = () => {
  // PAGINATION
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  // const { user } = useAuthStore()

  const { notifications, changeSeenNotification, changeSeenMassiveNotification, getNotifications} = useNotificationStore()

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
   setPage(value);
 };

   useEffect(() => {
     if(notifications.length == 0)
     {
      getNotifications({perPage: 5, page})
     }
   }, [page])

   const markAsRead = async() => {
      const response = await changeSeenMassiveNotification();
      if(response){
        getNotifications({perPage: 5, page:1})
      }else{
        return false;
      }
   }

  return (
    <>
      <ThemeProvider theme={themeAvenir}>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 15px' }}>
          <Typography variant='h6' sx={{ color: '#121c4c' }}>Tus notificaciones</Typography>
          <Grid onClick={markAsRead} item display={'flex'} sx={{ cursor: 'pointer'}} alignItems='center'>
            <Typography variant='subtitle1' sx={{ marginRight: '3px', color: '#121c4c' }}>Marcar todas como leídas</Typography>
          </Grid>
        </div>

        <Divider />
        <div>
          {notifications.length > 0 && notifications.map((notification) => {
              const isRead = notification.date_seen !== null
              return (
                <div key={notification.id} style={{ background: `${(notification?.date_seen == null) ? '#efefef' : '#fff'}` }}>
                  <div style={{ wordWrap: 'break-word', justifyContent: 'space-between', display: 'flex', padding: '10px 10px 0px 10px', marginBottom: '0px' }}>
                    <Typography variant='body2' sx={{ fontWeight: isRead ? 500 : 700 }}>{notification.title}</Typography>
                    <Typography variant='caption'>{moment(notification.date_sent).startOf('day').fromNow()}</Typography>
                  </div>
                  <div style={{ wordWrap: 'break-word', paddingInline: ' 10px', justifyContent: 'space-between', display: 'flex' }}>
                    <Typography variant='caption' sx={{ fontWeight: isRead ? 500 : 700 }} >{notification.details}</Typography>
                    <OptionPopover notification={notification} isRead={isRead} />
                  </div>
                  <Divider />
                </div>
              )
            })}
        </div>
        <Grid item container justifyContent={'center'}>
        <Pagination count={1} page={page} onChange={handleChange} color="primary"  size="large"/>
        </Grid>
      </ThemeProvider>

    </>
  )
}
