import { useDispatch, useSelector } from "react-redux"
import { changeStatus, onFetchSpentTypes, onSetSelectedSpentType, selectSpentTypeState } from "../redux/slices/spentTypeSlice"
import { useDispatch as useDispatch2 } from "../redux/store"
import { ApiStatus } from "../types/api/status"
import { SpentTypeAPI } from "../apis/SpentType"
import { SpentType } from "../types/slices/spentTypeType"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"

export const useSpentTypeStore = () => {

    const dispatch = useDispatch()
    const { spentTypes, status, selectedSpentType } = useSelector(selectSpentTypeState)

    const dispatch2 = useDispatch2()
    
    const getSpentTypes = async () => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await SpentTypeAPI.getSpentTypes()
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchSpentTypes(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const setSelectedSpentType = (spentType: SpentType) => {
        try {
            dispatch(onSetSelectedSpentType(spentType))
        } catch (error) {
            console.log(error)
        }
    }

    return {
        //states
        spentTypes,
        status,
        selectedSpentType,
        //actions
        getSpentTypes,
        setSelectedSpentType
    }
}