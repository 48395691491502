import { Modal } from "../../../components/common/Modal/Modal";
import { ModalHeader } from "../../../components/common/Modal/ModalHeader";
import { ModalBody } from "../../../components/common/Modal/ModalBody";
import { Grid, Typography } from "@mui/material";

export const ReservationPoliticsModal: React.FC<any> = (props): JSX.Element | any => {

    const { open, closeModal } = props;
    
    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
            <ModalHeader
                text={'Nuestra Políticas'}
                className='positionElements'
                onCancel={closeModal}
            >
            </ModalHeader>
            <ModalBody>
                <Grid pb={1}>
                    <Typography color='#101010' sx={{ fontWeight:400, fontSize:'14px'}}>
                        Línea de protección 24
                    </Typography>
                    <Typography color='#808080' sx={{ fontSize:'14px'}}>
                        Si en algún momento te sientes en una situación insegura, vas a tener acceso prioritario a agentes especializados en seguridad, sea la hora que sea.
                    </Typography>
                </Grid>
                <Grid pb={2}>
                    <Typography color='#101010' sx={{ fontWeight:400, fontSize:'14px'}}>
                        Garantía de cumplimiento de lo reservado
                    </Typography>
                    <Typography color='#808080' sx={{ fontSize:'14px'}}>
                        Si durante tu estadía te das cuenta de que el alojamiento no coincide con la descripción del anuncio (p. ej.: si se daña la nevera y el anfitrión no puede arreglarla o si el espacio tiene menos habitaciones de las que aparecen en el anuncio), tendrás tres días para avisarnos. Cuando lo hagas, buscaremos un alojamiento similar o mejor, o te enviaremos un reembolso.
                    </Typography>
                </Grid>
            </ModalBody>
        </Modal>
    )
}