import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RenditionSpent, RenditionSpentType } from "../../types/slices/renditionSpentType";
import { RootState } from "../store";

const initialState: RenditionSpentType = {
    status: ApiStatus.FETCHED,
    renditionSpents: [],
    errorMessage: undefined,
    selectedRenditionSpent: {} as RenditionSpent
}

const renditionSpentSlice = createSlice({
    name: 'renditionSpent',
    initialState,
    reducers: {
        onFetchRenditionSpent (state, { payload }: { payload: RenditionSpent[] }) {
            state.status       = ApiStatus.FETCHED
            state.renditionSpents    = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedRenditionSpent (state, { payload }: { payload: RenditionSpent }) {
            state.selectedRenditionSpent = payload
        }
    }
})

export const selectRenditionSpentState = (state: RootState) => state.renditionSpentSlice
export default renditionSpentSlice.reducer

export const {
    onFetchRenditionSpent,
    changeStatus,
    onSetSelectedRenditionSpent
} = renditionSpentSlice.actions