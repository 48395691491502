import { useDispatch, useSelector } from "react-redux"
import { changeStatus, onFetchServices, onSetSelectedService, selectServiceState, setAction } from "../redux/slices/serviceSlice"
import { ApiStatus } from "../types/api/status"
import { ServiceAPI } from "../apis/ServiceAPI"
import { Service } from "../types/slices/serviceSlice"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"

export const useServiceStore = () => {
    const dispatch = useDispatch()
    const { services, status, selectedService, action } = useSelector(selectServiceState)

    const getServices = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ServiceAPI.getServices(data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchServices(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const createService = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ServiceAPI.create(data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const editService = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ServiceAPI.edit(id, data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const deleteService = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ServiceAPI.delete(id)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              CustomSnackbar('error', response.data.message || "");
              return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const setSelectedService = (service: Service) => {
        try {
            dispatch(onSetSelectedService(service))
        } catch (error) {
            console.log(error)
        }
    }

    const setActionService = (state) => {
      try {
          dispatch(setAction(state))
      } catch (error) {
          console.log(error)
      }
    }

    return {
        //states
        services,
        status,
        selectedService,
        action,
        //actions
        getServices,
        setSelectedService,
        createService,
        editService,
        deleteService,
        setActionService,
    }
}