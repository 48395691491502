import { useSelector } from "react-redux"
import { useDispatch } from "../redux/store"
import { changeStatus, onFetchRenditionSpent, onSetSelectedRenditionSpent, selectRenditionSpentState } from "../redux/slices/renditionSpentSlice"
import { ApiStatus } from "../types/api/status"
import { RenditionSpentAPI } from "../apis/RenditionSpentAPI"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { RenditionSpent } from "../types/slices/renditionSpentType"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"

export const useRenditionSpentStore = () => {
  const dispatch = useDispatch()
  const { renditionSpents, selectedRenditionSpent, status } = useSelector(selectRenditionSpentState)

  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany

  const getRenditionSpents = async (data?: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await RenditionSpentAPI.getRenditionSpents(idcompany, data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      const { detail } = response.data
      dispatch(onFetchRenditionSpent(detail))
      dispatch(changeStatus(ApiStatus.FETCHED))
    } catch (error) {
      console.error(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const createRenditionSpent = async (data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await RenditionSpentAPI.create(data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "")
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "")
      return true
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const editRenditionSpent = async (id: number, data: any) => {
    try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await RenditionSpentAPI.edit(id, data)
        if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || "");
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message  || "");
        return true
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
}

  const deleteRenditionSpent = async (id: number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await RenditionSpentAPI.delete(id, idcompany)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      return true
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const setSelectedRenditionSpent = (renditionSpent: RenditionSpent) => {
    try {
        dispatch(onSetSelectedRenditionSpent(renditionSpent))
    } catch (error) {
        console.log(error)
    }
  }

  return {
    //states
    status,
    renditionSpents,
    selectedRenditionSpent,
    //actions
    getRenditionSpents,
    createRenditionSpent,
    deleteRenditionSpent,
    setSelectedRenditionSpent,
    editRenditionSpent
  }
}