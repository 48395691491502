import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { ServiceType, ServiceTypeType } from "../../types/slices/serviceTypeType";
import { RootState } from "../store";

const initialState: ServiceTypeType = {
    status: ApiStatus.FETCHED,
    serviceTypes: [],
    errorMessage: undefined,
    selectedServiceType: {} as ServiceType
}

const serviceTypeSlice = createSlice({
    name: 'serviceType',
    initialState,
    reducers: {
        onFetchServiceTypes (state, { payload }: { payload: ServiceType[] }) {
            state.status       = ApiStatus.FETCHED
            state.serviceTypes    = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedServiceType (state, { payload }: { payload: ServiceType }) {
            state.selectedServiceType = payload
        }
    }
})

export const selectServiceTypeState = (state: RootState) => state.serviceTypeSlice
export default serviceTypeSlice.reducer

export const {
    onFetchServiceTypes,
    changeStatus,
    onSetSelectedServiceType
} = serviceTypeSlice.actions