import { ChangeEvent, FC, useEffect, useState } from "react";
import { CustomForm } from "../../components/common/CustomForm/CustomForm";
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader";
import { RoutesMap } from "../../types";
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody";
import { Formik } from "formik";
import { useDispatch } from "../../redux/store";
import { showBackdrop } from "../../toolbox/helpers/custom-action";
import { Divider, Grid, TextField, FormLabel, RadioGroup, FormControlLabel, Radio, Typography, Button, Autocomplete } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CessionAddThirdModal } from "./components/CessionAddThirdModal";
import { useOwnerStore } from "../../hooks/useOwnerStore"
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { Department } from "../../types/slices/departmentType"
import { AssignmentAPI } from "../../apis/AssignmentAPI";
import { useAssignmentStore } from "../../hooks/useAssignmentStore";
import { Assignment } from "../../types/slices/assignmentType";
import { Owner } from "../../types/slices/ownerType";

type Values = {
    days_give: number,
    days_recieve: number,
}

export const CessionFormEdit : FC = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {owners, getOwners, setSelectedOwnerAssignee, setSelectedOwnerAssignor, ownerAssignee, ownerAssignor} = useOwnerStore();
    const { departments, selectedDepartment, getDepartments, setSelectedDepartment } = useDepartmentStore()
    const [ showModal, setShowModal ] = useState<boolean>(false)
    const [ cessionType, setCessionType ] = useState<number>(1)
    const {selectedAssignment, editAssignment} = useAssignmentStore();
    const [data, setData] = useState<Values>({
        days_give: selectedAssignment.days_give,
        days_recieve: selectedAssignment.days_recieve,
    });

    const validateForm = (values) => {
        let errors:any = {};
        if(!values.days_give) errors.days_give = "days_give requerido";
        if(!values.days_recieve) errors.days_recieve = "days_recieve requerido";
        return errors;
    }

    const onSubmit = (formData) => {
        dispatch(showBackdrop(true))
        let data = {
            ...formData,
            iddepartment : selectedDepartment.id,
            idowner_assignor : ownerAssignor?.id,
            idowner_assignee : ownerAssignee?.id,
            idassignment_type : cessionType,
        }

        editAssignment(selectedAssignment.id, data)
        navigate({ pathname: RoutesMap.CESSION_LIST })
    }

    const handleCancel = () => {
        navigate({ pathname: RoutesMap.CESSION_LIST })
    }

    const handleChangeCessionType = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const option = parseInt(event.target.value)
        setCessionType(option)
    }

    const onDataEdit = () => {
        console.log(selectedAssignment)
        setSelectedOwnerAssignee(owners.find((item) => item.id === selectedAssignment.idowner_assignee) || {} as Owner)
        setSelectedOwnerAssignor(owners.find((item) => item.id === selectedAssignment.idowner_assignor) || {} as Owner)
        setSelectedDepartment(departments.find((item) => item.id === selectedAssignment.iddepartment) || {} as Department)
    }

    useEffect(()=>{
        console.log('consumimos apis')
        getOwners();
        getDepartments();
    },[selectedAssignment])

    useEffect(()=>{
        console.log('ediot')
        onDataEdit();
    },[owners, departments])

    return(
        <>
        <CustomForm>
            <CustomFormHeader
                title={'Editar cesión'}
                text={'Modifique los datos de la nueva solicitud'}
                goBack={RoutesMap.CESSION_LIST}
            >
            </CustomFormHeader>
            <CustomFormBody>
                <Formik initialValues={data} enableReinitialize onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                        return (
                        <Grid container sx={{ padding:'0px 20px' }}>
                            <Grid container item>
                                <Grid item container xs={12} spacing={2} sx={{mt:2}}>
                                    <Grid item xs={12} md={6}>
                                        <FormLabel>Cedente:</FormLabel>
                                            <Autocomplete
                                                id="combo-box-department"
                                                size="small"
                                                options={owners}
                                                fullWidth
                                                value={ownerAssignor}
                                                getOptionLabel={(owner) => owner.name}
                                                renderInput={(params) => <TextField {...params} label="" size="small"  />}
                                                onChange={(e, newValue) => {
                                                    newValue && setSelectedOwnerAssignor(newValue);
                                                }}
                                            />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormLabel>Cesionario:</FormLabel>
                                        <Grid item container>
                                            <Grid item xs={9}>
                                            <Autocomplete
                                                id="combo-box-department"
                                                size="small"
                                                options={owners}
                                                fullWidth
                                                value={ownerAssignee}
                                                getOptionLabel={(owner) => owner.name}
                                                renderInput={(params) => <TextField {...params} label="" size="small"  />}
                                                onChange={(e, newValue) => {
                                                    newValue && setSelectedOwnerAssignee(newValue);
                                                }}
                                            />
                                            </Grid>
                                           <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                                               <Button
                                                onClick={ () => setShowModal(true) }
                                                sx={{ backgroundColor: '#fff', border: '1px solid #212D39', color: '#212D39', height: 40, padding: '5px', textTransform: 'none', width: 100, "&:hover": { backgroundColor: "#212D39", color: '#fff' } }}
                                                >
                                                    Tercero
                                                </Button>
                                           </Grid>    
                                        </Grid>                              
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormLabel>Departamento:</FormLabel>
                                        <Autocomplete
                                            id="combo-box-department"
                                            size="small"
                                            options={departments || []}
                                            getOptionLabel={(departament) => departament?.name}
                                            value={selectedDepartment}
                                            renderInput={(params) => <TextField {...params} label="" size="small" />}
                                            onChange={(e, newValue: any) => {
                                                setSelectedDepartment(newValue || {} as Department);
                                            }}
                                            />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormLabel>Tipo de Cesión:</FormLabel>
                                        <RadioGroup
                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                            name="controlled-radio-buttons-group"
                                            row
                                        >
                                            <FormControlLabel sx={{ padding: '0px 20px' }} value={1} checked={cessionType == 1 ? true : false} control={<Radio onChange={ (e) => handleChangeCessionType(e) } sx={{ color: '#000000', '&.Mui-checked': { color: '#000000' } }}/>} label="Equitativo" />
                                            <FormControlLabel sx={{ padding: '0px 20px' }} value={2} checked={cessionType == 2 ? true : false} control={<Radio onChange={ (e) => handleChangeCessionType(e) } sx={{ color: '#000000', '&.Mui-checked': { color: '#000000' } }}/>} label="Negociable"/>
                                        </RadioGroup>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormLabel>Días reservados:</FormLabel>
                                        <TextField
                                            id="days_give"
                                            type="text" 
                                            name="days_give"
                                            fullWidth
                                            size="small"
                                            value={values.days_give}
                                            onChange={handleChange}
                                            error={errors.days_give && touched.days_give ? true : false}
                                            helperText={errors.days_give && touched.days_give ? errors.days_give : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormLabel>Días a ceder:</FormLabel>
                                        <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                                            <TextField
                                                id="days_recieve"
                                                type="text" 
                                                name="days_recieve"
                                                sx={{ width: '50px' }}
                                                size="small"
                                                value={values.days_recieve}
                                                onChange={handleChange}
                                                error={errors.days_recieve && touched.days_recieve ? true : false}
                                                helperText={errors.days_recieve && touched.days_recieve ? errors.days_recieve : ''}
                                                variant="standard"
                                            />
                                            <Typography sx={{ color: 'grey' }}>{'días (Equitativo)'}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container item sx={{ marginTop: '30px' }}>
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button
                                        onClick={ () => handleSubmit() }
                                        sx={{ backgroundColor: '#212D39', color: '#fff', height: '25px' , margin: '0px 20px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": {backgroundColor: "#212D39" } }}
                                        type="submit"
                                    >
                                        Editar
                                    </Button>
                                    <Button
                                        onClick={ () => handleCancel() }
                                        style={{ color:'#73B2FF', border: '1px solid #73B2FF', borderRadius: '5px', height: '25px', margin: '0px 20px', padding: '6px 16px', textTransform: 'none', width: 160  }}
                                    >
                                        Cancelar
                                    </Button>                      
                                </Grid>
                            </Grid>
                        </Grid>
                    )}}
                </Formik>
            </CustomFormBody>
        </CustomForm>
        {
            showModal && (
                <CessionAddThirdModal
                    open={showModal}
                    closeModal={ () => setShowModal(false) }
                />
            )
        }
        </>
    )
}