import { api } from "./configs/axiosConfigs"

export const RenditionBuildingSpentIndividualAPI = {
    getRenditionBuildingSpentIndividuals: async (idcompany: number, data?: any) => {
        const response = await api.get(
        '/reportSpentSpecific',
        {
            params: {
                ...data,
                idcompany
            }
        }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    create: async (data: any) => {
        const response = await api.post(
            '/reportSpentSpecific/register', data
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    edit: async (id: number, data: any) => {
        const response = await api.post(
        `/reportSpentSpecific/update/${id}`, data
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    delete: async (id: number, idcompany: number) => {
        const response = await api.delete(
        `/reportSpentSpecific/${id}`,
        {
            params: {
                idcompany
            }
        }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    }
}