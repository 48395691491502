import { ModalHeader } from "../../../components/common/Modal/ModalHeader"
import { Modal } from "../../../components/common/Modal/Modal"
import { ModalBody } from "../../../components/common/Modal/ModalBody"
import { Box, Grid, Divider, Typography, ListItemButton, TextField, Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import { useAssignmentStore } from "../../../hooks/useAssignmentStore"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const DetailModal: React.FC<any> = (props): JSX.Element | any => {

    const { open, closeModal } = props
    const { selectedAssignment } = useAssignmentStore();

    console.log('selectedAssignment', selectedAssignment)
    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="sm">
            <ModalHeader
                text={'Detalle de la Cesión'}
                className='positionElements'
                onCancel={closeModal}
            >
            </ModalHeader>
            <ModalBody>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Información General</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ListItemButton>
                                <Typography sx={{ width: '40%', flexShrink: 0 }}>Cedente</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{selectedAssignment.name_assignor}</Typography>
                            </ListItemButton>
                            <Divider />
                            <ListItemButton>
                                <Typography sx={{ width: '40%', flexShrink: 0 }}>Cesionario</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{selectedAssignment.name_assignee}</Typography>
                            </ListItemButton>
                            <Divider />
                            <ListItemButton>
                                <Typography sx={{ width: '40%', flexShrink: 0 }}>Tipo</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{selectedAssignment?.idowner_assignee ? 'Propietario' : 'Externo'}</Typography>
                            </ListItemButton>
                            <Divider />
                            <ListItemButton>
                                <Typography sx={{ width: '40%', flexShrink: 0 }}>Fecha de Creación</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{selectedAssignment.created_date}</Typography>
                            </ListItemButton>
                            <Divider />
                            {
                                selectedAssignment.days_give !== selectedAssignment.days_recieve &&
                                <>
                                    <ListItemButton>
                                        <Typography sx={{ width: '40%', flexShrink: 0 }}>Fecha Inicio</Typography>
                                        <Typography sx={{ color: 'text.secondary' }}>{selectedAssignment.start_date}</Typography>
                                    </ListItemButton>
                                    <Divider />
                                    <ListItemButton>
                                        <Typography sx={{ width: '40%', flexShrink: 0 }}>Fecha Fin</Typography>
                                        <Typography sx={{ color: 'text.secondary' }}>{selectedAssignment.end_date}</Typography>
                                    </ListItemButton>
                                    <Divider />
                                </>
                            }
                           

                        </AccordionDetails>


                    </Accordion>

                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Cedente</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ListItemButton>
                                <Typography sx={{ width: '40%', flexShrink: 0 }}>RUT</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{selectedAssignment.rut_assignor}</Typography>
                            </ListItemButton>
                            <Divider />
                            <ListItemButton>
                                <Typography sx={{ width: '40%', flexShrink: 0 }}>Nombre</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{selectedAssignment.name_assignor}</Typography>
                            </ListItemButton>
                            <Divider />
                            <ListItemButton>
                                <Typography sx={{ width: '40%', flexShrink: 0 }}>Correo</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{selectedAssignment.email_assignor}</Typography>
                            </ListItemButton>
                            <Divider />
                            <ListItemButton>
                                <Typography sx={{ width: '40%', flexShrink: 0 }}>Teléfono</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{selectedAssignment.phone_assignor}</Typography>
                            </ListItemButton>
                            <Divider />

                        </AccordionDetails>
                    </Accordion>

                    <Accordion>

                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Cesionario</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ListItemButton>
                                <Typography sx={{ width: '40%', flexShrink: 0 }}>RUT</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{selectedAssignment?.idowner_assignee ? selectedAssignment?.rut_assignee : selectedAssignment?.rut_invited_owner}</Typography>
                            </ListItemButton>
                            <Divider />

                            <ListItemButton>
                                <Typography sx={{ width: '40%', flexShrink: 0 }}>Tipo</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{selectedAssignment?.idowner_assignee ? 'Propietario' : 'Externo'}</Typography>
                            </ListItemButton>
                            <Divider />
                            <ListItemButton>
                                <Typography sx={{ width: '40%', flexShrink: 0 }}>Nombre</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{selectedAssignment?.idowner_assignee ? selectedAssignment?.name_assignee : selectedAssignment?.name_invited_owner}</Typography>
                            </ListItemButton>
                            <Divider />
                            <ListItemButton>
                                <Typography sx={{ width: '40%', flexShrink: 0 }}>Email</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{selectedAssignment?.idowner_assignee ? selectedAssignment?.email_assignee : selectedAssignment?.email_invited_owner}</Typography>
                            </ListItemButton>
                            <Divider />
                            <ListItemButton>
                                <Typography sx={{ width: '40%', flexShrink: 0 }}>Teléfono</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{selectedAssignment?.idowner_assignee ? selectedAssignment?.phone_assignee : selectedAssignment?.phone_invited_owner}</Typography>
                            </ListItemButton>
                            <Divider />


                        </AccordionDetails>

                    </Accordion>


                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Departamento</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ListItemButton>
                                <Typography sx={{ width: '40%', flexShrink: 0 }}>Cedente</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{selectedAssignment.name_department}</Typography>
                            </ListItemButton>
                            <Divider />
                            <ListItemButton>
                                <Typography sx={{ width: '40%', flexShrink: 0 }}>Cesionario</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{selectedAssignment.address_department}</Typography>
                            </ListItemButton>
                            <Divider />
                            <ListItemButton>
                                <Typography sx={{ width: '40%', flexShrink: 0 }}>Departamento</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{selectedAssignment.location_url_department}</Typography>
                            </ListItemButton>
                            <Divider />
                            <ListItemButton>
                                <Typography sx={{ width: '40%', flexShrink: 0 }}>Departamento</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{selectedAssignment.num_owner_department}</Typography>
                            </ListItemButton>
                            <Divider />


                        </AccordionDetails>
                    </Accordion>


                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography>Reserva</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ListItemButton>
                                <Typography sx={{ width: '40%', flexShrink: 0 }}>Código</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{selectedAssignment.code_reservation}</Typography>
                            </ListItemButton>
                            <Divider />
                            <ListItemButton>
                                <Typography sx={{ width: '40%', flexShrink: 0 }}>Fecha Inicio</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{selectedAssignment.arrival_date_reservation}</Typography>
                            </ListItemButton>
                            <Divider />
                            <ListItemButton>
                                <Typography sx={{ width: '40%', flexShrink: 0 }}>Fehca Fin</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{selectedAssignment.exit_date_reservation}</Typography>
                            </ListItemButton>
                            <Divider />
                            <ListItemButton>
                                <Typography sx={{ width: '40%', flexShrink: 0 }}>Fecha de Creación</Typography>
                                <Typography sx={{ color: 'text.secondary' }}>{selectedAssignment.date_reservation}</Typography>
                            </ListItemButton>
                            <Divider />


                        </AccordionDetails>
                    </Accordion>
                </Box>
            </ModalBody>
        </Modal>
    )
}