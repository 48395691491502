import { CommonOptionPopover } from './CommonOptionPopover'
import { useNotificationStore } from '../../hooks/useNotificationStore'
import { Notification } from '../../types/slices/notificationType'
import { MenuItem } from '@mui/material'

type OptionPopoverProps = {
  notification: Notification
  isRead: boolean
}

export const OptionPopover = (props: OptionPopoverProps) => {
  const { notification, isRead } = props

  const { changeSeenNotification, deleteReceivedNotification, getNotifications } = useNotificationStore()

  const markAsRead = async() => {
    const response = await changeSeenNotification(notification.id)
    if(response){
      getNotifications({perPage: 5, page:1})
    }else{
      return false;
    }
 }

  return (
    <>
      <CommonOptionPopover id={notification?.id}  >
        <MenuItem disabled={isRead} onClick={markAsRead}>Marcar como leído</MenuItem>
        <MenuItem onClick={() => deleteReceivedNotification(notification.id)}>Eliminar</MenuItem>
      </CommonOptionPopover>
    </>
  )
}