import { useDispatch, useSelector } from "react-redux"
import { changeStatus, onFetchOwners, onFetchOwnersDistincs, onSetSelectedOwner, onSetSelectedOwnerAdd, onSetSelectedOwnerAssignee, onSetSelectedOwnerAssignor, selectOwnerState, setAction } from "../redux/slices/ownerSlice"
import { ApiStatus } from "../types/api/status"
import { OwnerAPI } from "../apis/OwnerAPI"
import { Owner } from "../types/slices/ownerType"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"

export const useOwnerStore = () => {
  const dispatch = useDispatch()
  const { owners, ownersDistincs, status, selectedOwner, selectedOwnerAdd, ownerAssignee, ownerAssignor, action } = useSelector(selectOwnerState)
  
  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany

    const getOwners = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await OwnerAPI.getOwners(idcompany, data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            const { detail } = response.data
            dispatch(onFetchOwners(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
  }
  
  const getOwnersFilter = async (ids?: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      dispatch(onFetchOwnersDistincs([]));
      const response = await OwnerAPI.getOwners(idcompany)
      if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || '')
      }
      const { detail } = response.data
      const newArray = detail.filter(obj => !ids.some(idObj => idObj.idowner === obj.idowner));
      
      dispatch(onFetchOwnersDistincs(newArray))
      dispatch(changeStatus(ApiStatus.FETCHED))
    } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.error(error)
    }
  }

    const findOwner = async (idowner) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await OwnerAPI.findOwner(idowner)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            const { detail } = response.data
            dispatch(changeStatus(ApiStatus.FETCHED))
            return detail
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }
    
    const findInvitedByOwner = async (idinvitedbyowner) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await OwnerAPI.findInvitedByOwner(idinvitedbyowner)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            const { detail } = response.data
            dispatch(changeStatus(ApiStatus.FETCHED))
            return detail
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const createOwner = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await OwnerAPI.createOwner(data, idcompany)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || '')
            return true
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
    }

    const editOwner = async (idowner: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await OwnerAPI.editOwner(idowner, data, idcompany)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || '')
            return true
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
  }
  
  const editInvitedByOwner = async (idinvitedbyowner: number, data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await OwnerAPI.editInvitedByOwner(idinvitedbyowner, data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || '')
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message || '')
      return true
    } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.log(error)
    }
    }

    const deleteOwner = async (idowner: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await OwnerAPI.deleteOwner(idowner, idcompany)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || '')
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || '')
            return true
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
    }

    const setSelectedOwner = (owner: Owner) => {
        try {
            dispatch(onSetSelectedOwner(owner))
        } catch (error) {
            console.log(error)
        }
    }

    const setSelectedOwnerAdd = (owner: Owner) => {
        try {
            dispatch(onSetSelectedOwnerAdd(owner))
        } catch (error) {
            console.log(error)
        }
    }

    const setSelectedOwnerAssignee = (owner: Owner) => {
        try {
            dispatch(onSetSelectedOwnerAssignee(owner))
        } catch (error) {
            console.log(error)
        }
    }

    const setSelectedOwnerAssignor = (owner: Owner) => {
        try {
            dispatch(onSetSelectedOwnerAssignor(owner))
        } catch (error) {
            console.log(error)
        }
    }

    const setActionOner = (state) => {
        try {
            dispatch(setAction(state))
        } catch (error) {
            console.log(error)
        }
    }

    return {
      //states
      owners,
      ownersDistincs,
      status,
      selectedOwner,
      selectedOwnerAdd,
      ownerAssignee,
      ownerAssignor,
      action,
      //actions
      getOwners,
      getOwnersFilter,
      findOwner,
      findInvitedByOwner,
      createOwner,
      editOwner,
      editInvitedByOwner,
      deleteOwner,
      setSelectedOwner,
      setSelectedOwnerAdd,
      setSelectedOwnerAssignee,
      setSelectedOwnerAssignor,
      setActionOner,
    }
}