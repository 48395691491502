import { Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './Carrousel.css';
import { useCalendar } from "../../../hooks/useCalendar";

const themeAvenir = createTheme({
    typography: {
    fontFamily: 'Avenir',
    fontSize: 15
    },
});

export const Carrousel = () => {

  const { getCurrentRange, addPeopleInvolved, departament, owner } = useCalendar();

  const bannerByDepartameent = () => {

  }

  const items = [
      {
        Name: 'Conoce los nuevos departamentos',
        Caption: '',
        contentPosition: 'left',
        Items: [
          {
            Name: 'Nuestros especialistas',
            Image:
              'https://img10.naventcdn.com/avisos/11/00/58/57/48/38/720x532/326063458.jpg',
          },
          {
            Name: 'Tratamientos',
            Image:
              'https://upload.wikimedia.org/wikipedia/commons/8/88/Aguirre-y-boza-arquitectos-edificio-vivienda-departamentos-macul-01.jpg',
          },
        ],
      },
      {
        Name: 'Encuentra tu comodidad',
        Caption: '',
        contentPosition: 'right',
        Items: [
          {
            Image:
              'https://img10.naventcdn.com/avisos/11/00/63/79/24/63/360x266/309285044.jpg',
          },
          {
            Name: 'Comodidad',
            Image:
              'https://img10.naventcdn.com/avisos/11/00/66/73/98/13/360x266/366661838.jpg',
          },
        ],
      },
  ]

  const Banner = (props) => {
      const item = props?.item
      const contentPosition = props?.contentPos
      
      if (props.newProp) console.log(props.newProp)
      const contentPos = contentPosition ? contentPosition : 'left'
      const totalItems = props.length ? props.length : 3
      const mediaLength = totalItems - 1
      
      const items:any = []
      const content: JSX.Element = (
          <Grid item xs={12} md={12 / totalItems} key="content">
          <ThemeProvider theme={themeAvenir}>
              <CardContent className="Content">
              <Typography className="Title">{item.Name}</Typography>
      
              <Typography className="Caption">{item.Caption}</Typography>
              </CardContent>
          </ThemeProvider>
          </Grid>
      )
      
      for (let i = 0; i < mediaLength; i++) {
          const item2 = item.Items[i]
          const media: JSX.Element = (
              <Grid item xs={12} md={12 / totalItems}  key={item2.Name} >
                  <CardMedia className="Media" image={item2.Image} title={item2.Name}>
                  </CardMedia>
              </Grid>
          )
          items.push(media)
      }
      
      if (contentPos === 'left') {
          items.unshift(content)
      } else if (contentPos === 'right') {
          items.push(content)
      } else if (contentPos === 'middle') {
          items.splice(items.length / 2, 0, content)
      }
      
      return (
          <Card raised className="Banner">
          <Grid container spacing={0} className="BannerGrid">
              {items}
          </Grid>
          </Card>
      )
  }

  return (
      <>
      <Grid item xs={12}>
      <Carousel
        className="Example"
        autoPlay={true}
        indicators
        duration={2000}
        cycleNavigation
        navButtonsAlwaysVisible={false}
        navButtonsAlwaysInvisible={false}>
        {items.map((item, index) => {
          return (
            <Banner
              item={item}
              key={index}
              contentPos={item.contentPosition}
            />
          )
        })}
      </Carousel>
      
      </Grid>
      </>
  )
}