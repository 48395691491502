import { toast } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { RoutesMap } from '../../../types';

export const NotificationSnackBar = (noti: any) => {
    return toast.custom((t) => (
    <div style={{padding: '1.5em', display: 'flex', boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', background: '#fff'}}>
       <Link to={RoutesMap.NOTIFICATION} target='_blank' style={{textDecoration: 'none'}}>
       <div style={{flexGrow: '1', alignItems: 'flex-start', display: 'flex', marginRight: '1.5rem'}}>
          <div style={{paddingTop: '.125rem', flexShrink: '0'}}>
             <img style={{
                borderRadius: '9999px',
                width: '2.5rem',
                height: '2.5rem',
                maxWidth: '100%',
             }}
             src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png" />
          </div>
          <div style={{flex: '1 1 0%', marginLeft: '.75rem'}}>
             <p style={{fontWeight: '500', fontSize: '0.875rem', lineHeight: '1.25rem', margin: '0', color:'#000'}}>{noti.dataSender} te notificó:</p>
             <br></br>
             <p style={{color: 'rgb(107 114 128)', fontSize: '.875rem', lineHeight: '1.25rem', marginTop: '.25rem', margin: '0'}}>{noti.content}</p>
          </div>
       </div>
       </Link>
       <div style={{flexGrow: '2', borderLeft: '2px solid rgb(107 114 128)', borderLeftWidth: '1px', display: 'flex'}}>
             <button onClick={() => toast.remove(t.id)}
                style={{color: 'rgb(79 70 229)', fontWeight: '500', fontSize: '.875rem', lineHeight: '1.25rem',
                   padding: '1rem', borderColor: 'transparent', borderWidth: '1px', borderTopRightRadius: '.5rem', borderBottomRightRadius: '.5rem',
                   borderRadius: '0', justifyContent: 'center', alignItems: 'center', width: '100%', display: 'flex', outline: '2px solid transparent',
                   outlineOffset: '2px',
                   background: '#fff'
             }}>
                Cerrar
             </button>
       </div>
    </div>
    ),
    {
        duration: 6000,
    })
}