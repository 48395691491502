import { useState } from 'react';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover } from '@mui/material';
import { useAuthStore } from '../../../hooks';
import { alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { RoutesMap } from '../../../types';
import { Role } from '../../../types/roles/roleTypes';
import { CustomBackdrop } from '../CustomBackdrop/CustomBackdrop';
import { ModalInfo } from '../ModalInfo/ModalInfo';

export default function AccountPopover() {

    const [open, setOpen] = useState<any>(null);
    const [showUpdatePassModal, setShowUpdatePassModal] = useState<boolean>(false);
    const { user, logout } = useAuthStore()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false);

    const account = {
        photoURL: '',
        displayName: user.userType,
        role: user.userType
      }

    const handleOpen = (event:any) => {
        setOpen(event.currentTarget);
    };

    const handleClose = (option:any) => {
        setOpen(null);
        const routeHome = user.userType == Role.SUPER_ADMIN ? RoutesMap.COMPANIES : (user.userType == Role.ADMIN ? RoutesMap.DEPARTAMENT : RoutesMap.HOME);
        switch(option){
            case 'inicio':
                navigate({ pathname: routeHome}, { replace: true })
                break;
            case 'perfil':
                navigate({ pathname: RoutesMap.MY_PROFILE}, { replace: true })
                break;
            case 'updatePass':
                setShowUpdatePassModal(true);
                break;
        }
    };

    const handlelogout = async() => {
        try {
            setLoading(true);
            const response = await logout();
            navigate('/login')
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
        
    }

    return (
        <>
        <IconButton
            onClick={handleOpen}
            sx={{
            p: 0,
            ...(open && {
                '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                },
            }),
            }}
        >
            <Avatar src={account.photoURL} alt="photoURL" sx={{width:'25px', height:'25px'}}/>
        </IconButton>
        <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
            sx: {
                p: 0,
                mt: 1.5,
                ml: 0.75,
                width: 180,
                '& .MuiMenuItem-root': {
                typography: 'body2',
                borderRadius: 0.75,
                },
            },
            }}
        >
            <Box sx={{ my: 1.5, px: 2.5 }}>
                <Typography variant="subtitle2" noWrap>
                    {account.displayName}
                </Typography>
            </Box>

            <Divider sx={{ borderStyle: 'dashed' }} />

            <Stack sx={{ p: 1 }}>
                <MenuItem key={"inicio"} onClick={()=>handleClose("inicio")}>
                    {"Inicio"}
                </MenuItem>
                <MenuItem key={"perfil"} onClick={()=>handleClose("perfil")}>
                    {"Mi perfil"}
                </MenuItem>
                <MenuItem key={"updatePass"} onClick={()=>handleClose("updatePass")}>
                    {"Cambiar contraseña"}
                </MenuItem>
            </Stack>

            <Divider sx={{ borderStyle: 'dashed' }} />

            <MenuItem onClick={()=>{handlelogout()}} sx={{ m: 1 }}>
                {"salir"}
            </MenuItem>
        </Popover>
        {
            loading && (
                <ModalInfo
                    open={loading}
                    text={'Cerrando sesión...'}
                />
            )
        }
        </>
    )
}