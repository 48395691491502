import { api } from "./configs/axiosConfigs"

export const RenditionAPI = {
    getRenditions: async (idcompany: number, data?: any) => {
        const response = await api.get(
        '/report',
        {
            params: {
                ...data,
                idcompany
            }
        }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    create: async (idcompany: number, data: any) => {
        const response = await api.post(
            '/report/register', 
            {
                ...data,
                idcompany
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    edit: async (id: number, data: any) => {
        const response = await api.patch(
        `/report/${id}`,
        {
            ...data
        }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    delete: async (id: number, idcompany: number) => {
        const response = await api.delete(
        `/report/${id}`,
        {
            params: {
                idcompany
            }
        }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    getOrCreate: async (idcompany: number, data: any) => {
        const response = await api.post(
            '/report/getOrCreate', 
            {
                ...data,
                idcompany
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    getRenditionOwner: async (iddepartment: number, idowner: number) => {
        const response = await api.get(
        '/report/detailCategoryRendicion',
        {
            params: {
                iddepartment,
                idowner
            }
        }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    getById: async (id:number) => {
        const response = await api.get(
        `/report/${id}`
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    getRenditionOwnerPDF: async (idreport:number, idowner:number, idcompany:number) => {
        const response = await api.get(
        '/report/detailRendicionPDF',
        {
            params: {
                idreport,
                idowner,
                idcompany
            },
            responseType: 'blob'
        }
        ).then(response => response)
        .catch((error) => {
            console.error(error)
            return error.response
        })
        return response
    },
    closeRendition: async (id:number, idcompany:number) => {
        const response = await api.post(
            `/report/validate/${id}`,
            {
                idcompany
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    detailRendicionAdminPDF: async (idreport:number, idcompany:number) => {
        const response = await api.get(
        '/report/detailRendicionAdminPDF',
        {
            params: {
                idreport,
                idcompany
            },
            responseType: 'blob'
        }
        ).then(response => response)
        .catch((error) => {
            console.error(error)
            return error.response
        })
        return response
    },
}