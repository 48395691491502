import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";

const initialState: any = {
    status: ApiStatus.FETCHED,
    renditionBuildingSpents: [],
    errorMessage: undefined,
    selectedRenditionBuildingSpent: {} as any
}

const renditionBuildingSpentSlice = createSlice({
    name: 'renditionSpent',
    initialState,
    reducers: {
        onFetchRenditionBuildingSpent (state, { payload }: { payload: any[] }) {
            state.status       = ApiStatus.FETCHED
            state.renditionBuildingSpents    = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedRenditionBuildingSpent (state, { payload }: { payload: any }) {
            state.selectedRenditionBuildingSpent = payload
        }
    }
})

export const selectRenditionBuildingSpentState = (state: RootState) => state.renditionBuildingSpentSlice
export default renditionBuildingSpentSlice.reducer

export const {
    onFetchRenditionBuildingSpent,
    changeStatus,
    onSetSelectedRenditionBuildingSpent
} = renditionBuildingSpentSlice.actions