import { api } from "./configs/axiosConfigs"

export const NotificationAPI ={
    getNotifications: async (data?: any) => {
        const response = await api.get(
            '/notifications',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    getNotViewNotifications: async (data?: any) => {
        const response = await api.get(
            '/notifications/notView',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    getViewNotifications: async (data?: any) => {
        const response = await api.get(
            '/notifications/view',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    getTypeNotifications: async (data?: any) => {
        const response = await api.get(
            '/notifications/typeNotifications'
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    getSentNotifications: async (data?: any) => {
        const response = await api.get(
            '/notifications/sent',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    createNotification: async (data: any, idcompany: number) => {
        const response = await api.post(
            '/notifications/register',
            {
                ...data,
                idcompany: idcompany
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    changeSeenNotification: async (iddepartment: number) => {
        const response = await api.patch(
        `/notifications/changeSeen/${iddepartment}`
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    changeSeenMassiveNotification: async (idcompany: number) => {
        const response = await api.patch(
        `/notifications/changeSeenMassive`,
        {
            idcompany: idcompany
        }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    deleteSentNotification: async (iddepartment: number) => {
        const response = await api.delete(
        `/notifications/sent/${iddepartment}`
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    deleteReceivedNotification: async (id?: number) => {
      const response = await api.delete(
        `/notifications/received/${id}`
      ).then(response => response.data)
      .catch((error) => {
        console.error(error)
        return error.response.data
      })
      return response
    },
}