import { useSelector } from "react-redux"
import { useDispatch } from "../redux/store"
import { ApiStatus } from "../types/api/status"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { Rendition } from "../types/slices/renditionType"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"
import { changeStatus, onFetchRenditionBuildingOwner, onFetchRenditionBuildings, onSetIdRenditionBuilding, onSetSelectedRenditionBuilding, selectRenditionBuildingState } from "../redux/slices/renditionBuildingSlice"
import { RenditionBuildingAPI } from "../apis/RenditionBuildingAPI"
import { onFetchRenditionBuildingSpent } from "../redux/slices/renditionBuildingSpentSlice"
import { onFetchRenditionBuildingSpentIndividual } from "../redux/slices/renditionBuildingSpentIndividualSlice"
import { AnyAaaaRecord } from "dns"

export const useRenditionBuildingStore = () => {
  const dispatch = useDispatch()
  const { renditionBuildings, renditionBuildingOwner, selectedRenditionBuilding, idrenditionBuilding, selectedRenditionBuildingOwner, status } = useSelector(selectRenditionBuildingState)

  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany

  const getOrCreateRendition = async (data?: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await RenditionBuildingAPI.getOrCreate(data, idcompany)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      const { detail } = response.data
      if (detail[0].validation_status == "1") {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', "La Rención para la fecha seleccionada ya fue registrada y cerrada.");
      }      
      dispatch(onFetchRenditionBuildings(detail))
      dispatch(onFetchRenditionBuildingSpent(detail[0].spent_building || []))
      dispatch(onFetchRenditionBuildingSpentIndividual(detail[0].spent_especific_department || []))

      dispatch(changeStatus(ApiStatus.FETCHED))
      return true
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const getRenditionsBuilding = async (data?: any) => {
      try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await RenditionBuildingAPI.getRenditions(idcompany, data)
          if (!response?.status) {                
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || "");
          }
          const { detail } = response.data
          dispatch(onFetchRenditionBuildings(detail))
          dispatch(changeStatus(ApiStatus.FETCHED))
          return detail
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
  }

  const createRenditionBuilding = async (data: any) => {
      try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await RenditionBuildingAPI.create(idcompany, data)
          if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('error', response.data.message || "")
            return false
          }
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar('success', response.data.message || "");
          return true
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
  }

  const editRenditionBuilding = async (id: number, data: any) => {
      try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await RenditionBuildingAPI.edit(id, data)
          if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('error', response.data.message || "")
            return false
          }
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar('success', response.data.message || "");
          return true
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
  }

  const deleteRenditionBuilding = async (id: number) => {
    try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await RenditionBuildingAPI.delete(id, idcompany)
        if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar('error', response.data.message || "");
          return false
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message || "");
        return true
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const getRenditionDetailPDF = async (idreport_building:number, iddepartment:number) => {
      try {
        const response = await RenditionBuildingAPI.getRenditionDetailPDF(idreport_building, iddepartment, idcompany)
        if (!response?.status) {                
          CustomSnackbar('error', response.data.message)
          return false
        }
        return response.data
      } catch (error) {
        console.log(error)
      }
  }

  const getRenditionBuildingDetailPDF = async (idreport_building:number) => {
      try {
        const response = await RenditionBuildingAPI.getRenditionBuildingDetailPDF(idreport_building, idcompany)
        if (!response?.status) {
          CustomSnackbar('error', response.data.message)
          return false
        }
        return response.data
      } catch (error) {
        console.log(error)
      }
  }

  const getRenditionBuildingById = async (id:number) => {
      try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await RenditionBuildingAPI.getById(id)
        if (!response?.status) {                
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || "");
        }
        const { detail } = response.data
        dispatch(onFetchRenditionBuildings(detail))
        dispatch(onFetchRenditionBuildingSpent(detail[0].spent_building || []))
        dispatch(onFetchRenditionBuildingSpentIndividual(detail[0].spent_especific_department || []))
        dispatch(changeStatus(ApiStatus.FETCHED))
        return true
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
  }

  const closeRenditionBuilding = async (id:number) => {
      try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await RenditionBuildingAPI.closeRendition(id, idcompany)
        if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar('error', response.data.message || "")
          return false
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message || "");
        return true
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
  }

  const getRenditionBuildingOwner = async (data:any) => {
    try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await RenditionBuildingAPI.getRenditionBuildingOwner(data)
        if (!response?.status) {                
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || "");
        }
        const { detail } = response.data
        dispatch(onFetchRenditionBuildingOwner(detail))
        dispatch(changeStatus(ApiStatus.FETCHED))
        return true
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
  }

  const setIdRenditionBuilding = (id:number) => {
      try {
          dispatch(onSetIdRenditionBuilding(id))
      } catch (error) {
          console.log(error)
      }
  }

  const setSelectedRenditionBuilding = (rendition: Rendition) => {
    try {
      dispatch(onSetSelectedRenditionBuilding(rendition))
    } catch (error) {
      console.log(error)
    }
  }

  const getRenditionDetailOwnerPDF = async (idreport_building:number, iddepartment:number) => {
      try {
        const response = await RenditionBuildingAPI.getRenditionDetail(idreport_building, iddepartment, idcompany)
        if (!response?.status) {                
          CustomSnackbar('error', response.data.message)
          return false
        }
        return response.data
      } catch (error) {
        console.log(error)
      }
  }

  return {
    //states
    status,
    renditionBuildings,
    renditionBuildingOwner,
    selectedRenditionBuilding,
    selectedRenditionBuildingOwner,
    idrenditionBuilding,
    setSelectedRenditionBuilding,
    getOrCreateRendition,
    getRenditionDetailOwnerPDF,
    getRenditionBuildingDetailPDF,
    getRenditionBuildingById,
    closeRenditionBuilding,
    getRenditionsBuilding,
    getRenditionDetailPDF,
    createRenditionBuilding,
    editRenditionBuilding,
    deleteRenditionBuilding,
    setIdRenditionBuilding,
    getRenditionBuildingOwner,
  }
}