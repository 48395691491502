import { changeStatus, onFetchConceptCategories, onSetSelectedConceptCategory, selectConceptCategoryState, setAction } from "../redux/slices/conceptCategorySlice"
import { ApiStatus } from "../types/api/status"
import { ConceptCategoryAPI } from "../apis/ConceptCategoryAPI"
import { ConceptCategory } from "../types/slices/conceptCategoryType"
import { useDispatch, useSelector } from "../redux/store"
import { CategoryDataForm } from "../types/api/conceptCategory.type"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"

export const useConceptCategoryStore = () => {

  const dispatch = useDispatch()
  const { conceptCategories, status, selectedConceptCategory, action } = useSelector(selectConceptCategoryState)

  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany

    const getConceptCategories = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ConceptCategoryAPI.getConceptCategory(data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            const { detail } = response.data
            dispatch(onFetchConceptCategories(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const createConceptCategory = async (data:CategoryDataForm) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ConceptCategoryAPI.createConceptCategory(data, idcompany)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || '')
            return true;
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
    }

    const setSelectedConceptCategory = (conceptCategory: ConceptCategory) => {
        try {
            dispatch(onSetSelectedConceptCategory(conceptCategory))
        } catch (error) {
            console.log(error)
        }
    }

    const editConceptCategory = async (idseason: number, data: CategoryDataForm) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ConceptCategoryAPI.editConceptCategory(idseason, data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || '')
            return true;
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
    }

    const deleteConceptCategory = async (idseason: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ConceptCategoryAPI.deleteConceptCategory(idseason)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || '')
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || '')
            return true;
        } catch (error) {
            console.log(error)
        }
    }

    const setActionCategory = (state) => {
        try {
            dispatch(setAction(state))
        } catch (error) {
            console.log(error)
        }
    }

    return {
        //states
        conceptCategories,
        status,
        selectedConceptCategory,
        action,
        //actions
        getConceptCategories,
        createConceptCategory,
        setSelectedConceptCategory,
        editConceptCategory,
        deleteConceptCategory,
        setActionCategory,
    }
}