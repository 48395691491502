import { Grid } from "@mui/material";
import { FC, useEffect, useState } from "react";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { useAdministratorStore } from "../../hooks/useAdministratorStore";
import { useNavigate } from "react-router-dom";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { Administrator } from "../../types/slices/administratorType";
import { useSelector } from "react-redux";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { RoutesMap } from "../../types";
import { AdministratorModal } from "./AdministratorModal";

const columns = [
    { type: 'options', field: 'options', label: 'Opciones', align: 'center' },
    { type: 'text', field: 'name', label: 'NOMBRE', align: 'center' },
    { type: 'text', field: 'rut', label: 'RUT', align: 'center' },
    { type: 'text', field: 'email', label: 'EMAIL', align: 'center' },
    { type: 'text', field: 'phone', label: 'TELEFONO', align: 'center' },
    { type: 'detail', field: 'detail', label: '', align:'center' },
];

export const AdministratorList : FC = () => {
    
    const navigate = useNavigate()
    const { status: adminStatus, administrators, selectedAdministrator, getAdministrators, setSelectedAdministrator, deleteAdministrator } = useAdministratorStore()
    const { search } = useSelector(selectHeaderState)

    const [ rowSelected, setRowSelected ] = useState<any>('');
    const [ showModal, setShowModal ] = useState<boolean>(false)
    const [ showModalConfirm, setShowModalConfirm ] = useState<boolean>(false)
    const [ loading, setLoading ] = useState(false)

    useEffect(() => {
        getAdminApi()
    },[search])

    const getAdminApi = async() => {
        try {
          setLoading(true)
          await getAdministrators({search})
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
    }

    const onAdd = () => {
        setSelectedAdministrator({} as Administrator)
        navigate({ pathname: RoutesMap.ADMINISTRATOR_FORM })
    }

    const onEdit = (selected) => {
        setSelectedAdministrator(selected)
        navigate({ pathname: RoutesMap.ADMINISTRATOR_FORM })
    }

    const onDetails = (selected) => {
        setRowSelected(selected)
        setShowModal(true);
    }

    const onDelete = (selected) => {
        setSelectedAdministrator(selected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = async () => {
        if (selectedAdministrator.id) { 
            setSelectedAdministrator({} as Administrator)
            const response = await deleteAdministrator(selectedAdministrator.id)
            if (response === true) {
                setShowModalConfirm(false)
                getAdminApi()
            }
        }
    }
    
    return (<>
        <Grid item xs={12}>
            <CustomTable 
                title={'Administradores'}
                columns={columns} 
                loading={loading}
                rows={administrators || []}
                onRowClick={() => {}}
                hasOptions
                onAddFn={onAdd}
                onDetails={onDetails}
                onDelete={onDelete}
                onEdit={onEdit}
            />
        </Grid>
        {
            showModalConfirm && (
                <ModalConfirm
                    open={showModalConfirm}
                    closeModal={()=>{setShowModalConfirm(false); setSelectedAdministrator({} as Administrator)}}
                    onConfirm={onDeleteConfirm}
                    status={adminStatus}
                />
            )
        }
        {
            showModal && (
                <AdministratorModal
                    open={showModal}
                    closeModal={()=>{setShowModal(false)}}
                    rowSelected={rowSelected}
                />
            )
        }
    </>)
}