
import { Grid, IconButton, Typography } from '@mui/material'
import { useAuthStore } from '../../../hooks'
import { Role } from '../../../types/roles/roleTypes'
import AccountPopover from './AccountPopover'
import NotificationPopover from './NotificationPopover'
import { RoutesMap } from '../../../types'
import { useNavigate } from 'react-router-dom'
import logoheader from '../../../assets/image/costamar_header.png'
import { CustomSearch } from '../CustomSearch/CustomSearch'
import { ChangeDepartament } from './ChangeDepartament'

// ----------------------------------------------------------------------

export const HeaderCustom = (): JSX.Element => {

    const navigate = useNavigate();

    //Hooks
    const { user } = useAuthStore()

    const department_owner_active:any = user?.data?.department_owner_active && (user.data).department_owner_active.length > 0 ? true : false ;

    const BACKGROUND_COLOR  = user.userType == Role.OWNER || user.userType == Role.FAMILY ? '#212D39' : '#C3DEFF'
    const ICON_COLOR_HEADER = user.userType == Role.OWNER || user.userType == Role.FAMILY ? '#FFFFFF' : '#7a7a7a'


    const onChangeLogo = () => {
        switch (user.userType) {
          case Role.OWNER:
              navigate({ pathname: RoutesMap.HOME }, { replace: true })
              break
            case Role.FAMILY:
                navigate({ pathname: RoutesMap.HOME }, { replace: true })
                break
            default:
              break
        }
    }

    return (
        <>
        <Grid display='block' position='relative' 
            sx={{
                    backgroundColor: BACKGROUND_COLOR,
                    padding:'5px'
                }}
            >
                <Grid container xs={12} display='flex' justifyContent='center' alignItems='center' 
                sx={{
                    width:'100%'
                }}
                >

                    {
                        ((user && user.userType != Role.ADMIN) && (user && user.userType != Role.SUPER_ADMIN) ) && (
                            <Grid item xs={12} sm={2} md={2} lg={1.5} spacing={2}>
                                <Grid sx={{height:'40px', width:'125px', cursor:'pointer', marginBottom:'18px'}} onClick={onChangeLogo} >
                                    <img style={{ width: '110px'}} src={logoheader}/>
                                </Grid>
                            </Grid>
                        )
                    }
                    {
                        (user && user.userType == Role.OWNER && department_owner_active) && (
                            <Grid item xs={12} sm={3} md={3} lg={3.5} spacing={2} display='flex' justifyContent='center' alignItems='center' textAlign='center'>
                                <Grid display="flex" justifyContent="start" alignItems="center" container direction="row">
                                    <IconButton onClick={() =>  navigate({ pathname: RoutesMap.RESERVATION })}>
                                        <Typography variant='body2' component='h1' gutterBottom color='#fff' sx={{fontWeight:400, '&:hover':{textDecoration: 'underline'}}}>
                                            {'Mis reservas'}
                                        </Typography>
                                    </IconButton>
                                    <IconButton onClick={() =>  navigate({ pathname: RoutesMap.RENDITION })}>
                                        <Typography variant='body2' component='h1' gutterBottom color='#fff' sx={{fontWeight:400, '&:hover':{textDecoration: 'underline'}}}>
                                            {'Rendiciones'}
                                        </Typography>
                                    </IconButton>
                                    <IconButton onClick={() =>  navigate({ pathname: RoutesMap.AFFILIATED_FAMILY })}>
                                        <Typography variant='body2' component='h1' gutterBottom color='#fff' sx={{fontWeight:400, '&:hover':{textDecoration: 'underline'}}}>
                                            {'Afiliados'}
                                        </Typography>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )
                    }
                    {
                        user && (user.userType === Role.FAMILY)&& (
                            <Grid item xs={12} sm={3} md={3} lg={3.5} spacing={2} display='flex' justifyContent='center' alignItems='center' textAlign='center'>
                                <Grid display="flex" justifyContent="start" alignItems="center" container direction="row">
                                    <IconButton onClick={() =>  navigate({ pathname: RoutesMap.RESERVATION })}>
                                        <Typography variant='body2' component='h1' gutterBottom color='#fff' sx={{ml:1 , fontWeight:400, '&:hover':{textDecoration: 'underline'}}}>
                                            {'Mis reservas'}
                                        </Typography>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        )
                    }
                    {
                        ((user && user.userType != Role.ADMIN && department_owner_active) && (user && user.userType != Role.SUPER_ADMIN && department_owner_active) ) && (
                            <Grid item xs={12} sm={5} md={5} lg={5} spacing={2}>
                                <CustomSearch/>
                            </Grid>
                        )
                    }
                    <Grid item xs={(user?.userType != Role.ADMIN && user?.userType != Role.SUPER_ADMIN) ? 12 : 2} sm={2} md={2} lg={2} spacing={2} display='flex' justifyContent='end' alignItems='center'>
                        {
                            ((user && user.userType != Role.ADMIN && department_owner_active) && (user && user.userType != Role.SUPER_ADMIN && department_owner_active) ) && (
                                <>
                                <Grid display='flex' alignItems='center'>
                                    <IconButton>
                                        <ChangeDepartament colorIcon={ICON_COLOR_HEADER} />
                                    </IconButton>
                                    <span className='divider'></span>
                                </Grid>
                                </>
                            )
                        }
                        {
                            (department_owner_active || (user && user.userType == Role.ADMIN)) && (
                                <IconButton>
                                    <NotificationPopover colorIcon={ICON_COLOR_HEADER}/> 
                                </IconButton>
                            )
                        }
                        <IconButton>
                            <AccountPopover/>
                        </IconButton>
                    </Grid>
                </Grid>
        </Grid>
        </>
    )
}
