import { Formik } from "formik"
import { CustomForm } from "../../components/common/CustomForm/CustomForm"
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody"
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader"
import { Divider, Grid, TextField, Autocomplete, FormLabel } from "@mui/material"
import { useEffect, useState } from "react"
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter"
import { RoutesMap } from "../../types"
import { useNavigate } from "react-router-dom"
import { ApiStatus } from "../../types/api/status"
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop"
import { useDispatch } from "../../redux/store"
import { useServiceStore } from "../../hooks/useServiceStore"
import { Service } from "../../types/slices/serviceSlice"
import { useServiceTypeStore } from "../../hooks/useServiceTypeStore"
import { ServiceType } from "../../types/slices/serviceTypeType"
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar"

type Values = {
    id: number ,
    name: string
    idservice_type: number | undefined
}

export const ServiceForm = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { status: statusService, selectedService, action, setSelectedService, createService, editService, setActionService } = useServiceStore()
    const { status: statusServiceType, serviceTypes, getServiceTypes } = useServiceTypeStore()

    const [ selectedServiceType, setSelectedServiceType ] = useState<ServiceType|null>(null)
    const [ isEdit, setIsEdit ] = useState<boolean>(false)
    const [ data, setData ] = useState<Values>({
        id: 0,
        name: '',
        idservice_type: undefined
    });

    useEffect(()=>{
        if(!selectedService.id && action=='') {
            navigate({ pathname: RoutesMap.SERVICES}, { replace: true })
        }
    }, [!selectedService.id])

    useEffect(()=>{
        getServiceTypes()
    },[])

    useEffect(()=>{
        if(selectedService.id && serviceTypes.length > 0) {
            setIsEdit(true)
            setData({
                id: selectedService.id,
                name: selectedService.name,
                idservice_type: selectedService.idservice_type
            })
            const serviceTypeSelect = setCustomServiceTypes(selectedService.idservice_type, serviceTypes)
            setSelectedServiceType(serviceTypeSelect[0] || null)
            setSelectedService({} as Service)
        }
    },[selectedService.id && serviceTypes.length > 0])

    //service types
    const setCustomServiceTypes = (idservice_type, original_service_types) => {
        const data = idservice_type ? original_service_types.filter(value => value.id === idservice_type) : [];
        return getCustomServiceTypes(data);
    }

    const getCustomServiceTypes = (original_service_types) => {
        const res = original_service_types ? original_service_types.map(serviceType => ({...serviceType})) : null;
        return res;
    }

    const customServiceTypes = getCustomServiceTypes(serviceTypes || []);

    const validateForm = (values) => {
        let errors:any = {};
        if(!values.name) errors.name = "nombre requerido";
        return errors;
    }

    const onCancel = ()  => {
        navigate({ pathname: RoutesMap.SERVICES })
    }

    const onSubmit = async (values)  => {
        if(!selectedServiceType?.id) return CustomSnackbar('warning', 'Seleccione un tipo de servicio.')

        const payload = {...values, idservice_type: selectedServiceType?.id }
        const method = !isEdit ? createService(payload) : editService(data?.id, payload)
        const response = await method
        if (response === true) {
            setActionService('')
            navigate({ pathname: RoutesMap.SERVICES })
        }
    }

    return (
        <>
        {statusService === ApiStatus.FETCHING && <CustomBackdrop open={true}/>}
        {statusServiceType === ApiStatus.FETCHING && <CustomBackdrop open={true}/>}
        <CustomForm >

            <CustomFormHeader
                title={!isEdit ? 'Nuevo Servicio' : 'Editar Servicio'}
                text={!isEdit ? 'Ingrese los datos del nuevo servicio' : 'Ingrese los datos del servicio'}
                goBack={RoutesMap.SERVICES}
            >
            </CustomFormHeader>

            <CustomFormBody>

                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{padding:'20px'}}>

                                <Grid item container xs={12} spacing={2} sx={{mt:2}}>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Nombre:</FormLabel>
                                        <TextField
                                            id="name"
                                            type="text" 
                                            name="name"
                                            fullWidth
                                            size="small"
                                            value={values.name}
                                            onChange={handleChange}
                                            error={errors.name && touched.name ? true : false}
                                            helperText={errors.name && touched.name ? errors.name : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Tipo de servicio:</FormLabel>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-service.type"
                                            value={selectedServiceType}
                                            options={customServiceTypes || []}
                                            getOptionLabel={(option) => option.name || ""}
                                            isOptionEqualToValue={(option, value) => option?.name === value?.name}
                                            fullWidth
                                            renderInput={(params) => <TextField {...params} size="small" />}
                                            onChange={(e, newValue) => {
                                                setSelectedServiceType(newValue);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                
                                </Grid>

                                <Divider />
                                <CustomFormFooter 
                                    buttonType="submit"
                                    confirmText={ !isEdit ? 'Guardar Cambios' : 'Actualizar'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        )
                    }} 
                </Formik>

            </CustomFormBody>

        </CustomForm>
        </>
    )
}