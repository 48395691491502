
import { Typography } from "@mui/material";
import { Modal } from "../Modal/Modal";
import { ModalHeader } from "../Modal/ModalHeader";
import { ModalBody } from "../Modal/ModalBody";
import { ModalFooter } from "../Modal/ModalFooter";
import { ApiStatus } from "../../../types/api/status";
import { CustomBackdrop } from "../CustomBackdrop/CustomBackdrop";

export const ModalConfirm: React.FC<any> = (props): JSX.Element | any => {
    const { open, title, text, closeModal, clearState, onConfirm, status, status2=false } = props;
  
    return (
      <>

      <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="sm">

        {status && status == ApiStatus.FETCHING && <CustomBackdrop open={true}/>}

        <ModalHeader
          text={title || '¿Esta seguro de eliminar el elemento seleccionado?'}
          className='positionElements'
          onCancel={closeModal}
          clearState={clearState}
        >
        </ModalHeader>

        <ModalBody>
          <Typography variant="subtitle1" className="textColor"> 
            {text || 'Si estas seguro, haz click en el boton "aceptar" y el registro será eliminado'}
          </Typography>
        </ModalBody>

        <ModalFooter 
          confirmText={"Aceptar"}
          onConfirm={onConfirm}
          cancelText={"Cancelar"}
          onCancel={closeModal}
          className='modal-confirm-footer'
          clearState={clearState}
          // loadingConfirmText={status == ApiStatus.FETCHING}
          loadingConfirmText={status2}
        />
      </Modal>
      </>
    )
}