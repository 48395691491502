import { useEffect, useState } from 'react';
import { Grid, Typography, Card, CardContent, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RoutesMap } from '../../types';
import { ModalCalendar } from '../../components/common/ModalCalendar/ModalCalendar';
import { useCalendar } from '../../hooks/useCalendar';
import { useReservationStore } from '../../hooks/useReservationStore';
import { ApiStatus } from '../../types/api/status';
import { CustomBackdrop } from '../../components/common/CustomBackdrop/CustomBackdrop';
import moment from 'moment';
import { CustomForm } from '../../components/common/CustomForm/CustomForm';
import { CustomFormHeader } from '../../components/common/CustomForm/CustomFormHeader';
import { Reservation } from '../../types/slices/reservationType';

export const ReservationAdminEdit = (props) => {
    const navigate = useNavigate();
    const [showModalCalendar, setShowModalCalendar] = useState<boolean>(false);

    const { getCurrentRange, resetCalendar } = useCalendar();
    const { status: statusReservation, reservations, selectedReservation, getReservationsFilterEdit, setSelectedReservation, editReservation } = useReservationStore()

    const currentRange = getCurrentRange();
    const arrivalDate = currentRange ? moment(currentRange.startDate).format('MMMM Do YYYY') : moment(selectedReservation.startDate).format('MMMM Do YYYY');
    const returnDate = currentRange ? moment(currentRange.endDate).format('MMMM Do YYYY') : moment(selectedReservation.endDate).format('MMMM Do YYYY');

    useEffect(()=>{
        if(!selectedReservation.id) {
            clearState();
            navigate({ pathname: RoutesMap.RESERVATION_ADMIN_LIST}, { replace: true })
        }
    }, [!selectedReservation.id])

    useEffect(()=>{
        if(selectedReservation.id) onSearchAvailability();
    }, [selectedReservation.id])

    const onSearchAvailability = () => {
        const params:any = {
            iddepartment: selectedReservation.iddepartment,
            status: 1
        }
        getReservationsFilterEdit(params, selectedReservation.id);
    }

    const clearState = () => {
        resetCalendar(); 
        setSelectedReservation({} as Reservation)
    }
    
    const onConfirmEditReservation = async() =>{

        const data:any = {
            arrival_date: currentRange ? moment(currentRange.startDate).format('YYYY-MM-DD') : selectedReservation.startDate,
            exit_date: currentRange ? moment(currentRange.endDate).format('YYYY-MM-DD') : selectedReservation.endDate,
        }

        const response = await editReservation(selectedReservation.id, data);
        if (response) {resetCalendar(); navigate({ pathname: RoutesMap.RESERVATION_ADMIN_LIST })}
    }

    return (
        <>
        { statusReservation === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }

        <CustomForm>
            <CustomFormHeader
                title={'Editar Reservación'}
                text={'Ingrese los datos a editar'}
                goBack={RoutesMap.RESERVATION_ADMIN_LIST}
                clearState={clearState}
            >
            </CustomFormHeader>

            <Grid item padding='20px 150px 20px 150px' justifyContent='center' alignContent='center' alignItems='center' sx={{width:'100%'}}>
                <Grid display='flex' mb={1}>
                    <Typography color='#000000' sx={{ fontWeight:600, pr:2, fontSize:'14px' }}>Departamento:</Typography>
                    <Typography sx={{fontSize:'14px'}}>{selectedReservation ? selectedReservation.name_department : '-----'}</Typography>
                </Grid>
                <Grid display='flex' mb={1}>
                    <Typography color='#000000' sx={{ fontWeight:600, pr:2, fontSize:'14px' }}>Propietario:</Typography>
                    <Typography  sx={{fontSize:'14px'}}>{selectedReservation ? selectedReservation.name_owner : '-----'}</Typography>
                    <Typography color='#000000' sx={{ fontWeight:600, pr:2, fontSize:'14px', marginLeft:'50px' }}>Rut:</Typography>
                    <Typography  sx={{fontSize:'14px'}}>{selectedReservation ? selectedReservation.rut_owner : '-----'}</Typography>
                </Grid>
            </Grid>
            <Grid display='flex' justifyContent='center' alignItems='center' sx={{ width:'100%'}} pb={3}>
                <Card onClick={()=>{setShowModalCalendar(true)}} sx={{ width: '600px', display:'flex', justifyContent:'center', alignItems:'center', border:'solid 1px #808080', cursor:'pointer' }}>
                    <Grid sx={{borderRight: '.5px solid #808080'}}>
                        <CardContent>
                            <Typography color='#808080' sx={{ fontWeight:400, fontSize:'14px'}}>
                            LLegada:
                            </Typography>
                            <Grid sx={{padding:'0px 40px 0px 40px', textAlign:'center'}}>
                                <Typography color='#212D39' sx={{ fontWeight:600, fontSize:'21px'}}>
                                    {arrivalDate}
                                </Typography>
                                <Typography color='#212D39' sx={{ fontWeight:400, fontSize:'14px'}}>
                                    {selectedReservation.check_in ? selectedReservation.check_in + ' horas.' : '-----'}
                                </Typography>
                            </Grid>
                        </CardContent>
                    </Grid>
                    <Grid sx={{borderLeft: '.5px solid #808080'}}>
                        <CardContent>
                            <Typography color='#808080' sx={{ fontWeight:400, fontSize:'14px'}}>
                            Salida :
                            </Typography>
                            <Grid sx={{padding:'0px 40px 0px 40px', textAlign:'center'}}>
                                <Typography color='#212D39' sx={{ fontWeight:600, fontSize:'21px'}}>
                                    {returnDate}
                                </Typography>
                                <Typography color='#212D39' sx={{ fontWeight:400, fontSize:'14px'}}>
                                    {selectedReservation.check_out ? selectedReservation.check_out + ' horas.' : '-----'}
                                </Typography>
                            </Grid>
                        </CardContent>
                    </Grid>
                </Card>
            </Grid>
            <Grid display='flex' justifyContent='center' alignItems='center' sx={{ width:'100%'}}>
                <Button onClick={onConfirmEditReservation} size="small" variant="contained" sx={{background:'#212D39',color:'#fff', '&:hover':{bgcolor:'#007ea7'}}}>
                    {'Editar reserva'}
                </Button>
            </Grid>
        </CustomForm >
        {
            showModalCalendar && (
                <ModalCalendar
                    open={showModalCalendar}
                    closeModal={()=>{setShowModalCalendar(false)}}
                    datesRegister={reservations}
                    onReservation={() => {setShowModalCalendar(false)}}
                />
            )
        }
        </>
    )
}