
import MenuIcon from '@mui/icons-material/Menu'
import { AppBar, Grid, IconButton, Toolbar } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useAuthStore } from '../../../hooks'
import { useDrawerStore } from '../../../hooks/useDrawerStore'
import { Role } from '../../../types/roles/roleTypes'
import AccountPopover from './AccountPopover'
import NotificationPopover from './NotificationPopover'
import { RoutesMap } from '../../../types'
import { useNavigate } from 'react-router-dom'
import logoheader from '../../../assets/image/costamar_header.png'
import { CustomSearchByTable } from '../CustomSearchByTable/CustomSearchByTable'
import { ChangeDepartament } from './ChangeDepartament'
import { useNavSectionStore } from '../../../hooks/useNavSectionStore'

// ----------------------------------------------------------------------

export const HeaderView = (): JSX.Element => {

    const navigate = useNavigate();

    //Hooks
    const { user } = useAuthStore()
    const { drawerOpen, openDrawer } = useDrawerStore()
    const { navOpen, openNavSection } = useNavSectionStore()

    const department_owner_active:any = user?.data?.department_owner_active && (user.data).department_owner_active.length > 0 ? true : false ;

    const MARGIN_RIGHT      = (user.userType == Role.SUPER_ADMIN || user.userType == Role.ADMIN) ? 16 : 0
    const BORDER_RADIUS     = (user.userType == Role.SUPER_ADMIN || user.userType == Role.ADMIN) ? 10 : 0
    const MARGIN_TOP        = (user.userType == Role.SUPER_ADMIN || user.userType == Role.ADMIN) ? 5 : 0
    const BACKGROUND_COLOR  = user.userType == Role.OWNER || user.userType == Role.FAMILY ? '#212D39' : '#C3DEFF'
    const ICON_COLOR_HEADER = user.userType == Role.OWNER || user.userType == Role.FAMILY ? '#FFFFFF' : '#7a7a7a'
    const HEADER_MOBILE     = 64
    const HEADER_DESKTOP    = 50

    let NAV_WIDTH = 0
    if (user.userType == Role.SUPER_ADMIN || user.userType == Role.ADMIN) {
        NAV_WIDTH = navOpen ? 312 : 30
    } else {
        NAV_WIDTH = 0
    }

    const StyledRoot = styled(AppBar)(({ theme }) => ({
        backgroundColor: BACKGROUND_COLOR,
        [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${NAV_WIDTH}px)`,
        maxHeight: '75px',
        minHeight: '75px',
        marginRight: MARGIN_RIGHT,
        borderRadius: BORDER_RADIUS,
        marginTop: MARGIN_TOP,
        overflow: 'hidden',
        },
    }))

    const StyledToolbar = styled(Toolbar)(({ theme }) => ({
        alignItems:'center',
        minHeight: HEADER_MOBILE,
        [theme.breakpoints.up('lg')]: {
        minHeight: HEADER_DESKTOP,
        padding: theme.spacing(0, 3),
        },
    }))

    const onChangeLogo = () => {
        switch (user.userType) {
            case Role.SUPER_ADMIN:
              navigate({ pathname: RoutesMap.COMPANIES}, { replace: true })
              break
            case Role.ADMIN:
              navigate({ pathname: RoutesMap.DEPARTAMENT}, { replace: true })
              break
          case Role.OWNER:
              navigate({ pathname: RoutesMap.HOME }, { replace: true })
              break
            case Role.FAMILY:
                navigate({ pathname: RoutesMap.HOME }, { replace: true })
                break
            default:
              break
        }
    }

    return (
        <>
            <StyledRoot >
                <StyledToolbar>
                    <Grid container xs={12} display='flex' justifyContent='center' alignItems='center' 
                    sx={{
                        width:'100%', 
                        marginTop:'5px', 
                    }}
                    >

                        {
                            user && user.userType != Role.OWNER && user.userType != Role.FAMILY && (
                                <Grid xs={2} sx={{display: { lg: 'none', xl: 'none' }}} >
                                <IconButton
                                    onClick={() => openDrawer(!drawerOpen)}
                                    sx={{
                                        mr: 1,
                                        color: 'text.primary',
                                        display: { lg: 'none', xl: 'none' },
                                        paddingTop:'10px'
                                    }}
                                >
                                    <MenuIcon sx={{color: ICON_COLOR_HEADER}}/>
                                </IconButton>
                                </Grid>
                            )
                        }
                        {
                            user && user.userType != Role.OWNER && user.userType != Role.FAMILY && (
                                <Grid xs={2} sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' }}}>
                                    <IconButton
                                        onClick={() => openNavSection(!navOpen)}
                                        sx={{
                                            mr: 1,
                                            color: 'text.primary',
                                            display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' },
                                            paddingTop:'10px'
                                        }}
                                    >
                                        <MenuIcon sx={{color: ICON_COLOR_HEADER}}/>
                                    </IconButton>
                                </Grid>
                                
                            )
                        }

                        {
                            ((user && user.userType != Role.ADMIN) && (user && user.userType != Role.SUPER_ADMIN) ) && (
                                <Grid item xs={12} sm={2} md={2} lg={1.5} spacing={2}>
                                    <Grid sx={{height:'40px', width:'125px', cursor:'pointer', marginBottom:'15px'}} onClick={onChangeLogo} >
                                        <img style={{ width: '110px'}} src={logoheader}/>
                                    </Grid>
                                </Grid>
                            )
                        }
                        {
                            ((user && user.userType == Role.ADMIN) || (user && user.userType == Role.SUPER_ADMIN) ) && (
                                <Grid item xs={8} sm={8} md={8} lg={8} spacing={2} display='flex' justifyContent='center' >
                                    <Grid sx={{display:{xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block'}}}>
                                        <CustomSearchByTable/>
                                    </Grid>
                                </Grid>
                            )
                        }
                        <Grid item xs={(user?.userType != Role.ADMIN && user?.userType != Role.SUPER_ADMIN) ? 12 : 2} sm={2} md={2} lg={2} spacing={2} display='flex' justifyContent='end' alignItems='center'>
                            {
                                ((user && user.userType != Role.ADMIN && department_owner_active) && (user && user.userType != Role.SUPER_ADMIN && department_owner_active) ) && (
                                    <>
                                    <Grid display='flex' alignItems='center'>
                                        <IconButton>
                                            <ChangeDepartament colorIcon={ICON_COLOR_HEADER} />
                                        </IconButton>
                                        <span className='divider'></span>
                                    </Grid>
                                    </>
                                )
                            }
                            {
                                (department_owner_active || (user && user.userType == Role.ADMIN)) && (
                                    <IconButton>
                                        <NotificationPopover colorIcon={ICON_COLOR_HEADER}/> 
                                    </IconButton>
                                )
                            }
                            <IconButton>
                                <AccountPopover/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </StyledToolbar>
            </StyledRoot>
        </>
    )
}
