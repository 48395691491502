import { api } from "./configs/axiosConfigs"

export const AdministratorAPI = {
    getAdministrators: async (data?: any) => {
        const response = await api.get(
            '/admin',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    create: async (data: any) => {
        const response = await api.post(
            '/admin/register',
            {
                ...data
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    edit: async (id: number, data: any) => {
        const response = await api.patch(
        `/admin/${id}`,
        {
            ...data
        }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    delete: async (id: number) => {
        const response = await api.delete(
        `/admin/${id}`
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    }
}