import { useEffect, useState } from 'react'
// @mui
import { useNotificationStore } from '../../../hooks/useNotificationStore'
// import { useAuthStore, useNotificationStore } from '@/hooks'
import { Notification } from '../../../types/slices/notificationType'
import { RoutesMap } from '../../../types'
import EmailIcon from '@mui/icons-material/Email'
import NotificationsIcon from '@mui/icons-material/Notifications'
import RedoIcon from '@mui/icons-material/Redo'
import { Badge, CircularProgress, Divider, Grid, IconButton, ListItemIcon, MenuItem, Popover, Tooltip, Typography } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import moment from 'moment'
import 'moment/locale/es'
import { Link, useNavigate } from 'react-router-dom'
moment.locale('es')

const themeAvenir = createTheme({
  typography: {
    fontFamily: 'Avenir',
    fontSize: 15
  },
})

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 750,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
}

export default function NotificationPopover(props) {
  //props
  const { colorIcon } = props;

  //Hooks
  const { notifications, counter, changeSeenMassiveNotification, changeSeenNotification, deleteReceivedNotification, getNotifications } = useNotificationStore()
  const navigate = useNavigate()
  moment.locale('es')
  //NOTIFICATION POPOVER
  const [open, setOpen] = useState(null)
  const [idPopover, setidPopover] = useState(0);
  const [booleanPopover, setBooleanPopover] = useState(true);
  const [loading, setLoading] = useState(false);
  //  console.log(counter)
  const handleOpen = (event) => {
    setOpen(event.currentTarget)
    getNotificationsAPi();
  }

  const getNotificationsAPi = async() => {
    setLoading(true);
    const response =  await getNotifications({perPage: 5, page:1})
    setLoading(false);
  }

  const handleClose = (option: Notification) => {
    setOpen(null)
  }
  //NEW VIEW POPOVER
  const [openAction, setOpenAction] = useState(null)

  const handleOpenAction = (event) => {
    setOpenAction(event.currentTarget)
  }

  const handleCloseAction = () => {
    setOpenAction(null)
  }

  // const perfilRouteClient = {
  //   label: 'Perfil',
  //   icon: 'eva:person-fill',
  //   route: RoutesMap.PRESENTATION_CUSTOMER
  // }
  // const perfilRouteProfessional = {
  //   label: 'Perfil',
  //   icon: 'eva:person-fill',
  //   route: RoutesMap.PRESENTATION
  // }

  const handleOpenOption = (e, option) => {
    e.preventDefault()
    handleOpenAction(e)
    setidPopover(option?.id);
    // const booleanoPopover= (option?.date_seen !== null)? true: false;
    setBooleanPopover((option?.date_seen !== null) ? true : false);
  }

  const markAsReadMasive = async() => {
    const response = await changeSeenMassiveNotification();
    if(response){
      getNotifications({perPage: 5, page:1})
    }else{
      return false;
    }
  }

  const markAsRead = async(params) => {
    const response = await changeSeenNotification(params)
    if(response){
      getNotifications({perPage: 5, page:1})
    }else{
      return false;
    }
 }

  const limitedNotifications = notifications.slice(0, 5)
  return (
    <>
      <IconButton
        // sx={{ marginLeft: '5px' }}
        aria-label="delete"
        size="large"
        onClick={handleOpen} color="primary" >
        <Badge badgeContent={counter} color="error">
          <NotificationsIcon sx={{fontSize:'22px', color:colorIcon}}/>
        </Badge>
      </IconButton>
      <ThemeProvider theme={themeAvenir}>
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              width: 380,
              borderRadius: '8px',
              overflow: 'visible',
              filter: 'drop-shadow(0px 0px 1px rgba(0,0,0,0.32))',
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 20,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 0px 5px 10px', alignItems: 'center' }}>
            <Typography variant='subtitle1' sx={{ color: '#121c4c' }}>Notificaciones</Typography>
            {/* <ListItemIcon>
              <IconButton onClick={() => navigate({ pathname: RoutesMap.HOME })} color="secondary" size="large">
                <Tooltip title='Recordatorios'>
                  <EmailIcon sx={{ color: '#121c4c' }} fontSize="small" />
                </Tooltip>

              </IconButton>
            </ListItemIcon> */}
          </div>
          <div>
          <Divider sx={{ marginBottom: '0px !important', marginTop: '0px !important', opacity: '0.8', background: '#121c4c', border: '1px solid #121c4c' }} />

            {
              loading ? (
                  <Grid sx={{textAlign:'center', paddingTop:'10px'}}>
                    <CircularProgress size={28} color="inherit" /> 
                  </Grid>
              ) : (
                  limitedNotifications.length > 0 && limitedNotifications.map((option)=>(
                    <Link
                      onContextMenu={(e) => handleOpenOption(e, option)}
                      key={option.id}
                      to={RoutesMap.NOTIFICATION} style={{ textDecoration: 'none' }}>
                      <div onClick={() => handleClose(option)} style={{ cursor: 'pointer', background: `${(option?.date_seen !== null) ? '#fff' : '#efefef'}` }} >
                        <div style={{ wordWrap: 'break-word', justifyContent: 'space-between', display: 'flex', padding: '10px 10px 10px 10px', }}>
                          {/* <Grid container sx={{mr:2}}> */}
                          <Typography variant='body2' sx={{ color: '#000 !important' }}>{option.title}</Typography>
                          {/* <Typography variant='caption'>{option.details}</Typography> */}
                          {/* </Grid> */}
                          <Typography variant='caption' sx={{ color: '#000 !important' }}>{moment(option.date_sent).startOf('day').fromNow()}</Typography>

                        </div>
                        <Divider />
                      </div>
                    </Link>
                  ))
              )
            }

            {/* {limitedNotifications.length > 0 && limitedNotifications.map((option) => {
              return (
                <Link
                  onContextMenu={(e) => handleOpenOption(e, option)}
                  key={option.id}
                  to={RoutesMap.NOTIFICATION} style={{ textDecoration: 'none' }}>
                  <div onClick={() => handleClose(option)} style={{ cursor: 'pointer', background: `${(option?.date_seen !== null) ? '#fff' : '#efefef'}` }} >
                    <div style={{ wordWrap: 'break-word', justifyContent: 'space-between', display: 'flex', padding: '10px 10px 10px 10px', }}>
                      <Typography variant='body2' sx={{ color: '#000 !important' }}>{option.title}</Typography>
                      <Typography variant='caption' sx={{ color: '#000 !important' }}>{moment(option.date_sent).startOf('day').fromNow()}</Typography>

                    </div>
                    <Divider />
                  </div>
                </Link>
              )
            })} */}
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '5px 15px' }}>
            <Grid sx={{ cursor: 'pointer' }} onClick={markAsReadMasive}>
              <Typography variant='caption' sx={{ cursor: 'pointer', fontWeight: '600 !important', color: '#121c4c' }}>Marcar todas como leídas</Typography>
            </Grid>

            <Grid item display={'flex'} alignItems='center'>
              <Typography onClick={() => navigate({ pathname: RoutesMap.NOTIFICATION })} variant='caption' sx={{ cursor: 'pointer', fontWeight: '600 !important', marginRight: '3px', color: '#121c4c' }}> Ver todas </Typography>
              <RedoIcon fontSize='small' sx={{ color: '#121c4c' }} />
            </Grid>
          </div>
        </Popover>

        <Popover
          open={Boolean(openAction)}
          anchorEl={openAction}
          onClose={handleCloseAction}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              // padding:'5px 20px',
              width: 280,
              // borderRadius: '8px',
              overflow: 'visible',
              filter: 'drop-shadow(0px 0px 1px rgba(0,0,0,0.32))',
              mt: -3,
              ml: 2,
              '& .MuiMenuItem-root': {
                typography: 'body2',
                borderRadius: 0.75,
              }

            },
          }}
        >
          <div style={{ cursor: 'pointer' }}>
            {/* <Link to={RoutesMap.NOTIFICATION} target='_blank' style={{ textDecoration: 'none' }}>
              <div onClick={() => handleCloseAction()} style={{ wordWrap: 'break-word', display: 'flex', padding: '5px 10px 0px 10px', }}>
                <Typography variant='body2' sx={{ color: '#000 !important' }}>Abrir en una pestaña nueva</Typography>

              </div>
            </Link> */}
            <Link to={RoutesMap.NOTIFICATION} target='_blank' style={{ textDecoration: 'none', color: '#000' }}>
              <MenuItem onClick={() => handleCloseAction()}>Abrir en una pestaña nueva</MenuItem>
            </Link>
            <MenuItem disabled={booleanPopover} onClick={() => markAsRead(idPopover)}>Marcar como leído</MenuItem>

            <Divider />
            <MenuItem onClick={() => deleteReceivedNotification(idPopover)}>Quitar</MenuItem>
            {/* <div onClick={() => handleCloseAction()} style={{ wordWrap: 'break-word', display: 'flex', padding: '5px 10px 0px 10px', }}>
              <Typography variant='body2' sx={{ color: '#000 !important' }}>Quitar</Typography>

            </div> */}
          </div>
        </Popover>
      </ThemeProvider>
    </>
  )
}
