import { GuestDataForm } from "../types/api/Guest.type"
import { api } from "./configs/axiosConfigs"

export const GuestAPI = {

    getGuests: async () => {
        const response = await api.get(
            '/owner/invited'
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    getGuestsThird: async () => {
        const response = await api.get(
            '/owner/invited?type=5'
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    getGuestsByOwner: async (idowner: number) => {
        const response = await api.get(
            `owner/invitedByOwner/${idowner}`
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    createGuest: async (data: GuestDataForm) => {
        const response = await api.post(
            '/owner/registerInvited', data
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    editGuest: async (idguest: number, data: GuestDataForm) => {
        const response = await api.patch(
        `/owner/invited/${idguest}`,
        { ...data }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    
    deleteGuest: async (idguest: number) => {
        const response = await api.delete(
        `/owner/invited/${idguest}`
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },

    getGuestsType: async () => {
        const response = await api.get(
            '/owner/guestType'
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
}