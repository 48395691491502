import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { ApiStatus } from '../../types/api/status'
import { Season, SeasonGruop, seasonType } from '../../types/slices/seasonType'

const initialState: seasonType =
{
  status: ApiStatus.FETCHED,
  seasons: [],
  seasonsGroups: [],
  seasonSelected: {} as Season,
  seasonSelectedByOwner: {} as any,
  errorMessage: undefined,
  action: '',
}

const seasonSlice = createSlice({
  name: 'seasonSlice',
  initialState,
  reducers: {
    onFetchSeason (state, { payload }: { payload: Season[]}) {
      state.status = ApiStatus.FETCHED
      state.seasons = payload
      state.errorMessage = undefined
    },
    onFetchSeasonGroup (state, { payload }: { payload: SeasonGruop[]}) {
      state.status = ApiStatus.FETCHED
      state.seasonsGroups = payload
      state.errorMessage = undefined
    },
    onFetchActiveSeason (state, { payload }: { payload: Season[]}) {
        state.status = ApiStatus.FETCHED
        state.seasons = payload
        state.errorMessage = undefined
    },
    onSetSelectedSeason (state, { payload }: { payload: Season }) {
      state.seasonSelected = payload
    },
    onSetSelectedSeasonByOwner (state, { payload }: { payload: any }) {
      state.seasonSelectedByOwner = payload
    },
    changeStatus (state, { payload }: { payload: ApiStatus}){
      state.status = payload
    },
    setAction (state, { payload }: { payload: string }) {
      state.action = payload
    },
  }
})

export const selectSeasonState = (state: RootState) => state.seasonSlice
export default seasonSlice.reducer
export const {
    onFetchSeason,
    onFetchSeasonGroup,
    onFetchActiveSeason,
    onSetSelectedSeason,
    onSetSelectedSeasonByOwner,
    changeStatus,
    setAction,
} = seasonSlice.actions
