import { onHideBackdrop, onShowBackdrop } from "../../redux/slices/backdropSlice";
import { onHideSnackBar, onShowSnackBar } from "../../redux/slices/snackbarSlice";
import { Dispatch } from 'redux';

export const showSnackBar = (alertType:any, message:any) => (dispatch: Dispatch) => {
    dispatch(onShowSnackBar({
        alertType: alertType,
        message: message
    }));

    setTimeout(() => {
        dispatch(onHideSnackBar());
    }, 5000)
}

export const showBackdrop = (open:boolean) => async (dispatch: Dispatch) => {
    if(open){
        dispatch(onShowBackdrop())
    }else{
        dispatch(onHideBackdrop())
    }
    
}