import { useEffect, useState } from "react";
import { Grid } from '@mui/material'
import CustomTable from '../../components/common/CustomTable/CustomTable';
import { RoutesMap } from "../../types";
import { useNavigate } from "react-router-dom";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { UserModal } from "./components/UserModal";
import { useUserStore } from "../../hooks/UseUserStore";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { User } from "../../types/slices/userType";

const columns = [
    { type: 'options', field: 'options', label: 'Opciones' },
    { type: 'text', field: 'rut', label: 'Rut' },
    { type: 'text', field: 'email', label: 'Email' },
    { type: 'text', field: 'status_confirm', label: 'Estado de confirmación', format:(row) => `${row.status_confirm == 1 ? "Confirmado" : "No confirmado"}` },
    { type: 'text', field: 'status', label: 'Estado', format:(row) => `${row.status == 1 ? "Activo" : "Eliminado"}` },
    { type: 'detail', field: 'detail', label: '', align:'center' },
];

export const UserView = () => {

    const navigate = useNavigate()

    const {users, status:statusUser, getUsers, setSelectedUser, userSelected, deleteUser, setActionUser } = useUserStore();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getUsersApi()
    },[])

    const getUsersApi = async() => {
        try {
          setLoading(true);
          const response = await getUsers();
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }


    const onAdd = () => {
        setActionUser('add')
        navigate({ pathname: RoutesMap.USERS_FORM })
    }
    const onEdit = (selectedUser) => {
        setActionUser('edit')
        setSelectedUser(selectedUser)
        navigate({ pathname: RoutesMap.USERS_FORM })
    }
    const onDelete = (selectedUser) => {
        setActionUser('delete')
        setSelectedUser(selectedUser)
        setShowModalConfirm(true);
    }
    const onDetails = (selectedUser) => {
        setActionUser('detail')
        setSelectedUser(selectedUser)
        setShowModal(true);
    }

    const onDeleteConfirm = () => {
        if (userSelected.id) deleteUser(userSelected.id)
        setSelectedUser({} as User)
        setShowModalConfirm(false)
        getUsersApi()
    }

    return (
        <>
            {/* {statusUser == ApiStatus.FETCHING && <CustomBackdrop open={true}/>} */}

            <Grid item xs={12}>
                <CustomTable 
                    title={'Usuarios'}
                    columns={columns} 
                    loading={loading}
                    rows={users || []}
                    onRowClick={() => {}}
                    hasOptions
                    onAddFn={onAdd}
                    onDetails={onDetails}
                    onDelete={onDelete}
                    onEdit={onEdit}
                />
            </Grid>

            {
                showModalConfirm && (
                    <ModalConfirm
                        open={showModalConfirm}
                        closeModal={()=>{setShowModalConfirm(false); setSelectedUser({} as User)}}
                        onConfirm={onDeleteConfirm}
                        status={statusUser}
                    />
                )
            }
            {
                showModal && (
                    <UserModal
                        open={showModal}
                        closeModal={()=>{setShowModal(false); setSelectedUser({} as User)}}
                        rowSelected={userSelected}
                    />
                )
            }
        </>
    )
}