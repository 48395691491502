import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { ApiStatus } from '../../types/api/status'
import { specialdate, specialdateGroup, specialdateType } from '../../types/slices/specialdateType'

const initialState: specialdateType =
{
  status: ApiStatus.FETCHED,
  specialdates: [],
  specialdatesGroup: [],
  specialdateSelected: {} as specialdate,
  errorMessage: undefined,
  action: ''
}

const specialDateSlice = createSlice({
  name: 'specialDateSlice',
  initialState,
  reducers: {
    onFetchSpecialdate (state, { payload }: { payload: specialdate[]}) {
      state.status = ApiStatus.FETCHED
      state.specialdates = payload
      state.errorMessage = undefined
    },
    onFetchSpecialdateGroup (state, { payload }: { payload: specialdateGroup[]}) {
      state.status = ApiStatus.FETCHED
      state.specialdatesGroup = payload
      state.errorMessage = undefined
    },
    onSetSelectedSpecialdate (state, { payload }: { payload: specialdate }) {
        state.specialdateSelected = payload
    },
    changeStatus (state, { payload }: { payload: ApiStatus}){
      state.status = payload
    },
    setAction (state, { payload }: { payload: string }) {
      state.action = payload
    },
  }
})

export const selectSpecialdateState = (state: RootState) => state.specialDateSlice
export default specialDateSlice.reducer
export const {
    onFetchSpecialdate,
    onFetchSpecialdateGroup,
    onSetSelectedSpecialdate,
    changeStatus,
    setAction,
} = specialDateSlice.actions
