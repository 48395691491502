import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";

const initialState: any = {
    status: ApiStatus.FETCHED,
    renditionBuildings: [],
    renditionBuildingOwner: [],
    errorMessage: undefined,
    selectedRenditionBuilding: {} as any,
    renditionOwner: [],
    idrenditionBuilding: null,
    selectedRenditionBuildingOwner: {} as any,
}

const renditionBuildingSlice = createSlice({
    name: 'renditionBuilding',
    initialState,
    reducers: {
        onFetchRenditionBuildings (state, { payload }: { payload: any[] }) {
          state.status       = ApiStatus.FETCHED
          state.renditionBuildings   = payload
          state.errorMessage = undefined
        },
        onFetchRenditionBuildingOwner (state, { payload }: { payload: any[] }) {
          state.status       = ApiStatus.FETCHED
          state.renditionBuildingOwner   = payload
          state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
          state.status = payload
        },
        onSetSelectedRenditionBuilding (state, { payload }: { payload: any }) {
          state.selectedRenditionBuilding = payload
        },
        onSetSelectedRenditionBuildingOwner (state, { payload }: { payload: any }) {
          state.selectedRenditionBuildingOwner = payload
        },
        onFetchRenditionOwner (state, { payload }: { payload: any }) {
          state.status         = ApiStatus.FETCHED
          state.renditionOwner = payload
          state.errorMessage   = undefined
        },
        onSetIdRenditionBuilding (state, { payload }: { payload: number }) {
            state.idrenditionBuilding = payload
        }, 
    }
})

export const selectRenditionBuildingState = (state: RootState) => state.renditionBuildingSlice

export default renditionBuildingSlice.reducer

export const {
  onFetchRenditionBuildings,
  onFetchRenditionOwner,
  onFetchRenditionBuildingOwner,
  changeStatus,
  onSetSelectedRenditionBuilding,
  onSetSelectedRenditionBuildingOwner,
  onSetIdRenditionBuilding
} = renditionBuildingSlice.actions