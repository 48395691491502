import { useDispatch, useSelector } from "react-redux"
import { changeStatus, onFetchAdministrators, onSetEditAdministrator, onSetSelectedAdministrator, selectAdministratorState } from "../redux/slices/administratorSlice"
import { ApiStatus } from "../types/api/status"
import { AdministratorAPI } from "../apis/AdministratorAPI"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { Administrator } from "../types/slices/administratorType"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"

export const useAdministratorStore = () => {
    const dispatch = useDispatch()
    const { administrators, status, editAdministrator, selectedAdministrator } = useSelector(selectAdministratorState)

    const userData = readLocalStorage(LocalStorageKey.USER_DATA)
 
    const getAdministrators = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await AdministratorAPI.getAdministrators(data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchAdministrators(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const createAdministrator = async (data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await AdministratorAPI.create(data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const editAdmin = async (id: number, data: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await AdministratorAPI.edit(id, data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const deleteAdministrator = async (id: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await AdministratorAPI.delete(id)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              CustomSnackbar('error', response.data.message || "");
              return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const setSelectedAdministrator = (admin: Administrator) => {
        try {
            dispatch(onSetSelectedAdministrator(admin))
        } catch (error) {
            console.log(error)
        }
    }

    const setEditAdministrator = (admin: Administrator) => {
        try {
            dispatch(onSetEditAdministrator(admin))
        } catch (error) {
            console.log(error)
        }
    }

    return {
        administrators,
        status,
        editAdministrator,
        selectedAdministrator,
        getAdministrators,
        createAdministrator,
        editAdmin,
        deleteAdministrator,
        setSelectedAdministrator,
        setEditAdministrator
    }
}