import { useDepartmentSpentStore } from "../../hooks/useDepartmentSpentStore";
import { ModalBody } from "../../components/common/Modal/ModalBody";
import { Formik } from "formik";
import { Modal } from "../../components/common/Modal/Modal";
import { ChangeEvent, useEffect, useState } from "react";
import { Autocomplete, Button, FormLabel, Grid, TextField } from "@mui/material";
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { Department } from "../../types/slices/departmentType";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { usePaymentConceptStore } from "../../hooks/usePaymentConceptStore";


type DataForm = {
    id: number
    idconcept_spent: number
    iddepartment: number
    amount: number
}

export const PaymentConceptDetailFormModal : React.FC<any> = (props): JSX.Element | any => {
    const { open, closeModal, loadDepartmentSpents } = props

    const { status: apiStatus, createDepartmentSpent, selecteddepartmentSpent, editDepartmentSpent, departmentSpents } = useDepartmentSpentStore()
    const { departmentsFilter, getDepartmentsFilter } = useDepartmentStore()
    const { selectedDetail } = usePaymentConceptStore()
 
    const [ departmentSelected, setDepartmentSelected ] = useState<any>(null)
    const [ isEdit, setIsEdit ] = useState<boolean>(false)
    const [ data, setData ] = useState<DataForm>({
        id: 0,
        idconcept_spent: 0,
        iddepartment: 0,
        amount: 0
    })

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(()=>{
        if(Object.entries(selecteddepartmentSpent).length > 0) {
            setIsEdit(true)
            setData({
                id: selecteddepartmentSpent.id || 0,
                idconcept_spent: selecteddepartmentSpent.idconcept_spent || 0,
                iddepartment: selecteddepartmentSpent.iddepartment || 0,
                amount: parseInt(selecteddepartmentSpent.amount) || 0
            })
            setDepartmentSelected({
                id: selecteddepartmentSpent.iddepartment,
                name: selecteddepartmentSpent.department,
            })
        }
    },[selecteddepartmentSpent?.id])

    useEffect(() => {
        getOwnersFilterApi();
    }, [])
    
    const getOwnersFilterApi = async () => {
        try {      
          setLoading(true);
          const response = await getDepartmentsFilter(departmentSpents);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const onSubmit = async (values) => {
        if(!isEdit){
            const response = await createDepartmentSpent({
                ...values,
                iddepartment: departmentSelected.id,
                idconcept_spent: selectedDetail.id
            });
            if (response) {
                loadDepartmentSpents && loadDepartmentSpents({ idconcept_spent: selectedDetail.id })
                closeModal()
            }
        }else{
            const response = await editDepartmentSpent(data.id,{
                ...values,
                iddepartment: departmentSelected.id,
                idconcept_spent: data.idconcept_spent
            });
            if (response) {
                loadDepartmentSpents && loadDepartmentSpents({ idconcept_spent: data.idconcept_spent })
                closeModal()
            }
        }
    }

    const handleChangeAll = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        const regex = /^[0-9\b]+$/;
        if (name === 'amount') {
            if (value === "" || regex.test(value)) {
                setData({
                    ...data,
                    [name]: parseInt(value)
                })
            }
        } else {
            setData({
                ...data,
                [name]: value
            })
        }
    }

    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="xs">
            {
                apiStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} />
            }
            <ModalBody>
                <Formik initialValues={data} enableReinitialize onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                    return (<>
                    <Grid item container xs={12} spacing={2} sx={{mt:2}}>
                        
                        <Grid item xs={12} md={12}>
                            <FormLabel>Seleccione un departamento:</FormLabel>
                            <Autocomplete
                                id="disable-portal"
                                disablePortal
                                value={departmentSelected}
                                options={departmentsFilter || []}
                                getOptionLabel={(option) => option.name || ""}
                                fullWidth
                                renderInput={(params) => <TextField 
                                    {...params} 
                                    size='small'
                                />}
                                onChange={(e, newValue) => { 
                                    setDepartmentSelected(newValue|| {} as Department) 
                                    setFieldValue('iddepartment', newValue?.id)
                                }}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                disabled={selecteddepartmentSpent?.id ? true : false}
                                loading={loading}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormLabel>Monto:</FormLabel>
                            <TextField
                                id="amount"
                                type="number" 
                                name="amount"
                                fullWidth
                                size="small"
                                value={values.amount}
                                onChange={handleChangeAll}
                                inputProps={{ 
                                    readOnly: (selecteddepartmentSpent && selecteddepartmentSpent.id && selecteddepartmentSpent.idspent_type == 2) ? true : false  
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                            onClick={ () => handleSubmit() }
                            sx={{ backgroundColor: '#212D39', color: '#fff', float: 'right', height: '25px' , margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": {backgroundColor: "#212D39" } }}
                            >
                                Guardar
                            </Button>
                            <Button
                            onClick={ () => closeModal() }
                            sx={{ backgroundColor: '#212D39', color: '#fff', float: 'right', height: '25px' , margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": {backgroundColor: "#212D39" } }}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                    </>)}}
                </Formik>
            </ModalBody>
      </Modal>
    )
}