
import { Grid, Typography, CircularProgress } from "@mui/material";
import { Modal } from "../Modal/Modal";
import { ModalBody } from "../Modal/ModalBody";


export const ModalInfo: React.FC<any> = (props): JSX.Element | any => {
    const { open, text, width } = props;

    return (
      <>

      <Modal open={open} disableEscapeKeyDown disableBackdropClick size='xs'>

        <ModalBody>
            <Grid xs={12} textAlign={'center'}>
                <CircularProgress size={28}/>
            </Grid>
            <Typography variant="subtitle1" className="textColor" sx={{textAlign:'center'}}> 
                {text || ''}
            </Typography>
        </ModalBody>
{/* 
        <ModalBody>
          <Typography variant="subtitle1" className="textColor"> 
            {text || ''}
          </Typography>
        </ModalBody> */}

      </Modal>
      </>
    )
}