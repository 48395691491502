import { createSlice } from "@reduxjs/toolkit"
import { ApiStatus } from "../../types/api/status"
import { SpentType, SpentTypeType } from "../../types/slices/spentTypeType"
import { RootState } from "../store"


const initialState: SpentTypeType =
{
  status: ApiStatus.FETCHED,
  spentTypes: [],
  errorMessage: undefined,
  selectedSpentType: {} as SpentType
}

const spentTypeSlice = createSlice({
    name: 'spentType',
    initialState,
    reducers: {
        onFetchSpentTypes (state, { payload }: { payload: SpentType[] }) {
            state.status       = ApiStatus.FETCHED
            state.spentTypes   = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedSpentType (state, { payload }: { payload: SpentType }) {
            state.selectedSpentType = payload
        }
    }
})

export const selectSpentTypeState = (state: RootState) => state.spentTypeSlice
export default spentTypeSlice.reducer

export const {
    onFetchSpentTypes,
    changeStatus,
    onSetSelectedSpentType
} = spentTypeSlice.actions
  