import { Modal } from "../Modal/Modal";
import { ModalHeader } from "../Modal/ModalHeader";
import { ModalBody } from "../Modal/ModalBody";
import { ModalFooter } from "../Modal/ModalFooter";
import { Calendar } from '../Calendar/Calendar';
import 'react-date-range/dist/styles.css';  
import 'react-date-range/dist/theme/default.css'; 
import { useCalendar } from "../../../hooks/useCalendar";

type RequestModal = {
  open: boolean
  closeModal: () => void
  datesRegister:Array<any>
  onReservation: () => void
}

export const ModalCalendar: React.FC<RequestModal> = (props): JSX.Element | any => {
  const { open, closeModal, datesRegister, onReservation }: RequestModal = props;
  const { isActionBlocked }= useCalendar();
  return (
    <>
    <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="lg">
      {/* <ModalHeader
        className='positionElements'
        onCancel={closeModal}
      >
      </ModalHeader> */}

      <ModalBody>
        <Calendar 
          open={open}
          datesRegister= {datesRegister}
        />
      </ModalBody>

      <ModalFooter 
        confirmText={isActionBlocked ? "Bloquear" : "Reservar"}
        onConfirm={()=>{onReservation()}}
        cancelText={"Cancelar"}
        onCancel={closeModal}
      />
    </Modal>
    </>
  )
}