import { api } from "./configs/axiosConfigs"

export const ReportAPI = {

    getReportRenditionPay: async (data?:any) => {
        const response = await api.get(
            '/reportCompany/payRendicion',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.log(error)
            return error.response.data
        })
        return response
    },

    getReportRenditionPayExcel: async (data?:any) => {
        const response = await api.get(
            '/reportCompany/payRendicionExcel',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },

    getReportRendition: async (data?:any) => {
        console.log(data)
        const response = await api.get(
            '/reportCompany/rendicion',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.log(error)
            return error.response.data
        })
        return response
    },

    getReportRenditionExcel: async (data?:any) => {
        console.log(data)
        const response = await api.get(
            '/reportCompany/rendicionExcel',
            {
                params: {
                    ...data
                }
            },
        ).then(response => response.data)
        .catch((error) => {
            console.log(error)
            return error.response.data
        })
        return response
    },

    getReportReservation: async (data?:any) => {
        const response = await api.get(
            '/reportCompany/reservation',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.log(error)
            return error.response.data
        })
        return response
    },

    getReportReservationExcel: async (data?:any) => {
        const response = await api.get(
            '/reportCompany/reservationExcel',
            {
                params: {
                    ...data
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.log(error)
            return error.response.data
        })
        return response
    },

    getReportAssignment: async (data?:any) => {
        const response = await api.get(
            '/reportCompany/assignment',
            {
                params:{
                    ...data
                }
            }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    }
}