import { api } from "./configs/axiosConfigs"

export const BuildingSpentAPI ={
    getBuildingSpent: async (data?: any, idcompany?: number) => {
      const response = await api.get(
        '/buildingSpent',
        {
          params: {
            ...data,
            idcompany: idcompany
          }
        }
      ).then(response => response.data)
      .catch((error) => {
        console.error(error)
        return error.response.data
      })
      return response
    },
    createBuildingSpent: async (data: any, idcompany: number) => {
        const response = await api.post(
            '/buildingSpent/register', 
            {
                ...data,
                idcompany: idcompany
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    editBuildingSpent: async (idbuilding: number, data: any, idcompany: number) => {
        const response = await api.patch(
        `/buildingSpent/${idbuilding}`,
        {
          ...data,
          idcompany: idcompany
        }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    deleteBuildingSpent: async (idbuilding: number) => {
        const response = await api.delete(
        `/buildingSpent/${idbuilding}`
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },  
}