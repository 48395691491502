import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { Service, ServiceType } from "../../types/slices/serviceSlice";
import { RootState } from "../store";

const initialState: ServiceType =
{
  status: ApiStatus.FETCHED,
  services: [],
  errorMessage: undefined,
  selectedService: {} as Service,
  action: '',
}

const serviceSlice = createSlice({
    name: 'service',
    initialState,
    reducers: {
        onFetchServices (state, { payload }: { payload: Service[] }) {
            state.status       = ApiStatus.FETCHED
            state.services       = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedService (state, { payload }: { payload: Service }) {
            state.selectedService = payload
        },
        setAction (state, { payload }: { payload: string }) {
            state.action = payload
        },
    }
})

export const selectServiceState = (state: RootState) => state.serviceSlice
export default serviceSlice.reducer

export const {
    onFetchServices,
    changeStatus,
    onSetSelectedService,
    setAction,
} = serviceSlice.actions