
import { useDispatch, useSelector } from '../redux/store';
import { addRange, selectRange, updateCalendar, changePeopleInvolved, reset, changeStatus, changeIsActionBlocked} from '../redux/slices/calendarSlice';
import { daysBetweenDates } from '../toolbox/helpers/dates-helpers';
import moment from 'moment';
import { CustomSnackbar } from '../components/common/CustomSnackbar/CustomSnackbar';
import { ApiStatus } from '../types/api/status';
import { useReservationStore } from './useReservationStore';

export const useCalendar = () => {

    const dispatch = useDispatch();

    const { status, arrayDates, departament, owner, isActionBlocked }  = useSelector(selectRange);
    const {blocked} = useReservationStore();
    
    const refreshCalendar = async (datesRegister:any) => {
        try {    
            console.log('blocked', blocked)
            console.log('datesRegister', datesRegister)
            const arrayFilter:any=[];
            if(datesRegister.length > 0 || blocked.length > 0 ){
                
                if(datesRegister.length > 0){
                    (datesRegister).map((item)=>{
                        let newDates = {
                            startDate: (new Date(item.startDate)).toString(), 
                            endDate: (new Date(item.endDate)).toString(), 
                            state: item.state && item.state == 'current' ? 'current' : 'data' , 
                            color: item.state && item.state == 'current' ?  '#73B2FF' : '#212D39',
                            // color: item.state && item.state == 'current' ?  '#73B2FF' : 'rgba(250, 35, 5, 0.4)',
                        }
                        arrayFilter.push(newDates);
                    })
                }

                if(blocked.length > 0){
                    blocked.map((item)=>{
                        let newDates = {
                            startDate: (new Date(item.start_date)).toString(), 
                            endDate: (new Date(item.end_date)).toString(), 
                            state: 'blocked',
                            color: '#FF6600'
                        }
                        arrayFilter.push(newDates);
                    })
                    console.log(arrayFilter);
                }
               
                dispatch(updateCalendar(arrayFilter));
            }else{
                dispatch(reset());
                if(departament && owner){
                    addPeopleInvolved({
                        departament: departament,
                        owner: owner,
                    })
                }
            }
            
        } catch (error) {
            console.error(error);
        }
    }

    const addCurrentRange = async (value:any) => {
        try {
            let verify:boolean= false;

            const currentRange:any = {
                startDate: moment(value.startDate).format('YYYY/MM/DD'),
                endDate: moment(value.endDate).format('YYYY/MM/DD'),
            }
          
            const arrayCurrentDates = daysBetweenDates(moment(value.startDate), moment(value.endDate));
            
            const filtered = (arrayDates).filter((item) => item.state != 'current' && item.state != 'default');
        
            for(let i=0; i<filtered.length; i++){
                const start = moment(filtered[i].startDate).format('YYYY/MM/DD');
                const end = moment(filtered[i].endDate).format('YYYY/MM/DD');
                const state = filtered[i].state;

                if(currentRange.startDate <= end && currentRange.startDate>=start) {
                    if(state == 'blocked' ) {verify = true}else{
                       if(!isActionBlocked) {
                        verify = true
                       }
                    }
                }
                if(currentRange.endDate <= end && currentRange.endDate>=start){
                    if(state == 'blocked' ) {verify = true}else{
                       if(!isActionBlocked) {
                        verify = true
                       }
                    }
                }
                // if(currentRange.startDate == currentRange.endDate ) verify=true;
                if(arrayCurrentDates.includes(start) || arrayCurrentDates.includes(end)) {
                    if(state == 'blocked' ) {verify = true}else{
                       if(!isActionBlocked) {
                        verify = true
                       }
                    }
                }
            }
        
            if(verify) return CustomSnackbar('warning', 'El rango de fechas seleccionado no se encuentra disponible.');

            const data = {
                ...value,
                startDate: (new Date(value.startDate)).toString(), 
                endDate: (new Date(value.endDate)).toString(),
            }

            dispatch(addRange({...data, state:'current', color:'#73B2FF'}));
        } catch (error) {
            console.error(error);
        }
    }

    const getCurrentRange = () => {
        const currentRange = (arrayDates).find((item) => item.state == 'current');
        return currentRange;
    }

    const addPeopleInvolved = (data) => {
        try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          dispatch(changePeopleInvolved(data));
          dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
          console.error(error);
        }
    }

    const resetCalendar = () => {
        try {
            dispatch(reset());
        } catch (error) {
            console.error(error);
        }
    }

    const onChangeIsActionBlocked = (payload:boolean) => {
        try{
            dispatch(changeIsActionBlocked(payload))
        }catch(e){
            console.error(e)
        }
    }

    return {
        /* states */
        arrayDates,
        departament,
        owner,
        status,
        isActionBlocked,

        /* actions */
        refreshCalendar,
        addCurrentRange,
        getCurrentRange,
        addPeopleInvolved,
        resetCalendar,
        onChangeIsActionBlocked
    }
}