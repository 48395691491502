import { useEffect, useState } from "react"
import { Formik } from "formik"
import { CustomForm } from "../../components/common/CustomForm/CustomForm"
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody"
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader"
import { Divider, Grid, TextField, FormLabel } from "@mui/material"
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter"
import { RoutesMap } from "../../types"
import { useNavigate } from "react-router-dom"
import { ApiStatus } from "../../types/api/status"
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop"
import { useConceptCategoryStore } from "../../hooks/useConceptCategoryStore"
import { ConceptCategory } from "../../types/slices/conceptCategoryType"

type Values = {
    id: number | undefined,
    name: string
    idcompany: number | undefined
}

export const ConceptCategoryForm = (props) => {

    const navigate = useNavigate();

    const { selectedConceptCategory, status:statusCategory, action, setSelectedConceptCategory, createConceptCategory, editConceptCategory, setActionCategory } = useConceptCategoryStore();

    const [data, setData] = useState<Values>({
        id: selectedConceptCategory.id ? selectedConceptCategory.id : undefined,
        name: selectedConceptCategory.name ? selectedConceptCategory.name : '',
        idcompany: selectedConceptCategory.idcompany ? selectedConceptCategory.idcompany : 1,
    });

    useEffect(()=>{
        if(!selectedConceptCategory.id && action=='') {
            navigate({ pathname: RoutesMap.CONCEPT_CATEGORY}, { replace: true })
        }
    }, [!selectedConceptCategory.id])

    const validateForm = (values) => {
        let errors:any = {};
        if(!values.name) errors.name = "nombre requerido";
        return errors;
    }

    const onCancel = ()  => {
        setSelectedConceptCategory({} as ConceptCategory)
        navigate({ pathname: RoutesMap.CONCEPT_CATEGORY })
    }

    const clearState = () => {
        setSelectedConceptCategory({} as ConceptCategory)
        setActionCategory('')
    }

    const onSubmit = async(values)  => {

        if(selectedConceptCategory.id){
            const response = await editConceptCategory(selectedConceptCategory.id, values);
            if (response === true) {
                clearState()
                navigate({ pathname: RoutesMap.CONCEPT_CATEGORY })
            }
        }else{
            const response = await createConceptCategory(values);
            if (response === true) {
                clearState()
                navigate({ pathname: RoutesMap.CONCEPT_CATEGORY })
            }
        }
    }

    return (
        <>
        {statusCategory == ApiStatus.FETCHING && <CustomBackdrop open={true}/>}
        
        <CustomForm >

            <CustomFormHeader
                title={!selectedConceptCategory.id ? 'Nuevo Concepto de Categoria' : 'Editar Concepto de Categoria'}
                text={!selectedConceptCategory.id ? 'Ingrese los datos del nuevo Concepto de Categoria' : 'Ingrese los datos del Concepto de Categoria'}
                goBack={RoutesMap.CONCEPT_CATEGORY}
                clearState={clearState}
            >
            </CustomFormHeader>

            <CustomFormBody>

                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{padding:'20px'}}>

                                <Grid item container xs={12} spacing={2}>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Nombre:</FormLabel>
                                        <TextField
                                            id="name"
                                            type="text" 
                                            name="name"
                                            fullWidth
                                            size="small"
                                            value={values.name}
                                            onChange={handleChange}
                                            error={errors.name && touched.name ? true : false}
                                            helperText={errors.name && touched.name ? errors.name : ''}
                                        />
                                    </Grid>
                                </Grid>
                                </Grid>

                                <Divider />
                                <CustomFormFooter 
                                    buttonType="submit"
                                    confirmText={'Guiardar Cambios'}
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                    clearState={clearState}
                                />
                            </form>
                        )
                    }} 
                </Formik>

            </CustomFormBody>

        </CustomForm>
        </>
    )
}