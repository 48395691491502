import { useDispatch, useSelector } from "react-redux"
import { changeStatus, onFetchServiceTypes, onSetSelectedServiceType, selectServiceTypeState } from "../redux/slices/serviceTypeSlice"
import { ApiStatus } from "../types/api/status"
import { ServiceTypeAPI } from "../apis/ServiceTypeAPI"
import { ServiceType } from "../types/slices/serviceTypeType"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"

export const useServiceTypeStore = () => {
    const dispatch = useDispatch()
    
    const { serviceTypes, status, selectedServiceType } = useSelector(selectServiceTypeState)

    const getServiceTypes = async () => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await ServiceTypeAPI.getServiceTypes()
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchServiceTypes(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const setSelectedServiceType = (service: ServiceType) => {
        try {
            dispatch(onSetSelectedServiceType(service))
        } catch (error) {
            console.log(error)
        }
    }

    return {
        //states
        serviceTypes,
        status,
        selectedServiceType,
        //actions
        getServiceTypes,
        setSelectedServiceType,
    }
}