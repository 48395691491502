import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";

const initialState: any = {
    status: ApiStatus.FETCHED,
    configurationDepartments: [],
    configurationDepartment: {} as any,
    selectedConfigurationDepartment: {} as any,
    errorMessage: undefined,
}

const configurationDepartmentSlice = createSlice({
    name: 'configurationDepartment',
    initialState,
    reducers: {
        onFetchConfigurations (state, { payload }: { payload: any }) {
            state.status       = ApiStatus.FETCHED
            state.configurationDepartments    = payload
            state.errorMessage = undefined
        },
        onFetchConfiguration (state, { payload }: { payload: any }) {
            state.status       = ApiStatus.FETCHED
            state.configurationDepartment    = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetWaitingReservation (state, { payload }: { payload: any }) {
            state.configurationDepartment = { ...state.configurationDepartment, waiting_reservation: payload}
        },
        onSetCheckIn (state, { payload }: { payload: any }) {
            state.configurationDepartment = { ...state.configurationDepartment, check_in: payload}
        },
        onSetCheckOut (state, { payload }: { payload: any }) {
            state.configurationDepartment = { ...state.configurationDepartment, check_out: payload}
        },
        onSetDontBookWeekends (state, { payload }: { payload: any }) {
            state.configurationDepartment = { ...state.configurationDepartment, dont_book_weekends: payload}
        },
        onSetMaximumSelectionDays (state, { payload }: { payload: any }) {
            state.configurationDepartment = { ...state.configurationDepartment, maximum_selection_days_for_reservation: payload}
        },
        onSetBillingCycleCutoffTime (state, { payload }: { payload: any }) {
            state.configurationDepartment = { ...state.configurationDepartment, billing_cycle_cutoff_time: payload}
        },
        onSetStartCycle (state, { payload }: { payload: any }) {
            state.configurationDepartment = { ...state.configurationDepartment, start_cycle: payload}
        },
        onSetEndCycle (state, { payload }: { payload: any }) {
            state.configurationDepartment = { ...state.configurationDepartment, end_cycle: payload}
        },
        onSetDayIssuanceDocument (state, { payload }: { payload: any }) {
            state.configurationDepartment = { ...state.configurationDepartment, day_issuance_document: payload}
        },
        onSetLastDayPayment (state, { payload }: { payload: any }) {
            state.configurationDepartment = { ...state.configurationDepartment, last_day_payment: payload}
        },
        onSetSelectedConfigurationDepartment (state, { payload }: { payload: any }) {
            state.selectedConfigurationDepartment = payload
        },
    }
})

export const selectConfiguracionDepartmentState = (state: RootState) => state.configurationDepartmentSlice

export default configurationDepartmentSlice.reducer

export const {
    onFetchConfigurations,
    onFetchConfiguration,
    changeStatus,
    onSetWaitingReservation,
    onSetCheckIn,
    onSetCheckOut,
    onSetDontBookWeekends,
    onSetMaximumSelectionDays,
    onSetBillingCycleCutoffTime,
    onSetStartCycle,
    onSetEndCycle,
    onSetDayIssuanceDocument,
    onSetLastDayPayment,
    onSetSelectedConfigurationDepartment,
} = configurationDepartmentSlice.actions