import { Grid } from "@mui/material";
import { FC, useEffect, useState } from "react";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useReservationStore } from "../../hooks/useReservationStore";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import moment from "moment";

const columns = [
    { type: 'options', field: 'options', label: 'OPCIONES' },
    { type: 'text', field: 'name_department', label: 'DEPARTAMENTO',align: 'center' },
    { type: 'text', field: 'start_date', label: 'FECHA INICIO', align: 'center',
    format:(row) => row.start_date && moment(row.start_date).format('YYYY-MM-DD') },
    { type: 'text', field: 'end_date', label: 'FECHA FIN', align: 'center' ,
    format:(row) => row.end_date && moment(row.end_date).format('YYYY-MM-DD') },
    { type: 'detail', field: 'detail', label: '', align: 'center' },
];

export const ReservationBlockedList: FC = () => {
   
    const { getBlocked, blocked, deleteBlocked } = useReservationStore();
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
    const [rowSelected, setRowSelected] = useState<any>(null)

    const [loading, setLoading] = useState(false);
   
    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }
    const onDeleteConfirm = async () => {
        setLoading(true);
        setShowModalConfirm(false)
        await deleteBlocked(rowSelected.id)
        await getBlocked();
        setLoading(false)
    }

    useEffect(()=>{
        getBlocked();
    },[])


    return (
        <>
            <Grid item xs={12}>
                <CustomTable
                    title={'Lista de bloqueos'}
                    columns={columns}
                    loading={loading}
                    rows={blocked || []}
                    onRowClick={() => { }}
                    hasOptions
                    onDelete={onDelete}
                />
            </Grid>
            {
                showModalConfirm && (
                    <ModalConfirm
                        open={showModalConfirm}
                        closeModal={() => { setShowModalConfirm(false) }}
                        onConfirm={onDeleteConfirm}
                        onCancel={() => { setShowModalConfirm(false) }}
                    />
                )
            }
        </>
    )
}