import { api } from "./configs/axiosConfigs"

export const ConfigurationDepartmentAPI = {
    getConfigurationDepartment: async (id: number, listType?:string) => {
        const response = await api.get(
          `/departmentConfigurations/${id}`,
          {
            params: {
              listType
            }
          }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    getConfigurationByDepartment: async (iddepartment: number) => {
        const response = await api.get(
            `/departmentConfigurations/department/${iddepartment}`
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    getConfigurationDepartmentByOwner: async (idcompany: number, data?: any) => {
      //CONSULTAR EN BACK PORQUE SIEMPRE RESPONDE El departamento seleccionado es inválido.
        const response = await api.get(
            `/departmentConfigurations2/owner`,
            {
              params: {
                ...data,
                idcompany
              }
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    updateConfigurationDepartment: async (data:any,id: number) => {
        console.log(data)
        const response = await api.patch(
            `/departmentConfigurations/${id}`,{
                ...data
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    }
}