import { FC, useEffect, useState } from "react"
import { Skeleton, Button, Grid, IconButton, TextField, Typography } from "@mui/material"

import EditIcon from '@mui/icons-material/Edit'
import CustomTable from "../../components/common/CustomTable/CustomTable"
import { useConfigurationCompanyStore } from "../../hooks/useConfigurationCompanyStore"
import { useConfigurationDepartmentStore } from "../../hooks/useConfigurationDepartmentStore"
import { ModalChecking } from "./component/ModalChecking"
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm"

const columns = [
  { type: 'options',  field: 'options', align:'center', label: 'Opciones',  },
  { type: 'text', field: 'update_date', label: 'Fecha de Modificación' },
  { type: 'text', field: 'business_name_department', label: 'Departamento' },
  { type: 'text', field: 'check_in', label: 'CHECK-IN' },
  { type: 'text', field: 'check_out', label: 'CHECK-OUT' },
  { type: 'detail',   field: 'detail',  align:'center', label: '' },
]


export const SettingAdminChecking : FC = () => {
 
  const { getConfigurationCompany, configurationCompany, editConfigurationCompany, setCheckOut, setCheckIn} = useConfigurationCompanyStore();
  const { status:statusApiDepartment, configurationDepartments, selectedConfigurationDepartment, getConfigurationDepartment,  setSelectedConfigurationDepartment, editConfigurationCompany: editConfigurationDepartment, } = useConfigurationDepartmentStore();
  
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [loadingCompany, setLoadingCompany] = useState<boolean>(false);
  const [loadingDepartment, setLoadingDepartment] = useState<boolean>(false);
  const [loadingDepartmentDelete, setLoadingDepartmentDelete] = useState<boolean>(false);

  useEffect(() => {
    getConfigurationCompanyApi();
    getConfigurationDepartmentApi();
  }, [])

  const getConfigurationCompanyApi = async() => {
    try {
      setLoadingCompany(true);
      const response = await getConfigurationCompany();
      setLoadingCompany(false);
    } catch (error) {
      setLoadingCompany(false);
    }
  }

  const getConfigurationDepartmentApi = async() => {
    try {
      setLoadingDepartment(true);
      const response = await getConfigurationDepartment("check");
      setLoadingDepartment(false);
    } catch (error) {
      setLoadingDepartment(false);
    }
  }

  const onEditCompany = async () => {
    setLoadingCompany(true);
    const response = await editConfigurationCompany(configurationCompany);
    setLoadingCompany(false);
  }

  const onAdd = () => {
    setOpenModal(true);
    setEditMode(false);
  }

  const onEdit = (rowSelected:any) => {
    setOpenModal(true);
    setEditMode(true);
    setSelectedConfigurationDepartment(rowSelected)
  }

  const onDelete = (rowSelected: any) => {
    setOpenModalDelete(true);
    setSelectedConfigurationDepartment(rowSelected)
  }

  const closeModal = (submit:boolean = false) => {
    submit && getConfigurationDepartmentApi();
    setOpenModal(false);
  }

  const confirmDelete = async () => {
    setLoadingDepartmentDelete(true);
    const response = await editConfigurationDepartment(selectedConfigurationDepartment.iddepartment, {
      status_check: "0"
    })
    setSelectedConfigurationDepartment({});
    getConfigurationDepartmentApi();

    setLoadingDepartmentDelete(false);
    setOpenModalDelete(false);
  }

  return (
    <Grid container sx={{ margin: '0px 20px' }}>
      <Grid item container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom:'10px'}}>
        <Typography variant='h6' component='h1' gutterBottom color='#808080' className="tittle" sx={{ mt: 1, ml:1 , fontWeight:600}}>
          POLÍTICAS DE CHECKING
        </Typography>
      </Grid>
      <Grid item container xs={12} md={12} sx={{ margin: '10px 0px' }} direction="row">
        {
          loadingCompany ? (
            <Skeleton width="450px" height="40px" />
          ) : (
            <>
              <Grid item sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center'}}>
                  <Typography sx={{ fontSize: '16px', fontWeight: 300, marginRight: '10px' }}>Tiempo límite por Compañia:</Typography>
              </Grid>
              <Grid item sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                  <Typography sx={{ fontSize: '16px', fontWeight: 300, marginRight: '20px' }}>Check-in:</Typography>
                  <TextField 
                  size="small"
                  sx={{ width: '110px' }}
                  type="time"
                  value={configurationCompany.check_in}
                  onChange={(e)=>setCheckIn(e.target.value + ':00')}
                  />
                  <Typography sx={{ fontSize: '16px', fontWeight: 300, margin: '0px 10px' }}>horas.</Typography>
              </Grid>
              <Grid item sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                  <Typography sx={{ fontSize: '16px', fontWeight: 300, marginRight: '10px' }}>Check-out:</Typography>
                  <TextField 
                  size="small"
                  sx={{ width: '110px' }}
                  type="time"
                  value={configurationCompany.check_out}
                  onChange={(e)=>setCheckOut(e.target.value + ':00')}
                  />
                  <Typography sx={{ fontSize: '16px', fontWeight: 300, margin: '0px 10px' }}>horas.</Typography>
                  <IconButton size="small" color="inherit" aria-label="edit" onClick={onEditCompany}>
                      <EditIcon fontSize='small' />
                  </IconButton>
              </Grid>
            </>
          )
        }
      </Grid>
      <Grid item xs={12} md={12} sx={{ margin: '10px 0px -20px 0px' }}>
        <Button
        onClick={onAdd}
        sx={{ color:'#73B2FF', border: '1px solid #73B2FF', borderRadius: 10, float: 'right', height: 30, padding: '6px 16px', textTransform: 'none', width: 210  }}
        >
            Configurar departamento
        </Button>
      </Grid>
      <Grid item xs={12} md={12}>
        <CustomTable
          columns={columns} 
          rows={configurationDepartments || []}
          loading={loadingDepartment}
          onRowClick={() => {}}
          hasOptions
          onEdit={onEdit}
          onDelete={onDelete}
        />  
      </Grid>
      {
        openModal &&
        <ModalChecking
          open={openModal}
          editMode={editMode}
          closeModal={(e:boolean) => closeModal(e)}
          clearState={() => null}
        />
      }
      {
        openModalDelete &&
        <ModalConfirm
          open={openModalDelete}
          title={"Eliminar la Configuración por Departamento"}
          text={"¿Desea realmente eliminar la configuración por departamento?"}
          onConfirm={confirmDelete}
          closeModal={() => setOpenModalDelete(false)}
          status2={loadingDepartmentDelete}
        />
      }
  </Grid>
  )
}