import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { Period, PeriodType } from "../../types/slices/periodType";
import { RootState } from "../store";

const initialState: PeriodType = {
    status: ApiStatus.FETCHED,
    periods: [],
    errorMessage: undefined,
    selectedPeriod: {} as Period
}

const periodSlice = createSlice({
    name: 'period',
    initialState,
    reducers: {
        onFetchPeriod (state, { payload }: { payload: Period[] }) {
            state.status       = ApiStatus.FETCHED
            state.periods      = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedPeriod (state, { payload }: { payload: Period }) {
            state.selectedPeriod = payload
        }
    }
})

export const selectPeriodState = (state: RootState) => state.periodSlice
export default periodSlice.reducer

export const {
    onFetchPeriod,
    changeStatus,
    onSetSelectedPeriod
} = periodSlice.actions