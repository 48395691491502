import { api } from "./configs/axiosConfigs"

export const SpentTypeAPI = {
    getSpentTypes: async () => {
        const response = await api.get(
            '/concept/spentType'
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
}