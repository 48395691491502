import { Grid, Typography } from "@mui/material";
import { Modal } from "../../../components/common/Modal/Modal";
import { ModalHeader } from "../../../components/common/Modal/ModalHeader";
import { ModalBody } from "../../../components/common/Modal/ModalBody";

type Values = {
    doc: any,
    observation: string,
    amount: number,
}

export const RefusedDebtModal: React.FC<any> = (props): JSX.Element | any => {

    const { open, closeModal, rowSelected, clearState } = props;

    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
            <ModalHeader
                text={'Detalles de tu deuda rechazada'}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Grid pb={2}>
                    <Typography color='#101010' sx={{ fontWeight:400, fontSize:'14px'}}>
                        Comentario del administrador:
                    </Typography>
                    <Typography color='#808080' sx={{ fontWeight:400, fontSize:'14px'}}>
                        Tu deuda de codigo 001 no coincide con el gasto puesto. Revisa el recibo de pago que fue generado al pagar. Te recomiento revisarlo antes de que haya un cierre de apertura.
                    </Typography>
                </Grid>
            </ModalBody>
        </Modal>
    )
}