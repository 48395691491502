import { api } from "./configs/axiosConfigs"

export const ServiceTypeAPI = {
    getServiceTypes: async () => {
        const response = await api.get(
            '/service/type'
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
}