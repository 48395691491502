import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RootState } from "../store";

const initialState: any = {
    status: ApiStatus.FETCHED,
    configurationCompany: {} as any,
    errorMessage: undefined,
}

const configurationCompanySlice = createSlice({
    name: 'configurationCompany',
    initialState,
    reducers: {
        onFetchConfiguration (state, { payload }: { payload: any[] }) {
            state.status       = ApiStatus.FETCHED
            state.configurationCompany    = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetWaitingReservation (state, { payload }: { payload: any }) {
            state.configurationCompany = { ...state.configurationCompany, waiting_reservation: payload}
        },
        onSetCheckIn (state, { payload }: { payload: any }) {
            state.configurationCompany = { ...state.configurationCompany, check_in: payload}
        },
        onSetCheckOut (state, { payload }: { payload: any }) {
            state.configurationCompany = { ...state.configurationCompany, check_out: payload}
        },
        onSetDontBookWeekends (state, { payload }: { payload: any }) {
            state.configurationCompany = { ...state.configurationCompany, dont_book_weekends: payload}
        },
        onSetMaximumSelectionDays (state, { payload }: { payload: any }) {
            state.configurationCompany = { ...state.configurationCompany, maximum_selection_days_for_reservation: payload}
        },
        onSetBillingCycleCutoffTime (state, { payload }: { payload: any }) {
            state.configurationCompany = { ...state.configurationCompany, billing_cycle_cutoff_time: payload}
        },
        onSetStartCycle (state, { payload }: { payload: any }) {
            state.configurationCompany = { ...state.configurationCompany, start_cycle: payload}
        },
        onSetEndCycle (state, { payload }: { payload: any }) {
            state.configurationCompany = { ...state.configurationCompany, end_cycle: payload}
        },
        onSetDayIssuanceDocument (state, { payload }: { payload: any }) {
            state.configurationCompany = { ...state.configurationCompany, day_issuance_document: payload}
        },
        onSetLastDayPayment (state, { payload }: { payload: any }) {
            state.configurationCompany = { ...state.configurationCompany, last_day_payment: payload}
        }
    }
})

export const selectConfiguracionCompanyState = (state: RootState) => state.configurationCompanySlice
export default configurationCompanySlice.reducer

export const {
    onFetchConfiguration,
    changeStatus,
    onSetWaitingReservation,
    onSetCheckIn,
    onSetCheckOut,
    onSetDontBookWeekends,
    onSetMaximumSelectionDays,
    onSetBillingCycleCutoffTime,
    onSetStartCycle,
    onSetEndCycle,
    onSetDayIssuanceDocument,
    onSetLastDayPayment
} = configurationCompanySlice.actions