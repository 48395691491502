import { useEffect, useState } from "react";
import { Grid } from '@mui/material'
import CustomTable from '../../components/common/CustomTable/CustomTable';
import { RoutesMap } from "../../types";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { CompanyModal } from "./components/CompanyModal";
import { useDispatch } from "../../redux/store";
import { showBackdrop } from "../../toolbox/helpers/custom-action";
import { useCompanyStore } from "../../hooks/useCompanyStore";
import { Company } from "../../types/slices/companyType";
import { ApiStatus } from "../../types/api/status"
import { Stack, CircularProgress } from '@mui/material'
import { useNavigate } from "react-router-dom";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";

const columns = [
    { type: 'options',  align:'center',  field: 'options', label: 'OPCIONES' },
    { type: 'text',     align:'center',  field: 'business_name', label: 'RAZÓN SOCIAL' },
    { type: 'text',     align:'center',  field: 'line_business', label: 'LÍNEA DE NEGOCIO' },
    { type: 'text',     align:'center',  field: 'rut', label: 'RUT' },
    { type: 'text',     align:'center',  field: 'address', label: 'DIRECCIÓN' },
    { type: 'text',     align:'center',  field: 'email', label: 'EMAIL' },
    { type: 'text',     align:'center',  field: 'phone', label: 'CELULAR' },
    { type: 'detail',   align:'center',  field: 'detail', label: '' },
]

export const CompanyView = () => {

    const navigate = useNavigate()
    const { status: statusApiCompany, companies, getCompanies, setSelectedCompany, deleteCompany, setActionCompany } = useCompanyStore()
    
    const [ rows , setRows ]                        = useState<Company[]>([])
    const [ rowSelected, setRowSelected ]           = useState<Company>({} as Company);
    const [ showModal, setShowModal ]               = useState<boolean>(false);
    const [ showModalConfirm, setShowModalConfirm ] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getCompaniesApi()
    },[])

    useEffect(() => {
        if (companies) {
          setRows(companies)
          return
        }
        setRows([])
    }, [companies])

    const getCompaniesApi = async() => {
        try {
          setLoading(true);
          const response = await getCompanies();
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const onAdd = () => {
        setActionCompany('add')
        navigate({ pathname: RoutesMap.COMPANIES_FORM })
    }

    const onEdit = (rowSelected) => {
        setActionCompany('edit')
        setSelectedCompany(rowSelected)
        navigate({ pathname: RoutesMap.COMPANIES_FORM })
    }
    
    const onDelete = (rowSelected) => {
        setActionCompany('delete')
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = () => {
        setShowModalConfirm(false)
        deleteCompany(rowSelected.id)
        setSelectedCompany({} as Company)
        getCompaniesApi()
    }

    return (
        <>

            {/* {  statusApiCompany === ApiStatus.FETCHING && <CustomBackdrop open={true} /> } */}

            <Grid item xs={12}>
                <CustomTable 
                    title={'Empresas'}
                    columns={columns}
                    loading={loading}
                    rows={rows || []}
                    onRowClick={() => {}}
                    hasOptions
                    onAddFn={onAdd}
                    onDelete={onDelete}
                    onEdit={onEdit}
                />
            </Grid>


            {
                showModalConfirm && (
                    <ModalConfirm
                        open={showModalConfirm}
                        closeModal={()=>{setShowModalConfirm(false)}}
                        onCancel={()=>{setShowModalConfirm(false)}}
                        onConfirm={onDeleteConfirm}
                    />
                )
            }
            {
                showModal && (
                    <CompanyModal
                        open={showModal}
                        closeModal={()=>{setShowModal(false)}}
                        rowSelected={rowSelected}
                    />
                )
            }
        </>
    )
}