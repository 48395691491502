import { useEffect, useState } from "react";
import { Grid } from '@mui/material'
import CustomTable from '../../components/common/CustomTable/CustomTable';
import { RoutesMap } from "../../types";
import { useNavigate } from "react-router-dom";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { ApiStatus } from "../../types/api/status";
import { useSelector } from "../../redux/store";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { useBuildingSpentStore } from "../../hooks/useBuildingSpentStore";
import { moneyFormat } from "../../toolbox/helpers/money-helpers";

const columns = [
    { type: 'options', field: 'options', align:'center', label: 'OPCIONES' },
    { type: 'text', field: 'building', align:'center', label: 'Edificio' },
    { type: 'text', field: 'concept_spent', align:'center', label: 'Concepto' },
    { type: 'text', field: 'spent_type', align:'center', label: 'Tipo' },
    { type: 'text', field: 'concept_category', align:'center', label: 'Categoria' },
    { type: 'text', field: 'amount', align:'center', label: 'Monto', format:(row) => row.amount ? moneyFormat(row.amount, '$')  : '-----' },
    { type: 'detail', field: 'detail', label: '', align:'center' },
]

export const PaymentConceptBuildingView = () => {
    const navigate = useNavigate();
    const { search } = useSelector(selectHeaderState);

    const { status: statusPaymentConceptBuildingAPI, buildingSpents, getBuildingSpent, deleteBuildingSpent, setSelectedBuildingSpent, setActionBuildingSpent } = useBuildingSpentStore();

    const [rows, setRows] = useState<any[]>([])
    const [rowSelected, setRowSelected] = useState<any>('');
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        getBuildingSpentsApi()
    },[search])

    useEffect(() => {
        if (buildingSpents) {
          setRows(buildingSpents)
          return
        }
        setRows([])
    }, [buildingSpents])

    const getBuildingSpentsApi = async() => {
        try {
          setLoading(true);
          const response = await getBuildingSpent({search});
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const onAdd = () => {
        setActionBuildingSpent('add')
        navigate({ pathname: RoutesMap.PAYMENT_CONCEPT_BUILDING_FORM })
    }
    const onEdit = (rowSelected) => {
        setActionBuildingSpent('edit')
        setSelectedBuildingSpent(rowSelected)
        navigate({ pathname: RoutesMap.PAYMENT_CONCEPT_BUILDING_FORM })
    }
    const onDelete = (rowSelected) => {
        setActionBuildingSpent('delete')
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = async() => {
        const response = await deleteBuildingSpent(rowSelected.id)
        if(response){
            setSelectedBuildingSpent({} as any)
            setShowModalConfirm(false)
            getBuildingSpentsApi()
        }else{
            return false;
        }
    }

    return (
      <>
        <Grid item xs={12}>
          <CustomTable 
            title={'Concepto de Gastos de Edificios'}
            columns={columns} 
            loading={loading}
            rows={rows || []}
            onRowClick={() => {}}
            hasOptions
            // onAddFn={onAdd}
            onDelete={onDelete}
            onEdit={onEdit}
            disabledAdd={statusPaymentConceptBuildingAPI == ApiStatus.FETCHING ? true : false}
          />
        </Grid>
        {
          showModalConfirm && (
            <ModalConfirm
              open={showModalConfirm}
              closeModal={()=>{setShowModalConfirm(false)}}
              rowSelected={rowSelected}
              onConfirm={onDeleteConfirm}
              onCancel={()=>{setShowModalConfirm(false)}}
              status2={statusPaymentConceptBuildingAPI == ApiStatus.FETCHING ? true : false}
            />
          )
        }
      </>
    )
}