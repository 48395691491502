import { ChangeEvent, FC, useEffect, useState } from "react";
import { Grid, CircularProgress } from '@mui/material'
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { useNavigate } from "react-router-dom";
import { RoutesMap } from "../../types";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { useRenditionStore } from "../../hooks/useRenditionStore";
import { moneyFormat } from "../../toolbox/helpers/money-helpers";
import { useSelector } from "../../redux/store";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { UploadTranfersModal } from "../Rendition/components/UploadTranfersModal";
import { useOwnerStore } from "../../hooks/useOwnerStore";
import DescriptionIcon from '@mui/icons-material/Description';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";

const columns = [
    { type: 'options', field: 'options', label: 'Opciones' },
    { type: 'text', field: 'description', label: 'DESCRIPCION', align: 'center' },
    { type: 'text', field: 'department', label: 'DEPARTAMENTO', align: 'center' },
    { type: 'text', field: 'amount_general', label: 'TOTAL GENERAL', align: 'center',
        format: (row) => moneyFormat(row.amount_general, '$') },
    { type: 'text', field: 'amount_individual', label: 'TOTAL INDIVIDUAL', align: 'center',
        format: (row) => moneyFormat(row.amount_individual, '$') },
    { type: 'text', field: 'amount_total', label: 'TOTAL', align: 'center',
        format: (row) => moneyFormat(row.amount_total, '$') },
    { type: 'text', field: 'validation_status', label: 'ESTADO', align: 'center',
        format: (row) => row.validation_status && row.validation_status == '1' ? 'Cerrado' : 'Abierto' },
    { type: 'detail', field: 'detail', label: '', align:'center' },
]

export const RenditionAdminList : FC = (props) => {

    const navigate = useNavigate();
    const { search } = useSelector(selectHeaderState);

    const { renditions, getRenditions, setSelectedRendition, deleteRendition, setIdRendition, detailRendicionAdminPDF } = useRenditionStore()
    const { getOwners } = useOwnerStore()
    const [ rowSelected, setRowSelected ]           = useState<any>('')
    const [ showModalConfirm, setShowModalConfirm ] = useState<boolean>(false)
    const [loading, setLoading] = useState(false);
    const [ showModalTransfer, setShowModalTransfer ] = useState<boolean>(false)
    const [ downloading, setDownloading ] = useState<boolean>(false)

    useEffect(()=>{
        getRenditionsApi()
    }, [search])
  
    const getRenditionsApi = async() => {
      try {
        setLoading(true)
        await getRenditions({search})
        await getOwners()
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

  const onEdit = (rowSelected) => {
    if (rowSelected.validation_status == "1") return CustomSnackbar('error','Rendición Cerrada')
    setSelectedRendition(rowSelected)
    navigate({ pathname: RoutesMap.RENDITION_ADMIN_OPEN})
  }
  
    const onDelete = (rowSelected) => {
        setRowSelected(rowSelected)
        setShowModalConfirm(true)
    }

    const onPaidIcon = (rowSelected) => {
        setSelectedRendition(rowSelected)
        setIdRendition(rowSelected.id)
        navigate({ pathname: RoutesMap.RENDITION_ADMIN_PAID})
    }

    const onDeleteConfirm = async () => {
        setShowModalConfirm(false)
        const response = await deleteRendition(rowSelected.id)
        if (response === true) { getRenditionsApi() }
    }

    const onAdd = async () => {
        setShowModalTransfer(true)
    }

    const onHelper = async(rowSelected) => {
        setDownloading(true)
        const response = await detailRendicionAdminPDF(rowSelected.id)
        if (response !== false) {
            const blob = new Blob([response], { type: 'application/pdf' })
            const bloblURL = URL.createObjectURL(blob)
            window.open(bloblURL, '_blank')
        }
        setDownloading(false)
    }

    return(<>
        { downloading && <CustomBackdrop open={true} /> }
        <Grid item xs={12}>
            <CustomTable
                title={'Listado de rendiciones'}
                columns={columns} 
                rows={renditions || []}
                loading={loading}
                onRowClick={() => {}}
                hasOptions
                onDelete={onDelete}
                onEdit={onEdit}
                onPaidIcon={onPaidIcon}
                onAddFn={onAdd}
                onAddFnName={'REGISTRAR ABONO'}
                onHelper={onHelper}
                icon_helper={ <PictureAsPdfIcon fontSize="small" /> }
                tooltip_helper={'ver pdf'}
            />
        </Grid>
        {
            showModalConfirm && (
                <ModalConfirm
                    open={showModalConfirm}
                    closeModal={()=>{setShowModalConfirm(false)}}
                    onCancel={()=>{setShowModalConfirm(false)}}
                    onConfirm={onDeleteConfirm}
                />
            )
        }
                {
            showModalTransfer && (
                <UploadTranfersModal
                    open={showModalTransfer}
                    closeModal={()=>{setShowModalTransfer(false)}}
                    isAdmin={true}
                    isPayment={true}
                />
            )
        }
    </>)
}