import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { ApiStatus } from '../../types/api/status'
import { User, typeUsers, userType } from '../../types/slices/userType'

const initialState: userType =
{
  status: ApiStatus.FETCHED,
  users: [],
  typeUsers: [],
  userSelected: {} as User,
  errorMessage: undefined,
  action: ''
}

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    onFetchUsers (state, { payload }: { payload: User[]}) {
      state.status = ApiStatus.FETCHED
      state.users = payload
      state.errorMessage = undefined
    },
    onFetchTypeUsers (state, { payload }: { payload: typeUsers[]}) {
      state.status = ApiStatus.FETCHED
      state.typeUsers = payload
      state.errorMessage = undefined
    },
    onSetSelectedUser (state, { payload }: { payload: User }) {
      state.userSelected = payload
    },
    changeStatus (state, { payload }: { payload: ApiStatus}){
      state.status = payload
    },
    setAction (state, { payload }: { payload: string }) {
      state.action = payload
    },
  }
})

export const selectUserState = (state: RootState) => state.userSlice
export default userSlice.reducer
export const {
    onFetchUsers,
    onFetchTypeUsers,
    onSetSelectedUser,
    changeStatus,
    setAction,
} = userSlice.actions
