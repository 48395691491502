import { Grid, TextareaAutosize, Typography, Button, IconButton } from "@mui/material";
import { FC, useEffect, useState } from "react";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { Modal } from "../../components/common/Modal/Modal";
import { ModalBody } from "../../components/common/Modal/ModalBody";
import { useRenditionPaidStore } from "../../hooks/useRenditionPaidStore";
import { useRenditionStore } from "../../hooks/useRenditionStore";
import { Rendition } from "../../types/slices/renditionType";
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate, useParams } from "react-router-dom";
import { RoutesMap } from "../../types";
import { moneyFormat } from "../../toolbox/helpers/money-helpers";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { UploadTranfersModal } from "../Rendition/components/UploadTranfersModal";
import { useOwnerStore } from "../../hooks/useOwnerStore";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { UploadTranfersBuildingModal } from "./components/UploadTransferBuildingModal";
import { useRenditionBuildingStore } from "../../hooks/useRenditionBuildingStore";
import { useRenditionBuildingPaidStore } from "../../hooks/useRenditionBuildingPaidStore";
import { useBuildingStore } from "../../hooks/useBuildingStore";

const columns = [
    { type: 'options', field: 'options', label: 'Opciones', align: 'center' },
    { type: 'text', field: 'department', label: 'DEPARTAMENTO', align: 'center' },
    { type: 'text', field: 'date_pay', label: 'FECHA DE PAGO', align: 'center' },
    { type: 'text', field: 'amount', label: 'MONTO', align: 'center',
        format: (row) => moneyFormat(row.amount, '$') },
    { type: 'text', field: 'validation_name', label: 'ESTADO', align: 'center' },
    { type: 'detail', field: 'detail', label: '', align:'center' },
]

export const RenditionBuildingPaidList : FC = () => {

  const navigate = useNavigate()
  
  const { selectedRenditionBuilding, setSelectedRenditionBuilding, setIdRenditionBuilding } = useRenditionBuildingStore();
  const { renditionBuildingPaids ,getRenditionBuildingPaids, validateRenditionBuildingPaid } = useRenditionBuildingPaidStore();
  const { getBuildingByDepartments } = useBuildingStore()

  const [ rowSelected, setRowSelected ] = useState<any>('')
  const [ showModal, setShowModal ] = useState<boolean>(false)
  const [ comment, setComment ] = useState<string>('')
  const [ showModalConfirm, setShowModalConfirm ] = useState<boolean>(false)
  const [ showModalTransfer, setShowModalTransfer ] = useState<boolean>(false)
  const [ loading, setLoading ] = useState<boolean>(false)

  useEffect(()=>{
    if (selectedRenditionBuilding.id) {
      allGets()
      setIdRenditionBuilding(selectedRenditionBuilding.id)
      setSelectedRenditionBuilding({} as Rendition)
    }
  },[])

  const allGets = async () => {
    try {
      setLoading(true)
      await getRenditionBuildingPaids({ idreport: selectedRenditionBuilding.id, idbuilding: selectedRenditionBuilding.idbuilding })
      await getBuildingByDepartments(selectedRenditionBuilding.idbuilding)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const onCheckIcon = async () => {
      setShowModalConfirm(false)
      const response = await validateRenditionBuildingPaid(rowSelected.id, { validation_status: '1', comment: '' })
      if (response === true) {
          getRenditionBuildingPaids({ idreport: rowSelected.idreport })
      }
  }

  const onClearIcon = (rowSelected) => {
      setRowSelected(rowSelected)
      setShowModal(true)
  }

  const handleCancelRendition = async () => {
      setShowModal(false)
      const response = await validateRenditionBuildingPaid(rowSelected.id, { validation_status: '2', comment: comment })
      if (response === true) {
          getRenditionBuildingPaids({ idreport: rowSelected.idreport })
      }
  }
  
  const onDetails = (rowSelected) => {
      const url = rowSelected.img_transfer
      if (url === null || url === '') {
          return CustomSnackbar('error', 'No hay documento registrado')    
      } 
      const win:any = window.open(`${process.env.REACT_APP_ROOT_URL}/` + url, '_blank')
      win.focus()
  }

  const handleCheckIcon = (rowSelected) => {
      setRowSelected(rowSelected)
      setShowModalConfirm(true);
  }

  const onAdd = async () => {
      setShowModalTransfer(true)
  }

  return (
    <>
      <Grid item xs={12} display='flex' alignItems='center'>
          <IconButton id="back" size="small" color="primary" aria-label="view" onClick={() =>  navigate({ pathname: RoutesMap.RENDITION_ADMIN_BUILDING_LIST }) }>
              <ArrowBackIcon fontSize='small'/>
          </IconButton>
          <Typography>Volver</Typography>
      </Grid>
      <Grid item xs={12}>
          <CustomTable
              title={'Pago de rendición Por Departamento'}
              columns={columns} 
              rows={renditionBuildingPaids || []}
              onRowClick={() => {}}
              hasOptions
              onCheckbox
              onCheckIcon = { handleCheckIcon }
              onClearIcon = { onClearIcon }
              onDetails   = { onDetails }
              onAddFn     = { onAdd }
              onAddFnName = {'REGISTRAR PAGO'}
              loading     = { loading }
          />
      </Grid>
      {
          showModal && (
              <Modal open={showModal} handleClose={() => setShowModal(false)} disableEscapeKeyDown disableBackdropClick size="xs">
                  <ModalBody>
                      <Grid container sx={{ margin: '10px 0px'}}>
                          <Typography sx={{ fontWeight: 'bolder', margin: '0 auto' }}>Rendición rechazada</Typography>
                      </Grid>
                      <Grid container sx={{ margin: '10px 0px'}}>
                          <Typography>Comentario:</Typography>
                      </Grid>
                      <Grid container sx={{ margin: '10px 0px'}}>
                          <TextareaAutosize style={{ width: '100%', minHeight: '150px', resize: 'none', border: '1px solid #808080', borderRadius: '17px', padding: '10px', fontFamily: 'Arial' }} 
                          onChange={(e) => setComment(e.target.value)}
                          />
                      </Grid>
                      <Grid container sx={{ display: 'flex', justifyContent: 'center', margin: '10px 0px'}}>
                          <Button
                          onClick={ () => handleCancelRendition() }
                          sx={{ backgroundColor: '#212D39', color: '#fff', float: 'right', height: '25px' , padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": {backgroundColor: "#212D39" } }}
                          >
                              Enviar
                          </Button>
                          <Button
                          onClick={ () => setShowModal(false) }
                          sx={{ backgroundColor: '#212D39', color: '#fff', float: 'right', height: '25px' , margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": {backgroundColor: "#212D39" } }}
                          >
                              Cancelar
                          </Button>
                      </Grid>
                  </ModalBody>
              </Modal>
          )
      }
      {
          showModalConfirm && (
              <ModalConfirm
                  open={showModalConfirm}
                  closeModal={()=>{setShowModalConfirm(false)}}
                  onConfirm={onCheckIcon}
                  onCancel={()=>{setShowModalConfirm(false)}}
                  title="¿Está seguro de confirmar el pago seleccionado?"
                  text='Si estas seguro, haz click en el boton "aceptar".'
              />
          )
      }
      {
          showModalTransfer && (
              <UploadTranfersBuildingModal
                  open={showModalTransfer}
                  closeModal={()=>{setShowModalTransfer(false)}}
                  isAdmin={true}
              />
          )
      }
    </>
  )
}