import { useDispatch, useSelector } from "react-redux"
import { ApiStatus } from "../types/api/status"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"
import { changeStatus, onFetchRenditionBuildingPaid, selectRenditionBuildingPaidState } from "../redux/slices/renditionBuildingPaidSlice"
import { RenditionBuildingPaidAPI } from "../apis/RenditionBuildingPaidAPI"

export const useRenditionBuildingPaidStore = () => {
  const dispatch = useDispatch()
  const { renditionBuildingPaids, status, selectedRenditionBuildingPaid } = useSelector(selectRenditionBuildingPaidState)
  
  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany

    const getRenditionBuildingPaids = async (data?: any) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await RenditionBuildingPaidAPI.getRenditionBuildingPaids(idcompany, data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchRenditionBuildingPaid(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))
            return true
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const createRenditionBuildingPaid = async (data: any) => {
      try {
        dispatch(changeStatus(ApiStatus.FETCHING))
        const response = await RenditionBuildingPaidAPI.create(data)
        if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || "")
        }
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('success', response.data.message || '')
        return response.data.detail
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
    }

    const validateRenditionBuildingPaid = async (id: number, data: any) => {
      try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await RenditionBuildingPaidAPI.validate(id, idcompany, data)
          if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || "")
          }
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar('success', response.data.message  || "")
          return true
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
    }

    return {
        //states
        renditionBuildingPaids,
        status,
        selectedRenditionBuildingPaid,
        //actions
        getRenditionBuildingPaids,
        createRenditionBuildingPaid,
        validateRenditionBuildingPaid,
    }
}