import { createSlice } from "@reduxjs/toolkit"
import { ApiStatus } from "../../types/api/status"
import { ConceptCategory, ConceptCategoryType } from "../../types/slices/conceptCategoryType"
import { RootState } from "../store"

const initialState: ConceptCategoryType =
{
  status: ApiStatus.FETCHED,
  conceptCategories: [],
  errorMessage: undefined,
  selectedConceptCategory: {} as ConceptCategory,
  action: '',
}

const conceptCategorySlice = createSlice({
    name: 'conceptCategory',
    initialState,
    reducers: {
        onFetchConceptCategories (state, { payload }: { payload: ConceptCategory[] }) {
            state.status            = ApiStatus.FETCHED
            state.conceptCategories = payload
            state.errorMessage      = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedConceptCategory (state, { payload }: { payload: ConceptCategory }) {
            state.selectedConceptCategory = payload
        },
        setAction (state, { payload }: { payload: string }) {
            state.action = payload
        },
    }
})

export const selectConceptCategoryState = (state: RootState) => state.conceptCategorySlice
export default conceptCategorySlice.reducer

export const {
    onFetchConceptCategories,
    changeStatus,
    onSetSelectedConceptCategory,
    setAction
} = conceptCategorySlice.actions
  