import { api } from "./configs/axiosConfigs"

export const PaymentConceptAPI = {
    getPaymentConcepts: async (data?: any) => {
        const response = await api.get(
          '/concept/',
          {
            params: {
              ...data
            }
          }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    createPaymentConcept: async (data: any, idcompany: number) => {
        const response = await api.post(
            '/concept/register', {
                ...data,
                idcompany: idcompany
            }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    editPaymentConcept: async (idpayment_concept: number, data: any, idcompany: number) => {
        const response = await api.patch(
        `/concept/${idpayment_concept}`,
        {
            ...data,
            idcompany: idcompany
        }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    deletePaymentConcept: async (idpayment_concept: number) => {
        const response = await api.delete(
        `/concept/${idpayment_concept}`
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    getSpentLevel: async () => {
        const response = await api.get(
          '/concept/listSpentLevel'
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
}