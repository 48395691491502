import { toast } from 'react-hot-toast';
import WarningIcon from '@mui/icons-material/Warning';

export const CustomSnackbar = (alert_type:string,message:string) => {
  switch (alert_type) {
    case "warning":
      return toast(message, {
        icon: <WarningIcon style={{color: "orange"}}/>
      });
    case "success":
      return toast.success(message);
    case "error":
      return toast.error(message);
  }
}