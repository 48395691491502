import { ApiStatus } from "../types/api/status"
import { useDispatch, useSelector } from "../redux/store"
import { changeStatus, onFetchReservationGuests, onFetchReservations, onFetchReservationsByuser, onSetSelectedReservation, onSetSelectedReservationGuest, onSetSpecificReservation, selectReservationState, onFetchBlockedData } from "../redux/slices/reservationSlice"
import { ReservationDataForm, ReservationParams, ReservationBlockedDataForm } from "../types/api/Reservation.type"
import { ReservationAPI } from "../apis/ReservationAPI"
import { Reservation, ReservationGuest } from "../types/slices/reservationType"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { Role } from "../types/roles/roleTypes"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"

export const useReservationStore = () => {

  const dispatch = useDispatch()
  const { reservations, reservationsByUser, reservationGuests, selectedReservation, selectedReservationGuest, specificReservation, status, blocked } = useSelector(selectReservationState)
  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany
  
  const getReservations = async (params:ReservationParams|null = null) => {
    try {
      dispatch(onFetchReservations([]))
      dispatch(changeStatus(ApiStatus.FETCHING))
      let response = await ReservationAPI.getReservations(params)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      const { detail } = response.data

      const user = readLocalStorage(LocalStorageKey.USER_DATA);
      let getIdLabel;
      switch (user.userType) {
        case Role.OWNER:
          getIdLabel = 2;
          break
        case Role.FAMILY:
          getIdLabel = 1;
          break
      }

      let newData:any = [];

      if(detail && detail.length > 0){
        // if(user.userType == Role.ADMIN){
          detail.map((data) => {
            let filter:any = {
              id: data.id,
              code: data.code,
              startDate: data.arrival_date,
              endDate: data.exit_date,
              reservation_date: data.reservation_date,
              iddepartment: data.iddepartment,
              name_department: data.name_department,
              idowner: data.idowner,
              rut_owner: data.rut_owner,
              name_owner: data.name_owner,
              idlabel: data.idlabel,
              name_label: data.name_label,
              name_reservationSpecial: data.name_reservationSpecial,
              number_days: data.number_days,
              check_in: data.check_in,
              check_out: data.check_out,
              name_invited: data.name_invited,
              status: data.status,
            }
            newData.push(filter);
          })
        // }else{
        //   detail.map((data) => {
        //     if (getIdLabel == data.idlabel) {
        //       let filter:any = {
        //         id: data.id,
        //         code: data.code,
        //         startDate: data.arrival_date,
        //         endDate: data.exit_date,
        //         reservation_date: data.reservation_date,
        //         iddepartment: data.iddepartment,
        //         name_department: data.name_department,
        //         idowner: data.idowner,
        //         rut_owner: data.rut_owner,
        //         name_owner: data.name_owner,
        //         idlabel: data.idlabel,
        //         name_label: data.name_label,
        //         name_reservationSpecial: data.name_reservationSpecial,
        //         number_days: data.number_days,
        //         check_in: data.check_in,
        //         check_out: data.check_out,
        //         status: data.status,
        //       }
        //       newData.push(filter);
        //     }
        //   })
        // }
      }

      dispatch(onFetchReservations(newData || []))
      dispatch(changeStatus(ApiStatus.FETCHED))

    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const getReservationsByTypeUser = async (params:ReservationParams|null = null) => {
    try {
      dispatch(onFetchReservationsByuser([]))
      dispatch(changeStatus(ApiStatus.FETCHING))
      let response = await ReservationAPI.getReservations(params)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      const { detail } = response.data

      const user = readLocalStorage(LocalStorageKey.USER_DATA);
      let getIdLabel;
      switch (user.userType) {
        case Role.OWNER:
          getIdLabel = 2;
          break
        case Role.FAMILY:
          getIdLabel = 1;
          break
      }

      let newData:any = [];

      if(detail && detail.length > 0){
          detail.map((data:any) => {
            if (user.userType == Role.FAMILY ? (data.idlabel == 1 || (data.idlabel == 3 && data.idinvited)) : true) {
              let filter:any = {
                id: data.id,
                code: data.code,
                startDate: data.arrival_date,
                endDate: data.exit_date,
                reservation_date: data.reservation_date,
                iddepartment: data.iddepartment,
                name_department: data.name_department,
                idowner: data.idowner,
                rut_owner: data.rut_owner,
                name_owner: data.name_owner,
                idlabel: data.idlabel,
                name_label: data.name_label,
                name_reservationSpecial: data.name_reservationSpecial,
                number_days: data.number_days,
                check_in: data.check_in,
                check_out: data.check_out,
                name_invited: data.name_invited,
                status: data.status,
              }
              newData.push(filter);
            }
          })
      }

      dispatch(onFetchReservationsByuser(newData || []))
      dispatch(changeStatus(ApiStatus.FETCHED))

    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  /* lista las reservaciones menos el idreserve seleccionado (para el edit) */
  const getReservationsFilterEdit = async (params:ReservationParams|null = null, idreservse:any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      let response = await ReservationAPI.getReservations(params)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      const { detail } = response.data

      const filterResponse = detail.filter((resevation) => resevation.id != idreservse)

      let newData:any = [];
      if(filterResponse.length > 0){
        filterResponse.map((data)=>{
          let filter:any = {
            id: data.id,
            code: data.code,
            startDate: data.arrival_date,
            endDate: data.exit_date,
            reservation_date: data.reservation_date,
            iddepartment: data.iddepartment,
            name_department: data.name_department,
            idowner: data.idowner,
            rut_owner: data.rut_owner,
            name_owner: data.name_owner,
            idlabel: data.idlabel,
            name_label: data.name_label,
            name_reservationSpecial: data.name_reservationSpecial,
            check_in: data.check_in,
            check_out: data.check_out,
            status: data.status,
          }
          newData.push(filter);
        })
      }

      dispatch(onFetchReservations(newData || []))
      dispatch(changeStatus(ApiStatus.FETCHED))

    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const getBlocked = async () => {
    try{
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await ReservationAPI.getBlocked(idcompany)
      if(!response.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      const { detail } = response.data
      dispatch(onFetchBlockedData(detail))
      dispatch(changeStatus(ApiStatus.FETCHED))
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const getSpecificReservation = async (idreservation: number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await ReservationAPI.getSpecificReservation(idreservation)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      const { detail } = response.data
      const filter:any = {
        id: detail.id,
        code: detail.code,
        startDate: detail.arrival_date,
        endDate: detail.exit_date,
        reservation_date: detail.reservation_date,
        iddepartment: detail.iddepartment,
        name_department: detail.name_department,
        idowner: detail.idowner,
        rut_owner: detail.rut_owner,
        name_owner: detail.name_owner,
        idlabel: detail.idlabel,
        name_label: detail.name_label,
        name_reservationSpecial: detail.name_reservationSpecial,
        check_in: detail.check_in,
        check_out: detail.check_out,
        status: detail.status,
      }
      dispatch(onSetSpecificReservation(filter))
      dispatch(changeStatus(ApiStatus.FETCHED))

    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const getReservationGuests = async (idreservation:number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await ReservationAPI.getReservationGuests(idreservation)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      const { detail } = response.data
      dispatch(onFetchReservationGuests(detail))
      dispatch(changeStatus(ApiStatus.FETCHED))
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const createReservation = async (data: ReservationDataForm) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await ReservationAPI.createReservation(data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('error', response.data.message || "");
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const createReservationBlocked = async (data: ReservationBlockedDataForm) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await ReservationAPI.createReservationBlocked({...data, idcompany : idcompany})
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('error', response.data.message || "");
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const addGuestsReservation = async (idreservation: number, data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await ReservationAPI.addGuestsReservation(idreservation, data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const setSelectedReservation = (reservation: Reservation) => {
      try {
          dispatch(onSetSelectedReservation({
              ...reservation,
              // arrival_date: reservation.arrival_date ? moment(reservation.arrival_date).format('YYYY-MM-DD') : '', 
              // exit_date: reservation.exit_date ? moment(reservation.exit_date).format('YYYY-MM-DD') : '',
          }))
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
  }

  const setSelectedReservationGuest = (reservationGuest: ReservationGuest) => {
    try {
      dispatch(onSetSelectedReservationGuest(reservationGuest))
    } catch (error) {
      console.log(error)
    }
  }

  const editReservation = async (idreservation: number, data: ReservationDataForm) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await ReservationAPI.editReservation(idreservation, data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const deleteReservation = async (idreservation: number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await ReservationAPI.deleteReservation(idreservation)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('error', response.data.message || "");
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const deleteBlocked = async (id: number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await ReservationAPI.deleteBlocked(id)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('error', response.data.message || "");
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }


  const cancelReservation = async (idreservation: number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await ReservationAPI.cancelReservation(idreservation)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('error', response.data.message || "");
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const deleteReservationGuest = async (idreservationinvited: number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await ReservationAPI.deleteReservationGuest(idreservationinvited)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  return {
    //states
    reservations,
    blocked,
    reservationsByUser,
    reservationGuests,
    selectedReservation,
    selectedReservationGuest,
    specificReservation,
    status,
    //actions
    getReservations,
    getReservationsByTypeUser,
    getReservationsFilterEdit,
    getSpecificReservation,
    getReservationGuests,
    getBlocked,
    createReservation,
    createReservationBlocked,
    addGuestsReservation,
    setSelectedReservation,
    setSelectedReservationGuest,
    editReservation,
    deleteReservation,
    deleteBlocked,
    cancelReservation,
    deleteReservationGuest,
  }
}