import { ChangeEvent, useEffect, useState } from "react"
import { Formik } from "formik"
import { CustomForm } from "../../components/common/CustomForm/CustomForm"
import { CustomFormBody } from "../../components/common/CustomForm/CustomFormBody"
import { CustomFormHeader } from "../../components/common/CustomForm/CustomFormHeader"
import { Divider, Grid, TextField } from "@mui/material"
import { CustomFormFooter } from "../../components/common/CustomForm/CustomFormFooter"
import { RoutesMap } from "../../types"
import { useCompanyStore } from "../../hooks/useCompanyStore"
import { useNavigate } from "react-router-dom"
import { Company } from "../../types/slices/companyType"
import { ApiStatus } from "../../types/api/status"
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop"

type CompanyDataForm = {
    id: number
    rut: string,
    business_name: string,
    line_business: string,
    address: string,
    email: string,
    phone: string,
}

export const CompanyForm = (props) => {
    
    const navigate = useNavigate()
    const { status: statusCompanyApi, createCompany, selectedCompany, action, setSelectedCompany, editCompany, setActionCompany } = useCompanyStore()

    const [ isEdit, setIsEdit ] = useState<boolean>(false)
    const [ data, setData ] = useState<CompanyDataForm>({
        id: 0,
        rut: '',
        business_name: '',
        line_business: '',
        address: '',
        email: '',
        phone: '',
    });

    useEffect(()=>{
        if(!selectedCompany.id && action=='') {
            navigate({ pathname: RoutesMap.COMPANIES}, { replace: true })
        }
    }, [!selectedCompany.id])

    useEffect(()=>{
        if(Object.entries(selectedCompany).length > 0) {
            setIsEdit(true)
            setData({
                id: selectedCompany.id,
                rut: selectedCompany.rut,
                business_name: selectedCompany.business_name,
                line_business: selectedCompany.line_business,
                address: selectedCompany.address,
                email: selectedCompany.email,
                phone: selectedCompany.phone
            })
            setSelectedCompany({} as Company)
        }
    },[])

    const validateForm = (values) => {
        let errors:any = {};
        if(!values.rut) errors.rut = "RUT requerido";
        if(!values.business_name) errors.business_name = "Razón social es requerido";
        if(!values.line_business) errors.line_business = "Línea de negocio es requerido";
        if(!values.address) errors.address = "Dirección es requerido";
        if(!values.email) errors.email = "Correo es requerido";
        if(!values.phone) errors.phone = "Celular es requerido";
        return errors;
    }

    const onCancel = ()  => {
        navigate({ pathname: RoutesMap.COMPANIES })
    }

    const onSubmit = async (values: CompanyDataForm)  => {
        const method = isEdit ? editCompany(values.id, {...values}) : createCompany({...values})
        const response = await method
        if (response === true) {
            setActionCompany('')
            navigate({ pathname: RoutesMap.COMPANIES })
        }
    }

    return (
        <CustomForm >

            {  statusCompanyApi === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }

            <CustomFormHeader
                title={'Nueva Empresa'}
                text={'Ingrese los datos de la nueva Empresa'}
                goBack={RoutesMap.COMPANIES}
            >
            </CustomFormHeader>

            <CustomFormBody>

                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{padding:'20px'}}>

                                <Grid item container xs={12} spacing={2} sx={{mt:2}}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            id="rut"
                                            type="text" 
                                            name="rut"
                                            fullWidth
                                            size="small"
                                            value={values.rut}
                                            onChange={handleChange}
                                            placeholder={"RUT"}
                                            error={errors.rut && touched.rut ? true : false}
                                            helperText={errors.rut && touched.rut ? errors.rut : ''}
                                            InputProps={{
                                                inputProps: {
                                                    maxLength: 10
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            id="business_name"
                                            type="text" 
                                            name="business_name"
                                            fullWidth
                                            size="small"
                                            value={values.business_name}
                                            onChange={handleChange}
                                            placeholder={"Razón social"}
                                            error={errors.business_name && touched.business_name ? true : false}
                                            helperText={errors.business_name && touched.business_name ? errors.business_name : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            id="line_business"
                                            type="text" 
                                            name="line_business"
                                            fullWidth
                                            size="small"
                                            value={values.line_business}
                                            onChange={handleChange}
                                            placeholder={"Línea de negocio"}
                                            error={errors.line_business && touched.line_business ? true : false}
                                            helperText={errors.line_business && touched.line_business ? errors.line_business : ''}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} spacing={2} sx={{marginTop:'10px'}}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            id="address"
                                            type="text" 
                                            name="address"
                                            fullWidth
                                            size="small"
                                            value={values.address}
                                            onChange={handleChange}
                                            placeholder={"Dirección"}
                                            error={errors.address && touched.address ? true : false}
                                            helperText={errors.address && touched.address ? errors.address : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            id="email"
                                            type="text" 
                                            name="email"
                                            fullWidth
                                            size="small"
                                            value={values.email}
                                            onChange={handleChange}
                                            placeholder={"Correo"}
                                            error={errors.email && touched.email ? true : false}
                                            helperText={errors.email && touched.email ? errors.email : ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            id="phone"
                                            type="text" 
                                            name="phone"
                                            fullWidth
                                            size="small"
                                            value={values.phone}
                                            onChange={(e) => {
                                                const regex = /^[0-9\b]+$/;
                                                if (e.target.value === "" || regex.test(e.target.value)) {
                                                  setFieldValue('phone', e.target.value)
                                                }
                                            }}
                                            placeholder={"Celular"}
                                            error={errors.phone && touched.phone ? true : false}
                                            helperText={errors.phone && touched.phone ? errors.phone : ''}
                                        />
                                    </Grid>
                                </Grid>
                                
                                </Grid>

                                <Divider />
                                <CustomFormFooter 
                                    buttonType="submit"
                                    confirmText={ isEdit ? 'Actualizar' : 'Guardar' }
                                    cancelText={"Cancelar"}
                                    onConfirm={handleSubmit}
                                    onCancel={onCancel}
                                />
                            </form>
                        )
                    }} 
                </Formik>

            </CustomFormBody>

        </CustomForm>
    )
}