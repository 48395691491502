import { useEffect, useState } from "react";
import { Grid } from '@mui/material'
import CustomTable from '../../components/common/CustomTable/CustomTable';
import { RoutesMap } from "../../types";
import { useNavigate } from "react-router-dom";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { SpecialDateModal } from "./SpecialDateModal";
import { useSpecialDateStore } from "../../hooks/useSpecialDateStore";
import { specialdate } from "../../types/slices/specialdateType";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import moment from "moment";
import { useSelector } from "../../redux/store";
import { selectHeaderState } from "../../redux/slices/headerSlice";

const columns = [
    { type: 'options', field: 'options', label: 'Opciones' },
    { type: 'text', field: 'name', label: 'Nombre' },
    { type: 'text', field: 'arrival_date', label: 'Fecha de inicio', format:(row) => row.arrival_date && moment(row.arrival_date).format('YYYY-MM-DD'), align:'center' },
    { type: 'text', field: 'exit_date', label: 'Fecha de cierre', format:(row) => row.exit_date && moment(row.exit_date).format('YYYY-MM-DD'), align:'center' },
    // { type: 'text', field: 'department_name', label: 'Departamento' },
    { type: 'detail', field: 'detail', label: '', align:'center' },
];

export const SpecialDateView = () => {

    const navigate = useNavigate();
    const { search } = useSelector(selectHeaderState);

    const {specialdates, specialdatesGroup, specialdateSelected, status:statusSpecialdate, getSpecialDates, getSpecialDatesGroup, deleteSpecialDate, deleteSpecialDateMassive, setSelectedSpecialdate, setActionSpecialDate } = useSpecialDateStore();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      getSpecialDatesApi()
    },[search])

    const getSpecialDatesApi = async() => {
      try {
        setLoading(true);
        const params: any = { search };
        const response = await getSpecialDatesGroup(params);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    const onAdd = () => {
        setActionSpecialDate('add')
        navigate({ pathname: RoutesMap.SPECIAL_DATES_FORM })
    }
    const onEdit = (rowSelected) => {
        setActionSpecialDate('edit')
        setSelectedSpecialdate(rowSelected)
        navigate({ pathname: RoutesMap.SPECIAL_DATES_FORM })
    }
    const onDelete = (rowSelected) => {
        setActionSpecialDate('delete')
        setSelectedSpecialdate(rowSelected)
        setShowModalConfirm(true);
    }
    const onDetails = (rowSelected) => {
        setActionSpecialDate('detail')
        setSelectedSpecialdate(rowSelected)
        setShowModal(true);
    }

    const onDeleteConfirm = async() => {
        if (specialdateSelected.id){ 
            const response = await deleteSpecialDateMassive(specialdateSelected)
            if(response){
                setSelectedSpecialdate({} as specialdate)
                setShowModalConfirm(false)
                getSpecialDatesApi()
            }else{
                return false;
            }
            
        }
    }

    return (
        <>
        <Grid container>
            {/* {statusSpecialdate == ApiStatus.FETCHING && <CustomBackdrop open={true}/>} */}

            <Grid item xs={12}>
                <CustomTable 
                    title={'Fechas Especiales'}
                    columns={columns}
                    loading={loading}
                    rows={specialdatesGroup || []}
                    onRowClick={() => {}}
                    hasOptions
                    onAddFn={onAdd}
                    onDetails={onDetails}
                    onDelete={onDelete}
                    onEdit={onEdit}
                />
            </Grid>

            {
                showModalConfirm && (
                    <ModalConfirm
                        open={showModalConfirm}
                        closeModal={()=>{
                            setShowModalConfirm(false)
                            setSelectedSpecialdate({} as specialdate)
                        }}
                        onConfirm={onDeleteConfirm}
                        status2={statusSpecialdate == ApiStatus.FETCHING ? true : false}
                    />
                )
            }
            {
                showModal && (
                    <SpecialDateModal
                        open={showModal}
                        closeModal={()=>{
                            setShowModal(false); 
                            setSelectedSpecialdate({} as specialdate)
                        }}
                        rowSelected={specialdateSelected}
                    />
                )
            }
        </Grid>
        </>
    )
}