import { useEffect, useState } from "react";
import { Grid, Typography, Button, Card, CardContent, Divider, IconButton, styled, Skeleton } from '@mui/material'
import { RoutesMap } from "../../types";
import { useNavigate } from "react-router-dom";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { AffiliatedFamilyModal } from "./AffiliatedFamilyModal";
import { useGuestStore } from "../../hooks/useGuestStore";
import { useAuthStore } from "../../hooks";
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Guest } from "../../types/slices/guestType";
import { ApiStatus } from "../../types/api/status";

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'rgba(102, 102, 102, 0.4)',
}));

const skeletons:any = ['template1', 'template2', 'template3']

export const AffiliatedFamilyView = () => {

    const navigate = useNavigate();
    const { user } = useAuthStore()
    const { guests, selectedGuest, status:statusGuest, getGuestsByOwner, setSelectedGuest, deleteGuest, setActionGuest } = useGuestStore();

    const sessionOwner:any = user?.data

    const [rowSelected, setRowSelected] = useState<any>('');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(sessionOwner.id) getGuestsByOwnerApi(sessionOwner.id)
    },[sessionOwner])

    const getGuestsByOwnerApi = async(idowner) => {
        try {
          setLoading(true);
          const response = await getGuestsByOwner(idowner);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const onAdd = () => {
        setActionGuest('add')
        navigate({ pathname: RoutesMap.AFFILIATED_FAMILY_FORM })
    }
    const onEdit = (rowSelected) => {
        setActionGuest('edit')
        setSelectedGuest(rowSelected)
        navigate({ pathname: RoutesMap.AFFILIATED_FAMILY_FORM })
    }
    const onDelete = (rowSelected) => {
        setActionGuest('delete')
        setSelectedGuest(rowSelected)
        setShowModalConfirm(true);
    }
    const onDeleteConfirm = async () => {
        if (selectedGuest.id) {
            const response = await deleteGuest(selectedGuest.id)
            if(response){
                setSelectedGuest({} as Guest)
                setShowModalConfirm(false)
                getGuestsByOwnerApi(sessionOwner.id)
            }else{
                return false;
            }
        }
    }

    return (
        <>
            <Grid item xs={12}>
                <Grid container >
                    <Grid item container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom:'10px'}}>
                        <Typography variant='h6' component='h1' gutterBottom color='#808080' className="tittle" sx={{ mt: 1, ml:1 , fontWeight:600}}>
                            {('Listado de Afiliados').toUpperCase()}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', marginBottom:'10px'}}>
                        <Grid item xs={12} sx={{ textAlign: 'right' }}>
                            <Button
                            onClick={onAdd}
                            sx={{ border: 'solid 1px #73B2FF',mt: "10px",color:'#73B2FF', '&:hover':{ bgcolor:'#73B2FF', color: '#fff'}}}
                            >
                            {'Agregar'}
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent='center'>
                        {
                            !loading && guests && guests.length > 0 && guests.map((item, key)=>(
                                <>
                                <Card key={key} sx={{ minWidth: 300, margin:'5px' }}>
                                    <CardContent>
                                        <Grid display='flex' sx={{marginBottom:'5px'}}>
                                            <Grid xs={8} >
                                                <Typography sx={{ fontSize: 15 }} color="text.secondary" gutterBottom>
                                                    {item.name || '-----'}
                                                </Typography>
                                            </Grid>
                                            <Grid xs={4} sx={{ textAlign: 'right'}}>
                                                <Button 
                                                    sx={{ 
                                                        backgroundColor: '#60d711',
                                                        color:'#FFFFFF',
                                                        fontSize: '10px'
                                                    }}
                                                >
                                                    {item.typeguest || '-----'}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        <Grid display='flex' sx={{marginTop:'5px'}}>
                                            <Typography sx={{ width: '35%', fontSize: 14 }}>Rut</Typography>
                                            <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>{item.rut || '-----'}</Typography>
                                        </Grid>
                                        <Grid display='flex' sx={{marginTop:'5px'}}>
                                            <Typography sx={{ width: '35%', fontSize: 14 }}>Teléfono</Typography>
                                            <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>{item.phone || '-----'}</Typography>
                                        </Grid>
                                        <Grid display='flex'>
                                            <Typography sx={{ width: '35%', fontSize: 14 }}>Correo</Typography>
                                            <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>{item.email || '-----'}</Typography>
                                        </Grid>
                                    </CardContent>
                                    <Grid xs={12} justifyContent='end' textAlign='right'>
                                        <IconButton size="small" color="inherit" aria-label="edit" onClick={() => { onEdit(item) }}>
                                          <EditIcon fontSize='small' />
                                        </IconButton>
                                        <IconButton size="small" color="inherit" aria-label="delete" onClick={() => { onDelete(item) } }>
                                          <DeleteOutlineIcon fontSize='small' />
                                        </IconButton>
                                    </Grid>
                                </Card>
                            </>
                            ))
                        }
                        {
                            loading && skeletons.map((item, key)=>(
                                <Card key={key}  sx={{ width: 300, margin:'5px' }}>
                                    <CardContent>
                                        <Grid display='flex' sx={{marginBottom:'5px', alignItems:'center'}}>
                                            <Skeleton width="80%"  height='20px'/>
                                            <span style={{marginRight:'5px', marginLeft:'15px'}}></span>
                                            <Skeleton width="20%" height='40px'/>
                                        </Grid>
                                        <Divider />
                                        <Grid display='flex' sx={{ alignItems:'center'}}>
                                                <Skeleton width="30%" />
                                                <span style={{marginRight:'5px', marginLeft:'5px'}}>:</span>
                                                <Skeleton width="60%" />
                                        </Grid>
                                        <Grid display='flex' sx={{ alignItems:'center'}}>
                                            <Skeleton width="30%" />
                                            <span style={{marginRight:'5px', marginLeft:'5px'}}>:</span>
                                            <Skeleton width="60%" />
                                        </Grid>
                                        <Grid display='flex' sx={{ alignItems:'center'}}>
                                            <Skeleton width="30%" />
                                            <span style={{marginRight:'5px', marginLeft:'5px'}}>:</span>
                                            <Skeleton width="60%" />
                                        </Grid>
                                    </CardContent>
                                    <Grid xs={12} display='flex' justifyContent='end' textAlign='right' marginRight='5px'>
                                        <Skeleton width="10%" />
                                        <span style={{marginRight:'5px', marginLeft:'5px'}}> </span>
                                        <Skeleton width="10%" />
                                    </Grid>
                                </Card>
                            ))
                        }
                        {
                            (!loading && guests.length == 0) && (
                                <Grid sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px', marginBottom:'10px', width: '100%'}}>
                                    <Div>{"NO TIENES FAMILIARES AFILIADOS"}</Div>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>

            {
                showModalConfirm && (
                    <ModalConfirm
                        open={showModalConfirm}
                        closeModal={()=>{
                            setShowModalConfirm(false)
                            setSelectedGuest({} as Guest)
                        }}
                        onConfirm={onDeleteConfirm}
                        status2={statusGuest == ApiStatus.FETCHING ? true : false}
                    />
                )
            }
            {
                showModal && (
                    <AffiliatedFamilyModal
                        open={showModal}
                        closeModal={()=>{setShowModal(false)}}
                        rowSelected={rowSelected}
                    />
                )
            }
        </>
    )
}