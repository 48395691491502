import { ApiStatus } from "../types/api/status"
import { useDispatch, useSelector } from "../redux/store"
import { changeStatus, onFetchSpecialdate, onFetchSpecialdateGroup, onSetSelectedSpecialdate, selectSpecialdateState, setAction } from "../redux/slices/specialDateSlice"
import { SpecialDateAPI } from "../apis/SpecialDateAPI"
import { DateParams, SpecialDateDataForm } from "../types/api/SpecialDate.type"
import { specialdate } from "../types/slices/specialdateType"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"
import moment from "moment"

export const useSpecialDateStore = () => {

  const dispatch = useDispatch()
  const { specialdates, specialdateSelected, status, action, specialdatesGroup } = useSelector(selectSpecialdateState)

  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany

    const getSpecialDates = async (dates:DateParams|null = null) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            let response = await SpecialDateAPI.getSpecialDates(dates)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            dispatch(onFetchSpecialdate(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))

        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const getSpecialDatesGroup = async (dates:any) => {
      try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          let response = await SpecialDateAPI.getSpecialDatesGroup(dates)
          if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            return CustomSnackbar('error', response.data.message || "");
          }
          const { detail } = response.data
          dispatch(onFetchSpecialdateGroup(detail))
          dispatch(changeStatus(ApiStatus.FETCHED))

      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
  }

    const getSpecialDatesForCalendar = async (dates:DateParams|null = null) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            let response = await SpecialDateAPI.getSpecialDates(dates)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              return CustomSnackbar('error', response.data.message || "");
            }
            const { detail } = response.data
            let newData:any = [];
            if(detail && detail.length > 0){
                detail.map((date)=>{
                    let filter:any = {
                        id: date.id,
                        name: date.name,
                        startDate: date.arrival_date,
                        endDate: date.exit_date,
                        status: date.status,
                    }
                    newData.push(filter);
                })
            }

            dispatch(onFetchSpecialdate(newData || []))
            dispatch(changeStatus(ApiStatus.FETCHED))

        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const createSpecialDate = async (data: SpecialDateDataForm) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await SpecialDateAPI.createSpecialDate(data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              CustomSnackbar('error', response.data.message || "");
              return false
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true;
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const createSpecialDateMassive = async (data: any) => {
      try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await SpecialDateAPI.createSpecialDateMassive(data)
          if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('error', response.data.message || "");
            return false
          }
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar('success', response.data.message  || "");
          return true;
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
  }

    const setSelectedSpecialdate = (specialdate: specialdate) => {
        try {
            dispatch(onSetSelectedSpecialdate({
                ...specialdate,
                arrival_date: specialdate.arrival_date ? moment(specialdate.arrival_date).format('YYYY-MM-DD') : '', 
                exit_date: specialdate.exit_date ? moment(specialdate.exit_date).format('YYYY-MM-DD') : '',
            }))
        } catch (error) {
            console.log(error)
        }
    }

    const editSpecialDate = async (idspecialdate: number, data: SpecialDateDataForm) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await SpecialDateAPI.editSpecialDate(idspecialdate, data)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              CustomSnackbar('error', response.data.message || "");
              return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true;
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const updateMassive = async (data: any) => {
      try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await SpecialDateAPI.updateMassive(data)
          if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('error', response.data.message || "");
            return false;
          }
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar('success', response.data.message  || "");
          return true;
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
    }

    const deleteSpecialDate = async (idspecialdate: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await SpecialDateAPI.deleteSpecialDate(idspecialdate)
            if (!response?.status) {
              dispatch(changeStatus(ApiStatus.FETCHED))
              CustomSnackbar('error', response.data.message || "");
              return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message  || "");
            return true;
        } catch (error) {
          console.log(error)
          return dispatch(changeStatus(ApiStatus.FETCHED))
        }
    }

    const deleteSpecialDateMassive = async (data: any) => {
      try {
          dispatch(changeStatus(ApiStatus.FETCHING))
          const response = await SpecialDateAPI.deleteSpecialDateMassive(data)
          if (!response?.status) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('error', response.data.message || "");
            return false;
          }
          dispatch(changeStatus(ApiStatus.FETCHED))
          CustomSnackbar('success', response.data.message  || "");
          return true;
      } catch (error) {
        console.log(error)
        return dispatch(changeStatus(ApiStatus.FETCHED))
      }
    }

    const setActionSpecialDate = (state) => {
      try {
        dispatch(setAction(state))
      } catch (error) {
        console.log(error)
      }
    }


    return {
        //states
        specialdates,
        specialdatesGroup,
        specialdateSelected,
        status,
        action,
        //actions
        getSpecialDates,
        getSpecialDatesGroup,
        createSpecialDate,
        createSpecialDateMassive,
        setSelectedSpecialdate,
        editSpecialDate,
        updateMassive,
        deleteSpecialDate,
        deleteSpecialDateMassive,
        getSpecialDatesForCalendar,
        setActionSpecialDate,
    }
}