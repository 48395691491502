import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { PaymentConcept, PaymentConceptType, spentLevel } from "../../types/slices/paymentConceptType";
import { RootState } from "../store";

const initialState: PaymentConceptType = {
    status: ApiStatus.FETCHED,
    paymentConcepts: [],
    spentLevel: [],
    errorMessage: undefined,
    selectedPaymentConcept: {} as PaymentConcept,
    selectedDetail: {} as PaymentConcept,
    action: '',
}

const paymentConceptSlice = createSlice({
    name: 'paymentConcept',
    initialState,
    reducers: {
        onFetchPaymentConcepts (state, { payload }: { payload: PaymentConcept[] }) {
            state.status            = ApiStatus.FETCHED
            state.paymentConcepts = payload
            state.errorMessage      = undefined
        },
        onFetchSpentLevel (state, { payload }: { payload: spentLevel[] }) {
            state.status            = ApiStatus.FETCHED
            state.spentLevel = payload
            state.errorMessage      = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedPaymentConcept (state, { payload }: { payload: PaymentConcept }) {
            state.selectedPaymentConcept = payload
        },
        onSetSelectedDetail (state, { payload }: { payload: PaymentConcept }) {
            state.selectedDetail = payload
        },
        setAction (state, { payload }: { payload: string }) {
            state.action = payload
        },
    }
})

export const selectPaymentConceptState = (state: RootState) => state.paymentConceptSlice
export default paymentConceptSlice.reducer

export const {
    onFetchPaymentConcepts,
    onFetchSpentLevel,
    changeStatus,
    onSetSelectedPaymentConcept,
    onSetSelectedDetail,
    setAction,
} = paymentConceptSlice.actions