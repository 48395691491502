import { useState } from "react"
import { Modal } from "../../components/common/Modal/Modal"
import { ModalHeader } from "../../components/common/Modal/ModalHeader"
import { ModalBody } from "../../components/common/Modal/ModalBody"
import { Formik } from "formik"
import { Divider, Grid, TextField, Typography } from "@mui/material"
import { ModalFooter } from "../../components/common/Modal/ModalFooter"

type AdminDataForm = {
    id: number
    name: string
    rut: string
    email: string
    phone: string
}

export const AdministratorModal : React.FC<any> = (props): JSX.Element | any => {

    const { open, closeModal, rowSelected, clearState } = props;
    
    const [data, setData] = useState<AdminDataForm>({
        id: rowSelected.id || '',
        rut: rowSelected.rut || '',
        name: rowSelected.name || '',
        email: rowSelected.email || '',
        phone: rowSelected.phone || ''
    })

    const validateForm = (values) => {}

    const onSubmit = async(values)  => {}

    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
            <ModalHeader
                text={'Detalle del administrador'}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Formik initialValues={data} enableReinitialize validate={ (values) => validateForm(values) } onSubmit={onSubmit}>
                    {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                <Grid container sx={{padding:'20px'}}>

                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{"Nombre"}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <TextField
                                            id="name"
                                            variant="standard"
                                            type="text" 
                                            name="name"
                                            multiline
                                            fullWidth
                                            value={values.name}
                                            placeholder={"nombre"}
                                            inputProps={{ readOnly: true }}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{"RUT"}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <TextField
                                            id="rut"
                                            variant="standard"
                                            type="text" 
                                            name="rut"
                                            multiline
                                            fullWidth
                                            value={values.rut}
                                            placeholder={"RUT"}
                                            inputProps={{ readOnly: true }}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{"Email"}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <TextField
                                            id="email"
                                            variant="standard"
                                            type="text" 
                                            name="email"
                                            multiline
                                            fullWidth
                                            value={values.email}
                                            placeholder={"Email"}
                                            inputProps={{ readOnly: true }}
                                        />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                        <Typography variant="subtitle1" className="custom-input"><b>{"Teléfono"}:</b></Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <TextField
                                            id="phone"
                                            variant="standard"
                                            type="phone" 
                                            name="email"
                                            multiline
                                            fullWidth
                                            value={values.phone}
                                            placeholder={"Teléfono"}
                                            inputProps={{ readOnly: true }}
                                        />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Divider />
                                <ModalFooter 
                                    buttonType="submit"
                                    cancelText={"Cancelar"}
                                    onCancel={closeModal}
                                />
                            </form>
                        )
                    }} 
                </Formik>
            </ModalBody>
        </Modal>
    )
}