import React, { useEffect, useState } from "react";
import { Modal } from "../../components/common/Modal/Modal";
import { ModalBody } from "../../components/common/Modal/ModalBody";
import { ModalHeader } from "../../components/common/Modal/ModalHeader";
import { ModalFooter } from "../../components/common/Modal/ModalFooter";
import { Formik } from "formik";
import { Grid, Divider, TextField, InputAdornment, Typography } from "@mui/material"
import { useDepartmentStore } from "../../hooks/useDepartmentStore";

export const EditPercentageModal: React.FC<any> = (props): JSX.Element | any => {
  const { open, closeModal, owners, ownerSelected } = props;

  const {  editPercentage } = useDepartmentStore();

  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [percentage, setPercentage] = useState<any>('');

  const [data, setData] = useState<any>({
    idowner: ownerSelected ? ownerSelected.id : "",
    name: ownerSelected ? ownerSelected.name : "",
    participation_percentage: ownerSelected ? ownerSelected.participation_percentage : ''
  });

  const validateForm = (values) => {
    let errors: any = {};
    if(!values.idowner) errors.idowner = "Campo requerido";
    
    return errors;
  }

  const handleKeyDown = (event) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 189 || keyCode === 109 || parseInt(event.target.value + event.key) > 100) {
      event.preventDefault();
    }
  }

  const showPercentageAvailable = async(e, values) => {
    e.preventDefault();

    setLoading(true);
    const data = {
      participation_available: true,
    }
    const response:any = await editPercentage(ownerSelected.id, data);
    if(response?.data){
      let filter:any = [];

      const array = response?.data?.detail?.detail?.valid_values || []
      if(array.length > 0){
        array.map((item)=>{
          let newitem = item + '%';
          filter.push(newitem)
        })
      }
      setLoading(false);
      setPercentage(filter)
    }
    setLoading(false);
  }
  
  const onSubmit = async (formData) => {
    setLoadingSubmit(true); 
    const data = {
      ...formData,
      participation_available: true,
      accept_create: true,
    }
    const response:any = await editPercentage(ownerSelected.id, data);
    setLoadingSubmit(false);
    
    if (response?.status)  closeModal(true);
    
  }

  return ( 
    <>
      <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="sm">
        <ModalHeader
          text={"Editar porcentaje de participación"}
          className="positionElements"
          onCancel={closeModal}
        />
        <ModalBody>
          <Formik
            initialValues={data}
            enableReinitialize
            validate={ (values) => validateForm(values) }
            onSubmit={onSubmit}
          >
          {({values, errors, touched, handleSubmit, handleChange}) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                    <Grid item xs={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>{"Nombre"}:</b></Typography>
                    </Grid>
                    <Grid item xs={8}>
                    <TextField
                        id="name"
                        variant="standard"
                        type="text" 
                        name="name"
                        multiline
                        fullWidth
                        value={values.name}
                        inputProps={{ readOnly: true }}
                    />
                    </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center" justifyContent="center" marginBottom='25px' >
                    <Grid item xs={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>{"Porcentaje (%)"}:</b></Typography>
                    </Grid>
                    <Grid item xs={8}>
                    <TextField
                        id="participation_percentage"
                        type="number" 
                        name="participation_percentage"
                        fullWidth
                        size="small"
                        value={values.participation_percentage}
                        onChange={handleChange}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          inputProps: {
                              min: 0,
                              max: 100,
                              step: 0.01,
                          }
                        }}
                        onKeyDown={handleKeyDown}
                    />
                    </Grid>
                </Grid>

                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                  <Grid item xs={4}>
                    <button onClick={(e)=>{showPercentageAvailable(e, values)}}>ver porcentajes disponibles</button>
                  </Grid>
                  <Grid item xs={8} sx={{paddingLeft:'10px'}}>
                    {
                      percentage && percentage.length > 0 && !loading && (
                        <Typography color='#575454' sx={{ fontSize:15, fontWeight:100}}>
                          {percentage.join(" - ")}
                        </Typography>
                      )
                    }
                    {
                      loading && (
                        <Typography color='#575454' sx={{ fontSize:15, fontWeight:100}}>
                          Espere un momento...
                        </Typography>
                      )
                    }
                  </Grid>
                </Grid>

                <Divider />
                <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'center'}}>
                  <ModalFooter 
                    confirmText={'Actualizar'}
                    onConfirm={handleSubmit}
                    loadingConfirmText={loadingSubmit}
                    cancelText={"Cancelar"}
                    onCancel={closeModal}
                  />
                </Grid>
              </form>
            )
          }} 
          </Formik>
        </ModalBody>
      </Modal>
    </>
  )
}