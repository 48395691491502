import { onOpenDrawer, selectDrawerState } from "../redux/slices/drawerSlice"
import { useDispatch, useSelector } from "../redux/store"


export const useDrawerStore = () => {

  const dispatch = useDispatch()
  const { drawerOpen } = useSelector(selectDrawerState)

  const openDrawer = (value: boolean) => {
    dispatch(onOpenDrawer(value))
  }
  return {
    //states
    drawerOpen,
    //actions
    openDrawer
  }
}