import { Grid, IconButton, Typography, Card, CardContent, Skeleton, Divider, styled, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RoutesMap } from "../../types";
import CustomTable from "../../components/common/CustomTable/CustomTable";
import { useEffect, useState } from "react";
import { useRenditionPaidStore } from "../../hooks/useRenditionPaidStore";
import { useAuthStore } from "../../hooks";
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { moneyFormat } from "../../toolbox/helpers/money-helpers";
import { formatDateTimeToDate } from "../../toolbox/helpers/dates-helpers";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { MODE_BUILDING_OWNER } from "../../toolbox/defaults/app";
import { useRenditionBuildingPaidStore } from "../../hooks/useRenditionBuildingPaidStore";

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.button,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    fontSize: '20px',
    color: 'rgba(102, 102, 102, 0.4)',
}));

const columns = [
    { type: 'text', field: 'year', label: 'Año', align: 'center' },
    { type: 'text', field: 'month', label: 'Mes', align: 'center' },
    { type: 'text', field: 'img_name', label: 'Transferencia', align: 'center'},
    { type: 'text', field: 'amount', label: 'Monto', align: 'center' },
    { type: 'text', field: 'validation_name', label: 'Estado', align: 'center' },
    { type: 'detail', field: 'Esta', label: '', align:'center' },
];

const skeletons:any = ['template1', 'template2']

export const RenditionHistory = (props) => {

    const { user } = useAuthStore()
    const navigate = useNavigate()
    const { renditionPaids, getRenditionPaids } = useRenditionPaidStore()
    const { renditionBuildingPaids, getRenditionBuildingPaids } = useRenditionBuildingPaidStore()
    const { selectedDepartment, selectedBuildingOwner } = useDepartmentStore()

    const [loading, setLoading] = useState(false);
    const [ renditionPaidsList, setRenditionPaidsList ] = useState<any>([]);

  useEffect(() => {
    if (MODE_BUILDING_OWNER === "building") {
      getRenditionBuildingPaidsApi()
    } else {
      getRenditionPaidsApi()
    }
  },[])
  
    useEffect(()=>{
      if (MODE_BUILDING_OWNER === "building") {
        setRenditionPaidsList(renditionBuildingPaids)
      } else {
        setRenditionPaidsList(renditionPaids)
      }
    },[renditionPaids,renditionBuildingPaids])

    const getRenditionPaidsApi = async () => {
        try {
            setLoading(true)
            const response = await getRenditionPaids({ idowner: user?.data?.id, iddepartment: selectedDepartment.id })
            if (response === true) { setLoading(false) }
        } catch (error) {
            setLoading(false)
        }
    }
    
    const getRenditionBuildingPaidsApi = async () => {
        try {
            setLoading(true)
            const response = await getRenditionBuildingPaids({ iddepartment: selectedDepartment.id, idbuilding: selectedBuildingOwner.idbuilding })
            if (response === true) { setLoading(false) }
        } catch (error) {
            setLoading(false)
        }
    }

    const onDetails = (rowSelected) => {
        const url = rowSelected.img_transfer
        const win:any = window.open(`${process.env.REACT_APP_ROOT_URL}/` + url, '_blank')
        win.focus()
    }

    return (
        <>
        {/* { renditionPaidStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} /> } */}
        <Grid container>
            <Grid item xs={12} display='flex' alignItems='center'>
                <IconButton size="small" color="primary" aria-label="view" onClick={() =>  navigate({ pathname: RoutesMap.RENDITION}) }>
                    <ArrowBackIcon fontSize='small'/>
                </IconButton>
                <Typography>Volver</Typography>
            </Grid>
            <Grid item container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom:'10px'}}>
                <Typography variant='h6' component='h1' gutterBottom color='#808080' className="tittle" sx={{ mt: 1, ml:1 , fontWeight:600}}>
                    {('Historial de mis pagos').toUpperCase()}
                </Typography>
            </Grid>
            <Grid item container justifyContent='center'>
                {
                    !loading && renditionPaidsList && renditionPaidsList.length > 0 && renditionPaidsList.map((item, key)=>(
                        <>
                        <Card key={key} sx={{ minWidth: 300, margin:'5px' }}>
                            <CardContent>
                                <Grid display='flex' sx={{marginBottom:'5px'}}>
                                    <Grid xs={12} >
                                        <Typography sx={{ fontSize: 15 }} color="text.secondary" gutterBottom>
                                            {item.description_report || '-----'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Grid display='flex' sx={{marginTop:'5px'}}>
                                    <Typography sx={{ width: '35%', fontSize: 14 }}>Transferencia:</Typography>
                                    <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>{item.img_name || '-----'}</Typography>
                                </Grid>
                                <Grid display='flex' sx={{marginTop:'5px'}}>
                                    <Typography sx={{ width: '35%', fontSize: 14 }}>Monto:</Typography>
                                    <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>{moneyFormat(item.amount, '$')  || '-----'}</Typography>
                                </Grid>
                                <Grid display='flex' sx={{marginTop:'5px'}}>
                                    <Typography sx={{ width: '35%', fontSize: 14 }}>Fecha de pago:</Typography>
                                    <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>{formatDateTimeToDate(item.date_pay) || '-----'}</Typography>
                                </Grid>
                                <Grid display='flex' sx={{marginTop:'5px'}}>
                                    <Typography sx={{ width: '35%', fontSize: 14 }}>Estado:</Typography>
                                    <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>{item.validation_name || '-----'}</Typography>
                                </Grid>
                            </CardContent>
                            <Divider />
                            <Grid xs={12} justifyContent='center' textAlign='center' sx={{marginBottom: '10px', marginTop: '10px'}}>
                                <Button variant="outlined" onClick={()=>{onDetails(item)}} startIcon={<PictureAsPdfIcon />}>
                                    Ver documento
                                </Button>
                            </Grid>
                        </Card>
                        </>
                    ))
                }
                {
                    loading && skeletons.map((item, key)=>(
                        <Card key={key}  sx={{ width: 400, margin:'5px' }}>
                            <CardContent>
                                <Grid display='flex' sx={{marginBottom:'5px', alignItems:'center'}}>
                                    <Skeleton width="100%"  height='20px'/>
                                </Grid>
                                <Divider />
                                <Grid display='flex' sx={{ alignItems:'center'}}>
                                        <Skeleton width="30%" />
                                        <span style={{marginRight:'5px', marginLeft:'5px'}}>:</span>
                                        <Skeleton width="60%" />
                                </Grid>
                                <Grid display='flex' sx={{ alignItems:'center'}}>
                                    <Skeleton width="30%" />
                                    <span style={{marginRight:'5px', marginLeft:'5px'}}>:</span>
                                    <Skeleton width="60%" />
                                </Grid>
                                <Grid display='flex' sx={{ alignItems:'center'}}>
                                    <Skeleton width="30%" />
                                    <span style={{marginRight:'5px', marginLeft:'5px'}}>:</span>
                                    <Skeleton width="60%" />
                                </Grid>
                                <Grid display='flex' sx={{ alignItems:'center'}}>
                                    <Skeleton width="30%" />
                                    <span style={{marginRight:'5px', marginLeft:'5px'}}>:</span>
                                    <Skeleton width="60%" />
                                </Grid>
                            </CardContent>
                            <Divider />
                            <Grid xs={12} display='flex' justifyContent='center' textAlign='center' marginRight='5px'>
                                <Skeleton width="30%" />
                            </Grid>
                        </Card>
                    ))
                }
                {
                    (!loading && renditionPaidsList.length == 0) && (
                        <Grid sx={{backgroundColor: 'rgba(224, 224, 224, 0.5)', padding: '15px', borderRadius: '10px', height:'150px', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '20px', marginBottom:'10px', width: '100%'}}>
                            <Div>{"NO TIENES PAGOS REALIZADOS"}</Div>
                        </Grid>
                    )
                }
            </Grid>
        </Grid>
        </>
    )

}