import { Formik } from "formik"
import { Modal } from "../../../components/common/Modal/Modal"
import { ModalBody } from "../../../components/common/Modal/ModalBody"
import { useEffect, useState } from "react"
import { Grid, TextField, FormLabel, Autocomplete, Button,Checkbox, InputAdornment, Typography } from '@mui/material'
import { ApiStatus } from "../../../types/api/status"
import { CustomBackdrop } from "../../../components/common/CustomBackdrop/CustomBackdrop"
import { Owner } from "../../../types/slices/ownerType"
import { useRenditionBuildingSpentStore } from "../../../hooks/useRenditionBuildingSpentStore"
import { useRenditionStore } from "../../../hooks/useRenditionStore"
import { useRenditionSpentIndividualStore } from "../../../hooks/useRenditionSpentIndividual"

import FileUploadIcon from '@mui/icons-material/FileUpload'
import { readLocalStorage } from "../../../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../../../types"
import { CustomSnackbar } from "../../../components/common/CustomSnackbar/CustomSnackbar"
import { useBuildingStore } from "../../../hooks/useBuildingStore"
import { useBuildingSpentStore } from "../../../hooks/useBuildingSpentStore"
import { BuildingByDepartment } from "../../../types/slices/buildingType"
import { useRenditionBuildingStore } from "../../../hooks/useRenditionBuildingStore"
import { useRenditionBuildingSpentIndividualStore } from "../../../hooks/useRenditionBuildingSpentIndividualStore"

type costData = {
    id: number
    idreport_building: number|null
    idconcept_spent: string|null
    amount: number|null
    file: string|null
    iddepartment: number|null
    department: string|null
}

export const RenditionBuildingAddCostModal : React.FC<any> = (props): JSX.Element | any => {
  const { open, edit, closeModal, rowSelected, buildingSelected } = props
  
  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany
  
  const { status: renditionStatus, renditionBuildings, getOrCreateRendition } = useRenditionBuildingStore();
  const { status: departmentSpentStatus, buildingByDepartment, selectedBuildingByDepartment, getBuildingByDepartments, setSelectedBuildingByDepartment } = useBuildingStore()
  const { status: buildingSpentStatus, selectedBuildingSpent, buildingSpents, getBuildingSpent, setSelectedBuildingSpent } = useBuildingSpentStore();
  const { status: renditionSpents, createRenditionBuildingSpent, editRenditionBuildingSpent } = useRenditionBuildingSpentStore();
  const { status: spentIndividualStatus, createRenditionBuildingSpentIndividual, editRenditionBuildingSpentIndividual } = useRenditionBuildingSpentIndividualStore()
  
    const [ nameFile, setNameFile ]             = useState<string>('')
    const [ updateFile,  setUpdateFile  ]       = useState<any>(null)
    const [ addIndividual, setAddIndividual ]   = useState<boolean>(false)
    const [ isGeneral, setIsGeneral ]           = useState<boolean>(false)
    const [ amount, setAmount ]                 = useState<any>('')
    const [ data, setData ]                     = useState<costData>({ 
      id: 0,
      idreport_building: null,
      idconcept_spent: null,
      amount: null,
      file: null,
      iddepartment: null,
      department: null 
    })

    useEffect(() => {
      setSelectedBuildingByDepartment({} as BuildingByDepartment)
      setSelectedBuildingSpent({})
      getBuildingSpent({idbuilding: buildingSelected.id})
    },[])

    useEffect(()=>{
      if (addIndividual === true) {
        getBuildingByDepartments(buildingSelected.id)
      }
    },[addIndividual])

    useEffect(()=>{
        if(open && edit) {          
            setData({
                id: rowSelected.id || 0, 
                idreport_building: rowSelected.idreport_building || null,
                idconcept_spent: rowSelected.idconcept_spent || null,
                amount: parseInt(rowSelected.amount) || null, 
                file: rowSelected.document || null,
                iddepartment: rowSelected.iddepartment || null,
                department: rowSelected.department || null
            })
            setAmount(parseInt(rowSelected.amount) || null);
            
            const buildingSpent = setCustomBuildingSpent(rowSelected.idconcept_spent, buildingSpents)
            setSelectedBuildingSpent(buildingSpent[0] || {})
            setNameFile(rowSelected.document_name || '')
            if (rowSelected.iddepartment && rowSelected.department) {
                setAddIndividual(true)
                const department = setCustomDepartmentsByBuilding(rowSelected.iddepartment, buildingByDepartment)
                setSelectedBuildingByDepartment(department[0] || {} as BuildingByDepartment);
            } else {
                setIsGeneral(true)
            }
        }
    },[open, edit])

    useEffect(()=>{
      if (!edit) {
        validateValueConceptSpent()
      }
    },[selectedBuildingSpent])

    //departmentsByBuilding
    const setCustomDepartmentsByBuilding = (iddepartmentsByBuilding, original_departments_building) => {
        const data = iddepartmentsByBuilding ? original_departments_building.filter(value => value.id == iddepartmentsByBuilding) : null;
        return getCustomDepartmentsByBuilding(data);
    }

    const getCustomDepartmentsByBuilding = (original_departments_building) => {
        const res = original_departments_building ? original_departments_building.map(departments => ({...departments})) : null;
        return res;
    }

    const customDepartmentsByBuilding = getCustomDepartmentsByBuilding(buildingByDepartment || []);

    //buildingSpent
    const setCustomBuildingSpent = (idbuildingSpent, original_building_spents) => {
        const data = idbuildingSpent ? original_building_spents.filter(value => value.idconcept_spent === idbuildingSpent) : null;
        return getCustomBuildingSpent(data);
    }

    const getCustomBuildingSpent = (original_building_spents) => {
        const res = original_building_spents ? original_building_spents.map(buildingSpent => ({...buildingSpent})) : null;
        return res;
    }

    const customBuildingSpent = getCustomBuildingSpent(buildingSpents || []);

    const onSubmit = async (values) => {
        if (selectedBuildingSpent?.idspent_type === 1 && (selectedBuildingSpent?.amount === '' || selectedBuildingSpent?.amount === null)) {
            return CustomSnackbar('warning', 'El gasto fijo seleccionado no tiene un monto definido.')
        }
        if (nameFile === null || nameFile === '') {
            return CustomSnackbar('warning', 'No se ha seleccionado algún documento.')
        }
        if(amount === null || amount === '' || amount === 'MONTO NO DEFINIDO') {
            return CustomSnackbar('warning', 'No se ha insertado un monto.')
        }
        const amount_value:any = parseInt(amount)
        const formData = new FormData()
        formData.append('idreport_building', renditionBuildings[0]?.id.toString())
        formData.append('idconcept_spent', selectedBuildingSpent?.idconcept_spent.toString() || '')
        formData.append('idbuilding', buildingSelected?.id || '')
        formData.append('amount', amount_value)
        formData.append('idcompany', idcompany.toString() || '')
        if (updateFile != null) {
            formData.append('document', updateFile || '')
        }
        if (addIndividual) { 
            formData.append('iddepartment', selectedBuildingByDepartment?.iddepartment.toString() || '')
        }
        const create_method = !addIndividual ? createRenditionBuildingSpent : createRenditionBuildingSpentIndividual
        const edit_method   = !addIndividual ? editRenditionBuildingSpent : editRenditionBuildingSpentIndividual
        const method        = !edit ? create_method(formData) : edit_method(data.id, formData)
        const response = await method
        if (response === true) {
            await getOrCreateRendition({ month: renditionBuildings[0].month, year: renditionBuildings[0].year, idbuilding: buildingSelected.id})
            closeModal()
        }
    }

    const setUpdateArchFile = (event) => {
        const files = (event.target && event.target.files) || []
        const titleCert = files[0]
        if (titleCert) {
          setUpdateFile(titleCert)
          setNameFile(titleCert.name)
        }
    }

    const handleChangeCheckBox = (e) => {
        const { checked } = e.target
        if (checked === true) {
            setAddIndividual(true)
        } else {
            setAddIndividual(false)
            // setSelectedBuildingSpent({} as BuildingByDepartment);
        }
    }

    const validateValueConceptSpent = () => {
        if (selectedBuildingSpent?.idspent_type === 1) {
            if (selectedBuildingSpent.amount === null || selectedBuildingSpent.amount === '') {
                setAmount('MONTO NO DEFINIDO')
            } else {
                setAmount(selectedBuildingSpent.amount)
            }
        } else {
            setAmount('')
        }
    }

    return(
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="sm">
            { departmentSpentStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
            { renditionSpents === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
            { spentIndividualStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
            { renditionStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
            { buildingSpentStatus === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
            <ModalBody>
                <Typography sx={{ display: 'flex', justifyContent: 'center', fontWeight: 'bolder'}}>
                    {!edit ? 'AGREGAR GASTO' : 'ACTUALIZAR GASTO'}
                </Typography>
                <Formik initialValues={data} enableReinitialize onSubmit={onSubmit}>
                    {({ values, errors, touched, handleSubmit, handleChange, setFieldValue }) => {
                    return (<>
                        <Grid container sx={{ margin: '15px 0px'}}>
                            <Grid item xs={6}>
                                <FormLabel sx={{ color: '#000000' }}>Concepto de gasto:</FormLabel>
                            </Grid>
                            <Grid item xs={6}>
                                  <Autocomplete
                                    disabled={isGeneral}
                                    disablePortal
                                    id="combo-box-payment-concept"
                                    value={selectedBuildingSpent}
                                    options={customBuildingSpent || []}
                                    getOptionLabel={(option) => option.concept_spent || ""}
                                    isOptionEqualToValue={(option, value) => option?.concept_spent === value?.concept_spent}
                                    fullWidth
                                    renderInput={(params) => <TextField {...params} size="small" variant="standard"/>}
                                    onChange={(e, newValue) => {
                                      setSelectedBuildingSpent(newValue || {})
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container sx={{ margin: '15px 0px'}}>
                            <Grid item xs={6}>
                                <FormLabel sx={{ color: '#000000' }}>Monto:</FormLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                fullWidth
                                size='small'
                                value={ amount }
                                onChange={(e) => {
                                  const regex = /^[0-9\b]+$/
                                  const { value } = e.target
                                  if (value === "" || regex.test(value)) {
                                    setAmount(parseInt(value))
                                  }
                                }}
                                variant="standard"
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                disabled={selectedBuildingSpent?.idspent_type === 1 ? true : false}
                                />
                            </Grid>
                        </Grid>
                        <Grid container sx={{ margin: '15px 0px'}}>
                            <Grid item xs={6}>
                                <FormLabel sx={{ color: '#000000' }}>Seleccione el archivo:</FormLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                variant="contained"
                                component="label"
                                style={{ maxWidth: '100%', width: '100%' }}
                                sx={{ boxShadow: 'none', textTransform: 'none', color: '#fff', background: '#212D39', "&:hover": {backgroundColor: "#212D39" } }}
                            >
                                <FileUploadIcon fontSize="small" />
                                {nameFile}
                                <input
                                style={{ display: 'none' }}
                                type='file'
                                name='file'
                                onChange={(e) => setUpdateArchFile(e)}
                                />
                            </Button>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ margin: '15px 0px'}}>
                            <Grid item xs={6}>
                                <Checkbox color="primary" onChange={ (e) => handleChangeCheckBox(e)} checked={addIndividual} disabled={isGeneral}/>
                                <FormLabel>Gasto individual:</FormLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                  disabled={!addIndividual}
                                  disablePortal
                                  id="combo-box-building-department"
                                  value={selectedBuildingByDepartment}
                                  options={customDepartmentsByBuilding || []}
                                  getOptionLabel={(option) => option.department_name || ""}
                                  isOptionEqualToValue={(option, value) => option?.department_name === value?.department_name}
                                  fullWidth
                                  renderInput={(params) => <TextField {...params} size="small" variant="standard"/>}
                                  onChange={(e, newValue) => {
                                    setSelectedBuildingByDepartment(newValue || {} as BuildingByDepartment);
                                  }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container sx={{ margin: '15px 0px'}}>
                            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Button
                                onClick={ () => handleSubmit() }
                                sx={{ backgroundColor: '#212D39', color: '#fff', float: 'right', height: '25px' , margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": {backgroundColor: "#212D39" } }}
                                >
                                    {
                                        edit
                                        ?
                                        'Actualizar'
                                        :
                                        'Agregar'
                                    }
                                </Button>
                                <Button
                                onClick={ () => closeModal() }
                                sx={{ backgroundColor: '#212D39', color: '#fff', float: 'right', height: '25px' , margin: '0px 5px', padding: '6px 16px', textTransform: 'none', width: 160, "&:hover": {backgroundColor: "#212D39" } }}
                                >
                                    Cancelar
                                </Button>
                            </Grid>
                        </Grid>
                    </>)}}
                </Formik>
            </ModalBody>
      </Modal>
    )
}