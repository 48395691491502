import { combineReducers } from 'redux';
import authSlice from './slices/authSlice';
import snackbarSlice from './slices/snackbarSlice';
import backdropSlice from './slices/backdropSlice';
import drawerSlice from './slices/drawerSlice';
import calendarSlice from './slices/calendarSlice';
import navSectionSlice from './slices/navSectionSlice';
import companySlice from './slices/companySlice';
import UserSlice from './slices/UserSlice';
import departmentSlice from './slices/departmentSlice';
import ownerSlice from './slices/ownerSlice';
import seasonSlice from './slices/seasonSlice';
import spentTypeSlice from './slices/spentTypeSlice';
import conceptCategorySlice from './slices/conceptCategorySlice';
import paymentConceptSlice from './slices/paymentConceptSlice';
import serviceSlice from './slices/serviceSlice';
import specialDateSlice from './slices/specialDateSlice';
import reservationSlice from './slices/reservationSlice';
import departmentSpentSlice from './slices/departmentSpentSlice';
import assignmentSlice from './slices/assignmentSlice';
import serviceTypeSlice from './slices/serviceTypeSlice';
import monthSlice from './slices/monthSlice';
import yearSlice from './slices/yearSlice';
import guestSlice from './slices/guestSlice';
import renditionSlice from './slices/renditionSlice';
import renditionSpentSlice from './slices/renditionSpentSlice';
import periodSlice from './slices/periodSlice';
import renditionSpentIndividual from './slices/renditionSpentIndividual';
import renditionPaidSlice from './slices/renditionPaidSlice';
import configurationCompanySlice from './slices/configurationCompanySlice';
import configurationDepartmentSlice from './slices/configurationDepartmentSlice';
import headerSlice from './slices/headerSlice';
import buildingSlice from './slices/buildingSlice';
import notificationSlice from './slices/notificationSlice';
import administratorSlice from './slices/administratorSlice';
import buildingSpentSlice from './slices/buildingSpentSlice';
import reportSlice from './slices/reportSlice';
import renditionBuildingSlice from './slices/renditionBuildingSlice';
import renditionBuildingSpentSlice from './slices/renditionBuildingSpentSlice';
import renditionBuildingSpentIndividualSlice from './slices/renditionBuildingSpentIndividualSlice';
import renditionBuildingPaidSlice from './slices/renditionBuildingPaidSlice';

const reducers = combineReducers({
    authSlice: authSlice,
    snackbarSlice: snackbarSlice,
    backdropSlice: backdropSlice,
    drawerSlice: drawerSlice,
    calendarSlice: calendarSlice,
    navSectionSlice: navSectionSlice,
    companySlice: companySlice,
    userSlice: UserSlice,
    departmentSlice: departmentSlice,
    ownerSlice: ownerSlice,
    buildingSlice: buildingSlice,
    buildingSpentSlice: buildingSpentSlice,
    seasonSlice: seasonSlice,
    spentTypeSlice: spentTypeSlice,
    conceptCategorySlice: conceptCategorySlice,
    paymentConceptSlice: paymentConceptSlice,
    serviceSlice: serviceSlice,
    specialDateSlice: specialDateSlice,
    reservationSlice: reservationSlice,
    departmentSpentSlice: departmentSpentSlice,
    assignmentSlice: assignmentSlice,
    serviceTypeSlice: serviceTypeSlice,
    monthSlice: monthSlice,
    yearSlice: yearSlice,
    guestSlice: guestSlice,
    renditionSlice: renditionSlice,
    renditionSpentSlice: renditionSpentSlice,
    renditionBuildingSpentSlice: renditionBuildingSpentSlice,
    periodSlice: periodSlice,
    renditionSpentIndividualSlice: renditionSpentIndividual,
    renditionBuildingSpentIndividualSlice: renditionBuildingSpentIndividualSlice,
    renditionPaidSlice: renditionPaidSlice,
    renditionBuildingPaidSlice: renditionBuildingPaidSlice,
    configurationCompanySlice: configurationCompanySlice,
    configurationDepartmentSlice: configurationDepartmentSlice,
    notificationSlice: notificationSlice,
    headerSlice: headerSlice,
    reportSlice: reportSlice,
    administratorSlice: administratorSlice,
    renditionBuildingSlice: renditionBuildingSlice
})

export default reducers;
