//formato redondeado (crudo) sin decimal 151
export function moneyFormat (amount: number|any, format: string) {
    const amountFormat = parseFloat(amount);
    const amountCurrent =  Math.round(amountFormat);
    let value;
    if(format == '$') value = '$ ' + amountCurrent;
    if(format == 'clp') value = 'CLP ' + amountCurrent;
    if(format == 'per') value = 's/. ' + amountCurrent;
    return value
}

//formato redondeado con 2 ceros 151.00}
export function moneyFormat2 (amount: number|any, format: string) {
    const amountFormat = parseFloat(amount);
    const amountCurrent = amountFormat.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    let value;
    if(format == '$') value = '$ ' + amountCurrent;
    if(format == 'clp') value = 'CLP ' + amountCurrent;
    if(format == 'per') value = 's/. ' + amountCurrent;
    return value
}

//formato con 3 decimales => 150.450
export function moneyFormat3 (amount: number|any, format: string) {
    const amountFormat = parseFloat(amount);
    const amountCurrent = amountFormat.toFixed(3).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    let value;
    if(format == '$') value = '$ ' + amountCurrent;
    if(format == 'clp') value = 'CLP ' + amountCurrent;
    if(format == 'per') value = 's/. ' + amountCurrent;
    return value
}
