import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { Year, YearType } from "../../types/slices/yearType";
import { RootState } from "../store";

const initialState: YearType = {
    status: ApiStatus.FETCHED,
    years: [],
    errorMessage: undefined,
    selectedYear: {} as Year
}

const yearSlice = createSlice({
    name: 'year',
    initialState,
    reducers: {
        onFetchYears (state, { payload }: { payload: Year[] }) {
            state.status       = ApiStatus.FETCHED
            state.years        = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedYear (state, { payload }: { payload: Year }) {
            state.selectedYear = payload
        }
    }
})

export const selectYearState = (state: RootState) => state.yearSlice
export default yearSlice.reducer

export const {
    onFetchYears,
    changeStatus,
    onSetSelectedYear
} = yearSlice.actions