import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { Department, DepartmentByOwner, DepartmentType, buildingOwner } from "../../types/slices/departmentType";
import { RootState } from "../store";

const initialState: DepartmentType =
{
  status: ApiStatus.FETCHED,
  departments: [],
  departmentsFilter: [],
  departmentByOwner: [],
  errorMessage: undefined,
  selectedDepartment: {} as Department,
  selectedDepartmentByOwner: {} as DepartmentByOwner,
  action: '',
  selectedBuildingOwner: {} as buildingOwner,
}

const departmentSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    onFetchDepartments (state, { payload }: { payload: Department[] }) {
      state.status       = ApiStatus.FETCHED
      state.departments  = payload
      state.errorMessage = undefined
    },
    onFetchDepartmentsFilter (state, { payload }: { payload: Department[] }) {
      state.status       = ApiStatus.FETCHED
      state.departmentsFilter  = payload
      state.errorMessage = undefined
    },
    onFetchDepartmentByOwner (state, { payload }: { payload: DepartmentByOwner[] }) {
      state.status       = ApiStatus.FETCHED
      state.departmentByOwner  = payload
      state.errorMessage = undefined
    },
    changeStatus (state, { payload }: { payload: ApiStatus }) {
      state.status = payload
    },
    onSetSelectedDepartment (state, { payload }: { payload: Department }) {
      state.selectedDepartment = payload
    },
    onSetSelectedDepartmentByOwner (state, { payload }: { payload: DepartmentByOwner }) {
      state.selectedDepartmentByOwner = payload
    },
    onSetSelectedBuildingOwner (state, { payload }: { payload: buildingOwner }) {
      state.selectedBuildingOwner = payload
    },
    setAction (state, { payload }: { payload: string }) {
      state.action = payload
    },
  }
})

export const selectDepartmentState = (state: RootState) => state.departmentSlice

export default departmentSlice.reducer

export const {
  onFetchDepartments,
  onFetchDepartmentByOwner,
  changeStatus,
  onSetSelectedDepartment,
  onSetSelectedDepartmentByOwner,
  setAction,
  onFetchDepartmentsFilter,
  onSetSelectedBuildingOwner,
} = departmentSlice.actions