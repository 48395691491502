import { Grid, IconButton, TextField, Typography } from "@mui/material"
import { FC } from "react"

import EditIcon from '@mui/icons-material/Edit'
import CustomTable from "../../components/common/CustomTable/CustomTable"

const columns = [
    { type: 'text', field: 'column1', label: 'FECHA DE MODIFICACIÓN' },
    { type: 'text', field: 'column2', label: 'NOMBRE' },
    { type: 'text', field: 'column3', label: 'INICIO' },
    { type: 'text', field: 'column4', label: 'CULMINO' }
]

const datatable = [
    {
        column1: '12 de abril 2023',
        column2: 'Año Nuevo',
        column3: '31 diciembre 2023',
        column4: '01 enero 2024',
    },
    {
        column1: '12 de abril 2023',
        column2: 'Año Nuevo',
        column3: '31 diciembre 2023',
        column4: '01 enero 2024',
    },
    {
        column1: '12 de abril 2023',
        column2: 'Año Nuevo',
        column3: '31 diciembre 2023',
        column4: '01 enero 2024',
    },
    {
        column1: '12 de abril 2023',
        column2: 'Año Nuevo',
        column3: '31 diciembre 2023',
        column4: '01 enero 2024',
    },
    {
        column1: '12 de abril 2023',
        column2: 'Año Nuevo',
        column3: '31 diciembre 2023',
        column4: '01 enero 2024',
    }
]

export const SettingAdminSpecialDate : FC = () => {
    return (
        <Grid container sx={{ margin: '0px 20px' }}>
            <Grid item xs={12} md={12} sx={{ margin: '10px 0px' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bolder' }} variant="h6">Politicas de fechas especiales</Typography>
            </Grid>
            <Grid item container xs={11} md={12} sx={{ alignItems: 'center', display: 'flex', margin: '15px 0px' }}>
                <Typography sx={{ fontSize: '16px', fontWeight: 300, marginRight: '10px' }}>Nombre:</Typography>
                <TextField 
                size="small"
                sx={{ marginRight: 1 }}
                type="number"
                />
                <IconButton size="small" color="inherit" aria-label="edit">
                    <EditIcon fontSize='small' />
                </IconButton>
            </Grid>
            <Grid item container xs={11} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Grid item xs={12} md={4} sx={{ border: '1px solid #000000', padding: '6px 14px' }}>
                    <Grid item xs={12} md={12}>
                        <Typography sx={{ color: '#808080', fontSize: '14px' }}>Fecha de inicio:</Typography>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography sx={{ fontSize: '21px' }}>31 de diciembre 2023</Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4} sx={{ border: '1px solid #000000', padding: '6px 14px' }}>
                    <Grid item xs={12} md={12}>
                        <Typography sx={{ color: '#808080', fontSize: '14px' }}>Fecha de culminación:</Typography>
                    </Grid>
                    <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography sx={{ fontSize: '21px' }}>01 de enero 2024</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={12}>
                <CustomTable
                columns={columns} 
                rows={datatable || []}
                />
            </Grid>
        </Grid>
    )
}