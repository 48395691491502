import { ApiStatus } from "../types/api/status"
import { useDispatch, useSelector } from "../redux/store"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { changeStatus, onFetchBuildingSpent, onSetBuildingSpentsExisting, onSetSelectedBuildingSpent, selectBuildingSpentState, setAction } from "../redux/slices/buildingSpentSlice"
import { BuildingSpentAPI } from "../apis/BuildingSpentAPI"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"

export const useBuildingSpentStore = () => {

  const dispatch = useDispatch()
  const { status, buildingSpents, selectedBuildingSpent, buildingSpentsExisting, action } = useSelector(selectBuildingSpentState)
  
  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany
  
  const getBuildingSpent = async (data?: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await BuildingSpentAPI.getBuildingSpent(data, idcompany)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || '')
      }
      const { detail } = response.data

      if (detail.length > 0) {
        const ids:any = [];
        detail.map((item:any) => {
          ids.push(item?.idconcept_spent);
        })
        dispatch(onSetBuildingSpentsExisting(ids))
      }
      dispatch(onFetchBuildingSpent(detail))
      dispatch(changeStatus(ApiStatus.FETCHED))
    } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.error(error)
    }
  }

  const createdBuildingSpent = async (data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await BuildingSpentAPI.createBuildingSpent(data, idcompany)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || '')
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message || '')
      return true
    } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.log(error)
    }
  }

  const editBuildingSpent = async (id_building: number, data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await BuildingSpentAPI.editBuildingSpent(id_building, data, idcompany)
      if (!response?.status) {
          dispatch(changeStatus(ApiStatus.FETCHED))
          return CustomSnackbar('error', response.data.message || '')
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message || '')
      return true
    } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.log(error)
    }
  }

  const deleteBuildingSpent = async (id_building: number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await BuildingSpentAPI.deleteBuildingSpent(id_building)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || '')
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message || '')
      return true
    } catch (error) {
      dispatch(changeStatus(ApiStatus.FETCHED))
      console.log(error)
    }
  }

  const setSelectedBuildingSpent = (building: any) => {
    try {
      dispatch(onSetSelectedBuildingSpent(building))
    } catch (error) {
      console.log(error)
    }
  }

  const setActionBuildingSpent = (state) => {
    try {
        dispatch(setAction(state))
    } catch (error) {
        console.log(error)
    }
  }

  return {
    //states
    buildingSpents,
    buildingSpentsExisting,
    selectedBuildingSpent,
    status,
    action,
    //actions
    getBuildingSpent,
    createdBuildingSpent,
    editBuildingSpent,
    deleteBuildingSpent,
    setSelectedBuildingSpent,
    setActionBuildingSpent
  }
}