export enum RoutesMap {
    LOGIN                             = '/login',
    MAIN                              = '/main',
    NOTIFICATION                      = '/notification',

    /* ------------------------- VIEWS OWNER AND FAMILY------------------------------- */
    HOME                              = '/home',
    REQUEST_DEPARTMENTS               = '/request-departments',
    MY_PROFILE                        = '/my-profile',
    RESERVATION                       = '/reservation',
    RESERVATION_CONFIRM               = '/reservation/:departament',
    RENDITION                         = '/rendition',
    RENDITION_HISTORY                 = '/rendition-history',
    //OWNER
    AFFILIATED_FAMILY                 = '/affiliated-family',
    AFFILIATED_FAMILY_FORM            = '/affiliated-family-form',

    /* ------------------------- VIEWS ADMIN ------------------------------- */
    ADMINISTRATOR                     = '/admin',
    ADMINISTRATOR_FORM                = '/admin-form',
    BUILDING                          = '/building',
    BUILDING_FORM                     = '/building-form',
    DEPARTAMENT_BY_BUILDING           = '/building/departament',
    DEPARTAMENT                       = '/departament',
    DEPARTAMENT_FORM                  = '/departament-form',
    OWNER_BY_DEPARTAMENT              = '/departament/owner',
    OWNER                             = '/owner',
    OWNER_FORM                        = '/owner-form',
    GUEST_ADMIN                       = '/owner/guest',
    GUEST_ADMIN_FORM                  = '/owner/guest-form',
    PAYMENT_CONCEPT                   = '/payment-concept',
    PAYMENT_CONCEPT_FORM              = '/payment-concept-form',
    PAYMENT_CONCEPT_DETAIL            = '/payment-concept-detail',
    PAYMENT_CONCEPT_BUILDING          = '/payment-concept-building',
    PAYMENT_CONCEPT_BUILDING_FORM     = '/payment-concept-building-form',
    PAYMENT_CONCEPT_BUILDING_DETAIL   = '/payment-concept-building-detail',
    SEASON                            = '/season',
    SEASON_FORM                       = '/season-form',
    SPECIAL_DATES                     = '/special-dates',
    SPECIAL_DATES_FORM                = '/special-dates-form',
    SERVICES                          = '/services',
    SERVICES_FORM                     = '/services-form',
    CONCEPT_CATEGORY                  = '/concept-category',
    CONCEPT_CATEGORY_FORM             = '/concept-category-form',
    RESERVATION_ADMIN                 = '/reservation-admin',
    RESERVATION_ADMIN_BLOCKED         = '/reservation-admin-blocked',
    RESERVATION_ADMIN_CONFIRM         = '/reservation-admin-confirm',
    RESERVATION_BLOCKED_CONFIRM       = '/reservation-blocked-confirm',
    RESERVATION_ADMIN_LIST            = '/reservation-admin-list',
    BLOCKED_LIST                      = 'blocked-list',
    RESERVATION_ADMIN_EDIT            = '/reservation-admin-edit',
    RESERVATION_ADMIN_INVITED         = '/reservation-admin-invited',
    RENDITION_ADMIN_OPEN              = '/rendition-admin-open',
    RENDITION_ADMIN_LIST              = '/rendition-admin-list',
    RENDITION_ADMIN_PAID              = '/rendition-admin-paid',
    RENDITION_ADMIN_BUILDING_OPEN     = '/rendition-admin-building-open',
    RENDITION_ADMIN_BUILDING_LIST     = '/rendition-admin-building-list',
    RENDITION_ADMIN_BUILDING_PAID     = '/rendition-admin-building-paid',
    CESSION_LIST                      = '/cession-list',
    CESSION_FORM                      = '/cession-form',
    CESSION_FORM_EDIT                 = '/cession-form-edit',
    CESSION_HISTORY                   = '/cession-history',
    REPORT_ADMIN_RENDITION_PAY  = '/report-admin-rendition-payment',
    REPORT_ADMIN_RENDITION            = '/report-admin-rendition',
    REPORT_ADMIN_DEPARTMENT           = '/report-admin-department',
    REPORT_ADMIN_AVAILABILITY         = '/report-admin-availability',
    SETTING_ADMIN_CANCELLATION        = '/setting-admin-cancelation',
    SETTING_ADMIN_CESSION             = '/setting-admin-cession',
    SETTING_ADMIN_SEASON              = '/setting-admin-season',
    SETTING_ADMIN_CHECKING            = '/setting-admin-checking',
    SETTING_ADMIN_CESSATION           = '/setting-admin-cessation',
    SETTING_ADMIN_CYLE_RENDITION      = '/setting-admin-cycle-rendition',
    SETTING_ADMIN_SPECIAL_DATE        = '/setting-admin-special-date',

    /* ------------------------- VIEWS SUPERADMIN ------------------------------- */
    COMPANIES                         = '/companies',
    COMPANIES_FORM                    = '/companies-form',
    USERS                             = '/users',
    USERS_FORM                        = '/user-form',
    SETTING_SUPERADMIN_RESERVATION    = '/setting-superadmin-reservation',

    /* ------------------------- VIEWS FAMILIES ------------------------------- */
}
