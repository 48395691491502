import { ApiStatus } from "../types/api/status"
import { useDispatch, useSelector } from "../redux/store"
import { changeStatus, onFetchGuest, onFetchGuestType, onSetSelectedGuest, onSetSelectedGuestType, selectGuestState, setAction } from "../redux/slices/guestSlice"
import { GuestAPI } from "../apis/GuestAPI"
import { GuestDataForm } from "../types/api/Guest.type"
import { Guest, typeGuest } from "../types/slices/guestType"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"

export const useGuestStore = () => {

  const dispatch = useDispatch()
  const { guests, typeGuest, selectedGuest, selectedGuestType, status, action } = useSelector(selectGuestState)

  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany

    const getGuests = async () => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await GuestAPI.getGuests()
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            const { detail } = response.data
            dispatch(onFetchGuest(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))

        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const getGuestsThird = async () => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await GuestAPI.getGuestsThird()
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            const { detail } = response.data
            dispatch(onFetchGuest(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))

        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const getGuestsByOwner = async (idowner: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await GuestAPI.getGuestsByOwner(idowner)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            const { detail } = response.data
            dispatch(onFetchGuest(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))

        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const getGuestsType = async () => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await GuestAPI.getGuestsType()
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            const { detail } = response.data
            dispatch(onFetchGuestType(detail))
            dispatch(changeStatus(ApiStatus.FETCHED))

        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.error(error)
        }
    }

    const createGuest = async (data: GuestDataForm) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await GuestAPI.createGuest(data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            dispatch(changeStatus(ApiStatus.FETCHED))            
            CustomSnackbar('success', response.data.message || '')
            return true;
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
    }

    const setSelectedGuest = (guest: Guest) => {
        try {
            dispatch(onSetSelectedGuest(guest))
        } catch (error) {
            console.log(error)
        }
    }

    const setSelectedGuestType = (typeGuest: typeGuest) => {
        try {
            dispatch(onSetSelectedGuestType(typeGuest))
        } catch (error) {
            console.log(error)
        }
    }

    const editGuest = async (idguest: number, data: GuestDataForm) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await GuestAPI.editGuest(idguest, data)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                return CustomSnackbar('error', response.data.message || '')
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || '')
            return true;
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
    }

    const deleteGuest = async (idguest: number) => {
        try {
            dispatch(changeStatus(ApiStatus.FETCHING))
            const response = await GuestAPI.deleteGuest(idguest)
            if (!response?.status) {
                dispatch(changeStatus(ApiStatus.FETCHED))
                CustomSnackbar('error', response.data.message || '')
                return false;
            }
            dispatch(changeStatus(ApiStatus.FETCHED))
            CustomSnackbar('success', response.data.message || '')
            return true;
        } catch (error) {
            dispatch(changeStatus(ApiStatus.FETCHED))
            console.log(error)
        }
    }

    const setActionGuest = (state) => {
        try {
            dispatch(setAction(state))
        } catch (error) {
            console.log(error)
        }
    }

    return {
        //states
        guests,
        typeGuest,
        selectedGuest,
        selectedGuestType,
        status,
        action,
        //actions
        getGuests,
        getGuestsThird,
        getGuestsType,
        getGuestsByOwner,
        createGuest,
        setSelectedGuest,
        setSelectedGuestType,
        editGuest,
        deleteGuest,
        setActionGuest,
    }
}