import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { Administrator, AdministratorType } from "../../types/slices/administratorType";
import { RootState } from "../store";

const initialState: AdministratorType = {
    status: ApiStatus.FETCHED,
    administrators: [],
    errorMessage: undefined,
    editAdministrator: {} as Administrator,
    selectedAdministrator: {} as Administrator
}

const administratorSlice = createSlice({
    name: 'administrator',
    initialState,
    reducers: {
        onFetchAdministrators (state, { payload }: { payload: Administrator[] }) {
            state.status       = ApiStatus.FETCHED
            state.administrators       = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetEditAdministrator (state, { payload }: { payload: Administrator }) {
            state.editAdministrator = payload
        },
        onSetSelectedAdministrator (state, { payload }: { payload: Administrator }) {
            state.selectedAdministrator = payload
        }
    }
})

export const selectAdministratorState = (state: RootState) => state.administratorSlice
export default administratorSlice.reducer

export const {
    onFetchAdministrators,
    changeStatus,
    onSetEditAdministrator,
    onSetSelectedAdministrator,
} = administratorSlice.actions