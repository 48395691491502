import { useDispatch } from "../../../redux/store";
import { showBackdrop } from "../../../toolbox/helpers/custom-action";
import { Modal } from "../../../components/common/Modal/Modal";
import { ModalHeader } from "../../../components/common/Modal/ModalHeader";
import { ModalBody } from "../../../components/common/Modal/ModalBody";
import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TvIcon from '@mui/icons-material/Tv';
import { CustomSnackbar } from "../../../components/common/CustomSnackbar/CustomSnackbar";
import { useDepartmentStore } from "../../../hooks/useDepartmentStore";

export const ReservationServicesModal: React.FC<any> = (props): JSX.Element | any => {

  const { open, closeModal, rowSelected, onList, clearState } = props;
  
  const { selectedDepartment } = useDepartmentStore();

  const dispatch = useDispatch();

    const onSubmit = async(values)  => {

        //prueba
        dispatch(showBackdrop(true));

        CustomSnackbar('success', 'creado con éxito');

        setTimeout(() => {
            dispatch(showBackdrop(false));
        }, 2000)
    }

    return (
      <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
        <ModalHeader
          text={'Servicios Adicionales'}
          className='positionElements'
          onCancel={closeModal}
          clearState={clearState}
        >
        </ModalHeader>
        <ModalBody>
          {
            selectedDepartment.services && selectedDepartment.services.length > 0 && (
              selectedDepartment.services.map((item:any, index) => {
                return (
                  <>
                    <Accordion>
                      <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                      >
                        <Typography>{ item.service }</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ListItemButton>
                          <Typography sx={{ width: '40%', flexShrink: 0 }}>Tipo</Typography>
                          <Typography sx={{ color: 'text.secondary' }}>{item.serviceType}</Typography>
                        </ListItemButton>
                        <Divider />
                      </AccordionDetails>
                    </Accordion>
                  </>
                )
              })
            )
          }
        </ModalBody>
      </Modal>
    )
}