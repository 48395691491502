import { useEffect, useState } from "react";
import { Grid } from '@mui/material'
import CustomTable from '../../components/common/CustomTable/CustomTable';
import { RoutesMap } from "../../types";
import { useNavigate } from "react-router-dom";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { ConceptCategoryModal } from "./ConceptCategoryModal";
import { useConceptCategoryStore } from "../../hooks/useConceptCategoryStore";
import { ConceptCategory } from "../../types/slices/conceptCategoryType";
import { useSelector } from "../../redux/store";
import { selectHeaderState } from "../../redux/slices/headerSlice";

const columns = [
    { type: 'options', field: 'options', label: 'Opciones', align:'center' },
    { type: 'text', field: 'name', label: 'Nombre', align:'center' },
    { type: 'detail', field: 'detail', label: '', align:'center' },
];

export const ConceptCategoryView = () => {

    const navigate = useNavigate();
    const { search } = useSelector(selectHeaderState);

    const {conceptCategories, selectedConceptCategory, status:statusCategory, getConceptCategories, setSelectedConceptCategory, deleteConceptCategory, setActionCategory } = useConceptCategoryStore();

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getConceptCategoriesApi()
    },[search])

    const getConceptCategoriesApi = async() => {
        try {
          setLoading(true);
          const response = await getConceptCategories({search});
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const onAdd = () => {
        setActionCategory('add')
        navigate({ pathname: RoutesMap.CONCEPT_CATEGORY_FORM })
    }
    const onEdit = (rowSelected) => {
        setActionCategory('edit')
        setSelectedConceptCategory(rowSelected)
        navigate({ pathname: RoutesMap.CONCEPT_CATEGORY_FORM })
    }
    const onDelete = (rowSelected) => {
        setActionCategory('delete')
        setSelectedConceptCategory(rowSelected)
        setShowModalConfirm(true);
    }
    const onDetails = (rowSelected) => {
        setActionCategory('detail')
        setSelectedConceptCategory(rowSelected)
        setShowModal(true);
    }

    const onDeleteConfirm = async() => {
        if (selectedConceptCategory.id) {
            const response = await deleteConceptCategory(selectedConceptCategory.id)
            if(response){
                setSelectedConceptCategory({} as ConceptCategory)
                setShowModalConfirm(false)
                getConceptCategoriesApi()
            }else{
                return false;
            }
        }
    }

    return (
        <>
            {/* {statusCategory == ApiStatus.FETCHING && <CustomBackdrop open={true}/>} */}

            <Grid item xs={12}>
                <CustomTable 
                    title={'Concepto de categorias'}
                    columns={columns} 
                    loading={loading}
                    rows={conceptCategories || []}
                    onRowClick={() => {}}
                    hasOptions
                    onAddFn={onAdd}
                    onDetails={onDetails}
                    onDelete={onDelete}
                    onEdit={onEdit}
                />
            </Grid>

            {
                showModalConfirm && (
                    <ModalConfirm
                        open={showModalConfirm}
                        closeModal={()=>{
                            setShowModalConfirm(false)
                            setSelectedConceptCategory({} as ConceptCategory)
                        }}
                        onConfirm={onDeleteConfirm}
                        status2={statusCategory == ApiStatus.FETCHING ? true : false}
                    />
                )
            }
            {
                showModal && (
                    <ConceptCategoryModal
                        open={showModal}
                        closeModal={()=>{
                            setShowModal(false); 
                            setSelectedConceptCategory({} as ConceptCategory)
                        }}
                        rowSelected={selectedConceptCategory}
                    />
                )
            }
        </>
    )
}