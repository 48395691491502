import { useEffect, useState } from 'react';
import ApartmentIcon from '@mui/icons-material/Apartment'
import { IconButton, Menu, MenuItem, Divider, ListItemIcon, ListItemText, CircularProgress, Grid } from '@mui/material'
import { useCalendar } from '../../../hooks/useCalendar';
import { useDepartmentStore } from '../../../hooks/useDepartmentStore';
import { useAuthStore } from '../../../hooks';
import { useReservationStore } from '../../../hooks/useReservationStore';
import { LocalStorageKey, RoutesMap } from '../../../types';
import CachedIcon from '@mui/icons-material/Cached';
import { readLocalStorage, saveLocalStorage } from '../../../toolbox/helpers/local-storage-helpers';
import { Department } from '../../../types/slices/departmentType';
import { useDispatch } from '../../../redux/store';
import { onFetchDepartments } from '../../../redux/slices/departmentSlice';
import { Role } from '../../../types/roles/roleTypes';
import { useRenditionStore } from '../../../hooks/useRenditionStore';
import { MODE_BUILDING_OWNER } from '../../../toolbox/defaults/app';
import { Check } from '@mui/icons-material';
import { useRenditionBuildingStore } from '../../../hooks/useRenditionBuildingStore';

export const ChangeDepartament = (props) => {

  const dispatch = useDispatch();
  const { colorIcon } = props;

  const buildingsStorage = readLocalStorage(LocalStorageKey.BUILDING_OWNER) || [];

  const { user } = useAuthStore()
  const { departments, selectedDepartment, getDepartments, getBuilding, setSelectedDepartment, setSelectedBuildingOwner } = useDepartmentStore()
  const { resetCalendar, addPeopleInvolved } = useCalendar();
  const { getReservations } = useReservationStore()
  const { getRenditionOwner } = useRenditionStore()
  const { getRenditionBuildingOwner } = useRenditionBuildingStore()

  const [openMenu, setOpenMenu] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const sessionOwner:any = user?.data
  const pathname = window.location.pathname;
  
  useEffect(() => {    
    validateDepartments();
  },[])

  useEffect(() => {    
    if(departments) SelectDefaultDepartament()
  }, [departments])

  const validateDepartments = () => {
    if (departments?.length < 1) {
      setSelectedDepartment({} as Department)
      const departmentsStorage = readLocalStorage(LocalStorageKey.DEPARTMENTS_OWNER);
      if (departmentsStorage && departmentsStorage.length > 0) {
        dispatch(onFetchDepartments(departmentsStorage))
      } else {
        dispatch(onFetchDepartments([]))
      }
    }
  }

  const SelectDefaultDepartament = () => {
    if (departments.length > 0) {
      let selectDepartmentLogic = departments[0];

      const departmentInfo = readLocalStorage(LocalStorageKey.DEPARTMENT_INFO);
      if (departmentInfo?.id) {
        const matchDepartment = departments.find((value) => { return value.id === departmentInfo.id });
        if (matchDepartment) selectDepartmentLogic = matchDepartment;
      }
      
      const buildingsInfo = readLocalStorage(LocalStorageKey.BUILDING_OWNER) || [];
      let selectBuildingLogic = buildingsInfo[0];
      
      if (buildingsInfo.length > 0) {
        buildingsInfo.map((item:any) => {
          const match = item.departments.find((value: any) => { return value?.id === selectDepartmentLogic?.id })
          
          if (match?.id) selectBuildingLogic = item;
        })
      }
      setSelectedBuildingOwner(selectBuildingLogic)

      const data = {
        departament: selectDepartmentLogic,
        owner: sessionOwner,
      }

      addPeopleInvolved(data)
      setSelectedDepartment(selectDepartmentLogic || {} as Department)
    }
  }

    const handleOpen = (event) => {
      setOpenMenu(event.currentTarget);
    };

    const syncDepartaments = async() => {
      try {
        let idowner:any;
        switch (user.userType) {
          case Role.OWNER:
            idowner = sessionOwner.id;
            break;
          case Role.FAMILY:
            idowner = sessionOwner.idowner;
            break;
        }
        setLoading(true);        
        const response = await getDepartments({ idowner })
        if (MODE_BUILDING_OWNER === "building") await getBuilding(idowner)
        resetCalendar()
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    const selectDepartament = (departament,building?:any) => {
      resetCalendar();
      setOpenMenu(false);
      const data = {
        departament: departament,
        owner: sessionOwner,
      }
      addPeopleInvolved(data) //calendario
      saveLocalStorage(LocalStorageKey.DEPARTMENT_INFO , departament);
      setSelectedDepartment(departament)      
      building && setSelectedBuildingOwner(building)

      const paramsreservation:any = {
        iddepartment: departament.id,
        idowner: sessionOwner.id,
        status: 1
      }
      
      switch (pathname) {
        case RoutesMap.RESERVATION:
          getReservations(paramsreservation)
          break
        case RoutesMap.RENDITION:
          if (MODE_BUILDING_OWNER === "building") {
            getRenditionBuildingOwner({iddepartment: departament?.id, idbuilding: building?.idbuilding})
          } else {
            getRenditionOwner(departament?.id, sessionOwner.id)
          }
          break
        default:
          break
      }
    }

    return (
        <>
        <IconButton onClick={handleOpen}>
            <ApartmentIcon sx={{fontSize:'22px', color:colorIcon}} />
        </IconButton>
        <Menu
          id="basic-menu"
          open={Boolean(openMenu)}
          anchorEl={openMenu}
          onClose={()=>{setOpenMenu(null)}}
          MenuListProps={{
          'aria-labelledby': 'basic-button',
          }}
        >
          <div style={{paddingInline: "1rem"}}>
            <MenuItem onClick={()=>syncDepartaments()}>
                <ListItemIcon>
                    <CachedIcon />
                </ListItemIcon>
                <ListItemText primary="Sincronizar" />
            </MenuItem>
            <Divider/>
            {
                loading ? (
                    <Grid sx={{textAlign:'center'}}>
                        <CircularProgress size={28} color="inherit" /> 
                    </Grid>
                ) : (
                  MODE_BUILDING_OWNER === "building" ? (
                    <>
                    {buildingsStorage.length > 0 && buildingsStorage.map((item: any) => {
                      return (
                        <div style={{ textAlign: "center" }} key={item.id}>
                          <label style={{ color: "#212D39", fontWeight:"bolder" }} >{item?.name_building || "Edificio" }</label>
                          <Divider/>
                          {
                            item?.departments?.length > 0 && item?.departments?.map((itemDepartment) => (
                              <MenuItem onClick={() => { selectDepartament(itemDepartment,item) }}>
                                {
                                  selectedDepartment && selectedDepartment.id == itemDepartment.id && (
                                    <ListItemIcon><Check /></ListItemIcon>
                                  )
                                }
                                {itemDepartment.name}
                              </MenuItem>
                            ))
                          }
                          <Divider/>
                        </div>
                        )
                      })}
                    </>
                    ) : (
                      departments && departments.length > 0 ? (
                        departments.map((item) => (
                          <MenuItem key={item.id} onClick={() => { selectDepartament(item) }}>
                            {
                              selectedDepartment && selectedDepartment.id == item.id && (
                                <ListItemIcon><Check /></ListItemIcon>
                              )
                            }
                            {item.name}
                          </MenuItem>
                        ))
                      ) : null
                    )
                )
            }
          </div>
        </Menu>
        </>
    )
}