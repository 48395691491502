import { Formik } from "formik";
import { Modal } from "../../components/common/Modal/Modal";
import { ModalBody } from "../../components/common/Modal/ModalBody";
import { ModalHeader } from "../../components/common/Modal/ModalHeader";
import { Divider, Typography, Accordion, AccordionSummary, AccordionDetails, ListItemButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useSeasonStore } from "../../hooks/useSeasonStore";
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

type Values = {
    id: number | undefined,
    name: string
    start_date: string
    end_date: string
    quantity_division: number | undefined
    remaining_division: number | undefined
    iddepartment: number | undefined
    idcompany: number | undefined
}

export const SeasonModal: React.FC<any> = (props): JSX.Element | any => {

    const { open, closeModal, rowSelected, clearState } = props;

    const { seasonSelected } = useSeasonStore();
    const { departments, getDepartments, setSelectedDepartment, selectedDepartment } = useDepartmentStore()

    const [data, setData] = useState<Values>({
        id: rowSelected ? rowSelected.id :'',
        name: rowSelected ? rowSelected.name :'',
        start_date: rowSelected ? rowSelected.start_date :'',
        end_date: rowSelected ? rowSelected.end_date :'',
        quantity_division: rowSelected ? rowSelected.quantity_division :'',
        remaining_division: rowSelected ? rowSelected.remaining_division :'',
        iddepartment: rowSelected ? rowSelected.iddepartment :'',
        idcompany: rowSelected ? rowSelected.idcompany :'',
    });

    useEffect(() => {
        getDepartments()
    },[])

    useEffect(() => {
        if(departments && seasonSelected.id){
            setCustomDepartament(seasonSelected.iddepartment, departments)
        }
    },[departments, seasonSelected.id])

    function setCustomDepartament(iddepartament, departments): any {
        const data = departments && departments.find(departament => departament.id === iddepartament);
        console.log(data)
        return setSelectedDepartment(data);
    }


    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
            <ModalHeader
                text={'Detalle de la Temporada'}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Información General</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ListItemButton>
                        <Typography sx={{ width: '40%', flexShrink: 0 }}>Nombre</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{rowSelected.name}</Typography>
                        </ListItemButton>
                        <Divider />
                        <ListItemButton>
                        <Typography sx={{ width: '40%', flexShrink: 0 }}>Fecha de inicio</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{rowSelected.start_date}</Typography>
                        </ListItemButton>
                        <Divider />
                        <ListItemButton>
                        <Typography sx={{ width: '40%', flexShrink: 0 }}>Fecha fin</Typography>
                        <Typography sx={{ color: 'text.secondary' }}>{rowSelected.end_date}</Typography>
                        </ListItemButton>
                        <Divider />
                        <Divider />
                        
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Departamentos</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            rowSelected.departments && (rowSelected.departments).length > 0
                            ?
                            (rowSelected.departments).map((item:any)=>{

                                return (
                                    <>
                                    <ListItemButton>
                                        <Typography sx={{ width: '40%', flexShrink: 0 }}>Nombre</Typography>
                                        <Typography sx={{ color: 'text.secondary' }}>{item.name}</Typography>
                                    </ListItemButton>
                                    <Divider />
                                    </>
                                )
                            })
                            :
                            <Typography sx={{ color: 'text.secondary' }}>No cuenta con departamentos afiliados</Typography>
                        }
                        

                    </AccordionDetails>
                </Accordion>
            </ModalBody>
        </Modal>
    )
}