import { useEffect, useState } from "react";
import { Grid } from '@mui/material'
import CustomTable from '../../components/common/CustomTable/CustomTable';
import { RoutesMap } from "../../types";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { DepartamentModal } from "./DepartamentModal";
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { Department } from "../../types/slices/departmentType";
import { useNavigate } from "react-router-dom";
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { useSelector } from "../../redux/store";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { ApiStatus } from "../../types/api/status";

const columns = [
    { type: 'options',  field: 'options',        align:'center',     label: 'Opciones' },
    { type: 'text',     field: 'name',           align:'center',     label: 'Nombre' },
    { type: 'text',     field: 'address',        align:'center',     label: 'Dirección' },
    { type: 'text',     field: 'location_url',   align:'center',     label: 'Locación' },
    { type: 'text',     field: 'capacitance',    align:'center',     label: 'Capacidad' },
    { type: 'text',     field: 'num_owner',      align:'center',     label: 'N° Max de Propietarios' },
    { type: 'detail',   field: 'detail',         align:'center',     label: '' }
];

export const DepartamentView = () => {
    
    const navigate = useNavigate();
    const { search } = useSelector(selectHeaderState);

    const { status: statusApiDepartment ,departments, getDepartments, setSelectedDepartment, deleteDepartment, setActionDepartment } = useDepartmentStore()

    const [ rows , setRows ]                        = useState<Department[]>([])
    const [ rowSelected, setRowSelected ]           = useState<Department>({} as Department);
    const [ showModal, setShowModal ]               = useState<boolean>(false);
    const [ showModalConfirm, setShowModalConfirm ] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
      setSelectedDepartment({} as Department)
      getDepartmentsApi();
    },[search])

    useEffect(() => {
        if (departments) {
          setRows(departments)
          return
        }
        setRows([])
    }, [departments])

    const getDepartmentsApi = async() => {
        try {
          setLoading(true);
          const response = await getDepartments({search});
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const onAdd = () => {
        setActionDepartment('add')
        navigate({ pathname: RoutesMap.DEPARTAMENT_FORM })
    }
    
    const onEdit = (rowSelected) => {
        setActionDepartment('edit')
        const a_idowner   = rowSelected.owners.map(owner => owner.idowner)
        const a_idservice = rowSelected.services.map(service => service.idservices)
        setSelectedDepartment({
            id: rowSelected.id,
            name: rowSelected.name,
            address: rowSelected.address,
            location_url: rowSelected.location_url,
            capacitance: rowSelected.capacitance,
            idsowner: a_idowner,
            num_rooms: rowSelected.detail.num_rooms,
            num_bathrooms: rowSelected.detail.num_bathrooms,
            num_kitchen: rowSelected.detail.num_kitchen,
            dimensions: rowSelected.detail.dimensions,
            check_in: rowSelected.detail.check_in,
            check_out: rowSelected.detail.check_out,
            idservices: a_idservice,
            idcompany: rowSelected.idcompany,
            num_owner: rowSelected.num_owner,
            owners: rowSelected.owners,
            detail: rowSelected.detail,
            services: rowSelected.services,
            status: rowSelected.status
        })
        navigate({ pathname: RoutesMap.DEPARTAMENT_FORM }) 
    }
    
    const onDelete = (rowSelected) => {
        setActionDepartment('delete')
        setRowSelected(rowSelected)
        setShowModalConfirm(true);
    }

    const onDeleteConfirm = async() => {
        const response = await deleteDepartment(rowSelected.id)
        if(response){
            setSelectedDepartment({} as Department)
            setShowModalConfirm(false)
            getDepartmentsApi()
        }else{
            return false;
        }
    }

    const onDetails = (rowSelected) => {
        setActionDepartment('detail')
        setRowSelected(rowSelected)
        setShowModal(true);
    }

    const onHelper = (rowSelected) => {
        setSelectedDepartment(rowSelected)
        navigate({ pathname: RoutesMap.OWNER_BY_DEPARTAMENT })
    }

    return (
        <>
            {/* {  statusApiDepartment === ApiStatus.FETCHING && <CustomBackdrop open={true} /> } */}
            
            <Grid item xs={12}>
                <CustomTable 
                    title={'Departamentos'}
                    columns={columns} 
                    loading={loading}
                    rows={rows || []}
                    onRowClick={() => {}}
                    hasOptions
                    onAddFn={onAdd}
                    onDetails={onDetails}
                    onDelete={onDelete}
                    onEdit={onEdit}
                    onHelper={onHelper}
                    icon_helper={<Diversity3Icon fontSize="small" />}
                    tooltip_helper={'Propietarios'}
                />
            </Grid>
            {
                showModalConfirm && (
                    <ModalConfirm
                        open={showModalConfirm}
                        closeModal={()=>{setShowModalConfirm(false)}}
                        onConfirm={onDeleteConfirm}
                        onCancel={()=>{setShowModalConfirm(false)}}
                        status2={statusApiDepartment == ApiStatus.FETCHING ? true : false}
                    />
                )
            }
            {
                showModal && (
                    <DepartamentModal
                        open={showModal}
                        closeModal={()=>{setShowModal(false)}}
                        rowSelected={rowSelected}
                    />
                )
            }
        </>
    )
}