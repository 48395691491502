import { ApiStatus } from "../types/api/status"
import { useDispatch, useSelector } from "../redux/store"
import { changeStatus, onFetchActiveSeason, onFetchSeason, onFetchSeasonGroup, onSetSelectedSeason, selectSeasonState, setAction, onSetSelectedSeasonByOwner } from "../redux/slices/seasonSlice"
import { SeasonAPI } from "../apis/SeasonAPI"
import { SeasonDataForm } from "../types/api/Season.type"
import { Season } from "../types/slices/seasonType"
import { CustomSnackbar } from "../components/common/CustomSnackbar/CustomSnackbar"
import { readLocalStorage } from "../toolbox/helpers/local-storage-helpers"
import { LocalStorageKey } from "../types"

export const useSeasonStore = () => {

  const dispatch = useDispatch()
  const { seasons, seasonsGroups, seasonSelected, seasonSelectedByOwner, status, action } = useSelector(selectSeasonState)

  const userData = readLocalStorage(LocalStorageKey.USER_DATA)
  const idcompany = userData?.data?.idcompany

  const getSeasons = async (data?: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await SeasonAPI.getSeasons(idcompany,data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      const { detail } = response.data
      dispatch(onFetchSeason(detail))
      dispatch(changeStatus(ApiStatus.FETCHED))
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const getSeasonsByOwner = async (idowner: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await SeasonAPI.getSeasonsByOwner(idowner)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      const { detail } = response.data
      dispatch(onSetSelectedSeasonByOwner(detail))
      dispatch(changeStatus(ApiStatus.FETCHED))
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const getSeasonsGroup = async (data?: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await SeasonAPI.getSeasonsGroup(idcompany, data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      const { detail } = response.data
      dispatch(onFetchSeasonGroup(detail))
      dispatch(changeStatus(ApiStatus.FETCHED))
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const getSeasonsByDepartment = async (data?: any) => {
    try {
      dispatch(onFetchSeason([]))
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await SeasonAPI.getSeasonsByDepartment(idcompany,data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      const { detail } = response.data
      dispatch(onFetchSeason(detail))
      dispatch(changeStatus(ApiStatus.FETCHED))
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const getActiveSeasons = async () => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await SeasonAPI.getActiveSeasons()
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      const { detail } = response.data
      dispatch(onFetchActiveSeason(detail))
      dispatch(changeStatus(ApiStatus.FETCHED))
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const createSeason = async (data: SeasonDataForm) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await SeasonAPI.createSeason(data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const createSeasonMassive = async (data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await SeasonAPI.createSeasonMassive(data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const setSelectedSeason = (season: Season) => {
    try {
      dispatch(onSetSelectedSeason(season))
    } catch (error) {
      console.log(error)
    }
  }

  const editSeason = async (idseason: number, data: SeasonDataForm) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await SeasonAPI.editSeason(idseason, data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const updateMassive = async (data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await SeasonAPI.updateMassive(data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        return CustomSnackbar('error', response.data.message || "");
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const deleteSeason = async (idseason: number) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await SeasonAPI.deleteSeason(idseason)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('error', response.data.message || "");
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const deleteSeasonMassive = async (data: any) => {
    try {
      dispatch(changeStatus(ApiStatus.FETCHING))
      const response = await SeasonAPI.deleteSeasonMassive(idcompany, data)
      if (!response?.status) {
        dispatch(changeStatus(ApiStatus.FETCHED))
        CustomSnackbar('error', response.data.message || "");
        return false;
      }
      dispatch(changeStatus(ApiStatus.FETCHED))
      CustomSnackbar('success', response.data.message  || "");
      return true;
    } catch (error) {
      console.log(error)
      return dispatch(changeStatus(ApiStatus.FETCHED))
    }
  }

  const setActionSeason = (state) => {
    try {
        dispatch(setAction(state))
    } catch (error) {
        console.log(error)
    }
  }

  return {
    //states
    seasons,
    seasonsGroups,
    seasonSelected,
    seasonSelectedByOwner,
    status,
    action,

    //actions
    getSeasons,
    getSeasonsByOwner,
    getSeasonsGroup,
    getSeasonsByDepartment,
    getActiveSeasons,
    createSeason,
    createSeasonMassive,
    setSelectedSeason,
    editSeason,
    updateMassive,
    deleteSeason,
    setActionSeason,
    deleteSeasonMassive,
  }
}