import { FC, useEffect, useState } from "react"
import { Grid } from '@mui/material'
import CustomTable from "../../components/common/CustomTable/CustomTable"
import { RoutesMap } from "../../types";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { useSelector } from "../../redux/store";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import { useAssignmentStore } from "../../hooks/useAssignmentStore";
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { DetailModalHistory } from "./components/DetailModalHistory";

const columns = [
  // { type: 'options', field: 'options', label: 'OPCIONES' },
  { type: 'text', field: 'name_assignor', label: 'CEDENTE' },
  { type: 'text', field: 'name_assignee', label: 'CESIONARIO' },
  { type: 'text', field: 'name_assignee', label: 'TIPO',
  format: (row) =>  row?.idowner_assignee ? 'Propietario' : 'Externo'},
  { type: 'text', field: 'name_department', label: 'DEPARTAMENTO' },
  { type: 'text', field: 'created_date', label: 'FECHA' },
  { type: 'text', field: 'isconfirmed', label: 'PROGRESO' },
  { type: 'detail', field: 'detail', label: '', align:'center' },
];

export const CessionHistory : FC = () => {
  const { search } = useSelector(selectHeaderState);

  const { historyAssignments, selectedHistoryAssignment, getHistoryAssignment, setSelectedHistoryAssignment, deleteAssignment } = useAssignmentStore();

  const [rowSelected, setRowSelected] = useState<any>('');
  const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
  const [loading, setLoading] = useState(false); 
  const [ showModalDetails, setShowModalDetails] = useState<boolean>(false);

  useEffect(()=>{
    getHistoryAssignmentApi();
  },[search])

  const getHistoryAssignmentApi = async() => {
    try {
      setLoading(true);
      const response = await getHistoryAssignment({ search });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  
  const onDelete = async (rowSelected) => {
    if (rowSelected.isconfirmed == "Pendiente") {
      setShowModalConfirm(true);
      setSelectedHistoryAssignment(rowSelected);
    } else {
      CustomSnackbar('error','No se puede eliminar una cesión aprobada o eliminada.')
    }
  }

  const onConfirmDelete = async () => {
    setLoading(true);
    const response = await deleteAssignment(selectedHistoryAssignment.id);
    setShowModalConfirm(false);
    getHistoryAssignmentApi();
  }

  const onDetails = (rowSelected) => {
    // setRowSelected(rowSelected);
    setSelectedHistoryAssignment(rowSelected);
  }

  const viewDetails = (rowSelected) => {
    setLoading(true);
    setShowModalDetails(true);
    setSelectedHistoryAssignment(rowSelected);
    setLoading(false)
  }

  return (
    <>
      <Grid item xs={12}>
        <CustomTable
          title={'Listado de historial'}
          columns={columns} 
          rows={historyAssignments || []}
          loading={loading}
          onRowClick={() => {}}
          onDetails={viewDetails}
          // hasOptions
          // onDetails={onDetails}
          // onDelete={onDelete}
        />
      </Grid>
      {
        showModalConfirm && (
          <ModalConfirm
            open={showModalConfirm}
            closeModal={()=>{setShowModalConfirm(false)}}
            rowSelected={rowSelected}
            onConfirm={()=>onConfirmDelete()}
            onCancel={()=>{setShowModalConfirm(false)}}
          />
        )
      }
       {
        showModalDetails && (
          <DetailModalHistory
           open={showModalDetails}
           closeModal={()=>setShowModalDetails(false)}
          />
        )
      }
    </>
  )
}