import { Modal } from "../../../components/common/Modal/Modal";
import { ModalHeader } from "../../../components/common/Modal/ModalHeader";
import { ModalBody } from "../../../components/common/Modal/ModalBody";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import moment from "moment";

export const ReservationAdminModal: React.FC<any> = (props): JSX.Element | any => {

    const { open, closeModal, rowSelected, clearState } = props;

    console.log(rowSelected)

    return (
        <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick handle size="sm">
            <ModalHeader
                text={'Detalle de la Reserva'}
                className='positionElements'
                onCancel={closeModal}
                clearState={clearState}
            >
            </ModalHeader>
            <ModalBody>
                <Grid item padding='0px 0px 10px 0px' justifyContent='center' alignContent='center' alignItems='center' sx={{width:'100%'}}>
                    <Grid xs={12}>
                        <Typography color='#101010' sx={{ fontSize:15, fontWeight:400}}>
                            {'Resumen de la reservación'}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item justifyContent='center' alignContent='center' alignItems='center' 
                    sx={{
                        width:'100%',
                        padding:{ xs: '0px', sm: '0px', md:'20px 50px 20px 50px', lg:'20px 50px 20px 50px', xl:'20px 50px 20px 50px'},
                }}>
                    <Grid display='flex' mb={1}>
                        <Typography color='#000000' sx={{ fontWeight:600, pr:2, fontSize:'14px' }}>Departamento:</Typography>
                        <Typography sx={{fontSize:'14px'}}>{rowSelected.name_department}</Typography>
                    </Grid>
                    <Grid display='flex' mb={1}>
                        <Typography color='#000000' sx={{ fontWeight:600, pr:2, fontSize:'14px' }}>Propietario:</Typography>
                        <Typography  sx={{fontSize:'14px'}}>{rowSelected.name_owner}</Typography>
                    </Grid>
                    <Grid display='flex' mb={1}>
                        <Typography color='#000000' sx={{ fontWeight:600, pr:2, fontSize:'14px' }}>Solicitante:</Typography>
                        <Typography  sx={{fontSize:'14px'}}>
                            {rowSelected.name_invited ? rowSelected.name_invited : rowSelected.name_owner}
                        </Typography>
                    </Grid>
                    {
                        rowSelected.name_reservationSpecial && (
                            <Grid display='flex' mb={1}>
                                <Typography color='#000000' sx={{ fontWeight:600, pr:2, fontSize:'14px' }}>Fecha especial:</Typography>
                                <Typography  sx={{fontSize:'14px'}}>{rowSelected.name_reservationSpecial}</Typography>
                            </Grid>
                        )
                    }
                </Grid>
                <Grid display='flex' justifyContent='center' alignItems='center' sx={{ width:'100%'}} pb={3}>
                    <Card sx={{ width: '600px', display:'flex', justifyContent:'center', alignItems:'center', border:'solid 1px #808080', cursor:'pointer' }}>
                        <Grid sx={{borderRight: '.5px solid #808080'}}>
                            <CardContent>
                                <Typography color='#808080' sx={{ fontWeight:400, fontSize:'14px'}}>
                                LLegada:
                                </Typography>
                                <Grid sx={{padding:'0px 30px 0px 30px', textAlign:'center'}}>
                                    <Typography color='#212D39' sx={{ fontWeight:600, fontSize:'21px'}}>
                                        {rowSelected.startDate}
                                    </Typography>
                                    {/* <Typography color='#212D39' sx={{ fontWeight:400, fontSize:'14px'}}>
                                        {rowSelected.check_in ? rowSelected.check_in + ' horas.' : '-----'}
                                    </Typography> */}
                                </Grid>
                            </CardContent>
                        </Grid>
                        <Grid sx={{borderLeft: '.5px solid #808080'}}>
                            <CardContent>
                                <Typography color='#808080' sx={{ fontWeight:400, fontSize:'14px'}}>
                                Salida :
                                </Typography>
                                <Grid sx={{padding:'0px 30px 0px 30px', textAlign:'center'}}>
                                    <Typography color='#212D39' sx={{ fontWeight:600, fontSize:'21px'}}>
                                        {rowSelected.endDate}
                                    </Typography>
                                    {/* <Typography color='#212D39' sx={{ fontWeight:400, fontSize:'14px'}}>
                                        {rowSelected.check_out ? rowSelected.check_out + ' horas.' : '-----'}
                                    </Typography> */}
                                </Grid>
                            </CardContent>
                        </Grid>
                    </Card>
                </Grid>
            </ModalBody>
        </Modal>
    )
}