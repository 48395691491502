import React, { useEffect, useState } from "react";
import { Modal } from "../../components/common/Modal/Modal";
import { ModalBody } from "../../components/common/Modal/ModalBody";
import { ModalHeader } from "../../components/common/Modal/ModalHeader";
import { ModalFooter } from "../../components/common/Modal/ModalFooter";
import { Formik } from "formik";
import { Grid, Divider, TextField, Autocomplete, Typography, InputAdornment } from "@mui/material"
import { CustomSnackbar } from "../../components/common/CustomSnackbar/CustomSnackbar";
import { useOwnerStore } from "../../hooks/useOwnerStore";
import { Owner } from "../../types/slices/ownerType";
import { useDepartmentStore } from "../../hooks/useDepartmentStore";

type Values = {
  idowner: number|string,
  participation_percentage: number,
}

export const OwnerByDepartamentModal: React.FC<any> = (props): JSX.Element | any => {
  const { open, closeModal, row, owners } = props;

  const { ownersDistincs, selectedOwnerAdd, setSelectedOwnerAdd, getOwnersFilter } = useOwnerStore();
  const { departmentByOwner, addDepartmentOwner } = useDepartmentStore();

  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingpercentage, setLoadingpercentage] = useState<boolean>(false);

  const [checked, setChecked] = useState<string>('');
  const [percentage, setPercentage] = useState<any>('');

  const [data, setData] = useState<Values>({
    idowner: "",
    participation_percentage: 10,
  });

  useEffect(() => {
    getOwnersFilterApi();
  }, [])

  const getOwnersFilterApi = async () => {
    try {      
      setLoading(true);
      const response = await getOwnersFilter(departmentByOwner);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  const handleChangeSelectOwner = (newValue:Owner|null, setFieldValue:any) => {
    setChecked('');
    if(row.num_owner == 0) return CustomSnackbar('warning', 'El N° de propiertarios no puede ser 0.')
    if(row.owners.length >= row.num_owner) return CustomSnackbar('warning', 'La cantidad de propietarios para el departamento esta completa.')

    setSelectedOwnerAdd(newValue || {} as Owner);
    setFieldValue('idowner', newValue ? newValue.id : "")
  }

  const validateForm = (values) => {
    let errors: any = {};
    if(!values.idowner) errors.idowner = "Campo requerido";
    
    return errors;
  }

  const handleKeyDown = (event) => {
    const keyCode = event.keyCode || event.which;
    if (keyCode === 189 || keyCode === 109 || parseInt(event.target.value + event.key) > 100) {
      event.preventDefault();
    }
  };

  const showPercentageAvailable = async(e, values) => {
    e.preventDefault();

    if(!values.idowner)return CustomSnackbar('warning', 'Asegurese de seleccionar un propietario.')

    setLoadingpercentage(true);
    const data = {
      idowner: values.idowner,
      iddepartment: row.detail.iddepartment,
      participation_available: true
    }
    const response:any = await addDepartmentOwner(data);
    if(response?.data){
      let filter:any = [];

      const array = response?.data?.detail?.detail?.valid_values || []
      if(array.length > 0){
        array.map((item)=>{
          let newitem = item + '%';
          filter.push(newitem)
        })
      }
      setLoadingpercentage(false);
      setPercentage(filter)
    }
    setLoadingpercentage(false);
  }
  
  const onSubmit = async (formData) => {
    setLoadingSubmit(true); 
    const data = {
      ...formData,
      iddepartment: row.detail.iddepartment,
      participation_available: true,
      accept_create: true,
    }
    const response:any = await addDepartmentOwner(data);
    setLoadingSubmit(false);
    
    if (response?.status)  closeModal(true);
    
  }

  return ( 
    <>
      <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="sm">
        <ModalHeader
          text={"Añadir un propietario"}
          className="positionElements"
          onCancel={closeModal}
        />
        <ModalBody>
          <Formik
            initialValues={data}
            enableReinitialize
            validate={ (values) => validateForm(values) }
            onSubmit={onSubmit}
          >
          {({values, errors, touched, handleSubmit, setFieldValue, handleChange}) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Typography variant="subtitle1" className="custom-input"><b>{"Propietario"}:</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={selectedOwnerAdd?.id ? selectedOwnerAdd : null}
                    options={ownersDistincs || []}
                    loading={loading}
                    getOptionLabel={(option) => option?.name || ""}
                    fullWidth
                    isOptionEqualToValue={(option, value) => option?.name === value?.name}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        size="small"
                        error={errors.idowner && touched.idowner ? true : false}
                        helperText={errors.idowner && touched.idowner ? errors.idowner : ''}
                      />
                    }
                    onChange={(e, newValue) => {
                      handleChangeSelectOwner(newValue,setFieldValue);
                    }}
                  />
                  </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography variant="subtitle1" className="custom-input"><b>{"Porcentaje"}:</b></Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                  <TextField
                      id="participation_percentage"
                      type="number" 
                      name="participation_percentage"
                      fullWidth
                      size="small"
                      value={values.participation_percentage}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        inputProps: {
                            min: 0,
                            max: 100,
                            step: 0.01,
                        }
                      }}
                      onKeyDown={handleKeyDown}
                  />
                  </Grid>
                </Grid>

                <Grid item xs={12} container alignItems="center" justifyContent="center" marginTop='20px' marginBottom='25px' >
                  <Grid item xs={4}>
                    <button onClick={(e)=>{showPercentageAvailable(e, values)}}>ver porcentaje disponible</button>
                  </Grid>
                  <Grid item xs={8} sx={{paddingLeft:'10px'}}>
                    {
                      percentage && percentage.length > 0 && !loadingpercentage && (
                        <Typography color='#575454' sx={{ fontSize:15, fontWeight:100}}>
                          {/* {percentage.join(" - ")} */}
                          {percentage[percentage.length-1]}
                        </Typography>
                      )
                    }
                    {
                      loadingpercentage && (
                        <Typography color='#575454' sx={{ fontSize:15, fontWeight:100}}>
                          Espere un momento...
                        </Typography>
                      )
                    }
                  </Grid>
                </Grid>

                
                <Divider />
                <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'center'}}>
                  <ModalFooter 
                    confirmText={'Guardar'}
                    onConfirm={handleSubmit}
                    loadingConfirmText={loadingSubmit}
                    cancelText={"Cancelar"}
                    onCancel={closeModal}
                  />
                </Grid>
              </form>
            )
          }} 
          </Formik>
        </ModalBody>
      </Modal>
    </>
  )
}