import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { RenditionSpentIndividual, RenditionSpentIndividualType } from "../../types/slices/renditionSpentIndividual";
import { RootState } from "../store";

const initialState: RenditionSpentIndividualType = {
    status: ApiStatus.FETCHED,
    renditionSpentIndividuals: [],
    errorMessage: undefined,
    selectedRenditionSpentIndividuals: {} as RenditionSpentIndividual
}

const renditionSpentIndividualSlice = createSlice({
    name: 'renditionSpentIndividual',
    initialState,
    reducers: {
        onFetchRenditionSpentIndividual (state, { payload }: { payload: RenditionSpentIndividual[] }) {
            state.status       = ApiStatus.FETCHED
            state.renditionSpentIndividuals = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedRenditionSpentIndividual (state, { payload }: { payload: RenditionSpentIndividual }) {
            state.selectedRenditionSpentIndividuals = payload
        }
    }
})

export const selectRenditionSpentIndividualState = (state: RootState) => state.renditionSpentIndividualSlice
export default renditionSpentIndividualSlice.reducer

export const {
    onFetchRenditionSpentIndividual,
    changeStatus,
    onSetSelectedRenditionSpentIndividual
} = renditionSpentIndividualSlice.actions