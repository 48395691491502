import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { Notification, NotificationSliceType } from "../../types/slices/notificationType";
import { RootState } from "../store";

const initialState: NotificationSliceType =
{
  status: ApiStatus.FETCHED,
  notifications: [],
  counter: 0,
  errorMessage: undefined,
}

const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState,
  reducers: {
    onFetchNotifications (state, { payload }: { payload: Notification[] }) {
      state.status       = ApiStatus.FETCHED
      state.notifications  = payload
      state.errorMessage = undefined
    },
    onCounter (state, { payload }: { payload: number}) {
        state.status           = ApiStatus.FETCHED
        state.counter          = payload
        state.errorMessage     = undefined
    },
    onChangeToSeenStatus (state, { payload } : { payload: Notification}) {
        state.status           = ApiStatus.FETCHED
        state.notifications    = state.notifications.map(notification => {
            if (notification.id === payload.id){
                return {...notification, id: payload.id, date_seen: payload.date_seen, date_sent: payload.date_sent}
            }
            return notification
        })
    },
    onDeleteNotification (state, { payload } : { payload: number}) {
        state.status          = ApiStatus.FETCHED
        state.notifications   = state.notifications.filter(notification => notification.id !== payload )
    },
    changeStatus (state, { payload }: { payload: ApiStatus }) {
        state.status = payload
    },
  }
})

export const selectNotificationState = (state: RootState) => state.notificationSlice

export default notificationSlice.reducer

export const {
    onFetchNotifications,
    onCounter,
    onChangeToSeenStatus,
    onDeleteNotification,
    changeStatus
} = notificationSlice.actions