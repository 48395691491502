import { api } from './configs/axiosConfigs'

export const AssignmentAPI = {

    getAssignment: async (data?: any) => {

    const response = await api.get(
        '/assignment',
          {
            params: {
              ...data
            }
          }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })  
        return response
    },
    
    getHistoryAssignment: async (data?: any) => {
      const response = await api.get(
        '/assignment/history',
          {
            params: {
              ...data
            }
          }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })  
        return response
    },

    createAssignment: async (data: any) => {
        const response = await api.post(
            '/assignment/register', data
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    acceptAssignment: async ( id) => {
        const response = await api.post(
            `/assignment/acceptAssignment/${id}`
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },

    editAssignment: async (id: number, data) => {
        const response = await api.patch(
        `/assignment/${id}`,
        { ...data }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },

    deleteAssignment: async (id: number) => {
        const response = await api.delete(
        `/assignment/${id}`
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    }
}