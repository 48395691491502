import { useEffect, useState } from "react";
import { Grid } from '@mui/material'
import CustomTable from '../../components/common/CustomTable/CustomTable';
import { RoutesMap } from "../../types";
import { useNavigate } from "react-router-dom";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { SeasonModal } from "./SeasonModal";
import { useSeasonStore } from "../../hooks/useSeasonStore";
import { Season } from "../../types/slices/seasonType";
import { ApiStatus } from "../../types/api/status";
import { Department } from "../../types/slices/departmentType";
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { useSelector } from "../../redux/store";
import { selectHeaderState } from "../../redux/slices/headerSlice";
import moment from "moment";

const columns = [
    { type: 'options', field: 'options', label: 'Opciones' },
    { type: 'text', field: 'name', label: 'Nombre' },
    { type: 'text', field: 'start_date', label: 'Fecha inicio', align:'center', format:(row) => row.start_date && moment(row.start_date).format('YYYY-MM-DD') },
    { type: 'text', field: 'end_date', label: 'Fecha fin', align:'center', format:(row) => row.end_date && moment(row.end_date).format('YYYY-MM-DD') },
    { type: 'detail', field: 'detail', label: '', align:'center' },
];

export const SeasonView = () => {

    const navigate = useNavigate();
    const { search } = useSelector(selectHeaderState);

    const {seasonsGroups, seasons, seasonSelected, status:statusSeason, getSeasonsGroup, getSeasons, getActiveSeasons, setSelectedSeason, deleteSeason, setActionSeason, deleteSeasonMassive } = useSeasonStore();
    const { setSelectedDepartment } = useDepartmentStore()

    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getSeasonsApi()
    },[search])

    const getSeasonsApi = async() => {
        try {
          setLoading(true);
          const response = await getSeasonsGroup({search});
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const onAdd = () => {
        setActionSeason('add')
        navigate({ pathname: RoutesMap.SEASON_FORM })
    }
    const onEdit = (rowSelected) => {
        setActionSeason('edit')
        setSelectedSeason(rowSelected)
        navigate({ pathname: RoutesMap.SEASON_FORM })
    }
    const onDelete = (rowSelected) => {
        setActionSeason('delete')
        setSelectedSeason(rowSelected)
        setShowModalConfirm(true);
    }
    const onDetails = (rowSelected) => {
        setActionSeason('detail')
        setSelectedSeason(rowSelected)
        setShowModal(true);
    }

    const onDeleteConfirm = async() => {
        if (seasonSelected.id) {
            const response = await deleteSeasonMassive(seasonSelected)
            if(response){
                setSelectedSeason({} as Season)
                setShowModalConfirm(false)
                getSeasonsApi()
            }else{
                return false;
            }
        }
    }

    return (
        <>

            <Grid item xs={12}>
                <CustomTable 
                    title={'Temporadas'}
                    columns={columns} 
                    loading={loading}
                    rows={seasonsGroups || []}
                    onRowClick={() => {}}
                    hasOptions
                    onAddFn={onAdd}
                    onDetails={onDetails}
                    onDelete={onDelete}
                    onEdit={onEdit}
                />
            </Grid>

            {
                showModalConfirm && (
                    <ModalConfirm
                        open={showModalConfirm}
                        closeModal={()=>{
                            setShowModalConfirm(false)
                            setSelectedSeason({} as Season)
                            setSelectedDepartment({} as Department)
                        }}
                        onConfirm={onDeleteConfirm}
                        status2={statusSeason == ApiStatus.FETCHING ? true : false}
                    />
                )
            }
            {
                showModal && (
                    <SeasonModal
                        open={showModal}
                        closeModal={()=>{
                            setShowModal(false); 
                            setSelectedSeason({} as Season)
                            setSelectedDepartment({} as Department)
                        }}
                        rowSelected={seasonSelected}
                    />
                )
            }
        </>
    )
}