import { SeasonDataForm } from '../types/api/Season.type'
import { api } from './configs/axiosConfigs'

export const SeasonAPI = {

  getSeasons: async (idcompany : number,data?: any) => {
      const response = await api.get(
        '/seasons',
        {
            params: {
              idcompany,
              ...data
            }
        }
      ).then(response => response.data)
        .catch((error) => {
          console.error(error)
          return error.response.data
        })
      return response
  },

  getSeasonsByOwner: async (idowner : number) => {
      const response = await api.get(
        '/reservationBalance',
        {
            params: {
              idowner
            }
        }
      ).then(response => response.data)
        .catch((error) => {
          console.error(error)
          return error.response.data
        })
      return response
  },

  getSeasonsGroup: async (idcompany : number, data?: any) => {
    const response = await api.get(
      '/seasons/listGroup',
      {
          params: {
            idcompany,
            ...data
          }
      }
    ).then(response => response.data)
      .catch((error) => {
        console.error(error)
        return error.response.data
      })
    return response
},

  getSeasonsByDepartment: async (idcompany : number, data?: any) => {
      const response = await api.get(
        '/seasons/byDepartment',
        {
            params: {
              idcompany,
              ...data
            }
        }
      ).then(response => response.data)
        .catch((error) => {
          console.error(error)
          return error.response.data
        })
      return response
  },

  getActiveSeasons: async () => {
    const response = await api.get(
      '/seasons/actuality',
    ).then(response => response.data)
      .catch((error) => {
        console.error(error)
        return error.response.data
      })
    return response
  },

  createSeason: async (data: SeasonDataForm) => {
    const response = await api.post(
        '/seasons/register', data
    ).then(response => response.data)
    .catch((error) => {
        console.error(error)
        return error.response.data
    })
    return response
  },

  createSeasonMassive: async (data: any) => {
    const response = await api.post(
        '/seasons/registerMassive', data
    ).then(response => response.data)
    .catch((error) => {
        console.error(error)
        return error.response.data
    })
    return response
  },

  editSeason: async (idseason: number, data: SeasonDataForm) => {
    const response = await api.patch(
    `/seasons/${idseason}`,
    { ...data }
    ).then(response => response.data)
    .catch((error) => {
        return error.response.data
    })
    return response
  },

  updateMassive: async (data: any) => {
    const response = await api.patch(
        '/seasons/updateMassive', data
    ).then(response => response.data)
    .catch((error) => {
        console.error(error)
        return error.response.data
    })
    return response
  },

  deleteSeason: async (idseason: number) => {
      const response = await api.delete(
      `/seasons/${idseason}`
      ).then(response => response.data)
      .catch((error) => {
          return error.response.data
      })
      return response
  },

  deleteSeasonMassive: async (idcompany : number, data: any) => {
    const response = await api.post(
        '/seasons/deleteMassive',
          {
            idcompany,
            ...data
          }
    ).then(response => response.data)
    .catch((error) => {
        console.error(error)
        return error.response.data
    })
    return response
  },
}