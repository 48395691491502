import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { DepartmentSpent, DepartmentSpentType } from "../../types/slices/departmentSpentType";
import { RootState } from "../store";

const initialState: DepartmentSpentType =
{
  status: ApiStatus.FETCHED,
  departmentSpents: [],
  errorMessage: undefined,
  selecteddepartmentSpent: {} as DepartmentSpent
}

const departmentSpentSlice = createSlice({
    name: 'departmentSpent',
    initialState,
    reducers: {
        onFetchDepartmentSpents (state, { payload }: { payload: DepartmentSpent[] }) {
            state.status       = ApiStatus.FETCHED
            state.departmentSpents    = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedDepartmentSpent (state, { payload }: { payload: DepartmentSpent }) {
            state.selecteddepartmentSpent = payload
        }
    }
})

export const selectDepartmentSpentState = (state: RootState) => state.departmentSpentSlice
export default departmentSpentSlice.reducer

export const {
    onFetchDepartmentSpents,
    changeStatus,
    onSetSelectedDepartmentSpent
} = departmentSpentSlice.actions