import { useEffect, useState } from "react";
import { Grid, ButtonGroup, IconButton, Typography, Autocomplete, Button, TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material'
import CustomTable from '../../components/common/CustomTable/CustomTable';
import AppsIcon from '@mui/icons-material/Apps'
import ListAltIcon from '@mui/icons-material/ListAlt'
import { ReservationAdminModal } from "./components/ReservationAdminModal";
import SearchIcon from '@mui/icons-material/Search'
import { useReservationStore } from "../../hooks/useReservationStore";
import { ApiStatus } from "../../types/api/status";
import { CustomBackdrop } from "../../components/common/CustomBackdrop/CustomBackdrop";
import { Reservation } from "../../types/slices/reservationType";
import { ModalConfirm } from "../../components/common/ModalConfirm/ModalConfirm";
import { useDepartmentStore } from "../../hooks/useDepartmentStore";
import { ReservationParams } from "../../types/api/Reservation.type";
import { RoutesMap } from "../../types";
import { useNavigate } from "react-router-dom";
import { useOwnerStore } from "../../hooks/useOwnerStore";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CustomFullCalendar } from "./components/CustomFullCalendar";
import GroupsIcon from '@mui/icons-material/Groups';
import moment from "moment";

const theme = createTheme({
    typography: {
      fontSize: 12, // Tamaño de texto personalizado
    },
});

const columns = [
    { type: 'options', field: 'options', label: 'Opciones' },
    { type: 'text', field: 'code', label: 'Código' }, 
    { type: 'text', field: 'name_department', label: 'Departamento' }, 
    { type: 'text', field: 'name_owner', label: 'Propietario' }, 
    { type: 'text', field: 'startDate', label: 'Fecha de llegada', 
        format:(row) => row.startDate && moment(row.startDate).format('YYYY-MM-DD')  
    }, 
    { type: 'text', field: 'endDate', label: 'Fecha de salida', 
        format:(row) => row.endDate && moment(row.endDate).format('YYYY-MM-DD') },
    { type: 'text', field: 'reservation_date', label: 'Fecha de reserva', 
        format:(row) => row.reservation_date && moment(row.reservation_date).format('YYYY-MM-DD') 
    },
    { type: 'text', field: 'status', label: 'Estado', 
        format:(row) => row.status && row.status == '0' ? 'Desactivado' : (row.status == '1' ? 'Activo' 
            : (row.status == '2' ? 'Eliminado' : 'Anulado')) 
    },
    { type: 'detail', field: 'detail', label: '', align:'center' },
];

export const ReservationAdminList = () => {

    const navigate = useNavigate();

    const { status: statusReservation, reservations, selectedReservation, getReservations, setSelectedReservation, deleteReservation, cancelReservation } = useReservationStore()
    const { status: statusApiDepartment, departments, getDepartments } = useDepartmentStore()
    const { status: statusOwnerApi, owners, getOwners } = useOwnerStore()

    const [showModalReserve, setShowModalReserve] = useState<boolean>(false);
    const [showModalConfirm, setShowModalConfirm] = useState<boolean>(false);
    const [showModalCancel, setShowModalCancel] = useState<boolean>(false);
    const [typeList, setTypeList] = useState<any>('table');
    const [loading, setLoading] = useState(false);

    /* filter */
    const [filterBy, setFilterBy] = useState<any>('11');
    const [departamentSelected, setDepartamentSelected] = useState<any>('');
    const [ownerSelected, setOwnerSelected] = useState<any>('');
    const [specific_date, setspecific_date] = useState<any>('');
    const [initial_date, setinitial_date] = useState<any>('');
    const [end_date, setend_date] = useState<any>('');
    const [status_reservation, setstatus_reservation] = useState<any>('1');

    useEffect(() => {
        getReservationsApi({status: status_reservation} as ReservationParams)
    },[])

    const getReservationsApi = async(params:ReservationParams|null = null) => {
        try {
          setLoading(true);
          const response = await getReservations(params);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
    }

    const onEdit = (rowSelected) => {
        setSelectedReservation(rowSelected)
        navigate({ pathname: RoutesMap.RESERVATION_ADMIN_EDIT })
    }
    const onDelete = (rowSelected) => {
        setSelectedReservation(rowSelected)
        setShowModalConfirm(true);
    }
    const onCancel = (rowSelected) => {
        setSelectedReservation(rowSelected)
        setShowModalCancel(true);
    }
    const onDetails = (rowSelected) => {
        const reservation:any = {
            id: rowSelected.id,
            name_department: rowSelected.name_department,
            name_owner: rowSelected.name_owner,
            name_label: rowSelected.name_label,
            startDate: moment(rowSelected.startDate).format("LL"),
            endDate: moment(rowSelected.endDate).format("LL"),
            reservation_date: moment(rowSelected.reservation_date).format('YYYY-MM-DD'),
            iddepartment: rowSelected.iddepartment,
            idowner: rowSelected.idowner,
            name_reservationSpecial: rowSelected.name_reservationSpecial,
            idlabel: rowSelected.idlabel,
            check_in: rowSelected.check_in,
            name_invited: rowSelected?.name_invited ? rowSelected?.name_invited : '',
            check_out: rowSelected.check_out,
        }
        setSelectedReservation(reservation)
        setShowModalReserve(true);
    }

    const onHelper = (rowSelected) => {
        setSelectedReservation(rowSelected)
        navigate({ pathname: RoutesMap.RESERVATION_ADMIN_INVITED })
    }

    const onCancelConfirm = async() => {
        if (selectedReservation.id) {
            const response = await cancelReservation(selectedReservation.id)
            if(response){
                setSelectedReservation({} as Reservation)
                setShowModalCancel(false)
                getReservationsApi({status: status_reservation} as ReservationParams)
            }else{
                return false;
            }
        }
    }

    const onDeleteConfirm = async () => {
        if (selectedReservation.id) { 
            const response = await deleteReservation(selectedReservation.id)
            if(response){
                setSelectedReservation({} as Reservation)
                setShowModalConfirm(false)
                getReservationsApi({status: status_reservation} as ReservationParams)
            }else{
                return false;
            }
        }
    }

    const onFilterBy = (e) => {
        const type = e.target.value;
        setFilterBy(type)
        switch (type) {
            case '08':
                getReservationsApi({special_date: true} as ReservationParams)
                break;
            case '09':
                getDepartments()
                break;
            case '10':
                getOwners()
                break;
            default:
                break;
        }
    }

    const onListReservation = () => {
        switch (filterBy) {
            case '01':
                break;
            case '02':
                if(specific_date) getReservationsApi({specific_date: specific_date, type: 3} as ReservationParams)
                break;
            case '03':
                if(specific_date) getReservationsApi({specific_date: specific_date, type: 1} as ReservationParams)
                break;
            case '04':
                if(specific_date) getReservationsApi({specific_date: specific_date, type: 2} as ReservationParams)
                break;
            case '05':
                if(initial_date && end_date) getReservationsApi({initial_date: initial_date, end_date:end_date, type: 3} as ReservationParams)
                break;
            case '06':
                if(initial_date && end_date) getReservationsApi({initial_date: initial_date, end_date:end_date, type: 1} as ReservationParams)
                break;
            case '07':
                if(initial_date && end_date) getReservationsApi({initial_date: initial_date, end_date:end_date, type: 2} as ReservationParams)
                break;
            case '09':
                if(departamentSelected) getReservationsApi({iddepartment: departamentSelected.id} as ReservationParams)
                break;
            case '10':
                if(ownerSelected) getReservationsApi({idowner: ownerSelected.id} as ReservationParams)
                break;
            case '11':
                if(status_reservation) getReservationsApi({status: status_reservation} as ReservationParams)
                break;
            default:
                break;
        }
    }

    const onCancelFilter = () => {
        setFilterBy('11')
        setstatus_reservation('1')
        setspecific_date('')
        setinitial_date('')
        setend_date('')
        setOwnerSelected('')
        setDepartamentSelected('')
        getReservationsApi({status: '1'} as ReservationParams)
    }

    return (
        <>
            {/* { statusReservation === ApiStatus.FETCHING && <CustomBackdrop open={true} /> } */}
            { statusApiDepartment === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }
            { statusOwnerApi === ApiStatus.FETCHING && <CustomBackdrop open={true} /> }

            <Grid container>
                <Grid item container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'end', flexWrap: 'wrap', marginBottom:'-5px'}}>
                    <ButtonGroup variant="contained" aria-label="button-group-custom-table" style={{padding: "0px 5px", color:"#808080"}}>
                        <IconButton size="small" color="inherit" aria-label="edit" onClick={() => {
                            setTypeList('table'); setSelectedReservation({} as Reservation)}}
                        >
                            <ListAltIcon fontSize='small' />
                        </IconButton>
                        <IconButton size="small" color="inherit" aria-label="edit" onClick={() => {setTypeList('other'); 
                            setSelectedReservation({} as Reservation)}}
                        >
                            <AppsIcon fontSize='small' />
                        </IconButton>
                    </ButtonGroup>
                </Grid>
                {
                    typeList == 'table' && (
                        <>
                        <Grid item container sx={{ alignItems: 'center', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginBottom:'10px'}}>
                            <Typography variant='h6' component='h1' gutterBottom color='#808080' className="tittle" sx={{ mt: 1, ml:1 , fontWeight:600}}>
                                {('Listado de Reservas').toUpperCase()}
                            </Typography>
                        </Grid>
                        <Grid item container xs={12} direction="row" justifyContent="space-between" alignItems="center" sx={{border: 'solid 1px #e9e2e2', borderRadius:'10px'}}>
                            <Grid xs={12} sm ={12} md={3} lg={3} xl={3} sx={{padding: '7px'}}>
                                <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" style={{ fontSize: "13px" }}>Tipo de filtrado</InputLabel>
                                <Select
                                    id="combo-box-department"
                                    size="small"
                                    value={filterBy}
                                    onChange={(event) => {onFilterBy(event)}}
                                    sx={{width:'240px', fontSize:"12px"}}
                                    label='Tipo de filtrado'
                                >
                                    <MenuItem value={'01'}>{"Por código"}</MenuItem>
                                    <MenuItem value={'02'}>{"Por fecha específica (reserva)"}</MenuItem>
                                    <MenuItem value={'03'}>{"Por fecha específica (llegada)"}</MenuItem>
                                    <MenuItem value={'04'}>{"Por fecha específica (salida)"}</MenuItem>
                                    <MenuItem value={'05'}>{"Por rango de fechas (reserva)"}</MenuItem>
                                    <MenuItem value={'06'}>{"Por rango de fechas (llegada)"}</MenuItem>
                                    <MenuItem value={'07'}>{"Por rango de fechas (salida)"}</MenuItem>
                                    <MenuItem value={'08'}>{"Por fechas especiales"}</MenuItem>
                                    <MenuItem value={'09'}>{"Por Departamento"}</MenuItem>
                                    <MenuItem value={'10'}>{"Por Propietario"}</MenuItem>
                                    <MenuItem value={'11'}>{"Por estado"}</MenuItem>
                                </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} sm ={12} md={9} lg={9} xl={9} item container display='flex' 
                                // justifyContent='end' 
                                sx={{
                                    alignItems: 'center', 
                                    justifyContent:{ xs: 'start', sm: 'start', md:'end', lg:'end', xl:'end'} 
                                }}
                            >
                                <Grid item >
                                    {
                                        filterBy == '01' && (
                                            <TextField
                                                id="date"
                                                type="text" 
                                                name="date"
                                                size="small"
                                                onChange={(e)=>{getReservations({code: e.target.value} as ReservationParams)}}
                                                sx={{width:'250px', padding: '8px'}}
                                                placeholder="Escribe el código"
                                                inputProps={{ style: { fontSize: "12px" } }}
                                            />
                                        )
                                    }
                                    {
                                        (filterBy == '02' || filterBy == '03' || filterBy == '04') && (
                                            <TextField
                                                id="date"
                                                type="date" 
                                                name="date"
                                                size="small"
                                                value={specific_date}
                                                onChange={(e)=>{setspecific_date(e.target.value)}}
                                                sx={{width:'240px', padding: '8px'}}
                                                inputProps={{ style: { fontSize: "12px" } }}
                                            />
                                        )
                                    }
                                    {
                                        (filterBy == '05' || filterBy == '06' || filterBy == '07')  && (
                                            <>
                                            <TextField
                                                id="start_date"
                                                type="date" 
                                                name="start_date"
                                                size="small"
                                                value={initial_date}
                                                onChange={(e)=>{setinitial_date(e.target.value)}}
                                                label={'fecha de inicio'}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                sx={{width:'240px', padding: '8px'}}
                                                inputProps={{ style: { fontSize: "12px" } }}
                                            />
                                            <TextField
                                                id="end_date"
                                                type="date" 
                                                name="end_date"
                                                size="small"
                                                value={end_date}
                                                onChange={(e)=>{setend_date(e.target.value)}}
                                                label={'fecha final'}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                sx={{width:'240px', padding: '8px'}}
                                                inputProps={{ style: { fontSize: "12px" } }}
                                            />
                                            </>
                                        )
                                    }
                                    {
                                        filterBy == '09' && (
                                            <ThemeProvider theme={theme}>
                                            <Autocomplete
                                                id="combo-box-department"
                                                size="small"
                                                options={departments}
                                                getOptionLabel={(departament) => departament.name}
                                                renderInput={(params) => <TextField {...params} placeholder="buscar..." label="Departamento" size="small" InputLabelProps={{
                                                    shrink: true,
                                                }}/>}
                                                sx={{width:'240px', padding: '8px',}}
                                                onChange={(e, newValue) => {
                                                    setDepartamentSelected(newValue);
                                                }}
                                            />
                                            </ThemeProvider>
                                        )
                                    }
                                    {
                                        filterBy == '10' && (
                                            <ThemeProvider theme={theme}>
                                            <Autocomplete
                                                id="combo-box-department"
                                                size="small"
                                                options={owners}
                                                getOptionLabel={(owner) => owner.name}
                                                renderInput={(params) => <TextField {...params} placeholder="buscar..." label="Propietario" size="small" InputLabelProps={{
                                                    shrink: true,
                                                }}/>}
                                                sx={{width:'240px', padding: '8px'}}
                                                onChange={(e, newValue) => {
                                                    setOwnerSelected(newValue);
                                                }}
                                            />
                                            </ThemeProvider>
                                        )
                                    }
                                    {
                                        filterBy == '11' && (
                                            <Grid sx={{padding: '7px'}}>
                                            <Select
                                                id="combo-box-department"
                                                size="small"
                                                value={status_reservation}
                                                onChange={(e) => {setstatus_reservation(e.target.value)}}
                                                sx={{width:'240px', fontSize:"12px"}}
                                            >
                                                <MenuItem value={'1'}>{'Activos'}</MenuItem>
                                                <MenuItem value={'2'}>{'Eliminados'}</MenuItem>
                                                <MenuItem value={'3'}>{'Anulados'}</MenuItem>
                                            </Select>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                                
                                <Grid item display='flex' justifyContent='space-between' paddingRight='5px'>
                                    {
                                        (filterBy != '01' && filterBy != '08') && (
                                            <Button 
                                                startIcon={<SearchIcon/>} 
                                                onClick={onListReservation} 
                                                size="small" 
                                                variant="contained" 
                                                sx={{ height:'35px',backgroundColor: '#212D39', color: '#fff', textTransform: 'none', padding: '4px 10px', "&:hover": {backgroundColor: "#212D39" }, fontSize:"12px", marginLeft:'6px', marginRight:'3px' }}>
                                                    Filtrar busqueda
                                            </Button>
                                        )
                                    }
                                    {
                                        (filterBy != '01') && (
                                            <Button 
                                                onClick={onCancelFilter} 
                                                size="small" 
                                                variant="contained" 
                                                sx={{ height:'35px', backgroundColor: '#5a5959', color: '#fff', textTransform: 'none', padding: '4px 10px', "&:hover": {backgroundColor: "#a79f9f" }, fontSize:"12px" }}>
                                                    Cancelar filtro
                                            </Button>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                
                        <CustomTable 
                            columns={columns} 
                            loading={loading}
                            rows={reservations || []}
                            onRowClick={() => {}}
                            hasOptions
                            onDetails={onDetails}
                            onDelete={onDelete}
                            // onEdit={onEdit}
                            onCancel={onCancel}
                            onHelper={onHelper}
                            icon_helper={<GroupsIcon fontSize="small" />}
                            tooltip_helper={'Invitados'}
                        />
                        </>
                    )
                }
                {
                    typeList == 'other' && (
                        <>
                            <CustomFullCalendar />
                        </>
                    )
                }
                
            </Grid>
            {
                showModalConfirm && (
                    <ModalConfirm
                        open={showModalConfirm}
                        closeModal={()=>{
                            setShowModalConfirm(false)
                            setSelectedReservation({} as Reservation)
                        }}
                        onConfirm={onDeleteConfirm}
                        status2={statusReservation == ApiStatus.FETCHING ? true : false}
                    />
                )
            }
            {
                showModalCancel && (
                    <ModalConfirm
                        title={'¿Esta seguro de anular la reservación seleccionada?'}
                        text={'Si estas seguro, haz click en el boton "aceptar" y la reserva será anulada'}
                        open={showModalCancel}
                        closeModal={()=>{
                            setShowModalCancel(false)
                            setSelectedReservation({} as Reservation)
                        }}
                        onConfirm={onCancelConfirm}
                        status2={statusReservation == ApiStatus.FETCHING ? true : false}
                    />
                )
            }
            {
                showModalReserve && (
                    <ReservationAdminModal
                        open={showModalReserve}
                        closeModal={()=>{
                            setShowModalReserve(false); 
                            setSelectedReservation({} as Reservation)
                        }}
                        rowSelected={selectedReservation}
                    />
                )
            }
        </>
    )
}