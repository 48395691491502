import { createSlice } from "@reduxjs/toolkit";
import { ApiStatus } from "../../types/api/status";
import { Company, CompanyType } from "../../types/slices/companyType";
import { RootState } from "../store";

const initialState: CompanyType =
{
  status: ApiStatus.FETCHED,
  companies: [],
  errorMessage: undefined,
  selectedCompany: {} as Company,
  action: '',
}

const companySlice = createSlice({
    name: 'company',
    initialState,
    reducers: {
        onFetchCompanies (state, { payload }: { payload: Company[] }) {
            state.status       = ApiStatus.FETCHED
            state.companies    = payload
            state.errorMessage = undefined
        },
        changeStatus (state, { payload }: { payload: ApiStatus }) {
            state.status = payload
        },
        onSetSelectedCompany (state, { payload }: { payload: Company }) {
            state.selectedCompany = payload
        },
        setAction (state, { payload }: { payload: string }) {
            state.action = payload
        },
    }
})

export const selectCompanyState = (state: RootState) => state.companySlice
export default companySlice.reducer

export const {
    onFetchCompanies,
    changeStatus,
    onSetSelectedCompany,
    setAction,
} = companySlice.actions
  