import { Modal } from "../../../components/common/Modal/Modal"
import { ModalBody } from "../../../components/common/Modal/ModalBody"
import { useEffect, useState } from "react"
import { Formik } from "formik";
import { Box, Grid, Divider, FormLabel, Autocomplete, TextField } from "@mui/material"
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import { ModalHeader } from "../../../components/common/Modal/ModalHeader"
import { ModalFooter } from "../../../components/common/Modal/ModalFooter"
import { useGuestStore } from "../../../hooks/useGuestStore";
import { Guest, typeGuest } from "../../../types/slices/guestType";
import { useOwnerStore } from "../../../hooks/useOwnerStore";
import { CustomSnackbar } from "../../../components/common/CustomSnackbar/CustomSnackbar";
import { GuestAPI } from "../../../apis/GuestAPI";
import { useAssignmentStore } from "../../../hooks/useAssignmentStore";

type Values = {
  rut: string,
  name: string,
  email: string,
  phone: string,
}

export const CessionAddThirdModal : React.FC<any> = (props) : JSX.Element | any => {

  const { open, closeModal, setTypeAssignee } = props
  const { typeGuest, guests, selectedGuestType, selectedGuest, getGuestsThird, getGuestsByOwner, setSelectedGuest, setSelectedGuestType } = useGuestStore();
  const { ownerAssignor } = useOwnerStore();
  const { selectedAssignment } = useAssignmentStore();
  
  const [loadingGuest, setLoadingGuest] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [submitExisting, setSubmitExisting] = useState<boolean>(false);
  const [value, setValue] = useState('1');
  const [data, setData] = useState<Values>({
    rut: "",
    name: "",
    email: "",
    phone: "",
  });
  
  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const validateForm = (values) => {
    let errors: any = {};
    let regRut = /^[0-9]{8}[-|‐]{1}[0-9kK]{1}$/;
    let regEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!values.rut) errors.rut = "Campo requerido";
    if (values.rut && !(regRut.test(values.rut))) errors.rut = "RUT no válido";
    if(!values.name) errors.name = "Campo requerido";
    if(!values.email) errors.email = "Campo requerido";
    if (values.email && !(regEmail.test(values.email))) errors.email = "Correo no válido";
    
    return errors;
  }
  
  const onSubmit = async (formData) => {
    setLoadingSubmit(true);
    const data = {
      ...formData,
      idowner: null,
      idguest_type: 5, // tercero
    }
    const response = await GuestAPI.createGuest(data);
    
    if (response && !response.status) {
      CustomSnackbar('error', response.data.message);
      setTypeAssignee && setTypeAssignee();
    }

    if (response && response.status) {
      CustomSnackbar('success', response.data.message);
      setSelectedGuest(response.data.detail);
      setTypeAssignee && setTypeAssignee(2);
      getGuestsThird();
      closeModal && closeModal();
    }
    setLoadingSubmit(false);
  }

  const handleSelectGuest = () => {
    setTypeAssignee && setTypeAssignee(2);
    closeModal && closeModal();
  }

  const handleGuestType = async(newValue:typeGuest|null) => {
    if (newValue) {
      setLoadingGuest(true);
      const response = await getGuestsByOwner(newValue.id);
      setLoadingGuest(false);
    }
  }

  const validateEdit = async () => {
    if (!!selectedAssignment.id) {
      if (selectedAssignment.type_assignee === "2") {
        setSelectedGuestType(typeGuest.find((item) => item.id === selectedAssignment.idguest_type_invited_owner) || {} as typeGuest);
        const response = await getGuestsByOwner(selectedAssignment.idowner_assignor);
        setSelectedGuest(guests.find((item) => item.id === selectedAssignment.idinvited_owner) || {} as Guest);
      }
    }
  }

  useEffect(() => {
    validateEdit()
    
  },[])

  return(
    <Modal open={open} handleClose={closeModal} disableEscapeKeyDown disableBackdropClick size="sm">
      <ModalHeader
        text={'Agregar Tercero'}
        className='positionElements'
        onCancel={closeModal}
      >
      </ModalHeader>
      <ModalBody>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          {/* <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                <Tab value="1" label="Existente"/>
                <Tab value="2" label="Registrar"/>
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: 0 }}>
              <Grid container spacing={2} sx={{ mt:2 }}>
                <Grid item xs={12} sm={12} md={12}>
                  <FormLabel>Tercero:</FormLabel>
                  <Autocomplete
                    id="combo-box-department"
                    size="small"
                    options={guests || []}
                    value={!!selectedGuest?.id ? selectedGuest : null}
                    fullWidth
                    loading={loadingGuest}
                    getOptionLabel={(guest) => guest.name}
                    renderInput={(params) => <TextField {...params} label="" size="small"  />}
                    onChange={(e, newValue) => {
                      setSelectedGuest(newValue || {} as Guest);
                      setSubmitExisting(!!newValue);
                    }}
                    // disabled={!!!selectedGuestType.id}
                  />
                </Grid>
                <Divider />
                <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'center'}}>
                  <ModalFooter 
                    confirmText={'Guardar'}
                    cancelText={"Cancelar"}
                    disabledConfirmText={!submitExisting}
                    onConfirm={()=>handleSelectGuest()}
                    onCancel={closeModal}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="2" sx={{ padding: 0 }}> */}
              <Formik
                initialValues={data}
                enableReinitialize
                validate={ (values) => validateForm(values) }
                onSubmit={onSubmit}
              >
              {({values, errors, touched, handleSubmit, handleChange, setFieldValue}) => {
                return (
                  <Grid container spacing={2} sx={{ mt:2 }}>
                    <Grid item xs={12} md={6}>
                      <FormLabel>RUT</FormLabel>
                      <TextField
                        id="rut"
                        type="text"
                        value={values.rut}
                        onChange={handleChange}
                        required
                        placeholder="Ingrese un RUT"
                        name="rut"
                        fullWidth
                        size="small"
                        error={errors.rut && touched.rut ? true : false}
                        helperText={errors.rut && touched.rut ? errors.rut : ''}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormLabel>Nombre</FormLabel>
                      <TextField
                        id="name"
                        type="text" 
                        value={values.name}
                        onChange={handleChange}
                        required
                        placeholder="Ingrese un Nombre"
                        name="name"
                        fullWidth
                        size="small"
                        error={errors.name && touched.name ? true : false}
                        helperText={errors.name && touched.name ? errors.name : ''}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormLabel>Correo</FormLabel>
                      <TextField
                        id="email"
                        type="text" 
                        value={values.email}
                        onChange={handleChange}
                        required
                        placeholder="Ingrese un Correo"
                        name="email"
                        fullWidth
                        size="small"
                        error={errors.email && touched.email ? true : false}
                        helperText={errors.email && touched.email ? errors.email : ''}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormLabel>Telefono</FormLabel>
                      <TextField
                        id="phone"
                        type="text" 
                        value={values.phone}
                        onChange={(e) => {
                            const regex = /^[0-9\b]+$/;
                            if (e.target.value === "" || regex.test(e.target.value)) {
                              setFieldValue('phone', e.target.value)
                            }
                        }}
                        placeholder="Ingrese un Telefono"
                        name="phone"
                        fullWidth
                        size="small"
                      />
                    </Grid>
                    <Divider />
                    <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'center'}}>
                      <ModalFooter 
                        confirmText={'Guardar'}
                        cancelText={"Cancelar"}
                        onConfirm={() => handleSubmit()}
                        loadingConfirmText={loadingSubmit}
                        onCancel={closeModal}
                      />
                    </Grid>
                  </Grid>
                )
              }} 
              </Formik>
            {/* </TabPanel>
          </TabContext> */}
        </Box>
      </ModalBody>
    </Modal>
  )
}