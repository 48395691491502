import { createSlice } from "@reduxjs/toolkit";
import { AuthStatus, User, UserData, UserType } from "../../types";

const initialState: UserType =
{
  status: AuthStatus.NOT_AUTHENTICATED,
  user: {
    id: 0,
    rut: '',
    iduser_type: 0,
    status_confirm: '',
    url_redirect:'',
    status: 0,
    userType: null,
    data: {} as UserData,
    views: [] ,
    modules: []
  },
  errorMessage: undefined
}

const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers:{
    onVerifying (state) {
      state.status        = AuthStatus.VERIFYING
      state.user          = initialState.user
      state.errorMessage  = undefined
    },
    onLogin (state, {payload}: {payload: User}) {
      state.status        = AuthStatus.AUTHENTICATED
      state.user          = payload
      state.errorMessage  = undefined
    },
    onLogout ( state, {payload}: {payload: string | undefined} ) {
      state.status        = AuthStatus.NOT_AUTHENTICATED
      state.user          = initialState.user
      state.errorMessage  = payload
    },
    onRefreshToken ( state, { payload } : { payload }) {
      state.status                = AuthStatus.AUTHENTICATED
      state.user.id               = payload.id
      state.user.iduser_type      = payload.iduser_type
      state.user.rut              = payload.rut
      state.user.status           = payload.status
      state.user.status_confirm   = payload.status_confirm

      state.errorMessage  = undefined
    },
    onChangeAuthStatus(state , { payload } : { payload: AuthStatus }) {
      state.status = payload
    }
  }
})

export const { onVerifying, onLogin, onLogout, onRefreshToken, onChangeAuthStatus } = authSlice.actions;
export const selectAuth = (state:any) => state.authSlice;
export default authSlice.reducer;