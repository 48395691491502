import { api } from "./configs/axiosConfigs"

export const RenditionSpentIndividualAPI = {
    getRenditionSpentIndividuals: async (idcompany: number, data?: any) => {
        const response = await api.get(
        '/reportIndividual',
        {
            params: {
                ...data,
                idcompany
            }
        }
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    create: async (data: any) => {
        const response = await api.post(
            '/reportIndividual/register', data
        ).then(response => response.data)
        .catch((error) => {
            console.error(error)
            return error.response.data
        })
        return response
    },
    edit: async (id: number, data: any) => {
        const response = await api.post(
        `/reportIndividual/update/${id}`, data
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    },
    delete: async (id: number, idcompany: number) => {
        const response = await api.delete(
        `/reportIndividual/${id}`,
        {
            params: {
                idcompany
            }
        }
        ).then(response => response.data)
        .catch((error) => {
            return error.response.data
        })
        return response
    }
}